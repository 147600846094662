import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faUpload, faEdit, faComment, faFileAlt, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { DataGrid } from '@material-ui/data-grid';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import Sidecr1 from '../components/layout/Sidecr1';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import Spinner from 'react-bootstrap/Spinner';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const coursenameref=useRef();
    const coursecoderef=useRef();
    const yearref=useRef();
    const activityref=useRef();
    const descriptionref=useRef();

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState([]);
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());
    const [format, setFormat] = useState(false);
    const [qno, setQno] = useState('');

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;
    //const name=global1.name;

    const [isUploading, setIsuploading] = useState(false);

    

    const columns = [
        // { field: '_id', headerName: 'ID', width: 70 },
        { field: 'student', headerName: 'Student', width: 130 },
        { field: 'regno', headerName: 'Reg no', width: 130 },
      ];

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    const edit = (id, program, programcode, course, coursecode, academicyear, intervention, student, regno,  remdate, type, status1) => {
        if(status1=='Accepted') {
            alert('This document is accepted. Please contact IQAC coordinator to allow editing');
            return;
        }
        global1.remedialid=id;
        global1.program=program;
        global1.programcode=programcode;
        global1.course=course;
        global1.coursecode=coursecode;
        global1.academicyear=academicyear;
        global1.intervention=intervention;
        global1.student=student;
        global1.studregno=regno;
        global1.remdate=remdate;
        global1.type=type;
        
        history.replace('/editremedial');
    }

    const addperiod = () => {
        history.replace('/addperiod');
    }

    const addstudents = () => {
        // global1.courseid=courseid;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/addstudents');
    }

    function getaffiliated(affvalue) {
        if(affvalue=="1") {
            return "Yes";
        } else {
            return "No";
        }
    }

    const upload = (id, status1) => {
        if(status1=='Accepted') {
            alert('This document is accepted. Please contact IQAC coordinator to allow editing');
            return;
        }
        global1.field1=id;
        global1.criteria='1';
        global1.metric='1.1.3';
        global1.collection1='employability';
        global1.type='Employability';
        global1.pagenav='viewemployability';
        
        // history.replace('/selectawsconfig');
        history.replace('/selectmetricrule');
    }

    const list = (id) => {
        global1.field1=id;
        global1.criteria='1';
        global1.metric='1.1.3';
        global1.collection1='employability';
        global1.type='Employability';
        global1.pagenav='viewemployability';
        
        history.replace('/viewsupportingdoc');
    }

    

   

    let tutorials = [];
    const searchApi = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getremedialbyfac', {
            params: {
                user: user,
                token: token,
                colid: colid
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        // localStorage.setItem("kempb", JSON.stringify(response.data.data.classes));

       

        
        setResults(response.data.data.classes);
        setLink(response.data.data.link);
        //alert('format ' + format);
        try{

            formattable();
        } catch(err) {

        }
        
       
        //alert(results.length);
       
    };

    function formattable() {
        if (!format) {
            $('#example').DataTable(
                {
                    pagingType: 'full_numbers',
                      pageLength: 5,
                      processing: true,
                      dom: 'Bfrtip',
                          buttons: ['copy', 'csv', 'print'
                          ]
                }
            );
            setFormat(true);

        }
    }

   

    const deleteenrolment = async (enrolmentid,status1) => {
        if(status1=='Accepted') {
            alert('This document is accepted. Please contact IQAC coordinator to allow editing');
            return;
        }
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteremedial', {
            params: {
                id: enrolmentid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    // const getlocal = () => {
    //     let collection=localStorage.getItem("kempb");
    //     if(!collection) {

    //     } else {
    //         setResults(JSON.parse(collection));

    //     }
        
    // }

    let { id } = useParams();
    function useQuery() {
        return new URLSearchParams(useLocation().search);
      }

      let query = useQuery();

    useEffect(() => {
        checklogin();
        //alert(id + ' ' + query.get("name"));
        setQno(query.get("name"));
        //formattable();
        //search1();
        //getexamlist();
        // getlocal();
        searchApi('');
        //$('#example').DataTable();
        //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable();
    //     },
    //     1000
    //     );
    // });
    
    //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable(
    //         {
    //             pagingType: 'full_numbers',
    //               pageLength: 5,
    //               processing: true,
    //               dom: 'Bfrtip',
    //                   buttons: ['copy', 'csv', 'print'
    //                   ]
    //         }
    //     );
    //     } ,
    //     1000
    //     );
    // });
        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);


    const uploadlink = async (link1, id1, year) => {
        link1=link1 + '?criteria=1&Section=1.1.3&Subsection=Employability&field1=' + id1 + '&Year=' + year; 
        var win = window.open(link1, '_blank');
        win.focus();
      };

      const list1 = async (id1) => {
        //var link1='http://localhost:3001/viewsupportingdocquery';
        var link1=window.location.origin + '/viewsupportingdocquery';
        link1=link1 + '?field1=' + id1 + '&colid=' + colid + '&user=' + user + '&name=' + name + '&token=' + token; 
        var win = window.open(link1, '_blank');
        win.focus();
      };

      const dateformat1 = (date1) => {
          const dt1=new Date(date1);
          var month=dt1.getMonth() + 1;
          return dt1.getDate() + '/' + month + '/' + dt1.getFullYear();
      }


    
   

    

    const width=window.innerWidth;
    const mdivwidth=width - 100;

    const addlink='/addempoyability' + qno

   
       
        //alert('loaded');
  

    


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width: 60 }}>
                        <Sideicon />
                    </td>
                    {/* <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <Sidecr1 />
                    </td> */}
                   
                    <td> 
                        <TopNavigation />

                    
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
            <p style={{ fontSize: 18 }}>{qno} View Remedial Coaching / Advanced Learner support etails</p>
            <hr />
            <table>
                <tr>
                    <td>
                    <Link to={'/addremedial?name=' + qno} className="btn btn-dark"> Add data</Link>
                    </td>
                    <td width="20px"></td>
                    <td>
                    {/* <Link to={'/addemployabilitybulk?name=' + qno} className="btn btn-dark"> Bulk add data</Link> */}

                    </td>
                </tr>
            </table>
            
           
    
           <br />
       <br />
        {isUploading ? (
				<div style={{ alignItems: 'center', marginTop: 30, marginBottom: 60}}>
                   <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
				</div>
			) : (
                <div></div>
			)} 
     
<Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
     
      <th>Department</th>
      <th>Program Name</th>
      <th>Program Code</th>
      <th>Course Name</th>
      <th>Course Code</th>
      <th>Year</th>
      <th>Intervention</th>
      <th>Student</th>
      <th>Reg no</th>
      <th>Date</th>
      <th>Type</th>
      <th>Status</th>
      <th>Comments</th>
      <th>Action</th>
    </tr>
    </thead>
    <tbody>
    

    {results.map((meetup3) => {
                return (
                    <tr>
                        <td>
                            {meetup3.department}
                        </td>
                        <td>
                            {meetup3.program}
                        </td>
                        <td>
                            {meetup3.programcode}
                        </td>
                        <td>
                        {meetup3.course}
                        </td>
                        <td>
                            {meetup3.coursecode}
                        </td>
                        <td>
                        {meetup3.academicyear}
                        </td>
                        <td>
                            {meetup3.intervention}
                        </td>
                        <td>
                        {meetup3.student}
                        </td>
                        <td>
                        {meetup3.regno}
                        </td>
                        <td>
                        {dateformat1(meetup3.remdate)}
                        </td>
                        <td>
                            {meetup3.type}
                        </td>
                        <td>
                            {meetup3.status1}
                        </td>
                        <td>
                            {meetup3.comments}
                        </td>
                     
                        <td>
                      
                        <Button variant="outline-danger" onClick={() => deleteenrolment(meetup3._id, meetup3.status1)}>
                        <FontAwesomeIcon icon={faTrash} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                            </Button>
                            <Button variant="outline-danger" onClick={() => edit(meetup3._id,meetup3.program, meetup3.programcode, meetup3.course,meetup3.coursecode, meetup3.academicyear, meetup3.intervention,meetup3.student, meetup3.regno,meetup3.remdate, meetup3.type, meetup3.status1)}>
                        <FontAwesomeIcon icon={faEdit} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                            </Button>
                            <Button variant="outline-danger" onClick={() => upload(meetup3._id, meetup3.status1)}>
                        <FontAwesomeIcon icon={faUpload} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                        Upload
                            </Button>
                            {/* <Button variant="outline-danger" onClick={() => list(meetup3._id)}>
                        <FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                        List
                            </Button> */}
                             <Button variant="outline-danger" onClick={() => list1(meetup3._id)}>
                        <FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                        List
                    </Button> 
                        </td>
                
                    
                
                
                   

                   
                                </tr>
              

                );
            })}
            </tbody>
            </Table>

            <br /><br />
            <h4>Document List</h4>
        <hr />
        Note: Upload only once against one student for the entire class. Do not upload same document for every student for the same class. Attendance list and time table would be same for all students in the same class.
        <br /><br />
1. Only One time table for the entire class<br />
2. Only one attendance list for the entire class





        


            

       
        
        </div>
        <FooterNav1 />
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;