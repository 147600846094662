import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faEdit, faComment, faFileAlt, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { DataGrid } from '@material-ui/data-grid';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import SidemetricNavigation from '../components/layout/SidemetricNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import Spinner from 'react-bootstrap/Spinner';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    
    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState([]);
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());
    const [format, setFormat] = useState(false);

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;
    //const name=global1.name;

    const [isUploading, setIsuploading] = useState(false);

    const columns = [
        // { field: '_id', headerName: 'ID', width: 70 },
        { field: 'student', headerName: 'Student', width: 130 },
        { field: 'regno', headerName: 'Reg no', width: 130 },
      ];

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    
    const addperiod = () => {
        history.replace('/addperiod');
    }  

    const addstudents = () => {
        // global1.courseid=courseid;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/addstudents');
    }

    let tutorials = [];
    const searchApi = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/gethigheredubyadmin', {
            params: {
                user: user,
                token: token,
                colid: colid
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        localStorage.setItem("p_highereduadmin", JSON.stringify(response.data.data.classes));

       

        
        setResults(response.data.data.classes);
        setLink(response.data.data.link);
        //alert('format ' + format);
        try{

            formattable();
        } catch(err) {

        }
        
       
        // alert(results.length);
       
    };

    function formattable() {
        if (!format) {
            $('#example').DataTable(
                {
                    pagingType: 'full_numbers',
                      pageLength: 5,
                      processing: true,
                      dom: 'Bfrtip',
                          buttons: ['copy', 'csv', 'print'
                          ]
                }
            );
            setFormat(true);

        }
    }

   

    const deleteenrolment = async (enrolmentid) => {
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deletehigheredubyfac', {
            params: {
                id: enrolmentid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const getlocal = () => {
        let collection=localStorage.getItem("p_highereduadmin");
        if(!collection) {

        } else {
            setResults(JSON.parse(collection));

        }
        
    }


    useEffect(() => {
        checklogin();
        //search1();
        //getexamlist();
        //getlocal();
        //searchApi('');
        formattable();
        //$('#example').DataTable();
        //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable();
    //     },
    //     1000
    //     );
    // });
    
    //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable(
    //         {
    //             pagingType: 'full_numbers',
    //               pageLength: 5,
    //               processing: true,
    //               dom: 'Bfrtip',
    //                   buttons: ['copy', 'csv', 'print'
    //                   ]
    //         }
    //     );
    //     } ,
    //     1000
    //     );
    // });
        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);


    const width=window.innerWidth;
    const mdivwidth=width - 330;

        //alert('loaded');
  

    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width: 60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidemetricNavigation />
                    </td>
                   
                    <td> 
                        <TopNavigation />

                    
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
            <p style={{ fontSize: 18 }}>NBA UG Tier 1</p>
            <hr />
            
       <br />
        {isUploading ? (
				<div style={{ alignItems: 'center', marginTop: 30, marginBottom: 60}}>
                   <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
				</div>
			) : (
                <div></div>
			)} 
     
<Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
    <th>Criteria No.</th>
    <th>Criteria Name</th>
    <th>Metric No.</th>
    <th>Metric Name</th>
    <th>Links</th>
</tr>
</thead>
<tbody>
    
                    <tr>
                        <td>1</td>
                        <td>Course Outcomes and Program Outcomes</td>
                        <td>1.1</td>
                        <td> State the Vision, Mission of the Department and Institute and Program Educational Objectives</td>
                        <td>
                        <Link to='/viewnbaug11?name=1.1&question=Vision and Mission and PEO&accreditation=NBAUGS&documents=Vision,Mission, PEO list' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Course Outcomes and Program Outcomes</td>
                        <td>1.2</td>
                        <td> Indicate where the Vision, Mission and PEOs are published and disseminated among stakeholders </td>
                        <td>
                        <Link to='/viewnbaug11?name=1.2&question=Dissemination of Vision, Mission, PEO&accreditation=NBAUGS&documents=Weblink, Process document' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Course Outcomes and Program Outcomes</td>
                        <td>1.3</td>
                        <td> Establish consistency of PEOs with Mission of the Department  </td>
                        <td>
                        <Link to='/viewnbaug11?name=1.3&question=Consistency of PEO with Mission&accreditation=NBAUGS&documents=Matrix and justification' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Course Outcomes and Program Outcomes</td>
                        <td>1.4</td>
                        <td>Establish the correlation between the courses and the Program Outcomes (POs) & Program Specific Outcomes  </td>
                        <td>
                        <Link to='/viewnbaug11?name=1.3&question=Consistency of PEO with Mission&accreditation=NBAUGS&documents=Matrix and justification' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>


                    <tr>
                        <td>1</td>
                        <td>Course Outcomes and Program Outcomes</td>
                        <td>1.7</td>
                        <td>Evidence of solving complex engineering Problems</td>
                        <td>
                        <Link to='/viewprojects?name=1.7' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Course Outcomes and Program Outcomes</td>
                        <td>1.7</td>
                        <td>Evidence of solving complex engineering Problems</td>
                        <td>
                        <Link to='/viewexplearning?name=1.7' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Program Curriculum</td>
                        <td>2.1.1</td>
                        <td>State the Structure and Component of the Curriculum </td>
                        <td>
                        <Link to='/viewcurstructure?name=2.1.1' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Program Curriculum</td>
                        <td>2.2.4</td>
                        <td>Initiatives related to industry interaction, industry internship/summer training </td>
                        <td>
                        <Link to='/viewexplearning?name=2.2.4' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                  

                    {/* <tr>
                        <td>1</td>
                        <td>Curicular Aspects</td>
                        <td>1.2.2 - 1.2.3</td>
                        <td>Value Added Courses</td>
                        <td>
                        <Link to='/viewaddoncourse?name=1.2.2 - 1.2.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr> */}

                  

                    <tr>
                        <td>3</td>
                        <td>Students’ Performance</td>
                        <td>Data entry</td>
                        <td>Admission</td>
                        <td>
                        <Link to='/viewadmission?name=3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Student's Performance</td>
                        <td>data entry</td>
                        <td>Admission</td>
                        <td>
                        <Link to='/viewreservecat?name=2.1.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3.2</td>
                        <td>Success Rate in the stipulated period of the program</td>
                        <td>3.2.1-3.2.2</td>
                        <td>Success rate without backlogs in any semester/year of study</td>
                        <td>
                        <Link to='/viewpassexam?name=3.2.1-3.2.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Student's Performance</td>
                        <td>3.5</td>
                        <td> Placement, Higher Studies and Entrepreneurship</td>
                        <td>
                        <Link to='/viewplacement?name=3.5' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Student's Performance'</td>
                        <td>3.5</td>
                        <td>Placement, Higher Studies and Entrepreneurship</td>
                        <td>
                        <Link to='/viewhigheredu?name=5.2.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3.6</td>
                        <td>Professional Activities</td>
                        <td>3.6.1</td>
                        <td>Professional societies/chapters and organizing engineering events
                        </td>
                        <td>
                        <Link to='/viewevent?name=3.6.1' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3.6</td>
                        <td>Professional Activities</td>
                        <td>3.6.2</td>
                        <td>Publication of technical magazines, newsletters, etc
                        </td>
                        <td>
                        <Link to='/viewdeppublications?name=3.6.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3.6</td>
                        <td>Professional Activities</td>
                        <td>3.6.3</td>
                        <td>Participation in inter-institute events by students of the program of study
                        </td>
                        <td>
                        <Link to='/viewstudpub?name=3.6.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Professional Activities</td>
                        <td>3.6.4</td>
                        <td>Participation in national/international competitive events by students of the program of study </td>
                        <td>
                        <Link to='/viewawards?name=5.3.1' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    {/* New set */}


                    <tr>
                        <td>2</td>
                        <td>Program Curriculum and Teaching –Learning Processes</td>
                        <td>2.1.1</td>
                        <td>State the Structure and Component of the Curriculum</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.1.1&question=Structure and Component of the Curriculum&accreditation=NBAUGS&documents=Curriculum copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Program Curriculum and Teaching –Learning Processes</td>
                        <td>2.1.2</td>
                        <td>State the process used to identify extent of compliance of the curriculum for attaining the Program Outcomes and Program Specific Outcomes</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.1.2&question=Process used to identify extent of compliance&accreditation=NBAUGS&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Program Curriculum and Teaching –Learning Processes</td>
                        <td>2.2.1</td>
                        <td>Describe Processes followed to improve quality of Teaching & Learning</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.2.1&question=Processes followed to improve quality of Teaching & Learning&accreditation=NBAUGS&documents=Process for improvement' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Program Curriculum and Teaching –Learning Processes</td>
                        <td>2.2.2</td>
                        <td>Quality of end semester examination, internal semester question papers, assignments and evaluation</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.2.2&question=Quality of end semester examination&accreditation=NBAUGS&documents=Initiatives, Implementation details and Analysis' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Program Curriculum and Teaching –Learning Processes</td>
                        <td>2.2.3</td>
                        <td>Quality of student projects</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.2.3&question=Quality of student projects&accreditation=NBAUGS&documents=Types of projects' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Program Curriculum and Teaching –Learning Processes</td>
                        <td>2.2.4</td>
                        <td>Initiatives related to industry interaction, industry internship/summer training</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.2.4&question=Initiatives related to industry interaction&accreditation=NBAUGS&documents=Industry involvement in the program' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Program Curriculum and Teaching –Learning Processes</td>
                        <td>2.2.5</td>
                        <td>Initiatives towards the New Education Policy </td>
                        <td>
                        <Link to='/viewnbaug11?name=2.2.5&question=Initiatives towards the New Education Policy&accreditation=NBAUGS&documents=Plans to implement' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Students’ Performance</td>
                        <td>3.1</td>
                        <td>Enrolment Ratio</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.1&question=Enrolment Ratio&accreditation=NBAUGS&documents=Student details' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Students’ Performance</td>
                        <td>3.2.1</td>
                        <td>Success rate without backlogs in any semester/year of study</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.2.1&question=Success rate without backlogs&accreditation=NBAUGS&documents=Success rate' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Students’ Performance</td>
                        <td>3.2.2</td>
                        <td>Success rate in stipulated period of study[Total of with backlog + without backlog]</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.2.2&question=Success rate with & without backlogs&accreditation=NBAUGS&documents=Success rate including backlogs' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Students’ Performance</td>
                        <td>3.3</td>
                        <td>Academic Performance in Second Year</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.1&question=Academic Performance in Second Year&accreditation=NBAUGS&documents=Students academic performance in second year' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Students’ Performance</td>
                        <td>3.4</td>
                        <td>Academic Performance in Third Year</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.4&question=Academic Performance in Third Year&accreditation=NBAUGS&documents=Students academic performance in third year' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Students’ Performance</td>
                        <td>3.5</td>
                        <td>Placement, Higher Studies and Entrepreneurship</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.5&question=Placement, Higher Studies and Entrepreneurship&accreditation=NBAUGS&documents=Placement details' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Students’ Performance</td>
                        <td>3.6.1</td>
                        <td>Professional societies/chapters and organizing engineering events</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.6.1&question=Professional societies&accreditation=NBAUGS&documents=Engineering events' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Students’ Performance</td>
                        <td>3.6.2</td>
                        <td>Publication of technical magazines, newsletters, etc.</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.6.2&question=Publications&accreditation=NBAUGS&documents=Publications with the names of the editors, publishers, ' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Students’ Performance</td>
                        <td>3.6.3</td>
                        <td>Participation in inter-institute events by students of the program of study</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.6.3&question=Participation in inter-institute events&accreditation=NBAUGS&documents=Publications which received awards' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Students’ Performance</td>
                        <td>3.6.4</td>
                        <td>Participation in national/international competitive events by students of the program of study</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.6.4&question=Participation in national/international competitive events&accreditation=NBAUGS&documents=Competitive events ' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Faculty Information and Contributions</td>
                        <td>4.1</td>
                        <td>Student-Faculty Ratio (SFR)</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.1&question=Student-Faculty Ratio&accreditation=NBAUGS&documents=All students & faculty count' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Faculty Information and Contributions</td>
                        <td>4.2</td>
                        <td>Faculty Cadre Proportion</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.2&question=Faculty Cadre Proportion&accreditation=NBAUGS&documents=All faculty count' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Faculty Information and Contributions</td>
                        <td>4.3</td>
                        <td>Faculty Qualification</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.1&question=Faculty Qualification&accreditation=NBAUGS&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Faculty Information and Contributions</td>
                        <td>4.4</td>
                        <td>Faculty Retention</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.4&question=Faculty Retention&accreditation=NBAUGS&documents=Regular faculty members details' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Faculty Information and Contributions</td>
                        <td>4.5</td>
                        <td>Faculty competencies in correlation to curriculum</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.5&question=Faculty competencies&accreditation=NBAUGS&documents=Components of curriculum and the competencies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Faculty Information and Contributions</td>
                        <td>4.6</td>
                        <td>Innovations by the Faculty in Teaching and Learning</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.6&question=Innovations by the Faculty&accreditation=NBAUGS&documents=Processes for making the contributions' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Faculty Information and Contributions</td>
                        <td>4.7</td>
                        <td>Faculty as participants in Faculty development/training activities/STTPs</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.7&question=Faculty development programs&accreditation=NBAUGS&documents=Faculty participation certificates' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Faculty Information and Contributions</td>
                        <td>4.8.1</td>
                        <td>Academic Research</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.8.1&question=Academic Research&accreditation=NBAUGS&documents=Research paper publications, Ph.D. guideship letters' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Faculty Information and Contributions</td>
                        <td>4.8.2</td>
                        <td>Sponsored Research</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.8.2&question=Sponsored Research&accreditation=NBAUGS&documents=List with Project Title, Funding Agency, Amount and Duration' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Faculty Information and Contributions</td>
                        <td>4.8.3</td>
                        <td>Development activities</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.8.3&question=Development activities&accreditation=NBAUGS&documents=Product Development, Research laboratories, Working models/charts/monographs' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Faculty Information and Contributions</td>
                        <td>4.8.4</td>
                        <td>Consultancy (from Industry)</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.8.4&question=Consultancy&accreditation=NBAUGS&documents=List of Projects with Title, Funding Agency, Amount and Duration' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Faculty Information and Contributions</td>
                        <td>4.9</td>
                        <td>Faculty Performance Appraisal and Development System (FPADS)</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.9&question=Faculty Performance Appraisal System&accreditation=NBAUGS&documents=Policy & its implementation' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Faculty Information and Contributions</td>
                        <td>4.10</td>
                        <td>Visiting/Adjunct/Emeritus Faculty</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.10&question=Visiting/Adjunct/Emeritus Faculty&accreditation=NBAUGS&documents=Details of participation and contributions' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Resources</td>
                        <td>5.1</td>
                        <td>Adequate and well-equipped laboratories, and technical manpower</td>
                        <td>
                        <Link to='/viewnbaug11?name=5.1&question=Infrastructure&accreditation=NBAUGS&documents=Laboratory Equipments' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Resources</td>
                        <td>5.2</td>
                        <td>Laboratories maintenance and overall ambiance</td>
                        <td>
                        <Link to='/viewnbaug11?name=5.2&question=Maintainance&accreditation=NBAUGS&documents=Laboratory Maintainance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Resources</td>
                        <td>5.3</td>
                        <td>Safety measures in laboratories</td>
                        <td>
                        <Link to='/viewnbaug11?name=5.3&question=Safety measures&accreditation=NBAUGS&documents=Measures undertaken' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Resources</td>
                        <td>5.4</td>
                        <td>Project laboratory</td>
                        <td>
                        <Link to='/viewnbaug11?name=5.4&question=Project laboratory&accreditation=NBAUGS&documents=Facilities & Utilization' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Resources</td>
                        <td>5.5</td>
                        <td>Feedback analysis and reward /corrective measures taken</td>
                        <td>
                        <Link to='/viewnbaug11?name=5.5&question=Feedback analysis&accreditation=NBAUGS&documents=Feedback collection process & analysis report' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Resources</td>
                        <td>5.6.1</td>
                        <td>Adequacy of budget allocation</td>
                        <td>
                        <Link to='/viewnbaug11?name=5.6.1&question=Budget Allocation&accreditation=NBAUGS&documents=Total budget allocation programwise' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Resources</td>
                        <td>5.6.2</td>
                        <td>Utilization of allocated funds</td>
                        <td>
                        <Link to='/viewnbaug11?name=5.6.2&question=Budget Utilization&accreditation=NBAUGS&documents=Total budget utilization programwise' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Resources</td>
                        <td>5.7.1</td>
                        <td>Quality of learning resources</td>
                        <td>
                        <Link to='/viewnbaug11?name=5.7.1&question=Quality of learning resources&accreditation=NBAUGS&documents=Available learning resources & accessbility to students' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Resources</td>
                        <td>5.7.2</td>
                        <td>Internet</td>
                        <td>
                        <Link to='/viewnbaug11?name=5.7.2&question=Internet&accreditation=NBAUGS&documents=Internet provider, Bandwidth, Wi Fi availability, Cyber Security measures' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Continuous Improvement</td>
                        <td>6.1</td>
                        <td>Actions taken based on the results of evaluation of each of the COs, POs & PSO</td>
                        <td>
                        <Link to='/viewnbaug11?name=6.1&question=Actions taken&accreditation=NBAUGS&documents=Action taken and analysis report' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Continuous Improvement</td>
                        <td>6.2</td>
                        <td>Academic Audit and actions taken thereof during the period of Assessment</td>
                        <td>
                        <Link to='/viewnbaug11?name=6.2&question=Academic Audit and actions taken&accreditation=NBAUGS&documents=Audit process & its implementation' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Continuous Improvement</td>
                        <td>6.3</td>
                        <td>Improvement in Placement, Higher Studies and Entrepreneurship</td>
                        <td>
                        <Link to='/viewnbaug11?name=6.3&question=Improvement in Education&accreditation=NBAUGS&documents=Processes implemented for quality improvement & utilisation' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Continuous Improvement</td>
                        <td>6.4</td>
                        <td>Improvement in the quality of students admitted to the program </td>
                        <td>
                        <Link to='/viewnbaug11?name=6.4&question=Improvement in the quality of students&accreditation=NBAUGS&documents=Improvement processes impleted & utlised' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Continuous Improvement</td>
                        <td>6.5</td>
                        <td>Remedial action taken on the observations made during last accreditation visit</td>
                        <td>
                        <Link to='/viewnbaug11?name=6.5&question=Remedial action taken&accreditation=NBAUGS&documents=Initiatives taken' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    {/* <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.3.3</td>
                        <td>Mentor Mentee</td>
                        <td>
                        <Link to='/viewmentees?name=2.3.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.4.1 - 2.4.3</td>
                        <td>Full time Teachers</td>
                        <td>
                        <Link to='/viewteacherdata?name=2.4.1 - 2.4.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.4.2-3.1.2-3.3.1</td>
                        <td>Research Guide-Faculties with Ph.D/D.M/M.Ch/D.N.B Superspeciality/D.Sc/D.Litt</td>
                        <td>
                        <Link to='/viewphdguide?name=2.4.2-3.1.2-3.3.1' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.1.1 - 3.1.3</td>
                        <td>Gov./Non-gov Grants for Research Projects.           

                        Each faculty may add data for their projects or bulk upload data for all faculties.
                        </td>
                        <td>
                        <Link to='/viewprojects?name=3.1.1 - 3.1.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>


                   

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.3.2</td>
                        <td>Research Papers.

                        Each faculty may add their data. Bulk upload facility is also available.
                        </td>
                        <td>
                        <Link to='/viewpub?name=3.3.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.3.3</td>
                        <td>Books & Chapters.

                        Each faculty may add their data. Bulk upload facility is also available.
                        </td>
                        <td>
                        <Link to='/viewbooks?name=3.3.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.4.2</td>
                        <td>Extension Awards</td>
                        <td>
                        <Link to='/viewextawards?name=3.4.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.4.3 - 3.4.4</td>
                        <td>Extension Activities.

                        You may add activities into specific metric or into All Events.
                        </td>
                        <td>
                        <Link to='/viewextact?name=3.4.3 - 3.4.4' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.4.3 - 3.4.4</td>
                        <td>Extension Activities.

                        You may add activities into specific metric or into All Events.
                        </td>
                        <td>
                        <Link to='/viewevent?name=3.4.3 - 3.4.4' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.5.1</td>
                        <td>Collaborative Activities</td>
                        <td>
                        <Link to='/viewcollaboration?name=3.5.1' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>


                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.5.2</td>
                        <td>MoU Activities</td>
                        <td>
                        <Link to='/viewmou?name=3.5.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.1.3</td>
                        <td>ICT Enabled Classrooms & Seminar Halls</td>
                        <td>
                        <Link to='/viewict?name=4.1.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.1.4 - 4.4.1</td>
                        <td>Infrastructure Augmentation</td>
                        <td>
                        <Link to='/viewexpenditure?name=4.1.4 - 4.4.1' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.2.2-4.2.3</td>
                        <td>Library Expenditures</td>
                        <td>
                        <Link to='/viewlibrary?name=4.2.2 - 4.2.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    

                    <tr>
                        <td>5</td>
                        <td>Student Support-Progression</td>
                        <td>5.1.1 - 5.1.2</td>
                        <td>Student Scholarships</td>
                        <td>
                        <Link to='/viewscholarship?name=5.1.1 - 5.1.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Student Support-Progression</td>
                        <td>5.1.3</td>
                        <td>Skill Development Programs</td>
                        <td>
                        <Link to='/viewskilldev?name=5.1.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Student Support-Progression</td>
                        <td>5.1.4</td>
                        <td>Career Counselling Activities</td>
                        <td>
                        <Link to='/viewcareercounsel?name=5.1.4' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    

                    

                    <tr>
                        <td>5</td>
                        <td>Student Support-Progression</td>
                        <td>5.2.3</td>
                        <td>Student Qualifying in Higher Examination</td>
                        <td>
                        <Link to='/viewhigherexam?name=5.2.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                  

                    <tr>
                        <td>5</td>
                        <td>Student Support-Progression</td>
                        <td>5.3.3</td>
                        <td>Sports & Cultural Activities.

                        Add data to all events with category as Sports and Cultural for both Sports and Cultural activities	
                        </td>
                        <td>
                        <Link to='/viewevent?name=5.3.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Student Support-Progression</td>
                        <td>5.4.2</td>
                        <td>Alumni Contribution</td>
                        <td>
                        <Link to='/viewalumnicon?name=5.4.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.2.3</td>
                        <td>Implementation of E Governance</td>
                        <td>
                        <Link to='/viewegovern?name=6.2.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.3.2</td>
                        <td>Financial Support provided for Faculty.

                        Each faculty may add data from their profile.
                        </td>
                        <td>
                        <Link to='/viewseminar?name=6.3.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.3.2</td>
                        <td>Financial Support provided for Faculty.

                        Add data to a centralized level.
                        </td>
                        <td>
                        <Link to='/viewteacherfs?name=6.3.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.3.3</td>
                        <td>Professional development/Administrative Training Programs.

                        Add data to all events with category as FDP.
                        </td>
                        <td>
                        <Link to='/viewevent?name=6.3.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.3.4</td>
                        <td>Faculty Development Programs.

                        Add data to a centralized level. 
                        </td>
                        <td>
                        <Link to='/viewfdp?name=6.3.4' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.3.4</td>
                        <td>Faculty Development Programs.

                        Each faculty may add data from their profile.   
                        </td>
                        <td>
                        <Link to='/viewseminar?name=6.3.4' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.4.2</td>
                        <td>Gov./Non-Governmnet Funds</td>
                        <td>
                        <Link to='/viewfunds?name=6.4.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.5.3</td>
                        <td>Institutional Quality Initiatives</td>
                        <td>
                        <Link to='/viewquality?name=6.5.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>   

               */}

              
            </tbody>
            </Table>

            <br /><br />
            

            

       
        
        </div>
        <FooterNav1 />
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;