import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import Sideaqar6 from '../components/layout/Sideaqar6';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const yearref=useRef();
    const typeref=useRef();
    const seminarref=useRef();
    const actionref=useRef();
    const partstatusref=useRef();
    const otherref=useRef();
    const instnameref=useRef();
    const dateref=useRef();
    
    

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const qualityinitid=global1.qualityinitid;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        const year=yearref.current.value;
        const type=typeref.current.value;
        const seminar=seminarref.current.value;
        const action=actionref.current.value;
        const partstatus=partstatusref.current.value;
        const other=otherref.current.value;
        const instname=instnameref.current.value;
        const date=dateref.current.value;
        
        

        

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updatequalityinitbyfac', {
            params: {
                id: qualityinitid,
                user: user,
                token: token,
                colid: colid,
                name: name,
                year: year,
                type:type,
                seminar:seminar,
                action:action,
                partstatus: partstatus,
                other:other,
                instname: instname,
                date:date,
                status1:'Submitted',
                comments:'NA'

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewqualityinit');
       
    };

    useEffect(() => {
        //logout();
        yearref.current.value=global1.year;
        typeref.current.value=global1.type;
        seminarref.current.value=global1.seminar;
        actionref.current.value=global1.action;
        partstatusref.current.value=global1.partstatus;
        otherref.current.value=global1.other;
        instnameref.current.value=global1.instname;
        dateref.current.value=global1.date;
        
        
        
 
     }, []);
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <Sideaqar6 />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Edit Institutional Quality Initiatives</p>
                        <hr />
                       
                       
                        <label htmlFor='year'>Academic Year</label>
                    <select id="year" className="form-control"  ref={yearref}>
                    <option value="2016-17">2016-17</option>
                    <option value="2017-18">2017-18</option>
                    <option value="2018-19">2018-19</option>
                    <option value="2019-20">2019-20</option>
                    <option value="2020-21">2020-21</option>
                    <option value="2021-22">2021-22</option>
                       
                    </select>
                    <br /><br />

                    <label htmlFor='type'>Type of Quality Initiatives</label>
                    <select id="type" className="form-control"  ref={typeref}>
                    <option value="Conferences/Seminar/Workshops on quality conducted ">Conferences/Seminar/Workshops  on quality conducted </option>
                    <option value="Academic Administrative Audit">Academic Administrative Audit</option>
                    <option value="Participation in NIRF">Participation in NIRF</option>
                    <option value="ISO Certification/NBA">ISO Certification/NBA</option>
                    <option value="Collaborative quality initiatives with other institution(s)">Collaborative quality initiatives with other institution(s)</option>
                    <option value="Orientation Programs">Orientation Programs</option>
                    
                
                    </select>
                    <br /><br />

                    <input type="text" required style={{width: '100%'}} placeholder="Enter name of Conferences/Seminar/Workshops" id="seminar" ref={seminarref} />
                            
                        <br /><br />
                            
                    <input type="text" required style={{width: '100%'}} placeholder="Enter Follow up Action" id="action" ref={actionref} />
                            
                        <br /><br />
                    <input type="text" required style={{width: '100%'}} placeholder="Enter Status of Participation in NIRF" id="partstatus" ref={partstatusref} />
                            
                        <br /><br />
                    <input type="text" required style={{width: '100%'}} placeholder="Enter other quality audit details(for ISO Certification- name,nature and validity period/for NBA or any other certification-name & program specifications)" id="other" ref={otherref} />
                            
                        <br /><br />
                            
                    <input type="text" required style={{width: '100%'}} placeholder="Enter Name of collaborating institution and activity" id="instname" ref={instnameref} />
                            
                        <br /><br />

                        
                    <p>Enter Date of Orientation programme on quality issues for teachers and students organised by the institution(From-To)(DD-MM-YYYY)</p>
                            
                    <input type="date" required style={{width: '100%'}} placeholder="Enter Date" id="date" ref={dateref} />
                            
                        <br /><br />
                            

                      
                        <button  onClick={searchapi}>Edit Institutional Quality Initiatives</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;