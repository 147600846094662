import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faEdit, faComment, faCheckCircle, faFlag, faFileAlt, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { DataGrid } from '@material-ui/data-grid';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import Sidecr2Navigation from '../components/layout/Reportfive';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';
import Checkdata from '../components/layout/checkdata';


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import Spinner from 'react-bootstrap/Spinner';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const linkref=useRef();
    const moduleref=useRef();
    const topicref=useRef();

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState([]);
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());
    const [format, setFormat] = useState(false);

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;
    //const name=global1.name;
    const aqaryear=global1.aqaryear;

    const [isUploading, setIsuploading] = useState(false);

    const columns = [
        // { field: '_id', headerName: 'ID', width: 70 },
        { field: 'student', headerName: 'Student', width: 130 },
        { field: 'regno', headerName: 'Reg no', width: 130 },
      ];

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    const edit = (id, year, category, sancseat, studadmt) => {
        global1.reservekid=id;
        global1.year=year;
        global1.category=category;
        global1.sancseat=sancseat;
        global1.studadmt=studadmt;
        
        history.replace('/editbooks');
    }

    const list = (id, user) => {
        global1.field1=id;
        global1.criteria='1';
        global1.metric='1.1.2';
        global1.collection1='syllabusrev';
        global1.type='Syllabus Revision';
        global1.buser=user;
        global1.pagenav='viewsyllabusrevadmin';
        
        history.replace('/viewsupportingdocadmin');
    }

    const addperiod = () => {
        history.replace('/addperiod');
    }

    const addstudents = () => {
        // global1.courseid=courseid;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/addstudents');
    }

    function getaffiliated(affvalue) {
        if(affvalue=="1") {
            return "Yes";
        } else {
            return "No";
        }
    }

    

   

    let tutorials = [];
    const searchApi = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/syllabusrevdocsd', {
            params: {
                user: user,
                token: token,
                colid: colid,
                year:aqaryear
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        //localStorage.setItem("p_reservecata", JSON.stringify(response.data.data.classes));

       

        
        setResults(response.data.data.classes);
        setLink(response.data.data.link);
        //alert('format ' + format);
        try{

            formattable();
        } catch(err) {

        }
        
       
        //alert(results.length);
       
    };

    function formattable() {
        if (!format) {
            $('#example').DataTable(
                {
                    pagingType: 'full_numbers',
                      pageLength: 5,
                      processing: true,
                      dom: 'Bfrtip',
                          buttons: ['copy', 'csv', 'print'
                          ]
                }
            );
            setFormat(true);

        }
    }

   

    const deleteenrolment = async (enrolmentid) => {
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deletereservecatbyfac', {
            params: {
                id: enrolmentid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const getlocal = () => {
        let collection=localStorage.getItem("p_reservecata");
        if(!collection) {

        } else {
            setResults(JSON.parse(collection));

        }
        
    }

    

    useEffect(() => {
        checklogin();
        //search1();
        //getexamlist();
        //getlocal();
        searchApi('');
        //$('#example').DataTable();
        //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable();
    //     },
    //     1000
    //     );
    // });
    
    //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable(
    //         {
    //             pagingType: 'full_numbers',
    //               pageLength: 5,
    //               processing: true,
    //               dom: 'Bfrtip',
    //                   buttons: ['copy', 'csv', 'print'
    //                   ]
    //         }
    //     );
    //     } ,
    //     1000
    //     );
    // });
        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);


    const uploadlink = async (link1, id1, yop1) => {
        link1=link1 + '?criteria=3&Section=3.4.6&Subsection=Book&field1=' + id1 + '&Year=' + yop1; 
        var win = window.open(link1, '_blank');
        win.focus();
      };

    
      const list1 = async (id1) => {
        var link1=window.location.origin + '/viewsupportingdocquery';
        link1=link1 + '?field1=' + id1 + '&colid=' + colid + '&user=' + user + '&name=' + name + '&token=' + token; 
        var win = window.open(link1, '_blank');
        win.focus();
      };

      const updatestatus = async (status1, id) => {
        const response = await ep1.get('/api/v1/updatesyllabusrevcomments', {
            params: {
                user: user,
                token: token,
                colid: colid,
                status1: status1,
                comments: "NA",
                id: id

            }

        });
        setLoading(false);
        alert(response.data.status + ' - ' + status1);
    };


    const audit = (id, programcode, programname, year, cbcs, cbcsyear, revisionyear, change) => {
        var comments='';   
        if(!programcode || !programname || !year || !cbcsyear || !revisionyear || !change) {
            comments='All fields are required';
            return comments;
        }
        if(isNaN(change)) {
            comments=comments + 'Invalid change entry. Must be a number.';
        }  else {
            if(change<21) {
                comments=comments + 'Min 20 percent changes required.'
            }

        }
        
        //comments=comments + ' year ' + year + ' ' + year.indexOf('-');

        if(year.indexOf('-') > -1) {
            const yeararray=year.split('-');
            //comments=comments + ' ' + yeararray[1] + ' ' + isNaN(yeararray[1]);
            if(isNaN(yeararray[1]) || isNaN(yeararray[0])) {
                comments=comments + ' Invalid introduction year. Must be in year such as 2017-18.'

            } else {
                
                
                var firstyear=Number(yeararray[0].substring(2,4));
                var secondyear=Number(yeararray[1]);
                var diff=secondyear-firstyear;
                //comments=comments + ' firstyear ' + firstyear + ' secondyear ' + secondyear;
                if(diff>1) {
                    comments=comments + ' Invalid introduction year format.'
                } else {
                    comments=comments + 'Introduction year ok.';
                }
            }
        } else {
            comments = comments + ' Invalid introduction year. Must be in academic year format.'
        }
        
        if(cbcsyear.indexOf('-') > -1) {
            const yeararray=cbcsyear.split('-');
            //comments=comments + ' ' + yeararray[1] + ' ' + isNaN(yeararray[1]);
            if(isNaN(yeararray[1]) || isNaN(yeararray[0])) {
                comments=comments + ' Invalid revision year. Must be in year such as 2017-18.'

            } else {
                
                var firstyear=Number(yeararray[0].substring(2,4));
                var secondyear=Number(yeararray[1]);
                var diff=secondyear-firstyear;
                //comments=comments + ' firstyear ' + firstyear + ' secondyear ' + secondyear;
                if(diff>1) {
                    comments=comments + ' Invalid cbcs year format.'
                } else {
                    comments=comments + 'CBCS year ok.';
                }
            }
        } else {
            comments = comments + ' Invalid cbcs year. Must be in academic year format.'
        }

        if(revisionyear.indexOf('-') > -1) {
            const yeararray=revisionyear.split('-');
            //comments=comments + ' ' + yeararray[1] + ' ' + isNaN(yeararray[1]);
            if(isNaN(yeararray[1]) || isNaN(yeararray[0])) {
                comments=comments + ' Invalid revision year. Must be in year such as 2017-18.'

            } else {

                if(Number(yeararray[0])<2017 || Number(yeararray[0])> 2022) {
                    comments=comments + ' Data outside assessment period.';
                }
                
                var firstyear=Number(yeararray[0].substring(2,4));
                var secondyear=Number(yeararray[1]);
                var diff=secondyear-firstyear;
                //comments=comments + ' firstyear ' + firstyear + ' secondyear ' + secondyear;
                if(diff>1) {
                    comments=comments + ' Invalid revision year format.'
                } else {
                    comments=comments + 'Revision year ok.';
                }
            }
        } else {
            comments = comments + ' Invalid revision year. Must be in academic year format.'
        }

        
        var program=programname.indexOf('MSc') + programname.indexOf('BSc') + programname.indexOf('BE')  + programname.indexOf('BTech') + programname.indexOf('BA') + programname.indexOf('MA') + programname.indexOf('PhD');
        //comments=comments + program;
        var progstatus=' Invalid program name.';
        if(programname.indexOf('MSc')>-1 || programname.indexOf('BSc') >-1 || programname.indexOf('BE') >-1 || programname.indexOf('BTech') > -1 || programname.indexOf('BA') > -1 || programname.indexOf('MA') > -1 || programname.indexOf('PhD') > -1 || programname.indexOf('MBA') > -1 || programname.indexOf('MCA') > -1 || programname.indexOf('LLB') > -1 || programname.indexOf('BCA') > -1 || programname.indexOf('BCom') > -1 || programname.indexOf('MBBS') > -1 || programname.indexOf('BDS') > -1 || programname.indexOf('MD') > -1 || programname.indexOf('MS') > -1 || programname.indexOf('BPT') > -1 || programname.indexOf('DP') > -1 || programname.indexOf('BP') > -1) {
            progstatus='Program name appears ok.'
        }
        if(programname.indexOf(',')>-1 || programname.indexOf('&')>-1 || programname.indexOf('  ')>-1) {
            progstatus='Invalid program name.';
        }
        comments=comments + progstatus;
        var programlength=programcode.length;
        //comments=comments + programlength;
        if(programlength<6 || programlength>12) {
            comments=comments + 'Check program code.';
        } else {
            if(programcode.indexOf(' ')>-1) {
                comments=comments + 'Check Program code.';
            } else {
                comments=comments + 'Program code appears ok.';
            }
            
        }
        return comments;
    }

    const audit2 = async (id, programcode, programname, year, cbcs, cbcsyear, revisionyear, change) => {
        var comments=''; 
        comments=comments + await Checkdata.strlength(programcode);
        comments=comments + ' test ';
        return comments;


    }

    // const getweblink=(link) => {
    //     var newlink='';
    //     //newlink=link.substring(link.indexOf('amazonaws.com') + 13);
    //     newlink=link.substring(link.indexOf('amazonaws.com') + 13, link.lastIndexOf('.'));
    //     var website=global1.website;
    //     if(!website) {
    //         website='https://<yourdomain.com>';
    //     }
    //     newlink=website + '/Criteria1/syllabusrevision' + newlink;
    //     return newlink;
    // }

    const getweblink=(link) => {
        var newlink='';
        //newlink=link.substring(link.indexOf('amazonaws.com') + 13);
        //newlink=link.substring(link.indexOf('amazonaws.com') + 14, link.lastIndexOf('.'));
        newlink=link.substring(link.indexOf('amazonaws.com') + 14);
        var website=global1.website;
        if(!website) {
            website='https://<yourdomain.com>';
        }
        //newlink=website + '/Criteria1/valueaddedcourse' + newlink;
        newlink=website + '?link=' + newlink;
        return newlink;
    }


    
    const getweblink21=(link,status1, content) => {
        var newlink='';
        //newlink=link.substring(link.indexOf('amazonaws.com') + 13);
        //newlink=link.substring(link.indexOf('amazonaws.com') + 14, link.lastIndexOf('.'));
        newlink=link.substring(link.indexOf('amazonaws.com') + 14);
        var website=global1.website;
        if(!website) {
            website='https://<yourdomain.com>';
        }
        //newlink=website + '/Criteria1/valueaddedcourse' + newlink;
        newlink=website + '?link=' + newlink;
        var str1='';
          if(status1=='Flagged') {     
              return '';
          } else {
            
            // return content + '<br />' + newlink + '<br />' ;
            return (
                <div>
                    <a href={newlink} target="_blank">{content} {newlink}</a>
                    <br /><br />
                </div>

            );
          }
        //return newlink;
    }

    

    const width=window.innerWidth;
    const mdivwidth=width - 330;

   
       
        //alert('loaded');
  

    


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    {/* <td valign="top" style={{ width: 60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <Sidecr2Navigation />
                    </td> */}
                   
                    <td> 
                     

                    
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
            <p style={{ fontSize: 18 }}>Links for Syllabus revision / CBCS implementation for Programs</p>
            <hr />
          
            
        <br /><br />
    
        
        {isUploading ? (
				<div style={{ alignItems: 'center', marginTop: 30, marginBottom: 60}}>
                   <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
				</div>
			) : (
                <div></div>
			)} 


{results.map((meetup3) => {
                return (
                   <div>

{meetup3.seminars.map((meetup31) => {
                return (
                    <div>
                        
                        {/* {meetup31.link} {meetup31.status1}  */}
                        {/* <a href={getweblink1(meetup31.link, meetup31.status1)} target="_blank">{getweblink1(meetup31.link, meetup31.status1)}</a>  */}
                        {/* <a href={getweblink2(meetup31.link, meetup31.status1, meetup3.coursetitle + ' ' + meetup3.coursecode + ' ' + meetup3.year)} target="_blank">{getweblink1(meetup31.link, meetup31.status1)}</a>  */}
                        {getweblink21(meetup31.link, meetup31.status1, meetup3.programname + ' ' + meetup3.programcode + ' Revision year ' + meetup3.yearofrevision)}
                        
                      
                        </div>

                    );
                })}
                       </div>
              

                );
            })}

     
            <br /><br />




        


            

       
        
        </div>
        
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;