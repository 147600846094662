import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';



import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidefacNavigation from '../components/layout/SidefacNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';


function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const coursecoderef=useRef();
    const courseref=useRef();
    const programref=useRef();
    const semesterref=useRef();
    const sectionref=useRef();
    const hoursref=useRef();
    const moduleref=useRef();

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const programname=global1.programname;
    const programcode=global1.programcode;
    const lmsyear=global1.lmsyear;
    const semester=global1.semester;
    const section=global1.section;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const aqaryear=global1.aqaryear;

    const handleSubmission = () => {
        //alert('checking');
        		const formData = new FormData();
        		formData.append('upl', selectedFile);
                formData.append('token', token);
                formData.append('user', user);
                formData.append('name', name);
                formData.append('colid', colid);
                formData.append('coursecode', coursecode);
                formData.append('course', coursename);
                formData.append('program', programname);
                formData.append('academicyear', lmsyear);
                formData.append('semester', semester);
                formData.append('section', section);
                formData.append('year', lmsyear);
        
        		fetch(
        			//'https://freeimage.host/api/1/upload?key=<YOUR_API_KEY>',
                    //'http://localhost:3000/api/v1/uploadclassenr',
                    //'https://ctnodeapps2.azurewebsites.net/api/v1/uploadclassenr',
                    'https://canvasapi1.azurewebsites.net/api/v1/uploadclassenrexta',
        			{
        				method: 'POST',
                        // headers: {
                        //     'Content-Length': selectedFile.length,
                        //     'Content-Type': 'multipart/form-data'
                        //   },
        				body: formData,
        			}
        		)
        			.then((response) => response.json())
        			.then((result) => {
        				console.log('Success:', result);
                        alert(result.status);
        			})
        			.catch((error) => {
        				console.error('Error:', error);
        		});
       	};
	

           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width: 60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        {/* <SidefacNavigation /> */}
                        <SidecolorNavigation />
                    </td>
                   
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}><Link to='/dashcourse'>Dashboard</Link> - <Link to='/courseactions'>Course Dashboard</Link> - Add Students to Course {coursecode} {coursename} {programname} {programcode} {lmsyear}</p>
                        <hr />
          

                        <div style={styles}>
        <FileDrop className={classes2.FileDrop}
          onFrameDragEnter={(event) => console.log('onFrameDragEnter', event)}
          onFrameDragLeave={(event) => console.log('onFrameDragLeave', event)}
          onFrameDrop={(event) => console.log('onFrameDrop', event)}
          onDragOver={(event) => console.log('onDragOver', event)}
          onDragLeave={(event) => console.log('onDragLeave', event)}
          onDrop={(files, event) => changeHandler1(files, event)}
        >
          Drop some files here!
        </FileDrop>
      </div>
      
            <div>
          
            <br />
            
                
                
        
                {isFilePicked ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>File Information</p>
					<p>Filename: {selectedFile.name}</p>
					<p>Filetype: {selectedFile.type}</p>
					<p>Size in bytes: {selectedFile.size}</p>
                    <div className={classes1.actions}>
                    <button  onClick={handleSubmission}>Add Students</button>
                    </div>
					{/* <p>
						lastModifiedDate:{' '}
						{selectedFile.lastModifiedDate.toLocaleDateString()}
					</p> */}
				</div>
			) : (
                <div>
				<p>Select a file to show details</p>
                <p
                style={{fontSize: 18, cursor: 'pointer', color: 'blue'}} 
                onClick={() => openlink('https://docs.google.com/spreadsheets/d/1GrJRb8WWLjFmAwhXtmZnp9R_lonMBTvx/edit?usp=sharing&ouid=117788565424189887038&rtpof=true&sd=true')}
                >
                    Click here to open sample file
                </p>
                </div>
			)}
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;