import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidekpiNavigation from '../components/layout/SidemetricNavigationadmin';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    // const fnameref=useRef();
    // const academicbodyref=useRef();
    // const yearref=useRef();

    const programref=useRef();
    const courseref=useRef();
    const facultyref=useRef();
    
    

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }

    useEffect(() => {
       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const updateprojects = async () => {
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getprojectalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
            }
        });
        setIsuploading(false);
        alert(response.data.status + ' - Projects updated');   
    };

    const updateseminar = async () => {
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getseminaralert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
            }
        });
        setIsuploading(false);
        alert(response.data.status + ' - Seminar updated');   
    };

    const updatepatent = async () => {
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getpatentalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
            }
        });
        setIsuploading(false);
        alert(response.data.status + ' - Patent updated');   
    };

    const updatepublication = async () => {
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getpubalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
            }
        });
        setIsuploading(false);
        alert(response.data.status + ' - Publication updated');   
    };

    const updatebook = async () => {
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getbookalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
            }
        });
        setIsuploading(false);
        alert(response.data.status + ' - Book updated');   
    };

    const updateexplearning = async () => {
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getexplearningalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
            }
        });
        setIsuploading(false);
        alert(response.data.status + ' - Exp learning updated');   
    };

    const updateemployability = async () => {
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getemployabilityalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
            }
        });
        setIsuploading(false);
        alert(response.data.status + ' - Employability status updated');   
    };

    const updatesyllabusrev = async () => {
        setIsuploading(true);
        const programs=programref.current.value;
        const response = await ep1.get('/api/v1/getsyllabusrevalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                programs: programs
            }
        });
        setIsuploading(false);
        alert(response.data.status + ' - Syllavus revision status is updated');   
    };



    const updatecbcs = async () => {
        
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getcbcsalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
                

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        
       
    };
    const updatebos = async () => {
        
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getbosalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
                

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        
       
    };
    const updateevent = async () => {
        
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/geteventalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
                

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        
       
    };
    const updateadmission = async () => {
        
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getidemandratioalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
                

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status + ' - Demand ratio admission is updated');
        
       
    };


    const updatereservecat = async () => {
        
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getireservedcatalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
                

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status + ' - reserved category is updated.');
        
       
    };
    const updateteacherdata = async () => {
        
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getifulltimeteacheralert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
                

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status + ' - teacher data is updated');
        
       
    };
    
    const updatepassexam = async () => {
        
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getipasspercentagealert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
                

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status + ' - pass percentage is updated.');
        
       
    };
    const updateawards = async () => {
        
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getifacultyawardalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
                

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status + ' - faculty awards is updated.');
       
       
    };

    const updateexamdays = async () => {
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getiexamdaysalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
            }
        });
        setIsuploading(false);
        alert(response.data.status + ' - Exam days status is updated');   
    };

    const updateexamautomation = async () => {
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getiexamautomationalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
            }
        });
        setIsuploading(false);
        alert(response.data.status + ' - Exam automation status is updated');   
    };

    const updatementor = async () => {
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getimentoralert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
            }
        });
        setIsuploading(false);
        alert(response.data.status + ' - Mentoring status is updated');   
    };

    const updateseedmoney = async () => {
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getiseedmoneyalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
            }
        });
        setIsuploading(false);
        alert(response.data.status + ' - Seed money status is updated');   
    };

    const updateteacherfellow = async () => {
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getiteacherfellowalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
            }
        });
        setIsuploading(false);
        alert(response.data.status + ' - Teacher fellowship status is updated');   
    };

    const updateresearchfellow = async () => {
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getiresearchfellowalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
            }
        });
        setIsuploading(false);
        alert(response.data.status + ' - Research fellowship status is updated');   
    };

    



    const updateextact = async () => {
        
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getextactalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
                

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        
       
    };
    const updatecollab = async () => {
        
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getcollabalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
                

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        
       
    };
    const updatemou = async () => {
        
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getmoualert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
                

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        
       
    };
    const updateict = async () => {
        
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getictalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
                

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        
       
    };
    const updateexpenditure = async () => {
        
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getexpenditurealert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
                

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        
       
    };
    const updatescholarship = async () => {
        
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getscholarshipalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
                

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        
       
    };
    const updatelibrary = async () => {
        
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getlibraryalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
                

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        
       
    };
    const updatefunds = async () => {
        
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getfundsalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
                

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        
       
    };
    const updatequality = async () => {
        
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getqualityalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
                

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
       
       
    };
    const updateskilldev = async () => {
        
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getskilldevalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
                

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        
       
    };
    const updatecareercounsel = async () => {
        
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getcareercounselalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
                

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        
       
    };
    const updateplacement = async () => {
        
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getplacementalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
                

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        
       
    };
    const updatehigheredu = async () => {
        
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/gethigheredualert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
                

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        
       
    };
    const updatehigherexam = async () => {
        
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/gethigherexamalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
                

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        
       
    };
    const updateteacherfs = async () => {
        
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getteacherfsalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
                

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        
       
    };
    const updateegovern = async () => {
        
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getegovernalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
                

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        
       
    };

    const deleteallkpi = async () => {

        setIsuploading(true);
        const response = await ep1.get('/api/v1/deleteallkpi', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
            }
        });
        setIsuploading(false);
        alert(response.data.status);
    };
    const updateaddonc = async () => {
        
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getaddoncalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
                

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        
       
    };
    const updateinstawards = async () => {
        
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/getinstawardsalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name
                

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        
       
    };
    
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidekpiNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />




                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}>

                    <p>Update My details</p>
                    <hr />

                    <p>Enter profile information</p>
                    <br />
                    Unique no of programs offerd in last five years <br/><br />
                    <input type="number" required style={{width: '100%'}} placeholder="Enter no. of unique programs" id="programs" ref={programref} />
                            
                            <br /><br />   

                            Unique no of courses offerd in last one year <br/><br />
                    <input type="number" required style={{width: '100%'}} placeholder="Enter no. of unique courses" id="courses" ref={courseref} />
                            
                            <br /><br />   
                    
                            Unique no of full time faculties in last five years <br/><br />
                    <input type="number" required style={{width: '100%'}} placeholder="Enter no. of unique faculties" id="faculties" ref={facultyref} />
                            
                            <br /><br />   




                    <table>
                        <tr>
                            <td>
                            <button  onClick={updateprojects}>Update projects</button>
                    
                            </td>
                            <td>
                            <button  onClick={updateseminar}>Update seminar</button>
                            </td>
                            <td>
                            <button  onClick={updatepublication}>Update publication</button>
                            </td>
                            <td>
                            <button  onClick={updatebook}>Update book</button>
                            </td>
                            <td>
                            <button  onClick={updatepatent}>Update patent</button>
                            </td>
                          
                        </tr>
                        </table>
                        <br /><br />


                        
                    <p>Update Departmental Data (Criteria 1)</p>
                    <br />

                    <table>
                        <tr>
                            <td>
                            <button  onClick={updatesyllabusrev}>Update syllabus revision</button>
                    
                            </td>
                            <td>
                            <button  onClick={updateemployability}>Update employability</button>
                            </td>
                            <td>
                            <button  onClick={updateaddonc}>Update Value Added Course</button>
                                </td>
                            <td>
                            <button  onClick={updateexplearning}>Update experiential learning</button>
                            </td>
                        </tr>
                        </table>
                        <br /><br />

                        <p>Update Criteria 2 data</p>
                    <br />

                    <table>
                        <tr>
                            <td>
                            <button  onClick={updateadmission}>Update demand ratio</button>
                    
                            </td>
                            <td>
                            <button  onClick={updatereservecat}>Update reserve category</button>
                            </td>
                            <td>
                            <button  onClick={updateteacherdata}>Update teacher data</button>
                            </td>
                            <td>
                            <button  onClick={updateawards}>Update teacher awards</button>
                            </td>
                        </tr>
                        </table>
                        <br /><br />

                        <table>
                        <tr>
                            <td>
                            <button  onClick={updatementor}>Update mentor data</button>
                    
                            </td>
                            <td>
                            <button  onClick={updatepassexam}>Update pass percentage</button>
                            </td>
                            <td>
                            <button  onClick={updateexamautomation}>Update exam automation</button>
                            </td>
                            <td>
                            <button  onClick={updateexamdays}>Update exam days</button>
                            </td>
                        </tr>
                        </table>
                        <br /><br />

                        <table>
                        <tr>
                            <td>
                            <button  onClick={updateseedmoney}>Update seed money</button>
                    
                            </td>
                            <td>
                            <button  onClick={updateteacherfellow}>Update teacher fellowship</button>
                            </td>
                            <td>
                            <button  onClick={updateresearchfellow}>Update research fellow</button>
                            </td>
                           
                        </tr>
                        </table>
                        <br /><br />

           <div class="row">
                    <div class="col-sm-6">
                    <button  onClick={deleteallkpi}>Delete all kpi</button>
                    <br />
                        <button  onClick={updatebos}>Update BoS</button>
                        <br />
                        <button  onClick={updatecbcs}>Update CBCS</button>
                        <br />
                        <button  onClick={updateaddonc}>Update Value Added Course</button>
                        <br />
                        <button  onClick={updateevent}>Update Activities</button>
                        <br />
                    </div>
                    <div class="col-sm-6">
                        <button  onClick={updateadmission}>Update Admission</button>
                        <br />
                        <button  onClick={updatereservecat}>Update Reserve Category</button>
                        <br />
                        <button  onClick={updateteacherdata}>Update Teacher data</button>
                        <br />
                        <button  onClick={updatepassexam}>Update Pass Exam</button>
                        <br />
                    </div>
                    <div class="col-sm-6">   
                        <button  onClick={updateinstawards}>Update Institutional Awards</button>
                        <br />
                        <button  onClick={updateextact}>Update Extension Activities</button>
                        <br />
                        <button  onClick={updatecollab}>Update Collaboration</button>
                        <br />
                        <button  onClick={updatemou}>Update MoU</button>
                        <br />
                    </div> 
                    <div class="col-sm-6">      
                        <button  onClick={updateexpenditure}>Update Expenditures</button>
                        <br />
                        <button  onClick={updatelibrary}>Update Library Expenditures</button>
                        <br />
                        <button  onClick={updateict}>Update ICT</button>
                        <br />
                    </div>
                    <div class="col-sm-6">    
                        <button  onClick={updatescholarship}>Update Scholarship data</button>
                        <br />
                        <button  onClick={updatecareercounsel}>Update Career Counselling Activities</button>
                        <br />
                        <button  onClick={updateskilldev}>Update Skill Development data</button>
                        <br />
                        <button  onClick={updatehigherexam}>Update Students Higher Examination data</button>
                        <br />
                        <button  onClick={updateplacement}>Update Placement data</button>
                        <br />
                        <button  onClick={updatehigheredu}>Update Higher Education data</button>
                        <br />
                        <button  onClick={updateawards}>Update Awards</button>
                        <br />
                    </div>
                    <div class="col-sm-6">  
                        <button  onClick={updateegovern}>Update E-governance</button>
                        <br />
                        <button  onClick={updateteacherfs}>Update Financial Support</button>
                        <br />
                        <button  onClick={updatefunds}>Update Funds</button>
                        <br />
                        <button  onClick={updatequality}>Update Quality Initiatives</button>
                        <br />
                    </div>  
                </div>     


                      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;