import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


import { FavoritesContextProvider } from './store/favourites-context';

ReactDOM.render(
                <FavoritesContextProvider>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </FavoritesContextProvider>, 
                document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();


