import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faEdit, faComment, faFileAlt, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser, faUpload } from '@fortawesome/free-solid-svg-icons';
import { DataGrid } from '@material-ui/data-grid';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import Reportfive from '../components/layout/Reportfive';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import Spinner from 'react-bootstrap/Spinner';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const yearref=useRef();
    const typeref=useRef();
    const statusref=useRef();

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState([]);
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());
    const [format, setFormat] = useState(false);

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;
    //const name=global1.name;

    const [isUploading, setIsuploading] = useState(false);

    const columns = [
        // { field: '_id', headerName: 'ID', width: 70 },
        { field: 'student', headerName: 'Student', width: 130 },
        { field: 'regno', headerName: 'Reg no', width: 130 },
      ];

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    const edit = (id, year, type, status, status1) => {
        if(status1=='Accepted') {
            alert('This document is accepted. Please contact IQAC coordinator to allow editing');
            return;
        }
        global1.examautoid=id;
        global1.year=year;
        global1.type=type;
        global1.status=status;
        
        history.replace('/editexamautomation');
    }
    const upload = (id, status1) => {
        if(status1=='Accepted') {
            alert('This document is accepted. Please contact IQAC coordinator to allow editing');
            return;
        }
        global1.field1=id;
        global1.criteria='2';
        global1.metric='2.5.4 U, 2.5.5 HU';
        global1.collection1='examautomation';
        global1.type='Exam Automation';
        global1.pagenav='viewexamautomation';
        
        // history.replace('/selectawsconfig');
        history.replace('/selectmetricrule');
    }

    const list = (id, user) => {
        global1.field1=id;
        global1.criteria='2';
        global1.metric='2.5.4 U, 2.5.5 HU';
        global1.collection1='examautomation';
        global1.type='Exam Automation';
        global1.buser=user;
        global1.pagenav='viewexamautomationadmin';
        
        history.replace('/viewsupportingdocadmin');
    }
    const addperiod = () => {
        history.replace('/addperiod');
    }

    const addstudents = () => {
        // global1.courseid=courseid;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/addstudents');
    }

    function getaffiliated(affvalue) {
        if(affvalue=="1") {
            return "Yes";
        } else {
            return "No";
        }
    }

    

   

    let tutorials = [];
    const searchApi = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/examautomationdd', {
            params: {
                user: user,
                token: token,
                colid: colid
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        // localStorage.setItem("kexamautomation", JSON.stringify(response.data.data.classes));

       

        
        setResults(response.data.data.classes);
        setLink(response.data.data.link);
        //alert('format ' + format);
        try{

            formattable();
        } catch(err) {

        }
        
       
        //alert(results.length);
       
    };

    function formattable() {
        if (!format) {
            $('#example').DataTable(
                {
                    pagingType: 'full_numbers',
                      pageLength: 5,
                      processing: true,
                      dom: 'Bfrtip',
                          buttons: ['copy', 'csv', 'print'
                          ]
                }
            );
            setFormat(true);

        }
    }

    const getweblink=(link) => {
        var newlink='';
        //newlink=link.substring(link.indexOf('amazonaws.com') + 13);
        newlink=link.substring(link.indexOf('amazonaws.com') + 13, link.lastIndexOf('.'));
        var website=global1.website;
        if(!website) {
            website='https://<yourdomain.com>';
        }
        newlink=website + '/Criteria2/examautomation' + newlink;
        return newlink;
    }
   

    const deleteenrolment = async (enrolmentid, status1) => {
        if(status1=='Accepted') {
            alert('This document is accepted. Please contact IQAC coordinator to allow editing');
            return;
        }
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteexamautomationbyfac', {
            params: {
                id: enrolmentid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    // const getlocal = () => {
    //     let collection=localStorage.getItem("kexamautomation");
    //     if(!collection) {

    //     } else {
    //         setResults(JSON.parse(collection));

    //     }
        
    // }

    

    useEffect(() => {
        checklogin();
        //search1();
        //getexamlist();
        // getlocal();
        searchApi('');
        //$('#example').DataTable();
        //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable();
    //     },
    //     1000
    //     );
    // });
    
    //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable(
    //         {
    //             pagingType: 'full_numbers',
    //               pageLength: 5,
    //               processing: true,
    //               dom: 'Bfrtip',
    //                   buttons: ['copy', 'csv', 'print'
    //                   ]
    //         }
    //     );
    //     } ,
    //     1000
    //     );
    // });
        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);


    const uploadlink = async (link1, id1, year) => {
        link1=link1 + '?criteria=2&Section=2.5.4&Subsection=ExamAutomation&field1=' + id1 + '&Year=' + year; 
        var win = window.open(link1, '_blank');
        win.focus();
      };

      const getweblink21=(link,status1, content) => {
        var newlink='';
        //newlink=link.substring(link.indexOf('amazonaws.com') + 13);
        //newlink=link.substring(link.indexOf('amazonaws.com') + 14, link.lastIndexOf('.'));
        newlink=link.substring(link.indexOf('amazonaws.com') + 14);
        var website=global1.website;
        if(!website) {
            website='https://<yourdomain.com>';
        }
        //newlink=website + '/Criteria1/valueaddedcourse' + newlink;
        newlink=website + '?link=' + newlink;
        var str1='';
          if(status1=='Flagged') {     
              return '';
          } else {
            
            // return content + '<br />' + newlink + '<br />' ;
            return (
                <div>
                    <a href={newlink} target="_blank">{content} {newlink}</a>
                    <br /><br />
                </div>

            );
          }
        //return newlink;
    }


    
   

    

    const width=window.innerWidth;
    const mdivwidth=width - 330;

   
       
        //alert('loaded');
  

    


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    
                   
                    <td> 
                        

                    
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
            <p style={{ fontSize: 18 }}>Links for supporting documents for Exam Automation</p>
            <hr />
            {/* <table>
                <tr>
                    <td>
                    <Link to='/addexamautomation' className="btn btn-dark"> Add Exam Automation</Link>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <Link to='/addexamautomationbulk' className="btn btn-dark"> Bulk add data</Link>

                    </td>
                </tr>
            </table> */}
            
           
    
           <br />
       <br />
        {isUploading ? (
				<div style={{ alignItems: 'center', marginTop: 30, marginBottom: 60}}>
                   <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
				</div>
			) : (
                <div></div>
			)} 

{results.map((meetup3) => {
                return (
                   <div>

{meetup3.seminars.map((meetup31) => {
                return (
                    <div>
                        
                        {/* {meetup31.link} {meetup31.status1}  */}
                        {/* <a href={getweblink1(meetup31.link, meetup31.status1)} target="_blank">{getweblink1(meetup31.link, meetup31.status1)}</a>  */}
                        {/* <a href={getweblink2(meetup31.link, meetup31.status1, meetup3.coursetitle + ' ' + meetup3.coursecode + ' ' + meetup3.year)} target="_blank">{getweblink1(meetup31.link, meetup31.status1)}</a>  */}
                        {getweblink21(meetup31.link, meetup31.status1, meetup3.type + ' ' + meetup3.year)}
                        
                      
                        </div>

                    );
                })}
                       </div>
              

                );
            })}
     
            <br /><br />
            
        


            

       
        
        </div>
        
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;