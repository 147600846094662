import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faEdit, faComment, faFileAlt, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { DataGrid } from '@material-ui/data-grid';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import ReportNavigation from '../components/layout/SidemetricNavigationadmin';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import Spinner from 'react-bootstrap/Spinner';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const linkref=useRef();
    const moduleref=useRef();
    const topicref=useRef();

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [events, setEvents] = useState([]);
    const [ipr, setIpr] = useState([]);
    const [fdp, setFdp] = useState([]);
    const [nopub, setNopub] = useState([]);
    const [nosem, setNosem] = useState([]);
    const [nobook, setNobook] = useState([]);
    const [nopatent, setNopatent] = useState([]);
    const [noproject, setNoproject] = useState([]);
    // const [fdp, setFdp] = useState([]);
    // const [fdp, setFdp] = useState([]);
    const [explearning, setExplearning] = useState([]);
    const [facultyfeedback, setFacultyfeedback] = useState([]);
    const [employerfeedback, setEmployerfeedback] = useState([]);
    const [moucount, setMoucount] = useState(0);
    const [collab, setCollab] = useState(0);
    const [addonc, setAddonc] = useState(0);
    const [quality, setQuality] = useState(0);
    const [facultyfeedbackcount, setFacultyfeedbackcount] = useState(0);
    const [employerfeedbackcount, setEmployerfeedbackcount] = useState(0);
    const [curriculumfeedbackcount, setCurriculumfeedbackcount] = useState(0);
    const [lastiqac, setLastiqac] = useState([]);
    const [iqaccount, setIqaccount] = useState(0);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState([]);
    const [loading, setLoading] = useState();
    const [lcurfeedback, setLcurfeedback] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());
    const [format, setFormat] = useState(false);

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;
    //const name=global1.name;

    const [isUploading, setIsuploading] = useState(false);

    const columns = [
        // { field: '_id', headerName: 'ID', width: 70 },
        { field: 'student', headerName: 'Student', width: 130 },
        { field: 'regno', headerName: 'Reg no', width: 130 },
      ];

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    const edit = (id, title, journal, yop, issn, articlelink, journallink, ugclisted) => {
        global1.pubid=id;
        global1.title=title;
        global1.journal=journal;
        global1.yop=yop;
        global1.issn=issn;
        global1.articlelink=articlelink;
        global1.journallink=journallink;
        global1.ugclisted=ugclisted;
        history.replace('/editpub');
    }

    const addperiod = () => {
        history.replace('/addperiod');
    }

    const addstudents = () => {
        // global1.courseid=courseid;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/addstudents');
    }

    

   

    let tutorials = [];
    const searchApi = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getlastcurriculumfeedback', {
            params: {
                user: user,
                token: token,
                colid: colid
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        //localStorage.setItem("pub", JSON.stringify(response.data.data.classes));
        setResults(response.data.data.classes);
        setLcurfeedback(response.data.data.classes.total_attendance);
        //formattable();

    };

    const getfacultyfeedback = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getlastfacultyfeedback', {
            params: {
                user: user,
                token: token,
                colid: colid
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        //localStorage.setItem("pub", JSON.stringify(response.data.data.classes));
        setFacultyfeedback(response.data.data.classes);
        
        //formattable();

    };

    const getemployerfeedback = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getlastemployerfeedback', {
            params: {
                user: user,
                token: token,
                colid: colid
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        //localStorage.setItem("pub", JSON.stringify(response.data.data.classes));
        setEmployerfeedback(response.data.data.classes);
        
        //formattable();

    };

    const getmoualert = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getmoualert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                year: '2021-22'
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        //localStorage.setItem("pub", JSON.stringify(response.data.data.classes));
        //alert('Mou count ' + response.data.data.moucount);
        if(response.data.data.moucount) {
            setMoucount(response.data.data.moucount);
        }
    };

    const getcollabalert = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/geticollabalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                year: '2021-22'
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        //localStorage.setItem("pub", JSON.stringify(response.data.data.classes));
        //alert(response.data.moucount);
        if(response.data.data.count) {
            setCollab(response.data.data.count);
        }
    };

    const getaddoncalert = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getiaddoncalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                year: '2021-22'
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        //localStorage.setItem("pub", JSON.stringify(response.data.data.classes));
        //alert(response.data.moucount);
        if(response.data.data.count) {
            setAddonc(response.data.data.count);
        }
    };

    const getqualityalert = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getiqualityalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                year: '2021-22'
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        //localStorage.setItem("pub", JSON.stringify(response.data.data.classes));
        //alert(response.data.moucount);
        if(response.data.data.count) {
            setQuality(response.data.data.count);
        }
    };

    const getfacultyfeedbackcount = async () => {
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getfacultyfeedbackcount', {
            params: {
                user: user,
                token: token,
                colid: colid,
                year: '2020-21'
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        if(response.data.data.moucount) {
            setFacultyfeedbackcount(response.data.data.moucount);
        }
    };

    const getcurriculumfeedbackcount = async () => {
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getcurriculumfeedbackcount', {
            params: {
                user: user,
                token: token,
                colid: colid,
                year: '2020-21'
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        if(response.data.data.moucount) {
            setCurriculumfeedbackcount(response.data.data.moucount);
        }
    };

    const getemployerfeedbackcount = async () => {
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getemployerfeedbackcount', {
            params: {
                user: user,
                token: token,
                colid: colid,
                year: '2020-21'
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        if(response.data.data.moucount) {
            setEmployerfeedbackcount(response.data.data.moucount);
        }
    };

    const getiqaccount = async () => {
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getiqaccount', {
            params: {
                user: user,
                token: token,
                colid: colid,
                year: '2020-21'
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        if(response.data.data.moucount) {
            setIqaccount(response.data.data.moucount);
        }
    };

    const getlastiqac = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getlastiqac', {
            params: {
                user: user,
                token: token,
                colid: colid
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        //localStorage.setItem("pub", JSON.stringify(response.data.data.classes));
        setLastiqac(response.data.data.classes);
    };

    function formattable() {
        if (!format) {
            $('#example').DataTable(
                {
                    pagingType: 'full_numbers',
                      pageLength: 5,
                      processing: true,
                      dom: 'Bfrtip',
                          buttons: ['copy', 'csv', 'print'
                          ]
                }
            );
            setFormat(true);

        }
    }

   

    

    const getlocal = () => {
        let collection=localStorage.getItem("pub");
        if(!collection) {

        } else {
            setResults(JSON.parse(collection));

        }
        
    }

    const eventdep = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/geteventbyyear', {
            params: {
                token: token,
                colid: colid,
                academicyear: '2021-22'

            }

        });
        
        console.log(response.data.data);
        //localStorage.setItem("attendance" + coursecode1, JSON.stringify(response.data.data.classes));
        setEvents(response.data.data.classes);
        $('#example1').DataTable(
            {
                pagingType: 'full_numbers',
                  pageLength: 5,
                  processing: true,
                  dom: 'Bfrtip',
                      buttons: ['copy', 'csv', 'print'
                      ]
            }
        );
        setLoading(false);
        //alert(results.length);
       
    };

    const ipr1 = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getieventtypebyyear', {
            params: {
                token: token,
                colid: colid,
                academicyear: '2021-22',
                type:'Research/IPR'
            }
        });
        setIpr(response.data.data.classes);
        setLoading(false);
    };

    const nopub1 = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/nopublist', {
            params: {
                token: token,
                colid: colid
            }
        });
        setNopub(response.data.data.classes);
        $('#enopub').DataTable(
            {
                pagingType: 'full_numbers',
                  pageLength: 5,
                  processing: true,
                  dom: 'Bfrtip',
                      buttons: ['copy', 'csv', 'print'
                      ]
            }
        );
        setLoading(false);
    };

    const nosem1 = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/nosemlist', {
            params: {
                token: token,
                colid: colid
            }
        });
        setNosem(response.data.data.classes);
        $('#enosem').DataTable(
            {
                pagingType: 'full_numbers',
                  pageLength: 5,
                  processing: true,
                  dom: 'Bfrtip',
                      buttons: ['copy', 'csv', 'print'
                      ]
            }
        );
        setLoading(false);
    };

    const nobook1 = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/nobooklist', {
            params: {
                token: token,
                colid: colid
            }
        });
        setNobook(response.data.data.classes);
        $('#enobook').DataTable(
            {
                pagingType: 'full_numbers',
                  pageLength: 5,
                  processing: true,
                  dom: 'Bfrtip',
                      buttons: ['copy', 'csv', 'print'
                      ]
            }
        );
        setLoading(false);
    };

    const noproject1 = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/noprojectlist', {
            params: {
                token: token,
                colid: colid
            }
        });
        setNoproject(response.data.data.classes);
        $('#enoproject').DataTable(
            {
                pagingType: 'full_numbers',
                  pageLength: 5,
                  processing: true,
                  dom: 'Bfrtip',
                      buttons: ['copy', 'csv', 'print'
                      ]
            }
        );
        setLoading(false);
    };

    const nopatent1 = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/nopatentlist', {
            params: {
                token: token,
                colid: colid
            }
        });
        setNopatent(response.data.data.classes);
        $('#enopatent').DataTable(
            {
                pagingType: 'full_numbers',
                  pageLength: 5,
                  processing: true,
                  dom: 'Bfrtip',
                      buttons: ['copy', 'csv', 'print'
                      ]
            }
        );
        setLoading(false);
    };

    const explearningdep = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getiexplearningyear', {
            params: {
                token: token,
                colid: colid,
                year: '2021-22'

            }

        });
        
        console.log(response.data.data);
        //localStorage.setItem("attendance" + coursecode1, JSON.stringify(response.data.data.classes));
        setExplearning(response.data.data.classes);
        $('#example2').DataTable(
            {
                pagingType: 'full_numbers',
                  pageLength: 5,
                  processing: true,
                  dom: 'Bfrtip',
                      buttons: ['copy', 'csv', 'print'
                      ]
            }
        );
        setLoading(false);
        //alert(results.length);
       
    };
    

    useEffect(() => {
        checklogin();
        //search1();
        //getexamlist();
        //getlocal();
        searchApi('');
        getfacultyfeedback();
        getemployerfeedback();
        getmoualert();
        getqualityalert();
        getaddoncalert();
        getcollabalert();
        getfacultyfeedbackcount();
        getcurriculumfeedbackcount();
        getemployerfeedbackcount();
        getlastiqac();
        getiqaccount();
        eventdep();
        explearningdep();
        nobook1();
        nopub1();
        nosem1();
        noproject1();
        nopatent1();
        ipr1();
        

    }, []);


    const uploadlink = async (link1, id1, yop1) => {
        link1=link1 + '?criteria=3&Section=3.4.3&Subsection=Patent&field1=' + id1 + '&Year=' + yop1 + '&userid=' + user; 
        var win = window.open(link1, '_blank');
        win.focus();
      };

      const checkdata = (date1) => {
          //alert(date1);
          if(!date1) {
            return (
                <div>
                <p style={{ color: 'red'}}>
                No activity was done for this metric. Please add data
                </p>
                </div>
            )

          }
          const dt1=new Date(date1);
          const dt2=new Date();
          //alert(dt1);
          const cmo=dt1.getMonth() + 1;
          var cm1=dt2.getMonth() + 1;
          if (cm1<7) {
              cm1=cm1+12;
          }
          const mdiff=cm1-cmo;
          //console.log(mdiff);
          if(mdiff>5) {
              return 'Feedback was taken long back. Please take again';
          } else {
            
            return (
                <div>
                <p style={{ color: 'green'}}>
                Hey you are on the right track. Feedback was taken  {mdiff} month(s) back. Please take again in next semester. Please continue completing the rest of the activities
                </p>
                </div>
            )
          }
          //alert(cm1);
          return 'Ok';


      }

      const checkdatacount = (count) => {
       
        if(count>0) {
            if(count<20)  {
                return 'Number of activities are less. Only ' + count + ' entries are there in the database for current academic year.';
            } else {
                
                return (
                    <div>
                    <p style={{ color: 'green'}}>
                    Hey you are on the right track. Please continue completing the rest of the activities
                    </p>
                    </div>
                )
                 
            }
            
        } else {
          return (
              <div>
              <p style={{ color: 'red'}}>
              No activity was done for this metric. Please add data
              </p>
              </div>
          )
        }
        //alert(cm1);
        return 'Ok';


    }

    const checkdatalimit = (count, limit) => {
       
        if(count>0) {
            if(count<limit)  {
                return (
                    <div>
                    <p style={{ color: 'red'}}>
                    Improve
                    </p>
                    </div>
                )
            } else {
                
                return (
                    <div>
                    <p style={{ color: 'green'}}>
                    Good
                    </p>
                    </div>
                )
                 
            }
            
        } else {
          return (
              <div>
              <p style={{ color: 'red'}}>
              No activity was done for this metric. Please add data
              </p>
              </div>
          )
        }
        //alert(cm1);
        return 'Ok';
    }

    const checkiqacdata = (date1,limit) => {
        //alert(date1);
        if(!date1) {
          return (
              <div>
              <p style={{ color: 'red'}}>
              No activity was done for this metric. Please add data
              </p>
              </div>
          )

        }
        const dt1=new Date(date1);
        const dt2=new Date();
        //alert(dt1);
        const cmo=dt1.getMonth() + 1;
        var cm1=dt2.getMonth() + 1;
        if (cm1<7) {
            cm1=cm1+12;
        }
        const mdiff=cm1-cmo;
        //console.log(mdiff);
        if(mdiff>limit) {
            return 'Last activity was conducted long back. Please conduct this activity again';
        } else {
          
          return (
              <div>
              <p style={{ color: 'green'}}>
              Hey you are on the right track. Last activity was conducted  {mdiff} month(s) back. Please conduct again regularly. Please continue completing the rest of the activities
              </p>
              </div>
          )
        }
    }

    const checkiqaccount = (count, limit) => {
        if(count>0) {
            if(count<limit)  {
                return 'Number of activities are less. Please conduct at least ' + limit + ' activities per year.';
            } else {
                return (
                    <div>
                    <p style={{ color: 'green'}}>
                    Hey you are on the right track. Please continue completing the rest of the activities
                    </p>
                    </div>
                )    
            }   
        } else {
          return (
              <div>
              <p style={{ color: 'red'}}>
              No activity was done for this metric. Please add data
              </p>
              </div>
          )
        }
    }

    const checknodata = (ar1) => {
        //alert(ar1.count);
        if(ar1>0) {
        } else {
            return (
                <div>
                <p style={{ color: 'red'}}>
                No activity was done for this metric. Please add data
                </p>
                </div>
            )
        }

    }

    const getmonthdate = (ar1) => {
        //alert(ar1.count);
        //const dt1=getDate();
        const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

const d = new Date();
const monthname = month[d.getMonth()];
const year=d.getFullYear();

       return monthname + ' ' + year;

    }

    


    
   

    

    const width=window.innerWidth;
    const mdivwidth=width - 330;

   
       
        //alert('loaded');
  

    


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width: 60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <ReportNavigation />
                    </td>
                   
                    <td> 
                        <TopNavigation />

                    
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
            <p style={{ fontSize: 18 }}>IQAC Alerts for {getmonthdate()}</p>
            <hr />
           
    
        <br />
        {isUploading ? (
				<div style={{ alignItems: 'center', marginTop: 30, marginBottom: 60}}>
                   <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
				</div>
			) : (
                <div></div>
			)} 

{/* begin iqac */}
<h5>Status of IQAC meetings (Required one in every quarter)</h5>
     <hr />
<Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
     
      <th>Activity</th>
      <th>Status</th>
      
    </tr>
    </thead>
    <tbody>
    
   
    
                    <tr>
                        <td>
                            IQAC meeting
                        </td>
                      
                        <td>
                        {checkiqaccount(iqaccount,4)}
                        {lastiqac.map((meetup3) => {
                return (
                    <div>
                        {checkiqacdata(meetup3.total_attendance)}
                        </div>
                        );
                    })}
                        </td>
                                </tr>
            
            </tbody>
            </Table>

            <br /><br />

{/* end iqac */}

     <h5>Status of feedback</h5>
     <hr />
<Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
     
      <th>Activity</th>
      <th>Status</th>
      
    </tr>
    </thead>
    <tbody>
    
   
    
                    <tr>
                        <td>
                            Curriculum feedback
                        </td>
                      
                        <td>
                        {checknodata(curriculumfeedbackcount)}
                        {results.map((meetup3) => {
                return (
                    <div>
                        {checkdata(meetup3.total_attendance)}
                        </div>
                        );
                    })}
                        </td>
                                </tr>
                   


                    <tr>
                        <td>
                            Faculty feedback
                        </td>
                      
                        <td>
                        {checknodata(facultyfeedbackcount)}
                       
                        {facultyfeedback.map((ff2) => {
                return (
                    <div>
                        {checkdata(ff2.total_attendance)}
                        </div>
                            );
                        })}
                        </td>
                                </tr>
                                
                
                             

                    <tr>
                        <td>
                            Employer feedback
                        </td>
                        {/* <td>
                            {facultyfeedbackcount}
                        </td> */}
                        <td>
                            {checknodata(employerfeedbackcount)}

                        {employerfeedback.map((ff3) => {
                return (
                    <div>
                   
                        {checkdata(ff3.total_attendance)}
                        </div>
                        );
                    })}
                            
                        </td>
                                </tr>


               
                        
            </tbody>
            </Table>

            <br /><br />

            <h5>Events conducted this year</h5>
            <hr />
            <Table striped bordered hover id="example1">
{/* <table id="example"> */}
<thead>
<tr>
      <th>Department</th>
      <th>Type</th>
      <th>Events conducted</th>
      <th>Target per year</th>
      <th>Status</th>
    </tr>
    </thead>
    <tbody>
    {events.map((meetup3e) => {
                return (
                    <tr>
                    <td>
                        {meetup3e._id.department}
                    </td>
                    <td>
                        {meetup3e._id.type}
                    </td>
                    <td>
                    {meetup3e.total_attendance}
                    </td>
                    <td>2</td>
                    <td>
                    {checkdatalimit(meetup3e.total_attendance,2)}
                    </td>
                          
                          </tr>

                );
            })}

    </tbody>
    </Table>

    <br /><br />

    <h5>Research/IPR Events conducted this year(at least 1 event per year should be conducted by every department)</h5>
            <hr />
            <Table striped bordered hover id="example1">
{/* <table id="example"> */}
<thead>
<tr>
      <th>Department</th>
      <th>Type</th>
      <th>Events conducted</th>
      <th>Target per year</th>
    </tr>
    </thead>
    <tbody>
    {ipr.map((meetup3e) => {
                return (
                    <tr>
                    <td>
                        {meetup3e._id.department}
                    </td>
                    <td>
                        {meetup3e._id.type}
                    </td>
                    <td>
                    {meetup3e.total_attendance}
                    </td>
                    <td>1</td>
                          
                          </tr>

                );
            })}

    </tbody>
    </Table>

    <br /><br />
        

    <h5>Field trips/ Projects conducted this year(every department must conduct at least 2 activities per year)</h5>
            <hr />
            <Table striped bordered hover id="example2">
{/* <table id="example"> */}
<thead>
<tr>
      <th>Department</th>
      <th>Type</th>
      <th>Activities conducted</th>
      <th>Target per year</th>
      <th>Status</th>
    </tr>
    </thead>
    <tbody>
    {explearning.map((meetup3l) => {
                return (
                    <tr>
                    <td>
                        {meetup3l._id.department}
                    </td>
                    <td>
                        {meetup3l._id.type}
                    </td>
                    <td>
                    {meetup3l.total_attendance}
                    </td>
                    <td>At least 2 activities per year</td>
                    <td>
                    {checkdatalimit(meetup3l.total_attendance, 2)}
                    </td>
                          
                          </tr>

                );
            })}

    </tbody>
    </Table>

    <br /><br />

    



            <h5>Other activities</h5>
     <hr />
<Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
     
      <th>Activity</th>
      <th>Status</th>
      
    </tr>
    </thead>
    <tbody>

    <tr>
                        <td>
                            MoU
                        </td>
                        <td>
                        {checkdatacount(moucount)}
                        </td>
                                </tr>

                                <tr>
                        <td>
                            Collaboration
                        </td>
                        <td>
                        {checkdatacount(collab)}
                        </td>
                                </tr>

                                <tr>
                        <td>
                            Add on courses
                        </td>
                        <td>
                        {checkdatacount(addonc)}
                        </td>
                                </tr>

                                <tr>
                        <td>
                            Quality initiatives
                        </td>
                        <td>
                        {checkdatacount(quality)}
                        </td>
                                </tr>
    

    {/* {results.map((meetup3) => {
                return (
                    <tr>
                        <td>
                            MoU
                        </td>
                        <td>
                        {checkdata(meetup3.total_attendance)}
                        </td>
                                </tr>
                );
            })} */}




                        
            </tbody>
            </Table>

            <br /><br />

            <h5>Faculties not uploaded any publication</h5>
            <hr />
            <Table striped bordered hover id="enopub">
{/* <table id="example"> */}
<thead>
<tr>
      <th>Department</th>
      <th>User</th>
      <th>Name</th>
 
    </tr>
    </thead>
    <tbody>
    {nopub.map((meetup3e) => {
                return (
                    <tr>
                    <td>
                        {meetup3e.department}
                    </td>
                    <td>
                        {meetup3e.email}
                    </td>
                    <td>
                    {meetup3e.name}
                    </td>   
                          </tr>

                );
            })}

    </tbody>
    </Table>

    <br /><br />

    <h5>Faculties not uploaded any seminar</h5>
            <hr />
            <Table striped bordered hover id="enosem">
{/* <table id="example"> */}
<thead>
<tr>
      <th>Department</th>
      <th>User</th>
      <th>Name</th>
 
    </tr>
    </thead>
    <tbody>
    {nosem.map((meetup3e) => {
                return (
                    <tr>
                    <td>
                        {meetup3e.department}
                    </td>
                    <td>
                        {meetup3e.email}
                    </td>
                    <td>
                    {meetup3e.name}
                    </td>   
                          </tr>

                );
            })}

    </tbody>
    </Table>

    <br /><br />

    <h5>Faculties not uploaded any book</h5>
            <hr />
            <Table striped bordered hover id="enobook">
{/* <table id="example"> */}
<thead>
<tr>
      <th>Department</th>
      <th>User</th>
      <th>Name</th>
 
    </tr>
    </thead>
    <tbody>
    {nobook.map((meetup3e) => {
                return (
                    <tr>
                    <td>
                        {meetup3e.department}
                    </td>
                    <td>
                        {meetup3e.email}
                    </td>
                    <td>
                    {meetup3e.name}
                    </td>   
                          </tr>

                );
            })}

    </tbody>
    </Table>

    <br /><br />

    <h5>Faculties not uploaded any patent</h5>
            <hr />
            <Table striped bordered hover id="enopatent">
{/* <table id="example"> */}
<thead>
<tr>
      <th>Department</th>
      <th>User</th>
      <th>Name</th>
 
    </tr>
    </thead>
    <tbody>
    {nopatent.map((meetup3e) => {
                return (
                    <tr>
                    <td>
                        {meetup3e.department}
                    </td>
                    <td>
                        {meetup3e.email}
                    </td>
                    <td>
                    {meetup3e.name}
                    </td>   
                          </tr>

                );
            })}

    </tbody>
    </Table>

    <br /><br />

    <h5>Faculties not uploaded any project</h5>
            <hr />
            <Table striped bordered hover id="enoproject">
{/* <table id="example"> */}
<thead>
<tr>
      <th>Department</th>
      <th>User</th>
      <th>Name</th>
 
    </tr>
    </thead>
    <tbody>
    {nopub.map((meetup3e) => {
                return (
                    <tr>
                    <td>
                        {meetup3e.department}
                    </td>
                    <td>
                        {meetup3e.email}
                    </td>
                    <td>
                    {meetup3e.name}
                    </td>   
                          </tr>

                );
            })}

    </tbody>
    </Table>

    <br /><br />




        


            

       
        
        </div>
        <FooterNav1 />
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;