import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function SidefacNavigation() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    



    return (
        <div className={classes1.sidebar}>
             <div className={classes1.sidebarinside}>

<div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
               
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            
            </div>
            <hr />
            
        
        {/* <div style={{marginBottom: 10}}><b>Insights</b></div> */}
        {/* <Link to='/viewaddoncourseadmin'> Add on course</Link>
        <Link to='/viewsyllabusrevadmin'> Syllabus revision and CBCS implementation</Link>
        <Link to='/viewemployabilityadmin'> Courses with employability and New courses introduction</Link>
        <Link to='/viewexplearningadmin'>Experiential learning</Link>
        <Link to='/viewadmissionadmin'> Admission</Link>
        <Link to='/viewbosadmin'> BoS</Link>
        <Link to='/viewcollaborationadmin'>Collaboration</Link>
        <Link to='/vieweventadmin'> All events</Link>
        <Link to='/vieweventipr'> Reserach / IPR</Link>
        <Link to='/vieweventextension'> Extension / Outreach</Link>
        <Link to='/vieweventfdp'> FDP</Link>
        <Link to='/viewexpenditureadmin'> Expenditure</Link>
        <Link to='/viewextensionactadmin'> Extension activities</Link>
        <Link to='/viewictadmin'>ICT</Link>
        <Link to='/viewinstawardsadmin'>Institutional awards</Link>
        <Link to='/viewlibraryexpadmin'>Library expenditure</Link>
        <Link to='/viewmouadmin'>MoU</Link>
        <Link to='/viewpassexamadmin'>Pass percentage</Link>
        <Link to='/viewreservecatadmin'>Reserved categories</Link>
        <Link to='/viewawardsadmin'> Student Awards/Medals</Link>
        <Link to='/viewcbcsadmin'> CBCS Programs</Link>
        <Link to='/viewcareercounseladmin'> Career Counselling Activities</Link>
        <Link to='/viewegovernadmin'> E- governance</Link>
        <Link to='/viewhighereduadmin'> Student progression to Higher Education</Link>
        <Link to='/viewhigherexamadmin'> Student qualifying in Higher Examination</Link>
        <Link to='/viewextawardsadmin'> Institutional Awards</Link>
        <Link to='/viewphdguideadmin'> PhD Guide</Link>
        <Link to='/viewplacementadmin'> Student Placement</Link>
        <Link to='/viewqualityadmin'> Quality Initiatives</Link>
        <Link to='/viewskilldevadmin'> Skill development Programs</Link>
        <Link to='/viewteacherdataadmin'> Full-time Teachers</Link>
        <Link to='/viewteacherfsadmin'> Faculty Finacial Support</Link>
        <Link to='/viewinnovationadmin'>Innovation</Link>
        <Link to='/viewresearchfellowadmin'>Research fellow</Link>
        <Link to='/viewteacherfellowadmin'>Teacher fellow</Link>
        <Link to='/viewecontentadmin'> Econtent</Link>
        <Link to='/viewalumniconadmin'> Alumni Contribution</Link>
        <Link to='/viewresultadmin'> Result Publications</Link>
        <Link to='/viewexamautomationadmin'> Exam Automation</Link>
        <Link to='/viewmenteesadmin'> Mentor-Mentee</Link>
        <Link to='/viewteacherawardadmin'> Teacher Awards/Recognitions</Link>
        <Link to='/viewteacherfellowadmin'> Teacher Fellowship</Link>
        <Link to='/viewseedmadmin'> Seed Money</Link>
        <Link to='/viewconsultancyadmin'> Consultancy </Link>
        <Link to='/viewscholarshipadmin'> Student Scholarship</Link>

        <hr /> */}

{/* <div style={{marginBottom: 10}}><b>Criteria 1</b></div> */}
        <Link to='/viewbosadmin'> BoS(1.1.3 A, 1.1.2 H)</Link>
        <Link to='/viewcbcsadmin'> CBCS Programs(1.1.2 - 1.2.2 C, 1.2.1 HU)</Link>
        <Link to='/viewsyllabusrevadmin'> Syllabus revision and CBCS implementation( 1.1.2 - 1.2.2 U, 1.1.2 - 1.2.2 C, 1.1.2 HU)</Link>
        <Link to='/viewsyllabuserror'> Error - Syllabus revision and CBCS implementation( 1.1.2 - 1.2.2 U, 1.1.2 - 1.2.2 C, 1.1.2 HU)</Link>
        <Link to='/viewemployabilityadmin'> Courses with employability and New courses introduction (1.1.3-1.2.1 U, 1.1.3-1.2.1 C, 1.1.3 HU)</Link>
        <Link to='/viewaddoncourseadmin'> Add on course (1.2.2 - 1.2.3 A, 1.3.2 - 1.3.3 U-C-H-HU)</Link>
        <Link to='/viewexplearningadmin'> Experential learning (1.3.3 A, 1.3.4 U, 1.3.4 C, 2.2.5 NBA)</Link>
        <Link to='/viewexplearnprojadmin'> Experential learning(AQAR) (1.3.3 A, 1.3.4 U, 1.3.4 C, 2.2.5 NBA)</Link>
        <Link to='/viewfieldprojadmin'> Field Project(AQAR) (1.3.3 A, 1.3.4 U, 1.3.4 C, 2.2.5 NBA)</Link>

        {/* <hr /> */}
        {/* <div style={{marginBottom: 10}}><b>Criteria 2</b></div> */}
        <Link to='/viewadmissionadmin'> Admission (2.1.1 A-U-C, 2.1.2 HU, 4.1-4.2.1-4.2.2 NBA)</Link>
        <Link to='/viewreservecatadmin'>Reserved categories (2.1.2 A-U-C, 2.1.1 H)</Link>
        <Link to='/viewcurgapadmin'>Action taken for curriculum gap (2.2 NBA)</Link>
        <Link to='/viewteacherdataadmin'> Full-time Teachers (2.4.1-2.4.3 A, 2.4.2 U, 2.4.1 C, 2.4.1-2.4.3 H, 2.4.3 HU - 5.1.1 NBA)</Link>
        <Link to='/viewpassexamadmin'>Pass percentage (2.6.3 A-U-C, 2.6.2 h-HU - 4.2.2-4.3-4.4 NBA)</Link>
        <Link to='/viewresultadmin'> Result Publications (2.5.1 U-C, 4.4 NBA)</Link>
        <Link to='/viewteacherawardadmin'> Teacher Awards/Recognitions (2.4.4-3.4.2 U, 2.4.5 H, 2.4.5 HU)</Link>
        <Link to='/viewexamautomationadmin'> Exam Automation (2.5.4 U, 2.5.5 HU )</Link>
        <Link to='/viewmenteesadmin'> Mentor-Mentee (2.3.3 U, 2.3.3 C, 2.3.4 H, 2.3.4 HU, 9.1 NBA)</Link>

        {/* <hr /> */}
        {/* <div style={{marginBottom: 10}}><b>Criteria 3</b></div> */}
        <Link to='/viewextawardsadmin'> Institutional Awards (3.4.2 A. 2.4.4 U, 5.1.1 C, 3.6.3 HU, 3.4.3 H)</Link>
        <Link to='/viewinnovationadmin'>Innovation (3.3.3 U)</Link>
        <Link to='/viewphdguideadmin'> PhD Guide (3.4.4 U, 5.8.1 NBA)</Link>
        <Link to='/viewextensionactadmin'> Extension activities (3.4.3 A, 3.6.3 U, 3.6.3 - 3.6.4 C, 3.6.1 - 3.6.2 HU, 3.4.1-3.4.2 H, 9.7 NBA)</Link>
        <Link to='/viewcollaborationadmin'>Collaboration Activities (3.5.1 A-H, 3.7.1 U-C-HU)</Link>
        <Link to='/viewconsultancyadmin'> Consultancy (3.5.2 U, 5.8.4 NBA) </Link>
        <Link to='/viewmouadmin'>MoU Activities (3.5.2 A-H, 3.7.2 U-C-HU)</Link>
        <Link to='/viewecontentadmin'> E-content (3.4.7 U, 4.3.4 C, 4.3.5 HU , 4.3.6 H, 5.6 NBA)</Link>
        <Link to='/viewseedmadmin'> Seed Money (3.1.2 U-C-HU)</Link>
        <Link to='/viewteacherfellowadmin'> Teacher fellowship (3.1.3 U, 3.1.3 C, 3.1.2 H, 3.1.3 HU)</Link>
        <Link to='/viewresearchfellowadmin'>Research fellowship (3.1.4 U-HU)</Link>
        <Link to='/viewincubationadmin'>Incubation Centre (3.3.1 U-HU, 9.6 NBA)</Link>

        {/* <hr />
        <div style={{marginBottom: 10}}><b>Criteria 4</b></div> */}
        <Link to='/viewictadmin'>ICT (4.1.3 A, 4.3.1, 4.1.3 C, 4.4.1 H-HU)</Link>
        <Link to='/viewdeppublicationsadmin'>Departmental Publications (4.5.2 NBA)</Link>
        <Link to='/viewstudpubadmin'> Student Publications(4.5.3 NBA) </Link>
        <Link to='/viewexpenditureadmin'> Expenditures (4.1.4 A-C-H, 4.1.4-4.4.1 U, 4.1.4 - 4.5.1 HU, 4.5.1 H)</Link>
        <Link to='/viewlibraryexpadmin'>Library expenditure (4.2.2-4.2.3 A, 4.2.2-4.2.3 U, 4.3.4 C, 4.3.4 H-HU)</Link>

        {/* <hr />
        <div style={{marginBottom: 10}}><b>Criteria 5</b></div> */}
        <Link to='/viewscholarshipadmin'> Student Scholarships (5.1.1 A-U-C-H-HU)</Link>
        <Link to='/viewskilldevadmin'> Skill development Programs (5.1.3 A-U-C, 5.1.2 HU)</Link>
        <Link to='/viewcareercounseladmin'> Career Counselling Activities (5.1.4 A, 5.1.2 U, 5.1.4 C, 5.1.3 H-HU, 9.5 NBA)</Link>
        <Link to='/viewplacementadmin'> Student Placement (5.2.1 A, 5.2.2 U-H-HU, 5.2.1 C, 4.4-4.4a NBA )</Link>
        <Link to='/viewhighereduadmin'> Student progression to Higher Education (5.2.2 A, 5.2.3 U, 5.2.2 C, 5.2.3 H, 5.2.3 HU, 4.4 NBA)</Link>
        <Link to='/viewhigherexamadmin'> Student qualifying in Higher Examination (5.2.3 A, 5.2.1 U, 5.2.3 C, 5.2.1 H, 5.2.1 HU, 7.4 NBA)</Link>
        <Link to='/viewawardsadmin'> Student Awards/Medals (5.3.1 A-U-C-H-HU, 4.5.3 NBA)</Link>
        <Link to='/viewalumniconadmin'> Alumni Contribution (5.3.3 A-U-C-H-HU)</Link>
        <Link to='/viewsportsactadmin'> Sports & Cultural Activities (5.3.3 A-U-C-H-HU)</Link>

        {/* <hr />
        <div style={{marginBottom: 10}}><b>Criteria 6</b></div> */}
        <Link to='/viewegovernadmin'> E- governance (6.2.3 A-U-C, 6.2.2 H, 6.2.3 HU)</Link>
        <Link to='/viewteacherfsadmin'> Faculty Finacial Support (6.3.2 A-U-C-H-HU)</Link>
        <Link to='/vieweventfdp'> FDP (6.3.4 A-U-C-H-HU, 5.7 NBA)</Link>
        <Link to='/viewqualityadmin'> Quality Initiatives (6.5.3 A-C-H-HU, 6.5.2 U)</Link>
        <Link to='/viewfdpadmin'> FDP(AQAR) (6.3.4 A-U-C-H-HU)</Link>
        <Link to='/viewfundsadmin'> Funds (6.4.2 A-U-C-H-HU, 6.4.2 -6.4.3 U-HU)</Link>
        <Link to='/viewqualityinitadmin'> Quality Initiatives(AQAR) (6.5.3 A-C-H-HU, 6.5.2 U)</Link>
       
        <hr />
        <div style={{marginBottom: 10}}><b>Activities</b></div>
        <Link to='/vieweventadmin'> All events (NAAC - 4.5.1 NBA)</Link>
        <Link to='/vieweventipr'> Reserach / IPR</Link>
        <Link to='/vieweventextension'> Extension / Outreach</Link>

        <hr />
<div style={{marginBottom: 10}}><b>Nomenclature</b></div>
A - Affiliated college<br />
U - University<br />
C- Autonomous institution<br />
H- Health Science institution<br />
HU- Health Science university<br />

<br />
        <br />

        <hr />





        {/* <div style={{marginBottom: 10}}><b>Useful links</b></div> */}
        {/* <Link to='/dashcourse'> Dashboard</Link>
        <Link to='/workload'> Create Class</Link> */}

        <br /><br /><br /><vr />
        
     
        
        </div>

</div>
    );

}

export default SidefacNavigation;