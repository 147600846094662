import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/Sfin1';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import { duration } from "@material-ui/core";



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const yearref=useRef();
const clientref=useRef();
const milestoneref=useRef();
const amountref=useRef();
const gstref=useRef();
const totalref=useRef();
const invoiceidref=useRef();
const duedateref=useRef();
const actualdateref=useRef();
const paidamountref=useRef();
const bankref=useRef();
const refnoref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const patentid=global1.addonid;


    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       

       yearref.current.value=global1.year;
clientref.current.value=global1.client;
milestoneref.current.value=global1.milestone;
amountref.current.value=global1.amount;
gstref.current.value=global1.gst;
totalref.current.value=global1.total;
invoiceidref.current.value=global1.invoiceid;
duedateref.current.value=global1.duedate;
actualdateref.current.value=global1.actualdate;
paidamountref.current.value=global1.paidamount;
bankref.current.value=global1.bank;
refnoref.current.value=global1.refno;


    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        //const title=titleref.current.value;
        const year=yearref.current.value;
const client=clientref.current.value;
const milestone=milestoneref.current.value;
const amount=amountref.current.value;
const gst=gstref.current.value;
const total=totalref.current.value;
const invoiceid=invoiceidref.current.value;
const duedate=duedateref.current.value;
const actualdate=actualdateref.current.value;
const paidamount=paidamountref.current.value;
const bank=bankref.current.value;
const refno=refnoref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updateninvoicebyfac', {
            params: {
                id: patentid,
                user: user,
                token: token,
                name: name,
                colid: colid,
                year:year,
client:client,
milestone:milestone,
amount:amount,
gst:gst,
total:total,
invoiceid:invoiceid,
duedate:duedate,
actualdate:actualdate,
paidamount:paidamount,
bank:bank,
refno:refno,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewninvoice');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Edit data</p>
                        <hr />
                       

                   <label htmlFor="year">year</label>

<select id="year" className="form-control"  ref={yearref}>
<option value="2017-18">2017-18</option>
<option value="2018-19">2018-19</option>
<option value="2019-20">2019-20</option>
<option value="2020-21">2020-21</option>
<option value="2021-22">2021-22</option>
<option value="2022-23">2022-23</option>
</select>
<br /><br />

Enter client<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter client" id="client" ref={clientref} />
<br /><br />

Enter milestone<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter milestone" id="milestone" ref={milestoneref} />
<br /><br />

Enter amount<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter amount" id="amount" ref={amountref} />
<br /><br />

Enter gst<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter gst" id="gst" ref={gstref} />
<br /><br />

Enter total<br /><br />

<input type="Number" required style={{width: "100%"}} placeholder="Enter total" id="total" ref={totalref} />
<br /><br />

Enter invoiceid<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter invoiceid" id="invoiceid" ref={invoiceidref} />
<br /><br />

Enter duedate<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter duedate" id="duedate" ref={duedateref} />
<br /><br />

Enter actualdate<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter actualdate" id="actualdate" ref={actualdateref} />
<br /><br />

Enter paidamount<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter paidamount" id="paidamount" ref={paidamountref} />
<br /><br />

Enter bank<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter bank" id="bank" ref={bankref} />
<br /><br />

Enter refno<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter refno" id="refno" ref={refnoref} />
<br /><br />


                  


                      
                        <button  onClick={searchapi}>Update data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;