import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo,faUpload, faEdit, faComment, faFileAlt, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { DataGrid } from '@material-ui/data-grid';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import SidedocNavigation from '../components/layout/SidedocNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import Spinner from 'react-bootstrap/Spinner';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const linkref=useRef();
    const moduleref=useRef();
    const topicref=useRef();

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState([]);
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());
    const [format, setFormat] = useState(false);

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;
    //const name=global1.name;

    const [isUploading, setIsuploading] = useState(false);

    const columns = [
        // { field: '_id', headerName: 'ID', width: 70 },
        { field: 'student', headerName: 'Student', width: 130 },
        { field: 'regno', headerName: 'Reg no', width: 130 },
      ];

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    const edit = (id, title,  patentno,  yop, faculty) => {
        global1.patentid=id;
        global1.title=title;
        global1.patentno=patentno;
        global1.yop=yop;
        global1.faculty=faculty;
        history.replace('/editpatent');
    }

    const addperiod = () => {
        history.replace('/addperiod');
    }

    const addstudents = () => {
        // global1.courseid=courseid;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/addstudents');
    }

    const upload = (id) => {
        global1.field1=id;
        global1.criteria='3';
        global1.metric='3.4.3';
        global1.collection1='patent';
        global1.type='Patent';
        global1.pagenav='viewpatent';
        
        //history.replace('/selectawsconfig');
        history.replace('/selectmetricrule');
    }

    const list = (id) => {
        global1.field1=id;
        global1.criteria='3';
        global1.metric='3.4.3';
        global1.collection1='patent';
        global1.type='Patent';
        
        history.replace('/viewsupportingdoc');
    }

    

   

    let tutorials = [];
    const searchApi = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getpatentbyfaculty', {
            params: {
                user: user,
                token: token,
                colid: colid
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        localStorage.setItem("patent", JSON.stringify(response.data.data.classes));

       

        
        setResults(response.data.data.classes);
        setLink(response.data.data.link);
        //alert('format ' + format);
        formattable();
        
       
        //alert(results.length);
       
    };

    function formattable() {
        if (!format) {
            $('#example').DataTable(
                {
                    pagingType: 'full_numbers',
                      pageLength: 5,
                      processing: true,
                      dom: 'Bfrtip',
                          buttons: ['copy', 'csv', 'print'
                          ]
                }
            );
            setFormat(true);

        }
    }

   

    const deleteenrolment = async (enrolmentid) => {
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deletepatentbyfaculty', {
            params: {
                id: enrolmentid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const getlocal = () => {
        let collection=localStorage.getItem("patent");
        if(!collection) {

        } else {
            setResults(JSON.parse(collection));

        }
        
    }

    

    useEffect(() => {
        checklogin();
        //search1();
        //getexamlist();
        getlocal();
        searchApi('');
        //$('#example').DataTable();
        //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable();
    //     },
    //     1000
    //     );
    // });
    
    //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable(
    //         {
    //             pagingType: 'full_numbers',
    //               pageLength: 5,
    //               processing: true,
    //               dom: 'Bfrtip',
    //                   buttons: ['copy', 'csv', 'print'
    //                   ]
    //         }
    //     );
    //     } ,
    //     1000
    //     );
    // });
        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);


    // const uploadlink = async (link1, id1, yop1) => {
    //     link1=link1 + '?criteria=3&Section=3.4.3&Subsection=Patent&field1=' + id1 + '&Year=' + yop1 + '&userid=' + user; 
    //     var win = window.open(link1, '_blank');
    //     win.focus();
    //   };

      const uploadlink = async (link1, id1, yop1) => {
        global1.link2=link1;
        global1.field1=id1;
        global1.yop1=yop1;
        global1.criteria='3';
        global1.section1='3.4.3';
        // link1=link1 + '?criteria=3&Section=3.4.6&Subsection=Book&field1=' + id1 + '&Year=' + yop1 + '&userid=' + user; 
        // var win = window.open(link1, '_blank');
        // win.focus();
        history.replace('/selectmetricrulefiles');
      };

      const list1 = async (id1) => {
        //var link1='http://localhost:3001/viewsupportingdocquery';
        var link1=window.location.origin + '/viewsupportingdocqueryuser';
        link1=link1 + '?field1=' + id1 + '&colid=' + colid + '&user=' + user + '&name=' + name + '&token=' + token; 
        var win = window.open(link1, '_blank');
        win.focus();
      };


    
   

    

    const width=window.innerWidth;
    const mdivwidth=width - 330;

    const uploadnew = (id, status1, name, title, patentnumber) => {
        // if(status1=='Accepted') {
        //     alert('This document is accepted. Please contact IQAC coordinator to allow editing');
        //     return;
        // }
        global1.field1=id;
        global1.criteria='1';
        global1.metric='1.3.4';
        global1.collection1='explearning';
        global1.type='Experiential Learning';
        global1.pagenav='viewprojects';
        
        //var funds1=funds + '-' + funds.toLocaleString('en-IN');

        global1.datatocheck=name + '`' + title + '`' + patentnumber + '`Government`Patent`Intellectual';

        global1.type='BOT';
        //global1.pagenav='viewevent';
        
        history.replace('/selectawsconfignew');
    }

    const uploadtitle = (id, status1, name, title, patentnumber) => {
        // if(status1=='Accepted') {
        //     alert('This document is accepted. Please contact IQAC coordinator to allow editing');
        //     return;
        // }
        global1.field1=id;
        global1.criteria='1';
        global1.metric='1.3.4';
        global1.collection1='explearning';
        global1.type='Experiential Learning';
        global1.pagenav='viewprojects';
        
        //var funds1=funds + '-' + funds.toLocaleString('en-IN');

        global1.datatocheck=title + '`' + patentnumber + '`Government`Patent`Intellectual';

        global1.type='BOT';
        //global1.pagenav='viewevent';
        
        history.replace('/selectawsconfignew');
    }

    const uploadsearch = (id, status1, name, title, patentnumber) => {
        // if(status1=='Accepted') {
        //     alert('This document is accepted. Please contact IQAC coordinator to allow editing');
        //     return;
        // }
        global1.field1=id;
        global1.criteria='1';
        global1.metric='1.3.4';
        global1.collection1='explearning';
        global1.type='Experiential Learning';
        global1.pagenav='viewprojects';
        
        //var funds1=funds + '-' + funds.toLocaleString('en-IN');

        global1.datatocheck=name;

        global1.type='BOT';
        //global1.pagenav='viewevent';
        
        history.replace('/selectawsconfignew');
    }

   
       
        //alert('loaded');
  

    


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width: 60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                   
                    <td> 
                        <TopNavigation />

                    
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
            <p style={{ fontSize: 18 }}>View patent</p>
            <hr />
           
            <table>
                <tr>
                    <td>
                    <Link to='/addpatent' className="btn btn-dark"> Add data</Link>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <Link to='/addbulkpatents' className="btn btn-dark"> Bulk add data</Link>

                    </td>
                </tr>
            </table>
           
    
        <br /><br />
        {isUploading ? (
				<div style={{ alignItems: 'center', marginTop: 30, marginBottom: 60}}>
                   <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
				</div>
			) : (
                <div></div>
			)} 
     
<Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
<th>Name</th>
      <th>Title</th>
      <th>Patent no</th>
      <th>Year of publication</th>
      <th>Status</th>
      {/* <th>Comments</th> */}
      <th>Action</th>
    </tr>
    </thead>
    <tbody>
    

    {results.map((meetup3) => {
                return (
                    <tr>
                        <td>
                        {meetup3.name}
                        </td>
                        <td>
                        {meetup3.title}
                        </td>
                        <td>
                            {meetup3.patentnumber}
                        </td>
                        <td>
                            {meetup3.yop}
                        </td>
                        <td>
                            {meetup3.status1}
                        </td>
                        {/* <td>
                            {meetup3.comments}
                        </td> */}
                        <td>
                        {link.map((meetup31) => {
                return (
                    <div>
                          <Button variant="outline-danger" onClick={() => uploadlink(meetup31.uploadlink,meetup3._id,meetup3.yop)}>
                        <FontAwesomeIcon icon={faFileAlt} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                            </Button>
                         
                       
                        </div>

                    );
                })}
                        <Button variant="outline-danger" onClick={() => deleteenrolment(meetup3._id)}>
                        <FontAwesomeIcon icon={faTrash} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                            </Button>
                            <Button variant="outline-danger" onClick={() => edit(meetup3._id,meetup3.title,meetup3.patentnumber,meetup3.yop, meetup3.name)}>
                        <FontAwesomeIcon icon={faEdit} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                            </Button>
                            {/* <Button variant="outline-danger" onClick={() => upload(meetup3._id)}>
                        <FontAwesomeIcon icon={faUpload} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                        Upload
                            </Button> */}
                            <Button variant="outline-danger" onClick={() => uploadnew(meetup3._id, meetup3.status1, meetup3.name, meetup3.title, meetup3.patentnumber)}>
                        <FontAwesomeIcon icon={faUpload} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                        Upload grant letter
                            </Button>
                            <Button variant="outline-danger" onClick={() => uploadsearch(meetup3._id, meetup3.status1, meetup3.name, meetup3.title, meetup3.patentnumber)}>
                        <FontAwesomeIcon icon={faUpload} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                        Upload search result
                            </Button>
                            <Button variant="outline-danger" onClick={() => uploadtitle(meetup3._id, meetup3.status1, meetup3.name, meetup3.title, meetup3.patentnumber)}>
                        <FontAwesomeIcon icon={faUpload} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                        Upload grant (if not in faculty name)
                            </Button>
                            {/* <Button variant="outline-danger" onClick={() => list(meetup3._id)}>
                        <FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                        List
                            </Button> */}
                             <Button variant="outline-danger" onClick={() => list1(meetup3._id)}>
                        <FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                        List
                    </Button> 
                        </td>
                
                    
                
                
                   

                   
                                </tr>
              

                );
            })}
            </tbody>
            </Table>

            <br /><br />
            <h5>Supporting documents</h5><hr />
            1. Award letter / letter of publish of patents<br /><br />
            Note: Patents published/awarded during the tenure faculty was with the institution will be considered. Letter must be in the name of the faculty.<br />





        


            

       
        
        </div>
        <FooterNav1 />
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;