import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import Sidecr1 from '../components/layout/SidelibNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import { duration } from "@material-ui/core";



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const clientref=useRef();
    const clientidref=useRef();
    const cgstref=useRef();
    const sgstref=useRef();
    const igstref=useRef();
    const amountref=useRef();
    const totalref=useRef();
    const duedateref=useRef();
    const createdonref=useRef();
    const clientgstref=useRef();
    const clientaddressref=useRef();
    const receiveddateref=useRef();
    const bankref=useRef();
    const accountnumberref=useRef();
    const amountreceivedref=useRef();
    const statusref=useRef();

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const invoiceid=global1.invoiceid;


    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       clientref.current.value=global1.client;
       clientidref.current.value=global1.clientid;
       cgstref.current.value=global1.cgst;
       sgstref.current.value=global1.sgst;
       igstref.current.value=global1.igst;
       amountref.current.value=global1.amount;
       totalref.current.value=global1.total;
       duedateref.current.value=global1.duedate;
       createdonref.current.value=global1.createdon;
       clientgstref.current.value=global1.clientgst;
       clientaddressref.current.value=global1.clientaddress;
       receiveddateref.current.value=global1.receiveddate;
       bankref.current.value=global1.bank;
       accountnumberref.current.value=global1.accountnumber;
       amountreceivedref.current.value=global1.amountreceived;
       statusref.current.value=global1.invoicestatus;

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        const client=clientref.current.value;
        const clientid=clientidref.current.value;
        const cgst=cgstref.current.value;
        const sgst=sgstref.current.value;
        const igst=igstref.current.value;
        const amount=amountref.current.value;        
        const total=totalref.current.value;
        const duedate=duedateref.current.value;
        const createdon=createdonref.current.value;
        const clientgst=clientgstref.current.value;
        const clientaddress=clientaddressref.current.value;
        const receiveddate=receiveddateref.current.value;
        const bank=bankref.current.value;
        const accountnumber=accountnumberref.current.value;
        const amountreceived=amountreceivedref.current.value;
        const status=statusref.current.value;

        
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updateinvoicebyfac', {
            params: {

                id: invoiceid,
                user: user,
                token:token,
                colid: colid,
                name: name,
                client: client,
                clientid: clientid,
                cgst: cgst,
                sgst: sgst,
                igst: igst,
                amount: amount,
                total: total,
                status:status,
                duedate: duedate,
                createdon: createdon,
                clientgst: clientgst,
                address: clientaddress,
                receiveddate: receiveddate,
                bank: bank,
                accountnumber: accountnumber,
                amountreceived: amountreceived

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewinvoice');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <Sidecr1 />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}>
                        
                        <p style={{ fontSize: 18 }}> Edit Invoice Details</p>
                        <hr />
                        
                        <p> Enter the Client Name </p>

                        <input type="text" required style={{width: '100%'}} placeholder="Enter client name" id="client" ref={clientref} />
                            
                        <br /><br />

                        <p> Enter the Client ID </p>

                        <input type="text" required style={{width: '100%'}} placeholder="Enter client ID" id="clientid" ref={clientidref} />
                            
                        <br /><br />

                        <p> Enter the CGST </p>
                        
                        <input type="number" required style={{width: '100%'}} placeholder="Enter cgst" id="cgst" ref={cgstref} />
                             
                        <br /><br /> 

                        <p> Enter the SGST </p>

                        <input type="number" required style={{width: '100%'}} placeholder="Enter sgst" id="sgst" ref={sgstref} />
                             
                        <br /><br /> 

                        <p> Enter the IGST </p>

                        <input type="number" required style={{width: '100%'}} placeholder="Enter igst" id="igst" ref={igstref} />
                             
                        <br /><br /> 

                        <p> Enter the Amount </p>

                        <input type="number" required style={{width: '100%'}} placeholder="Enter Amount" id="amount" ref={amountref} />
                             
                        <br /><br /> 

                        <p> Enter the Total</p>

                        <input type="number" required style={{width: '100%'}} placeholder="Enter Total" id="total" ref={totalref} />
                             
                        <br /><br /> 

                        <p> Enter the Due Date </p>

                        <input type="date" required style={{width: '100%'}} id="duedate" ref={duedateref} />
                             
                        <br /><br /> 

                        <p> Enter the Created On Date </p>

                        <input type="date" required style={{width: '100%'}} id="createdon" ref={createdonref} />
                             
                        <br /><br /> 

                        <p> Enter the Client GST </p>

                        <input type="text" required style={{width: '100%'}} placeholder="Enter Client GST" id="clientgst" ref={clientgstref} />
                             
                        <br /><br /> 

                        <p> Enter the Client Address </p>

                        <input type="text" required style={{width: '100%'}} placeholder="Enter Client address" id="clientaddress" ref={clientaddressref} />
                             
                        <br /><br /> 

                        <p> Enter the Received Date </p>

                        <input type="date" required style={{width: '100%'}} id="receiveddate" ref={receiveddateref} />
                             
                        <br /><br />

                        <p> Enter the Bank Details </p> 

                        <input type="text" required style={{width: '100%'}} placeholder="Enter Bank Detail" id="bank" ref={bankref} />
                             
                        <br /><br /> 

                        <p> Enter the Account Number </p>

                        <input type="text" required style={{width: '100%'}} placeholder="Enter Account Number" id="accountnumber" ref={accountnumberref} />
                             
                        <br /><br /> 

                        <p> Enter the Amount Received </p>

                        <input type="text" required style={{width: '100%'}} placeholder="Enter Amount Received" id="amountreceived" ref={amountreceivedref} />
                             
                        <br /><br /> 

                        <label htmlFor='status'>Status</label>
                            <select id="status" className="form-control"  ref={statusref}>
                                <option value="Accepted">Accepted</option>
                                <option value="Submitted">Submitted</option>
                                <option value="Not Paid">Not Paid</option>
                                
                       
                            </select>
                    <br /><br />

                    
                        
                    <button  onClick={searchapi}>Update data</button>


      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;