import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function Sideaqar1() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    



    return (
        <div className={classes1.sidebar}>
        <div className={classes1.sidebarinside}>   
    
        <div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />
<Link to='/viewbos'> Faculty engaged in Academic Council (1.1.3 A)</Link>
<Link to='/viewsyllabusrevaqao'>Old AQAR 1.1.1</Link>
<Link to='/viewcbcsuao'>Old AQAR CBCS 1.2.2</Link>
<Link to='/viewcbcs'>CBCS (1.2.1 A)</Link>
<Link to='/viewsyllabusrev'>View Syllabus Revision ( 1.1.2 - 1.2.2 U)</Link>
<Link to='/viewemployability'> View Course Focus on Employability (1.1.3 - 1.2.1 U)</Link>
<Link to='/viewaddoncourse'> Value added course (1.2.2 - 1.2.3 A, 1.3.2 - 1.3.3 U, 1.2.2-1.3.2-1.3.3 H )</Link>
<Link to='/viewexplearnproj'> View Experential Learning (1.3.2 )</Link>
<Link to='/viewfieldproj'> View Field Projects (1.3.3 A, 1.3.4 U, 1.3.4 H)</Link>

        <Link to='/viewevent'> All events</Link>


<hr />
<div style={{marginBottom: 10}}><b>Nomenclature</b></div>
A - Affiliated college<br />
U - University<br />
C- Autonomous institution<br />
H- Health Science institution<br />
HU- Health Science university<br />
        
        
        <hr />
        <div style={{marginBottom: 10}}><b>Useful links</b></div>
        <Link to='/dashcourse'> Dashboard</Link>
        <Link to='/getmyfeedback'> My feedback</Link>
     
        </div>       

</div>
    );

}

export default Sideaqar1;