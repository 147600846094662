import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faEdit, faComment, faFileAlt, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { DataGrid } from '@material-ui/data-grid';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import SidemetricNavigation from '../components/layout/SidemetricNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import Spinner from 'react-bootstrap/Spinner';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    
    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState([]);
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());
    const [format, setFormat] = useState(false);

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;
    //const name=global1.name;

    const [isUploading, setIsuploading] = useState(false);

    const columns = [
        // { field: '_id', headerName: 'ID', width: 70 },
        { field: 'student', headerName: 'Student', width: 130 },
        { field: 'regno', headerName: 'Reg no', width: 130 },
      ];

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    
    const addperiod = () => {
        history.replace('/addperiod');
    }  

    const addstudents = () => {
        // global1.courseid=courseid;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/addstudents');
    }

    let tutorials = [];
    const searchApi = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/gethigheredubyadmin', {
            params: {
                user: user,
                token: token,
                colid: colid
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        localStorage.setItem("p_highereduadmin", JSON.stringify(response.data.data.classes));

       

        
        setResults(response.data.data.classes);
        setLink(response.data.data.link);
        //alert('format ' + format);
        try{

            formattable();
        } catch(err) {

        }
        
       
        // alert(results.length);
       
    };

    function formattable() {
        if (!format) {
            $('#example').DataTable(
                {
                    pagingType: 'full_numbers',
                      pageLength: 5,
                      processing: true,
                      dom: 'Bfrtip',
                          buttons: ['copy', 'csv', 'print'
                          ]
                }
            );
            setFormat(true);

        }
    }

   

    const deleteenrolment = async (enrolmentid) => {
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deletehigheredubyfac', {
            params: {
                id: enrolmentid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const getlocal = () => {
        let collection=localStorage.getItem("p_highereduadmin");
        if(!collection) {

        } else {
            setResults(JSON.parse(collection));

        }
        
    }


    useEffect(() => {
        checklogin();
        //search1();
        //getexamlist();
        //getlocal();
        formattable();
        //searchApi('');
        //$('#example').DataTable();
        //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable();
    //     },
    //     1000
    //     );
    // });
    
    //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable(
    //         {
    //             pagingType: 'full_numbers',
    //               pageLength: 5,
    //               processing: true,
    //               dom: 'Bfrtip',
    //                   buttons: ['copy', 'csv', 'print'
    //                   ]
    //         }
    //     );
    //     } ,
    //     1000
    //     );
    // });
        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);


    const width=window.innerWidth;
    const mdivwidth=width - 330;

        //alert('loaded');
  

    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width: 60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidemetricNavigation />
                    </td>
                   
                    <td> 
                        <TopNavigation />

                    
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
            <p style={{ fontSize: 18 }}>NBA Diploma</p>
            <hr />
            
       <br />
        {isUploading ? (
				<div style={{ alignItems: 'center', marginTop: 30, marginBottom: 60}}>
                   <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
				</div>
			) : (
                <div></div>
			)} 
     
<Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
    <th>Criteria No.</th>
    <th>Criteria Name</th>
    <th>Metric No.</th>
    <th>Metric Name</th>
    <th>Links</th>
</tr>
</thead>
<tbody>

                    <tr>
                        <td>1</td>
                        <td>Vision, Mission and Program Educational Objectives</td>
                        <td>1.1</td>
                        <td>State the Vision and Mission of the Department & Institution</td>
                        <td>
                        <Link to='/viewnbaug11?name=1.1&question=Vision and Mission of the Department & Institution&accreditation=NBADIP&documents=Vision,Mission, PEO list' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Vision, Mission and Program Educational Objectives</td>
                        <td>1.2</td>
                        <td>State the Program Educational Objectives (PEOs)</td>
                        <td>
                        <Link to='/viewnbaug11?name=1.2&question=Program Educational Objectives&accreditation=NBADIP&documents=PEOs list' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Vision, Mission and Program Educational Objectives</td>
                        <td>1.3</td>
                        <td>Indicate where and how the Vision, Mission and PEOs are published and disseminated among stakeholders</td>
                        <td>
                        <Link to='/viewnbaug11?name=1.3&question=Indicate where and how the Vision, Mission and PEOs are published&accreditation=NBADIP&documents=Details of process, Its implementation ' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Vision, Mission and Program Educational Objectives</td>
                        <td>1.4</td>
                        <td>State the process for defining the Vision and Mission of the Department, and PEOs of the program</td>
                        <td>
                        <Link to='/viewnbaug11?name=1.4&question=Process for defining the Vision and Mission, PEOs&accreditation=NBADIP&documents=Process involved' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Vision, Mission and Program Educational Objectives</td>
                        <td>1.5</td>
                        <td>Establish consistency of PEOs with Mission of the Department</td>
                        <td>
                        <Link to='/viewnbaug11?name=1.5&question=Consistency of PEOs with Mission of the Department&accreditation=NBADIP&documents=Mission of the department, Mapping' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Program Curriculum and Teaching –Learning Processes</td>
                        <td>2.1.1</td>
                        <td>State the process used to identify extent of compliance of the curriculum for attaining the Program Outcomes and Program Specific Outcomes</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.1.1&question=Process used to identify extent of compliance&accreditation=NBADIP&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Program Curriculum and Teaching –Learning Processes</td>
                        <td>2.1.2</td>
                        <td>Contents beyond the Syllabus</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.1.2&question=Contents beyond the Syllabus&accreditation=NBADIP&documents=Details of the additional course/learning material/content/laboratory' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Program Curriculum and Teaching –Learning Processes</td>
                        <td>2.2.1</td>
                        <td>Describe Processes followed to improve quality of Teaching & Learning</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.2.1&question=Processes followed to improve quality of Teaching & Learning&accreditation=NBADIP&documents=Process for improvement' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Program Curriculum and Teaching –Learning Processes</td>
                        <td>2.2.2</td>
                        <td>Initiatives to improve the quality of semester tests and assignments</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.2.2&question=Initiatives to improve the quality of semester tests and assignments&accreditation=NBADIP&documents=Processes, Implementation details' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Program Curriculum and Teaching –Learning Processes</td>
                        <td>2.2.3</td>
                        <td>Quality of Experiments</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.2.3&question=Quality of experiments&accreditation=NBADIP&documents=Experimental methodologies, Innovative experiments' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Program Curriculum and Teaching –Learning Processes</td>
                        <td>2.2.4</td>
                        <td>Quality of Students Projects and Report Writing</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.2.4&question=Quality of student projects and report&accreditation=NBADIP&documents=Types of projects' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Program Curriculum and Teaching –Learning Processes</td>
                        <td>2.2.5</td>
                        <td>Industry interaction and Community Services</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.2.5&question=Initiatives related to industry interaction&accreditation=NBADIP&documents=Industry involvement in the program' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Program Curriculum and Teaching –Learning Processes</td>
                        <td>2.2.6</td>
                        <td>Information Access Facilities and Student Centric Learning Initiatives</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.2.6&question=Initiatives towards the New Education Policy&accreditation=NBADIP&documents=Availability of facilities & Effective Utilization' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Program Curriculum and Teaching –Learning Processes</td>
                        <td>2.2.7</td>
                        <td>New Initiatives for embedding Professional Skills</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.2.7&question=Initiatives for embedding Professional Skills&accreditation=NBADIP&documents=Initiatives and effective implementation' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Program Curriculum and Teaching –Learning Processes</td>
                        <td>2.2.8</td>
                        <td>Co-curricular & Extra-Curricular Activities</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.2.8&question=Co-curricular & Extra-Curricular Activities&accreditation=NBADIP&documents=Type of activities and relevance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Course Outcomes and Program Outcomes</td>
                        <td>3.1.1</td>
                        <td>Course Outcomes</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.1.1&question=CO of course from each semester&accreditation=NBADIP&documents=COs of all courses' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Course Outcomes and Program Outcomes</td>
                        <td>3.1.2</td>
                        <td>Number of Outcomes for a Course</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.1.2&question=Number of Outcomes for a Course&accreditation=NBADIP&documents=COs of all courses' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Course Outcomes and Program Outcomes</td>
                        <td>3.1.3</td>
                        <td>CO-PO/PSO matrices of courses</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.1.3&question=CO-PO/PSO matrices of courses&accreditation=NBADIP&documents=COs, POs, PSOs' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Course Outcomes and Program Outcomes</td>
                        <td>3.1.4</td>
                        <td>Program level Course-PO/PSO matrix of all courses</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.1.4&question=Program level Course-PO/PSO matrix of all courses&accreditation=NBADIP&documents=Program level Course-PO/PSO' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Course Outcomes and Program Outcomes</td>
                        <td>3.2.1</td>
                        <td>Describe the assessment processes upon which the evaluation of Course Outcome is based</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.2.1&question=Assessment processes used to gather data on which the evaluation of CO is based&accreditation=NBADIP&documents=Data collection processes' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Course Outcomes and Program Outcomes</td>
                        <td>3.2.2</td>
                        <td>Record the attainment of Course Outcomes of all courses with respect to set attainment levels</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.2.2&question=Measuring Course Outcomes attained through board examinations&accreditation=NBADIP&documents=Target set, Calculations' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Course Outcomes and Program Outcomes</td>
                        <td>3.3.1</td>
                        <td>Describe assessment tools and processes used for assessing the attainment of each POs and PSOs</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.3.1&question=Assessment tools, processes used for assessing the attainment of each POs, PSOs&accreditation=NBADIP&documents=Assessment tools, Processes used' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Course Outcomes and Program Outcomes</td>
                        <td>3.3.2</td>
                        <td>Provide results of evaluation of each PO & PSO</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.3.2&question=Attainment of PO, PSO&accreditation=NBADIP&documents=PO, PSO Attainment' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Students’ Performance</td>
                        <td>4.1</td>
                        <td>Enrolment Ratio</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.1&question=Enrolment Ratio&accreditation=NBADIP&documents=Student details' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Students’ Performance</td>
                        <td>4.2.1</td>
                        <td>Success rate without backlogs in any semester/year of study</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.2.1&question=Success rate without backlogs&accreditation=NBADIP&documents=Success rate' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Students’ Performance</td>
                        <td>4.2.2</td>
                        <td>Success rate in stipulated period of study[Total of with backlog + without backlog]</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.2.2&question=Success rate with & without backlogs&accreditation=NBADIP&documents=Success rate including backlogs' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Students’ Performance</td>
                        <td>4.3</td>
                        <td>Academic Performance in First Year</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.3&question=Academic Performance in First Year&accreditation=NBADIP&documents=Students academic performance in first year' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Students’ Performance</td>
                        <td>4.4</td>
                        <td>Academic Performance in Second Year</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.4&question=Academic Performance in Second Year&accreditation=NBADIP&documents=Students academic performance in second year' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Students’ Performance</td>
                        <td>4.5</td>
                        <td>Academic Performance in Final Year</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.5&question=Academic Performance in Final Year&accreditation=NBADIP&documents=Students academic performance in third year' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Students’ Performance</td>
                        <td>4.6</td>
                        <td>Placement, Higher Studies and Entrepreneurship</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.6&question=Placement, Higher Studies and Entrepreneurship&accreditation=NBADIP&documents=Placement details' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Students’ Performance</td>
                        <td>4.7.1</td>
                        <td>Professional societies / student chapters and organizing technical events</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.7.1&question=Professional societies&accreditation=NBADIP&documents=Technical events' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Students’ Performance</td>
                        <td>4.7.2</td>
                        <td>Publication of technical magazines, newsletters, etc.</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.7.2&question=Publications&accreditation=NBADIP&documents=Publications with the names of the editors, publishers, ' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>


                    <tr>
                        <td>4</td>
                        <td>Students’ Performance</td>
                        <td>4.7.3</td>
                        <td>Participation in inter-institute / state/national events by students of the program of study</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.7.3&question=Participation in events by students of the program of study&accreditation=NBADIP&documents=Participation details and certificates, award and recognitions' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Faculty Information and Contributions</td>
                        <td>5.1</td>
                        <td>Student-Faculty Ratio (SFR)</td>
                        <td>
                        <Link to='/viewnbaug11?name=5.1&question=Student-Faculty Ratio&accreditation=NBADIP&documents=All students & faculty count' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Faculty Information and Contributions</td>
                        <td>5.2.1</td>
                        <td>Faculty Qualification Index</td>
                        <td>
                        <Link to='/viewnbaug11?name=5.2.1&question=Faculty Qualification Index&accreditation=NBADIP&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Faculty Information and Contributions</td>
                        <td>5.2.2</td>
                        <td>Availability of Faculty/principal of that discipline with PhD. Qualification</td>
                        <td>
                        <Link to='/viewnbaug11?name=5.2.2&question=Faculty with PhD. Qualification&accreditation=NBADIP&documents=Faculty Phd Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Faculty Information and Contributions</td>
                        <td>5.3</td>
                        <td>Faculty Retention</td>
                        <td>
                        <Link to='/viewnbaug11?name=5.3&question=Faculty Retention&accreditation=NBADIP&documents=Regular faculty members details' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Faculty Information and Contributions</td>
                        <td>5.4.1</td>
                        <td>Faculty as participants in Faculty development/training activities conducted by other organizations</td>
                        <td>
                        <Link to='/viewnbaug11?name=5.4.1&question=Faculty development programs&accreditation=NBADIP&documents=Faculty participation certificates' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Faculty Information and Contributions</td>
                        <td>5.4.2</td>
                        <td>Organized/ Conducted FDPs and STTP by this department at State / National Level</td>
                        <td>
                        <Link to='/viewnbaug11?name=5.4.2&question=Faculty development programs&accreditation=NBADIP&documents=Faculty participation certificates' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Faculty Information and Contributions</td>
                        <td>5.5</td>
                        <td>Product development, Consultancy, Manufacturing contracts, testing contracts</td>
                        <td>
                        <Link to='/viewnbaug11?name=5.5&question=Product development, Consultancy, Manufacturing contracts, testing contracts&accreditation=NBADIP&documents=List of Projects with Title, Funding Agency, Amount and Duration' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Faculty Information and Contributions</td>
                        <td>5.6</td>
                        <td>Faculty Performance Appraisal and Development System (FPADS)</td>
                        <td>
                        <Link to='/viewnbaug11?name=5.6&question=Faculty Performance Appraisal and Development System&accreditation=NBADIP&documents=Policy & its implementation' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Facilities And Technical Support</td>
                        <td>6.1</td>
                        <td>Availability of adequate, well-equipped classrooms to meet the curriculum requirements</td>
                        <td>
                        <Link to='/viewnbaug11?name=6.1&question=Classroom Infrastructure&accreditation=NBADIP&documents=Classroom Equipments' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Facilities And Technical Support</td>
                        <td>6.2</td>
                        <td>Adequate and well-equipped laboratories, and technical manpower</td>
                        <td>
                        <Link to='/viewnbaug11?name=6.2&question=Infrastructure&accreditation=NBADIP&documents=Laboratory Equipments' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Facilities And Technical Support</td>
                        <td>6.3</td>
                        <td>Additional facilities created for improving the quality of learning experience in laboratories</td>
                        <td>
                        <Link to='/viewnbaug11?name=6.3&question=Additional facilities created for improving quality&accreditation=NBADIP&documents=Facilities and Effective utilisation' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>


                    <tr>
                        <td>6</td>
                        <td>Facilities And Technical Support</td>
                        <td>6.4</td>
                        <td>Laboratories maintenance and overall ambiance</td>
                        <td>
                        <Link to='/viewnbaug11?name=6.4&question=Maintainance&accreditation=NBADIP&documents=Laboratory Maintainance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Facilities And Technical Support</td>
                        <td>6.5</td>
                        <td>Availability of computing facility in the department</td>
                        <td>
                        <Link to='/viewnbaug11?name=6.5&question=Computing facilities&accreditation=NBADIP&documents=Available facilities' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Facilities And Technical Support</td>
                        <td>6.6</td>
                        <td>Language lab</td>
                        <td>
                        <Link to='/viewnbaug11?name=6.6&question=Language lab&accreditation=NBADIP&documents=Availability and Utilisation' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Continuous Improvement</td>
                        <td>7.1</td>
                        <td>Actions taken based on the results of evaluation of each of the POs & PSO</td>
                        <td>
                        <Link to='/viewnbaug11?name=7.1&question=Actions taken&accreditation=NBADIP&documents=Action taken and analysis report' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Continuous Improvement</td>
                        <td>7.2</td>
                        <td>Improvement in Success Index of Students without the backlog</td>
                        <td>
                        <Link to='/viewnbaug11?name=7.2&question=Academic Audit and actions taken&accreditation=NBADIP&documents=Assessment on improvement trends' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Continuous Improvement</td>
                        <td>7.3</td>
                        <td>Improvement in Placement, Higher Studies</td>
                        <td>
                        <Link to='/viewnbaug11?name=7.3&question=Improvement in Education&accreditation=NBADIP&documents=Processes implemented for quality improvement & utilisation' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Continuous Improvement</td>
                        <td>7.4</td>
                        <td>Improvement in Academic Performance in Final Year</td>
                        <td>
                        <Link to='/viewnbaug11?name=7.4&question=Improvement in the quality of students&accreditation=NBADIP&documents=Improvement processes impleted & utlised' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Continuous Improvement</td>
                        <td>7.5</td>
                        <td>Internal Academic Audits to Review Complete Academics & to Implement Corrective Actions on Continuous Basis</td>
                        <td>
                        <Link to='/viewnbaug11?name=7.5&question=Academic Audit and actions taken&accreditation=NBADIP&documents=Audit process & its implementation' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Continuous Improvement</td>
                        <td>7.6</td>
                        <td>New Facility created in the program</td>
                        <td>
                        <Link to='/viewnbaug11?name=7.6&question=New Facility created in the program&accreditation=NBADIP&documents=Facilities & its implementation' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>8</td>
                        <td>Student Support Systems</td>
                        <td>8.1</td>
                        <td>Mentoring system to help at individual level</td>
                        <td>
                        <Link to='/viewnbaug11?name=8.1&question=Mentoring system&accreditation=NBADIP&documents=Details of the mentoring system' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>8</td>
                        <td>Student Support Systems</td>
                        <td>8.2</td>
                        <td>Feedback analysis and reward /corrective measures taken</td>
                        <td>
                        <Link to='/viewnbaug11?name=8.2&question=Feedback analysis and reward /corrective measures taken&accreditation=NBADIP&documents=Methodology followed, Measures taken' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>


                    <tr>
                        <td>8</td>
                        <td>Student Support Systems</td>
                        <td>8.3</td>
                        <td>Feedback on facilities</td>
                        <td>
                        <Link to='/viewnbaug11?name=8.3&question=Student feedback on facilities, analysis and corrective action taken&accreditation=NBADIP&documents=Facilities, Analysis, Action taken' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>8</td>
                        <td>Student Support Systems</td>
                        <td>8.4</td>
                        <td>Career Guidance, Training, Placement</td>
                        <td>
                        <Link to='/viewnbaug11?name=8.4&question=Career Guidance, Training, Placement&accreditation=NBADIP&documents=Facility, its management and its effectiveness' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>8</td>
                        <td>Student Support Systems</td>
                        <td>8.5</td>
                        <td>Entrepreneurship Cell/Technology Business Incubator</td>
                        <td>
                        <Link to='/viewnbaug11?name=8.5&question=Entrepreneurship Cell/Technology Business Incubator&accreditation=NBADIP&documents=Management and its Effectiveness' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>9</td>
                        <td>Governance, Institutional Support and Financial Resources</td>
                        <td>9.1.1</td>
                        <td>State the Vision and Mission of the Institute</td>
                        <td>
                        <Link to='/viewnbaug11?name=9.1.1&question=Vision and Mission of the Institute&accreditation=NBADIP&documents=Availability, Relevance of statements' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>9</td>
                        <td>Governance, Institutional Support and Financial Resources</td>
                        <td>9.1.2</td>
                        <td>Governing body, administrative setup, functions of various bodies</td>
                        <td>
                        <Link to='/viewnbaug11?name=9.1.2&question=Governing body, administrative setup&accreditation=NBADIP&documents=Composition, memberships, functions, and responsibilities' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>9</td>
                        <td>Governance, Institutional Support and Financial Resources</td>
                        <td>9.1.3</td>
                        <td>Decentralization in working and grievance redressal mechanism</td>
                        <td>
                        <Link to='/viewnbaug11?name=9.1.3&question=Decentralization in working and grievance redressal mechanism&accreditation=NBADIP&documents=Faculty members, Mechanisms, Composition' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>9</td>
                        <td>Governance, Institutional Support and Financial Resources</td>
                        <td>9.1.4</td>
                        <td>Delegation of financial powers</td>
                        <td>
                        <Link to='/viewnbaug11?name=9.1.4&question=Delegation of financial powers&accreditation=NBADIP&documents=Utilization of financial powers' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>9</td>
                        <td>Governance, Institutional Support and Financial Resources</td>
                        <td>9.1.5</td>
                        <td>Transparency and availability of correct/unambiguous information in public domain</td>
                        <td>
                        <Link to='/viewnbaug11?name=9.1.5&question=Transparency and Availability of correct information in public domain&accreditation=NBADIP&documents=Policies, Rules, Processes' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>9</td>
                        <td>Governance, Institutional Support and Financial Resources</td>
                        <td>9.2.1</td>
                        <td>Adequacy of budget allocation at Institute Level</td>
                        <td>
                        <Link to='/viewnbaug11?name=9.2.1&question=Institute Level Budget Allocation&accreditation=NBADIP&documents=Total budget allocation at Institute Level' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>9</td>
                        <td>Governance, Institutional Support and Financial Resources</td>
                        <td>9.2.2</td>
                        <td>Utilization of allocated funds at Institute Level</td>
                        <td>
                        <Link to='/viewnbaug11?name=9.2.2&question=Institute Level Budget Utilization&accreditation=NBADIP&documents=Total budget utilization at Institute Level' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>9</td>
                        <td>Governance, Institutional Support and Financial Resources</td>
                        <td>9.2.3</td>
                        <td>Availability of the audited statements on the institute’s website</td>
                        <td>
                        <Link to='/viewnbaug11?name=9.2.3&question=Availability of audited statements on institute’s website&accreditation=NBADIP&documents=Weblink of audited statement' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>9</td>
                        <td>Governance, Institutional Support and Financial Resources</td>
                        <td>9.3.1</td>
                        <td>Adequacy of budget allocation department specific</td>
                        <td>
                        <Link to='/viewnbaug11?name=9.3.1&question=Department specific Budget Allocation&accreditation=NBADIP&documents=Total budget allocation programwise' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>9</td>
                        <td>Governance, Institutional Support and Financial Resources</td>
                        <td>9.3.2</td>
                        <td>Utilization of allocated funds department specific</td>
                        <td>
                        <Link to='/viewnbaug11?name=9.3.2&question=Department specific Budget Utilization&accreditation=NBADIP&documents=Total budget utilization programwise' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>9</td>
                        <td>Governance, Institutional Support and Financial Resources</td>
                        <td>9.4.1</td>
                        <td>Quality of learning resources</td>
                        <td>
                        <Link to='/viewnbaug11?name=9.4.1&question=Quality of learning resources&accreditation=NBADIP&documents=Available learning resources & accessbility to students' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>9</td>
                        <td>Governance, Institutional Support and Financial Resources</td>
                        <td>9.4.2</td>
                        <td>Internet</td>
                        <td>
                        <Link to='/viewnbaug11?name=9.4.2&question=Internet&accreditation=NBADIP&documents=Internet provider, Bandwidth, Wi Fi availability, Cyber Security measures' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>9</td>
                        <td>Governance, Institutional Support and Financial Resources</td>
                        <td>9.5</td>
                        <td>Institutional Contribution to the Community Development/ Go-green</td>
                        <td>
                        <Link to='/viewnbaug11?name=9.5&question=Contribution to the Community Development&accreditation=NBADIP&documents=List of Contributions' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>9</td>
                        <td>Governance, Institutional Support and Financial Resources</td>
                        <td>9.6</td>
                        <td>Alumni Performance and Connect</td>
                        <td>
                        <Link to='/viewnbaug11?name=9.6&question=Alumni Performance&accreditation=NBADIP&documents=Event report, participation list' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>


                    
                    

              
            </tbody>
            </Table>

            <br /><br />
            

            

       
        
        </div>
        <FooterNav1 />
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;