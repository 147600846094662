import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { Link, useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';


// import classes from './Allclasses.module.css';

import classes1 from './newform.module.css';

function Openclasses() {

    const favcontxt=useContext(FavoritesContext);

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    //const [term1, setTerm1] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [link, setLink] = useState();
    const [loading, setLoading] = useState();

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const semester=global1.semester;
    const section=global1.section;

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');


        }
    }

    

   


    const searchApi = async () => {
        //setLoading(true);
        const response = await ep1.get('/api/v1/getclassstudr', {
            params: {
                semester: semester,
                colid: colid,
                fac: term

            }

        });
        setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setResults(response.data.data.classes);
    };

    useEffect(() => {
        checklogin();
        //search1();
        //getexamlist();
        searchApi('');
        //searchApi1('');

    }, []);

    const searchApi23 = async (classid) => {
        //setLoading(true);
        const response = await ep1.get('/api/v1/getattend/' + classid, {
            params: {
                user: studid,
                name: name,
                colid: colid,
                regno: regno,
                semester: semester,
                section: section

            }

        });

    };

    function checkdata(examcode, examname) {
        alert (examcode + '-' + examname);
    }

    function timely(datestring) {
        var dt1=new Date(datestring);
        dt1.setHours(dt1.getHours() - 5);
        dt1.setMinutes(dt1.getMinutes() - 30);
        var suffix1=""
        if (dt1.getHours() - 5 > 11) {
            suffix1= "PM";
        } else {
            suffix1= "AM";
        }
        var str1=dt1.getHours() + ':' + dt1.getMinutes() + ' ' + suffix1;
        return str1;

        //navigation.navigate('ExamQO');
    }

    function joinclass1(courseid, email) {
        //searchApi23(classid);
        global1.courseid=courseid;
        global1.facemail=email;
        //_handlePressButtonAsync(classid, link);
        //navigation.navigate('Ndashenr1');
    }

    function joinclass2(courseid) {
        //searchApi23(classid);
        global1.coursecode=courseid;
        //_handlePressButtonAsync(classid, link);
        //navigation.navigate('Ncfiles1');
        Link.replace('/cfiles');
    }

    function joinclass(classid, link) {
        searchApi23(classid);
        _handlePressButtonAsync(classid, link);
        //navigation.navigate('ExamQO');
    }

    const _handlePressButtonAsync = async (classid, link) => {
        if(link=="NA") {
            link="https://meet.jit.si/" + classid;
        }
        var win = window.open(link, '_blank');
        win.focus();
      };

      function whiteboard(classid) {
        //searchApi23(classid);
        const link1="https://ctnodeapps2.azurewebsites.net/classboardstud/" + classid;
        _handlePressButtonAsync(classid, link1);
        //navigation.navigate('ExamQO');
    }

    function joinchat(classid) {
        //searchApi23(classid);
        const link2="https://ctnodeapps2.azurewebsites.net/classchatapp/" + classid + "?user=" + studid + "&colid=" + colid;
        _handlePressButtonAsync(classid, link2);
        //navigation.navigate('ExamQO');
    }

    return (
        <div style={{ width: 900, margin: 'auto ', marginTop: 30 }}>
            <h1>List of ongoing and upcoming classes</h1>
            <p style={{ fontSize: 25}}>Please click on the class name to get started.</p>
            <br />
           
            <br />
            
           
        <br />
       
        
        {results.map((item) => {
                return (
                <div className={classes1.card}>
                    {item.course} - {item.coursecode}
                    <br /><br />
                    <i>by {item.name}</i>
                    <br /><br />
                    <table>
                        <tr>
                            <td>
                                <div className={classes1.actions}>
                                    <button type="button" onClick={() => joinclass(item._id, item.link)}>Join Class</button>
                                </div>
                            </td>
                            <td width="20px"></td>
                            <td>
                                <div className={classes1.actions}>
                                    <button type="button" onClick={() => whiteboard(item._id) }>Class Board</button>
                                </div>
                            </td>
                            <td width="20px"></td>
                            <td>
                                <div className={classes1.actions}>
                                    <button type="button" onClick={() => joinchat(item._id)}>Join Chat</button>
                                </div>
                            </td>
                        </tr>
                    </table>
                        

                    
                    <br />
                </div>
                );
            })}
        
        </div>
        
    );

}

export default Openclasses;