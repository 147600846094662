import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function SidefacNavigation() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    



    return (
        <div className={classes1.sidebar}>
            <div className={classes1.sidebarinside}>

<div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />
            
        
        {/* <div style={{marginBottom: 10}}><b>Insights</b></div> */}
        <Link to='/viewnclientadmin'> Client list (AR)</Link>
<Link to='/viewnmilestoneadmin'> Client work milestone (AR)</Link>
<Link to='/viewninvoiceadmin'> Client Invoices (AR)</Link>
<Link to='/viewngstgainadmin'> GST Gain received (Incoming) - GST Reconcilliation</Link>
<Link to='/viewnvendoradmin'> Vendor list (AP)</Link>
<Link to='/viewnvworkorderadmin'> Vendor workorder milestone and schedule (AP)</Link>
<Link to='/viewnvinvoiceadmin'> Vendor invoice (AP)</Link>
<Link to='/viewntrialbalanceadmin'> Trial balance</Link>
<Link to='/viewnbudgetadmin'> Budget</Link>
<Link to='/viewnaccountsadmin'> Accounting head</Link>
<Link to='/viewnledgeradmin'> Ledger</Link>
<Link to='/viewprojectledgeradmin'> Project ledger</Link>
<Link to='/viewprojectcentraladmin'> Central projects</Link>
<Link to='/viewmaintenanceadmin'> Maintenance</Link>
<Link to='/viewotherincomeadmin'> Other income</Link>
<Link to='/viewnaccountheadsadmin'> Accounting heads</Link>
<Link to='/viewntransactionsadmin'> Transactions</Link>
<Link to='/viewntledgeradmin'> Ledger</Link>
<Link to='/selectfin'> Date wise summary ledger</Link>
<br /><br /><br /><br /><br /><br />
      
       

        <hr />
        {/* <div style={{marginBottom: 10}}><b>Useful links</b></div> */}
     
     

        
       </div>

</div>
    );

}

export default SidefacNavigation;