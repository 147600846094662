import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import Sidecr3Navigation from '../components/layout/Sidecr3Navigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const yearref=useRef();
    const fnameref=useRef();
    const moduleref=useRef();
    const platformref=useRef();
    const dateref=useRef();
    const facilityref=useRef();
    const doclinkref=useRef();
    const videolinkref=useRef();
    

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        const year=yearref.current.value;
        const fname=fnameref.current.value;
        const module=moduleref.current.value;
        const platform=platformref.current.value;
        const date=dateref.current.value;
        const facility=facilityref.current.value;
        const doclink=doclinkref.current.value;
        const videolink=videolinkref.current.value;
    
        

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/createecontentbyfac', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                year:year,
                fname: fname,
                module:module,
                platform:platform,
                date:date,
                facility: facility,
                doclink:doclink,
                videolink:videolink,
                status1:'Submitted',
                comments:'NA'

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewecontent');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <Sidecr3Navigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Add E-content</p>
                        <hr />
                       
                        <label htmlFor='year'>Academic year</label>
                        <select id="year" className="form-control"  ref={yearref}>
                            <option value="2016-17">2016-17</option>
                            <option value="2017-18">2017-18</option>
                            <option value="2018-19">2018-19</option>
                            <option value="2019-20">2019-20</option>
                            <option value="2020-21">2020-21</option>
                            <option value="2021-22">2021-22</option>
                       
                        </select>

                            <br /><br />

                        <input type="text" required style={{width: '100%'}} placeholder="Enter faculty name" id="fname" ref={fnameref} />
                            
                        <br /><br />    
                            
                        <input type="text" required style={{width: '100%'}} placeholder="Enter name of module developed" id="module" ref={moduleref} />
                            
                        <br /><br />

                        <input type="text" required style={{width: '100%'}} placeholder="Enter platform" id="platform" ref={platformref} />
                            
                        <br /><br />

                        <p> Enter date </p>
                        <input type="date" required style={{width: '100%'}} id="date" ref={dateref} />
                            
                        <br /><br />

                        <input type="text" required style={{width: '100%'}} placeholder="Enter facility" id="facility" ref={facilityref} />
                            
                        <br /><br />
    
                        <input type="text" required style={{width: '100%'}} placeholder="Enter document link" id="doclink" ref={doclinkref} />
                                
                        <br /><br /> 

                        <input type="text" required style={{width: '100%'}} placeholder="Enter video link" id="videolink" ref={videolinkref} />
                                
                        <br /><br /> 


                        

                        <button  onClick={searchapi}>Add E-content</button>

                      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;