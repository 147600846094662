import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function SidefacNavigation() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    



    return (
        <div className={classes1.sidebar}>
            <div className={classes1.sidebarinside}>

<div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />
            
        
        {/* <div style={{marginBottom: 10}}><b>Insights</b></div> */}
        <Link to='/viewnprogcourseadmin'> All programs and courses in last five years (All) (1.2.1.2 HA)</Link>
        <Link to='/viewnnursinginteradmin'> Interdisciplinary courses (1.2.1 HA)</Link>
        <Link to='/viewnnvacstudadmin'> Students in VAC (1.2.2 HA)</Link>
        <Link to='/viewhnewprogadmin'> New programs (1.2.2 HU)</Link>
        <Link to='/viewninteradmin'> Interdisciplinary courses (1.2.3 HU)</Link>
        <Link to='/viewnnexpadmin'> Experiential learning (1.3.4 HA)</Link>
        <Link to='/viewnnratioadmin'> Teacher student ratio (2.2.2 HA)</Link>
        <Link to='/viewnnmentoradmin'> Mentor mentee ratio (2.3.4 HA)</Link>
        <Link to='/viewnnexamadmin'> Exam reform (2.5.4 HA)</Link>
        <Link to='/viewnotherstudadmin'> Other state and country students (2.1.3 HU HA)</Link>
        <Link to='/viewnhlearneradmin'> Advanced learner (2.2.1 HU HA)</Link>
        <Link to='/viewnclinicskillsadmin'> Clinical skills lab (2.3.2 HU HA)</Link>
        <Link to='/viewnrevaladmin'> Revaluation (2.5.3 HU)</Link>
        <Link to='/viewnfacilitiesadmin'> Facilities (3.1.5 HU)</Link>
        <Link to='/viewndepfundingadmin'> Departmental Project Funding (3.1.6 All)</Link>
        <Link to='/viewnecourseadmin'> Ecourse training (2.4.4 HA)</Link>
<Link to='/viewnnextensionadmin'> Extension activities (3.4.1 and 3.4.2 HA)</Link>
<Link to='/viewnncollabadmin'> Collaboration (3.5.1 HA)</Link>
<Link to='/viewnnmouadmin'> MoU (3.5.2 HA)</Link>
<Link to='/viewnnpatientsadmin'> Patient attending (4.2.2 HA)</Link>
<Link to='/viewnnexposureadmin'>  Resources exposure (4.2.3 HA)</Link>
      
       <br /><br /><br /><br />

        <hr />
        {/* <div style={{marginBottom: 10}}><b>Useful links</b></div> */}
     
     

        
       </div>

</div>
    );

}

export default SidefacNavigation;