import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { DataGrid } from '@material-ui/data-grid';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import SidefacNavigation from '../components/layout/SidefacNavigation';
import Spinner from 'react-bootstrap/Spinner';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';


function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const linkref=useRef();
    const moduleref=useRef();
    const topicref=useRef();

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState();
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;
    //const name=global1.name;

    //const [loading, setLoading] = useState(false);

   

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    const addnewstudent = () => {
        history.replace('/enrollstudents');
    }

    const addperiod = () => {
        history.replace('/addperiod');
    }

    const addstudents = () => {
        // global1.courseid=courseid;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/addstudents');
    }

    

   

    let tutorials = [];
    const searchApi = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getmyproctoring', {
            params: {
                user: user,
                token: token,
                colid: colid

            }

        });
        setLoading(false);
        console.log(response.data.data);

        // {response.data.data.classes.map((meetup3) => {
        //     setResults(prevState => [{
        //         id: meetup3._id,
        //         student: meetup3.student,
        //         regno: meetup3.regno
        //     }, ...prevState,]);
        // })}

        
        setResults(response.data.data.classes);
        $('#example').DataTable(
            {
                pagingType: 'full_numbers',
                  pageLength: 5,
                  processing: true,
                  dom: 'Bfrtip',
                      buttons: ['copy', 'csv', 'print'
                      ]
            }
        );
       
        //alert(results.length);
       
    };

   

    const deleteexamenrolment = async (enrolmentid) => {
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteexamenr', {
            params: {
                id: enrolmentid,
                token: token
            }

        });
        setLoading(false);
        searchApi();

       
    };

    

    useEffect(() => {
        checklogin();

        searchApi('');
     
  
    }, []);

    function timely(datestring) {
        var dt1=new Date(datestring);
        //dt1.setHours(dt1.getHours() - 5);
        //dt1.setMinutes(dt1.getMinutes() - 30);
        var suffix1="";
        if (dt1.getHours() - 5 > 11) {
            suffix1= "PM";
        } else {
            suffix1= "AM";
        }
        var str1= dt1.getDate() + '/' + (dt1.getMonth() + 1) + '/' + dt1.getFullYear() + ' ' + dt1.getHours() + ':' + dt1.getMinutes() + ' ' + suffix1;
        return str1;

    }

    
    const _handlePressButtonAsync = async (classid, link) => {
        if(link=="NA") {
            link="https://meet.jit.si/" + classid;
        }
        var win = window.open(link, '_blank');
        win.focus();
      };

    
   

    

    const width=window.innerWidth;
    const mdivwidth=width - 330;

   
       
        //alert('loaded');
  

    


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidecolorNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />

        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
            <p style={{ fontSize: 18 }}>Proctoring List</p>
            <hr />
           
    
        <br />
        {loading ? (
                <div style={{ alignItems: 'center', marginTop: 30, marginBottom: 60}}>
                   <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <div></div>
            )} 
<Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
      <th>Exam</th>
      <th>Student</th>
      <th>Reg No</th>
      <th>Start date</th>
      <th>End date</th>
      <th>Actions</th>
    </tr>
    </thead>
    <tbody>
    

    {results.map((meetup3) => {
                return (
                    <tr>
                        <td>
                        {meetup3.course}
                        </td>
                        <td>
                        {meetup3.student}
                        </td>
                        <td>
                            {meetup3.regno}
                        </td>
                        <td>
                            {timely(meetup3.startdate)}
                        </td>
                        <td>
                            {timely(meetup3.enddate)}
                        </td>
                        
                        <td>
                        <Button variant="outline-danger" onClick={() => _handlePressButtonAsync(meetup3.examid, meetup3.proctorlink)}>
                        <FontAwesomeIcon icon={faVideo} style={{fontSize: 18, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} />
                            Start Proctoring</Button>  
                                     
                        </td>
                                </tr>
              

                );
            })}
            </tbody>
            </Table>

            <br /><br />

        
        </div>
        <FooterNav1 />
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;