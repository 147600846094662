import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function SidefacNavigation() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    



    return (
        <div className={classes1.sidebar}>
            <div className={classes1.sidebarinside}>

<div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />
            
        
        <div style={{marginBottom: 10}}><b>Workload</b></div>
        <hr />
        <Link to='/addworkloadbulk'> Bulk add workload</Link>
        <Link to='/viewallfacultieslms'> Facultywise Workload</Link>
        <Link to='/viewallfacultieslmsdep'> Facultywise Workload (Departmental)</Link>
        <Link to='/viewworkloadadmin'> All Workload</Link>
        <Link to='/deleteworkload'> Delete Workload</Link>

        <div style={{marginBottom: 10}}><b>Timetable</b></div>
        <hr />
        <Link to='/addclassnewweeks'> Add Timetable (Multiple weeks)</Link>
<Link to='/addclassbulk'> Add Timetable Bulk</Link>
<Link to='/viewclassadmin'> All Timetable</Link>
<Link to='/selectprogramstt'> Programwise Timetable for 7 days</Link>

<div style={{marginBottom: 10}}><b>Programwise current year</b></div>
        <hr />
<Link to='/viewcurrenyear'> Current year configuration</Link>
<Link to='/viewcurrenyearadmin'> Current year configuration (all)</Link>

        
<div style={{marginBottom: 10}}><b>Other reports</b></div>
        <hr />
<Link to='/viewlessonplanadmin'> Lessonplan</Link>
<Link to='/viewcoursefilesadmin'> Course files</Link>

{/* <Link to='/addclassnew'> Add Timetable (Single class)</Link> */}

<Link to='/viewcoadmin'> All Course Outcome</Link>
<Link to='/viewlannouncementadmin'> All announcement</Link>
<Link to='/viewlsyllabusadmin'> All syllabus</Link>
<Link to='/viewlcalendaradmin'> All Course Calendar</Link>
<Link to='/viewstudparentsadmin'> All parents data</Link>

      
       

        <hr />
        {/* <div style={{marginBottom: 10}}><b>Useful links</b></div> */}
     
     

        
       </div>

</div>
    );

}

export default SidefacNavigation;