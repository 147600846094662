import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidendashNavigation from '../components/layout/SidemetricNavigationadmin';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const yearref=useRef();
    const dateref=useRef();
    const linkref=useRef();
    const departmentref=useRef();
    const taskref=useRef();
    const percentageref=useRef();
    const statusref=useRef();
    const commentsref=useRef();
    const sendmailref=useRef();
    const toref=useRef();
    const timeref=useRef();
    
    

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };
    
    const [faculties, setFaculties] = useState([]);
    const [mlink, setMlink] = useState([]);

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    //const department=global1.department;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        const year=yearref.current.value;
        const link=linkref.current.value;
        const date=dateref.current.value;
        const department=departmentref.current.value;
        const task=taskref.current.value;
        const percentage=percentageref.current.value;
        const status=statusref.current.value;
        const comments=commentsref.current.value;
        const sendmail=sendmailref.current.value;
        const to=toref.current.value;
        const time=timeref.current.value;
        
    
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/createprojectstatus', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                year:year,
                date:date,
                department: department,
                task:task,
                percentage:percentage,
                status: status,
                comments:comments,
                link:link

            }
        });

        var doclist='';
        if(task=='Initial faculty meeting conducted') {
            doclist='<b>Intended audience</b><br><br>';
            doclist=doclist + 'All faculties of the department<br><br>';
            doclist=doclist + '<b>Data and documents</b><br>';
            doclist=doclist + 'The faculties would be required to upload the following data and documents to the portal for last five years. We will discuss the same in the meeting.<br><br>';
            doclist=doclist + '1. Details of publication, funded projects, consultancy activities, books published, patents, Ph.D. scholars guided, Innovation awards received.<br>';
            doclist=doclist + '2. Supporting documents - proof of participation in seminar, sanction letter for project and consultancy, link of journal and article, first page of journal, patent award/publish copy, allotment letter for Ph.D. students, Ph. D. award certificate of scholars guided, award copy.<br><br>';
        } else if(task=='HOD meeting conducted') {
            doclist='<b>Intended audience</b><br><br>';
            doclist=doclist + 'HoD<br><br>';
            doclist=doclist + '<b>Data and documents</b><br>';
            doclist=doclist + 'The following data and documents need to be uploaded to the portal for last five years. We will discuss the same in the meeting.<br><br>';
            doclist=doclist + '1. List of CO, PO and attainment calculation <br>';
            doclist=doclist + '2. Projects, Fields studies and internships conducted by students for last academic year<br>';
            doclist=doclist + '3. List of value added courses with documentation.<br>';
            doclist=doclist + '4. Details of mentoring, remedial coaching and support for advanced learners.<br>';
            doclist=doclist + '5. Extension lectures, guest lectures conducted outside curriculum.<br>';
            doclist=doclist + '6. Geo tagged photos of facilities in departments (classrooms, labs, any other facilities)<br><br>';
        }

        if(sendmail=='Yes') {

            var html='Dear sir/maam,<br><br>You are invited to the following meeting on ' + date + ' ' + time + '.<br><br>' + link + '<br><br>';
            html=html + doclist;
            html=html + 'Regards,<br><br>';
            html=html + 'Campus Technology Team<br><br>Sent on behalf of Institution by ' + name + '(' + user + ')<br><br>';
            html=html + 'For any questions, please contact ' + name + ' directly and do not reply to this email.';
            var cc='pooja.vasudev@epaathsala.com,suman@campus.technology,' + user;
            var subject='Meeting scheduled';

            const response1 = await ep1.get('/api/v1/sendmail', {
                params: {
                    user: user,
                    token: token,
                    colid: colid,
                    name: name,
                    to:to,
                    cc:cc,
                    subject:subject,
                    html:html
    
                }
            });
            var dt1=new Date();

            const response2 = await ep1.get('/api/v1/createprojectstatus', {
                params: {
                    user: user,
                    token: token,
                    colid: colid,
                    name: name,
                    year:year,
                    date:date,
                    department: department,
                    task:'Email sent',
                    percentage:100,
                    status: 'Yes',
                    comments:'Email sent on ' + dt1.toString(),
                    link:'NA'
    
                }
            });

        }
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewprojectstatus');
       
    };


    useEffect(() => {
        //logout();\
        getfacultylist();
        getmlink();
  
        
 
     }, []);

    const getfacultylist = async () => {
        // setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getdepartmentlist', {
        params: {
        colid: colid,
        user: user,
        token: token
        
        }
        
        });
        // setLoading(false);
        console.log(response.data.data);
        setFaculties(response.data.data.classes);
        //alert(results.length);
        };

        const getmlink = async () => {
            // setLoading(true);
            setTerm(studid);
            const response = await ep1.get('/api/v1/getmlink', {
            params: {
            colid: colid,
            user: user,
            token: token
            
            }
            
            });
            // setLoading(false);
            console.log(response.data.data);
            setMlink(response.data.data.classes);
            //alert(results.length);
            };
   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidendashNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Add Project Status</p>
                        <hr />
                       
                        <label htmlFor='year'>Academic Year</label>
                        <select id="year" className="form-control"  ref={yearref}>
                            <option value="2016-17">2016-17</option>
                            <option value="2017-18">2017-18</option>
                            <option value="2018-19">2018-19</option>
                            <option value="2019-20">2019-20</option>
                            <option value="2020-21">2020-21</option>
                            <option value="2021-22">2021-22</option>
                       
                        </select>

                            <br /><br />

                        <p>Date of Communication</p>
                        <input type="date" required style={{width: '100%'}} id="date" ref={dateref} />
                            
                            <br /><br />

                            <input type="text" required style={{width: '100%'}} placeholder="Enter meeting time" id="time" ref={timeref} />
                            
                            <br /><br />

                        <label htmlFor='department'>Select Department</label>
                        <select id="department" className="form-control" ref={departmentref}>
                            <option value="1">Select Department</option>

                            {faculties.map((meetup3) => {
                            return (
                            <option value={meetup3}>{meetup3}</option>
                        );
                        })}
                        </select>
                            <br />

                            <label htmlFor='link'>Select link</label>
                        <select id="link" className="form-control" ref={linkref}>
                            <option value="1">NA</option>

                            {mlink.map((meetup3) => {
                            return (
                            <option value={meetup3.link}>{meetup3.link}</option>
                        );
                        })}
                        </select>
                            <br />

                            
                            <label htmlFor='year'>Task</label>
                        <select id="task" className="form-control"  ref={taskref}>
                            <option value="Initial faculty meeting conducted">Initial faculty meeting conducted</option>
                            <option value="HOD meeting conducted">HOD meeting conducted</option>
                            <option value="Faculty data updation">Faculty data updation</option>
                            <option value="Departmental data updation">Departmental data updation</option>
                            <option value="Faculty data validation">Faculty data validation</option>
                            <option value="Departmental data validation">Departmental data validation</option>
                            <option value="Departmental data validation L2">Departmental data validation L2</option>
                            <option value="Departmental data validation L3">Departmental data validation L3</option>
                            <option value="Departmental data validation L4">Departmental data validation L4</option>
                            <option value="Departmental data validation L5">Departmental data validation L5</option>
                            <option value="Departmental data validation L6">Departmental data validation L6</option>
                            <option value="Email sent">Email sent</option>
                            <option value="Call received">Call received</option>
                            <option value="CO PO Attainment">CO PO Attainment</option>
                            <option value="Feedback completed">Feedback completed</option>
                            <option value="LMS implemented">LMS implemented</option>
                            <option value="Online Fees">Online Fees</option>
                       
                        </select>

                            <br /><br />

                            <input type="text" required style={{width: '100%'}} placeholder="Enter to email" id="to" ref={toref} />
                            
                            <br /><br /> 

                           

                        <input type="number" required style={{width: '100%'}} placeholder="Enter percentage of work done" id="percentage" ref={percentageref} />
                            
                        <br /><br /> 

                        <label htmlFor='status'>Completed</label>
                        <select id="status" className="form-control"  ref={statusref}>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                            
                       
                        </select>
                            <br /><br />  

                            <label htmlFor='sendmail'>Send mail</label>
                        <select id="sendmail" className="form-control"  ref={sendmailref}>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                            
                       
                        </select>
                            <br /><br /> 
                       
                        <input type="text" required style={{width: '100%'}} placeholder="Enter comments" id="comments" ref={commentsref} />
                            
                            <br /><br />

                        
                        
                        <button  onClick={searchapi}>Submit</button>

                      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;