import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/SidedocNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const pinameref=useRef();
    const titleref=useRef();
    const durationref=useRef();
    const membershipref=useRef();
    const yopref=useRef();
    const amountref=useRef();
    const roleref=useRef();
    const paperpresentedref=useRef();
    const levelref=useRef();
    const typeref=useRef();
  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       pinameref.current.value=global1.name;

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        const title=titleref.current.value;
        const membership=membershipref.current.value;
        const amount=amountref.current.value;
        const yop=yopref.current.value;
        const duration=durationref.current.value;
        const role=roleref.current.value;
        const paper=paperpresentedref.current.value;
        const level=levelref.current.value;
        const type=typeref.current.value;
        const piname=pinameref.current.value;
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/createseminarbyfac', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: piname,
                title: title,
                duration:duration,
                membership: membership,
                amount:amount,
                yop:yop,
                status1:'Submitted',
                comments:'',
                role:role,
                paper:paper,
                level:level,
                type: type

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewseminar');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Add seminar/conference/workshop participation</p>
                        <hr />
                       
                        Name <br />    
                            <input type="text" required style={{width: '100%'}} placeholder="Enter name" id="piname" ref={pinameref} />
                            
                        <br /><br />


                        Title <br />    
                            <input type="text" required style={{width: '100%'}} placeholder="Enter title" id="title" ref={titleref} />
                            
                        <br /><br />
                        Enter duration from to. For example, 23/03/2021-24/03/2021. In case of one day seminar, mention the same date for both from and to
                        <br />
                        <input type="text" required style={{width: '100%'}} placeholder="Enter duration" id="duration" ref={durationref} />
                            
                            <br /><br />
                            If you received any financial support from institution to attend this seminar please enter the amount. if not received, enter 0.
                            <br />

                            <input type="number" required style={{width: '100%'}} placeholder="Enter amount" id="amount" ref={amountref} />
                            
                            <br /><br />

                            Did you receive any reimbursement for any membership for any professional body for this seminar? If yes, enter the amount, else enter 0.
                            <br />
    

                        <input type="text" required style={{width: '100%'}} placeholder="Enter membership" id="membership" ref={membershipref} />
                            
                            <br /><br />

                            <input type="text" required style={{width: '100%'}} placeholder="Enter name of paper presented, if any" id="paperpresented" ref={paperpresentedref} />
                            
                            <br /><br />

                            <label htmlFor='level'>Level</label>
                    <select id="level" className="form-control"  ref={levelref}>
                    <option value="National">National</option>
                    <option value="Interational">International</option>
                    <option value="State">State</option>
                    <option value="Local">Local</option>
                   
                       
                    </select>
                    <br /><br />

                            <label htmlFor='role'>Role</label>
                    <select id="role" className="form-control"  ref={roleref}>
                    <option value="Participated">Participated</option>
                    <option value="Presented">Presented</option>
                    <option value="Invited">Invited</option>
                   
                       
                    </select>
                    <br /><br />

                            <label htmlFor='academicyear'>Academic year</label>
                    <select id="academicyear" className="form-control"  ref={yopref}>
                    <option value="2016-17">2016-17</option>
                    <option value="2017-18">2017-18</option>
                    <option value="2018-19">2018-19</option>
                    <option value="2019-20">2019-20</option>
                        <option value="2020-21">2020-21</option>
                        <option value="2021-22">2021-22</option>
                       
                    </select>
                    <br /><br />

                    <label htmlFor='type'>Type</label>
                    <select id="type" className="form-control"  ref={typeref}>
                    <option value="Seminar">Seminar</option>
                    <option value="FDP">FDP</option>
                    <option value="Webinar">Webinar</option>
                    <option value="Conference">Conference</option>
                    <option value="Workshop">Workshop</option>
                        
                       
                    </select>
                    <br /><br />

                  
                  


                      
                        <button  onClick={searchapi}>Add seminar</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;