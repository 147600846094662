import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/An';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);



    const softreqref=useRef();
const availsoftref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
       

        const softreq=softreqref.current.value;
const availsoft=availsoftref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/createpausoftwarebyfac', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                softreq:softreq,
availsoft:availsoft,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewnpausoftware');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Add data</p>
                        <hr />
                       
                            
                  <label htmlFor="softreq">Software required</label>

<select id="softreq" className="form-control"  ref={softreqref}>
<option value="System software(3)">System software(3)</option>
<option value="application software(20)">application software(20)</option>
</select>
<br /><br />

<label htmlFor="availsoft">Name of the software available</label>

<select id="availsoft" className="form-control"  ref={availsoftref}>
<option value="7 Zip File Manager">7 Zip File Manager</option>
<option value="Adobe Acrobat Pro XI">Adobe Acrobat Pro XI</option>
<option value="Adobe Creative Suite 6 ">Adobe Creative Suite 6 </option>
<option value="Adobe Flash Plugin">Adobe Flash Plugin</option>
<option value="Adobe Shockwave Plugin">Adobe Shockwave Plugin</option>
<option value="Android">Android</option>
<option value="ArchLinux">ArchLinux</option>
<option value="CoreFTP ">CoreFTP </option>
<option value="Debian">Debian</option>
<option value="Earth's Dynamic Surface">Earth's Dynamic Surface</option>
<option value="Eclipse Classic 4.2.1 ">Eclipse Classic 4.2.1 </option>
<option value="Explore the Planets">Explore the Planets</option>
<option value="FamilyGames Geography Tutors">FamilyGames Geography Tutors</option>
<option value="Fedora">Fedora</option>
<option value="GMAT Prep">GMAT Prep</option>
<option value="Google Chrome 39">Google Chrome 39</option>
<option value="Google Earth 7">Google Earth 7</option>
<option value="Gretl 1.9.12">Gretl 1.9.12</option>
<option value="IDEA 9.1.1">IDEA 9.1.1</option>
<option value="Java SDK 8">Java SDK 8</option>
<option value="JAWS 15">JAWS 15</option>
<option value="JMP Pro 11">JMP Pro 11</option>
<option value="Komodo Edit 8">Komodo Edit 8</option>
<option value="Linux">Linux</option>
<option value="Linux Mint">Linux Mint</option>
<option value="LISREL 8.7 (college student edition)">LISREL 8.7 (college student edition)</option>
<option value="Mac OS">Mac OS</option>
<option value="Maple 18">Maple 18</option>
<option value="MATLAB 2014b">MATLAB 2014b</option>
<option value="Mesquite">Mesquite</option>
<option value="Microsoft Office 2010">Microsoft Office 2010</option>
<option value="Microsoft Office 2013">Microsoft Office 2013</option>
<option value="Microsoft Vision 2010">Microsoft Vision 2010</option>
<option value="OpenSuse">OpenSuse</option>
<option value="Puppy Linux">Puppy Linux</option>
<option value="Ubuntu">Ubuntu</option>
<option value="Unix">Unix</option>
<option value="Windows 10">Windows 10</option>
<option value="Windows 7">Windows 7</option>
<option value="Windows 8">Windows 8</option>
<option value="Windows XP">Windows XP</option>
</select>
<br /><br />




                      
                        <button  onClick={searchapi}>Add data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;