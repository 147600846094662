import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faEdit, faComment, faFileAlt, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { DataGrid } from '@material-ui/data-grid';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import SidemetricNavigationadmin from '../components/layout/SidemetricNavigationadmin';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import Spinner from 'react-bootstrap/Spinner';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    
    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState([]);
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());
    const [format, setFormat] = useState(false);

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;
    //const name=global1.name;

    const [isUploading, setIsuploading] = useState(false);

    const columns = [
        // { field: '_id', headerName: 'ID', width: 70 },
        { field: 'student', headerName: 'Student', width: 130 },
        { field: 'regno', headerName: 'Reg no', width: 130 },
      ];

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    
    const addperiod = () => {
        history.replace('/addperiod');
    }  

    const addstudents = () => {
        // global1.courseid=courseid;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/addstudents');
    }

    let tutorials = [];
    const searchApi = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/gethigheredubyadmin', {
            params: {
                user: user,
                token: token,
                colid: colid
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        localStorage.setItem("p_highereduadmin", JSON.stringify(response.data.data.classes));

       

        
        setResults(response.data.data.classes);
        setLink(response.data.data.link);
        //alert('format ' + format);
        try{

            formattable();
        } catch(err) {

        }
        
       
        alert(results.length);
       
    };

    function formattable() {
        if (!format) {
            $('#example').DataTable(
                {
                    pagingType: 'full_numbers',
                      pageLength: 5,
                      processing: true,
                      dom: 'Bfrtip',
                          buttons: ['copy', 'csv', 'print'
                          ]
                }
            );
            setFormat(true);

        }
    }

   

    const deleteenrolment = async (enrolmentid) => {
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deletehigheredubyfac', {
            params: {
                id: enrolmentid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const getlocal = () => {
        let collection=localStorage.getItem("p_highereduadmin");
        if(!collection) {

        } else {
            setResults(JSON.parse(collection));

        }
        
    }


    useEffect(() => {
        checklogin();
        //search1();
        //getexamlist();
        //getlocal();
        //searchApi('');
        formattable();
        //$('#example').DataTable();
        //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable();
    //     },
    //     1000
    //     );
    // });
    
    //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable(
    //         {
    //             pagingType: 'full_numbers',
    //               pageLength: 5,
    //               processing: true,
    //               dom: 'Bfrtip',
    //                   buttons: ['copy', 'csv', 'print'
    //                   ]
    //         }
    //     );
    //     } ,
    //     1000
    //     );
    // });
        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);


    const width=window.innerWidth;
    const mdivwidth=width - 330;

        //alert('loaded');
  

    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width: 60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidemetricNavigationadmin />
                    </td>
                   
                    <td> 
                        <TopNavigation />

                    
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
            <p style={{ fontSize: 18 }}>University Metric</p>
            <hr />
            
       <br />
        {isUploading ? (
				<div style={{ alignItems: 'center', marginTop: 30, marginBottom: 60}}>
                   <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
				</div>
			) : (
                <div></div>
			)} 
     
<Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
    <th>Criteria No.</th>
    <th>Criteria Name</th>
    <th>Metric No.</th>
    <th>Metric Name</th>
    <th>Links</th>
</tr>
</thead>
<tbody>
    
<tr>
                        <td>1</td>
                        <td>Curicular Aspects</td>
                        <td>1.1.1</td>
                        <td>Programme outcomes (POs), Programme Specific Outcomes(PSOs) and Course Outcomes(COs) of the Programmes offered by the University</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=1.1.1&question=PO, PSO and CO of the Programmes offered by the University&accreditation=NAACHU&documents=PO,PSO and CO' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Curicular Aspects</td>
                        <td>1.1.2 - 1.2.1</td>
                        <td>Syllabus Revision - CBCS</td>
                        <td>
                        <Link to='/viewsyllabusrevadmin?name=1.1.2 - 1.2.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Curicular Aspects</td>
                        <td>1.1.3</td>
                        <td>Courses with focus on competency/employability/ entrepreneurship/ skill-development</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=1.1.3&question=Employability courses&accreditation=NAACHU&documents=Syllabus copies of employablility courses' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Curicular Aspects</td>
                        <td>1.2.2</td>
                        <td>Percentage of new Degree Programmes, Fellowships and Diplomas introduced by the University</td>
                        <td>
                        <Link to='/viewhnewprogadmin' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Curicular Aspects</td>
                        <td>1.2.3</td>
                        <td>Percentage of interdisciplinary courses under the Programmes</td>
                        <td>
                        <Link to='/viewnnursinginteradmin' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Curicular Aspects</td>
                        <td>1.3.1</td>
                        <td>Institution integrates crosscutting issues</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=1.3.1&question=Institution integrates crosscutting issues&accreditation=NAACHU&documents=List,Description of courses' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Curicular Aspects</td>
                        <td>1.3.2 - 1.3.3</td>
                        <td>Value Added Courses</td>
                        <td>
                        <Link to='/viewaddoncourseadmin?name=1.3.2 - 1.3.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Curicular Aspects</td>
                        <td>1.3.4</td>
                        <td>Students undertake field visits / research projects / Industry internship / visits/Community postings</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=1.3.4&question=Experential learning&accreditation=NAACHU&documents=Reports/Certificates' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.1.1</td>
                        <td>Demand Ratio</td>
                        <td>
                        <Link to='/viewadmissionadmin?name=2.1.1' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.1.2</td>
                        <td>Student Seats against Reserved Category</td>
                        <td>
                        <Link to='/viewreservecatadmin?name=2.1.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.1.3</td>
                        <td>Other state and country student</td>
                        <td>
                        <Link to='/viewnotherstudadmin' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.2.1</td>
                        <td>Activities for Advanced Learners and Slow Learners (Remedial)</td>
                        <td>
                        <Link to='/viewremedialadmin' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.2.1</td>
                        <td>Advanced learners and Slow learners(Writeup)</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=2.2.1&question=Advanced and Slow learners&accreditation=NAACHU&documents=Adv and Slow Learners' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.2.2</td>
                        <td>Student - Fulltime teacher ratio</td>
                        <td>
                        <Link to='/viewnnratioadmin' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.3.1</td>
                        <td>Student centric methods</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=2.3.1&question=Student centric methods&accreditation=NAACHU&documents=Student centric methods' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.3.2</td>
                        <td> Clinical Skills Laboratory and Simulation Based Learning</td>
                        <td>
                        <Link to='/viewnclinicskillsadmin' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.3.3</td>
                        <td>Teachers use ICT- enabled tools</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=2.3.3&question=Teachers use ICT- enabled tools&accreditation=NAACHU&documents=ICT-enabled tools' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.3.4</td>
                        <td>Mentor Mentee</td>
                        <td>
                        {/* <Link to='/viewnnmentoradmin' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link> */}
                        <Link to='/viewnmenteesadmin' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.4.1 - 2.4.3</td>
                        <td>Full time Teachers</td>
                        <td>
                        <Link to='/viewteacherdataadmin?name=2.4.1 - 2.4.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.4.2</td>
                        <td>Research Guide.

                        Each faculty may add their data. Bulk upload facility is also available.
                        </td>
                        <td>
                        <Link to='/viewphdguideadmin?name=2.4.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.4.4</td>
                        <td>Full time Teachers</td>
                        <td>
                        <Link to='/viewnecourseadmin' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.4.5</td>
                        <td>Teacher Awards/Recognitions</td>
                        <td>
                        <Link to='/viewteacherawardadmin?name=2.4.5' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.5.1</td>
                        <td>Result Publication</td>
                        <td>
                        <Link to='/viewresultadmin?name=2.5.1' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.5.2</td>
                        <td>Average percentage of student complaints / grievances about evaluation</td>
                        <td>
                        <Link to='/viewhugrievanceadmin' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.5.3</td>
                        <td>Evaluation-related Grievance Redressal mechanism followed by the Institution</td>
                        <td>
                        <Link to='/viewnrevaladmin' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.5.4</td>
                        <td>Reforms in the process and procedure in the conduct of evaluation/examination</td>
                        <td>
                        <Link to='/viewnnexamadmin' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>


                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.5.5</td>
                        <td>Automation for Examination</td>
                        <td>
                        <Link to='/viewexamautomationadmin?name=2.5.5' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.6.1</td>
                        <td>The institution has stated learning outcomes</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=2.6.1&question=The institution has stated learning outcomes&accreditation=NAACHU&documents=COs' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.6.2</td>
                        <td>Student Pass Percentage</td>
                        <td>
                        <Link to='/viewpassexamadmin?name=2.6.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.1.1</td>
                        <td>The institution’s Research facilities are frequently updated</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=3.1.1&question=Minutes of the Governing Council related to research promotion policy&accreditation=NAACHU&documents=Minutes, Promotion policy' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.1.2</td>
                        <td>Seed Money provided to Faculty</td>
                        <td>
                        <Link to='/viewseedmadmin?name=3.1.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.1.3</td>
                        <td>Teachers receiving Fellowship</td>
                        <td>
                        <Link to='/viewteacherfellowadmin?name=3.1.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.1.4</td>
                        <td>Research Fellowships</td>
                        <td>
                        <Link to='/viewresearchfellowadmin?name=3.1.4' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.1.5</td>
                        <td>University facilities</td>
                        <td>
                        <Link to='/viewnfacilitiesadmin' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.1.6 - 3.2.1 - 3.2.2 - 3.2.3</td>
                        <td>Gov./Non-gov Grants for Research Projects.

                        Each faculty may add data for their projects or bulk upload data for all faculties.
                        </td>
                        <td>
                        <Link to='/viewprojectsadmindoc?name=3.1.6 - 3.2.1 - 3.2.2 - 3.2.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.3.1</td>
                        <td>Institution has created an ecosystem for Innovations and Incubation centres</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=3.3.1&question=Innovations and Incubation centres&accreditation=NAACHU&documents=Availability, Usage of centres' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.3.2</td>
                        <td>Workshops/Seminars/Conferences.

                        Add every activity/event in All events and select proper category. During data validation, category will be validated.
                        </td>
                        <td>
                        <Link to='/vieweventadmin?name=3.3.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.3.3</td>
                        <td>Innovation Awards
                        </td>
                        <td>
                        <Link to='/viewinnovationadmin?name=3.3.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.3.4</td>
                        <td>Number of start-ups incubated on campus
                        </td>
                        <td>
                        <Link to='/viewhustartupadmin' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.4.1</td>
                        <td>The Institution has a stated Code of Ethics for research
                        </td>
                        <td>
                        <Link to='/viewhucodeofethicsadmin' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.4.2</td>
                        <td>The Institution provides incentives for teachers who receive state,national or internationalrecognitions/awards
                        </td>
                        <td>
                        <Link to='/viewhuincentivesadmin' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.4.3</td>
                        <td>Published Patents.

                        Each faculty may add their data. Bulk upload facility is also available.
                        </td>
                        <td>
                        <Link to='/viewallpatents?name=3.4.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.4.4</td>
                        <td>Research Guide.

                        Each faculty may add their data. Bulk upload facility is also available.
                        </td>
                        <td>
                        <Link to='/viewphdguideadmin?name=3.4.4' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>


                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.4.5</td>
                        <td>Research Papers.

                        Each faculty may add their data. Bulk upload facility is also available. System will combine individual data into metrics.
                        </td>
                        <td>
                        <Link to='/viewallpublications?name=3.4.5' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>


                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.4.6-3.4.7</td>
                        <td>Books & Chapters.

                        Each faculty may add their data. Bulk upload facility is also available. System will combine individual data into metrics.
                        </td>
                        <td>
                        <Link to='/viewallbooks?name=3.4.6-3.4.7' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.5.1</td>
                        <td>Institution has a policy for consultancy</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=3.5.1&question=Institution has a policy for consultancy&accreditation=NAACHU&documents=Consultancy policy' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.5.2</td>
                        <td>Revenue generated from Consultancy</td>
                        <td>
                        <Link to='/viewconsultancyadmin?name=3.5.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.6.1-3.6.2</td>
                        <td>Extension Activities</td>
                        <td>
                        <Link to='/viewnnextensionadmin' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.6.3</td>
                        <td>Extension Awards</td>
                        <td>
                        <Link to='/viewextawardsadmin?name=3.6.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.6.4</td>
                        <td>Extension activities in the neighborhood community</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=3.6.4&question=Impact of extension activities in sensitizing the students&accreditation=NAACHU&documents=Social issues, development activities' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.7.1</td>
                        <td>Collaborative Activities</td>
                        <td>
                        <Link to='/viewnncollabadmin' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>


                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.7.2</td>
                        <td>MoU Activities</td>
                        <td>
                        <Link to='/viewnnmouadmin' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.1.1</td>
                        <td>The institution has adequate facilities for teaching - learning</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=4.1.1&question=Adequacy of facilities for teaching-learning&accreditation=NAACHU&documents=Classrooms, Labs used' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.1.2</td>
                        <td>The institution has adequate facilities for cultural activities, yoga, games and sports</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=4.1.2&question=Adequacy of facilities for cultural activities&accreditation=NAACHU&documents=Gyms, Auditoriums used' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.1.3</td>
                        <td>Availability of general campus facilities and overall ambience</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=4.1.3&question=General campus facilities and overall ambience&accreditation=NAACHU&documents=General campus facilities' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.1.4 - 4.5.1</td>
                        <td>Infrastructure Augmentation</td>
                        <td>
                        <Link to='/viewexpenditureadmin?name=4.1.4 - 4.5.1' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.2.1</td>
                        <td>Teaching Hospital/s, Equipments, Laboratory and clinical teaching-learning facilities</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=4.2.1&question=Teaching learning facilities&accreditation=NAACHU&documents=Geotagged photos,List of facilties' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.2.2</td>
                        <td>Outpatient-Inpatient</td>
                        <td>
                        <Link to='/viewnnpatientsadmin' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.2.3</td>
                        <td>Availability of infrastructure for community based learning</td>
                        <td>
                        <Link to='/viewnnursing424admin' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.2.4</td>
                        <td>Teaching Hospital / Clinical Laboratory accredited by any National Accrediting Agency</td>
                        <td>
                        <Link to='/viewhuaccredstatusadmin' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.3.1</td>
                        <td>Library is automated using Integrated Library Management System</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=4.3.1&question=Library is automated using ILMS&accreditation=NAACHU&documents=Library automation implementation' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
<td>4</td>
<td>Infrastructure-Learning Resources</td>
<td>4.3.2</td>
<td>Total number of books,reference volumes,ancient books,manuscripts,discipline-specific learning resources,special reports or any other knowledge resource</td>
<td>
<Link to='/viewnbaaug11adminnew?name=4.3.2&question=Total number of books/manuscripts/learning resources&accreditation=NAACHU&documents=List of learning resources,Number of copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
</td>
</tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.3.3</td>
                        <td>Institution have an e-Library with membership/subscription</td>
                        <td>
                        <Link to='/viewhulibmembershipadmin' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.3.4</td>
                        <td>Library Expenditures</td>
                        <td>
                        <Link to='/viewlibraryexpadmin?name=4.3.4' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.3.5</td>
                        <td>E-content resources used by teachers/students </td>
                        <td>
                        <Link to='/viewecontentadmin?name=4.3.5' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.4.1</td>
                        <td>ICT Enabled Classrooms & Seminar Halls</td>
                        <td>
                        <Link to='/viewictadmin?name=4.4.1' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
<td>4</td>
<td>Infrastructure-Learning Resources</td>
<td>4.4.2</td>
<td>Institution frequently updates its computer availability and IT facilities including Wi-Fi</td>
<td>
<Link to='/viewnbaaug11adminnew?name=4.4.2&question=Institution frequently updates its computer availability&accreditation=NAACHU&documents=Asset register,Bills' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
</td>
</tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.4.3</td>
                        <td>Available bandwidth of internet connection in the Institution (Leased line)</td>
                        <td>
                        <Link to='/viewhubandwidthadmin' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
<td>4</td>
<td>Infrastructure-Learning Resources</td>
<td>4.4.4</td>
<td>Facilities for e-content development such as Media centre, audio visual centre, Lecture Capturing System</td>
<td>
<Link to='/viewnbaaug11adminnew?name=4.4.4&question=Facilities for e-content development&accreditation=NAACHU&documents=Geotagged photos,List of e-contents developed' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
</td>
</tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.4.2</td>
                        <td>Institution has established systems and procedures for maintaining and utilising physical, academic and support facilities</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=4.4.2&question=Institution has established systems and maintainance procedures&accreditation=NAACHU&documents=Policy details of systems and procedures' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Student Support-Progression</td>
                        <td>5.1.1</td>
                        <td>Student Scholarships</td>
                        <td>
                        <Link to='/viewscholarshipadmin?name=5.1.1' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Student Support-Progression</td>
                        <td>5.1.2</td>
                        <td>Career Counselling Activities</td>
                        <td>
                        <Link to='/viewcareercounseladmin?name=5.1.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Student Support-Progression</td>
                        <td>5.1.3</td>
                        <td>Skill Development Programs</td>
                        <td>
                        <Link to='/viewskilldevadmin?name=5.1.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
<td>5</td>
<td>Student Support-Progression</td>
<td>5.1.4</td>
<td>Presence of International Student Cell</td>
<td>
<Link to='/viewnbaaug11adminnew?name=5.1.4&question=Active International Student Cell&accreditation=NAACHU&documents=International Student Cell formation,committee members,activities' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
</td>
</tr>

<tr>
                        <td>5</td>
                        <td>Student Support-Progression</td>
                        <td>5.1.5</td>
                        <td>The Institution has a transparent mechanism for timely redressal of student grievances</td>
                        <td>
                        <Link to='/viewnnursing515admin' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Student Support-Progression</td>
                        <td>5.2.1</td>
                        <td>Student Qualifying in Higher Examination</td>
                        <td>
                        <Link to='/viewhigherexamadmin?name=5.2.1' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Student Support-Progression</td>
                        <td>5.2.2</td>
                        <td>Outgoing Student Placement</td>
                        <td>
                        <Link to='/viewplacementadmin?name=5.2.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Student Support-Progression</td>
                        <td>5.2.3</td>
                        <td>Student Progression to Higher Education</td>
                        <td>
                        <Link to='/viewhighereduadmin?name=5.2.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Student Support-Progression</td>
                        <td>5.3.1</td>
                        <td>Student Awards/Medals</td>
                        <td>
                        <Link to='/viewawardsadmin?name=5.3.1' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Student Support-Progression</td>
                        <td>5.3.2</td>
                        <td>Presence of Student Council</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=5.3.2&question=Student Council for institutional development&accreditation=NAACHU&documents=Student Council and its activities' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Student Support-Progression</td>
                        <td>5.3.3</td>
                        <td>Sports & Cultural Activities.

                        Add data to all events with category as Sports and Cultural for both Sports and Cultural activities
                        </td>
                        <td>
                        <Link to='/vieweventadmin?name=5.3.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Student Support-Progression</td>
                        <td>5.4.1</td>
                        <td>Institution has registered Alumni Association/Chapters</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=5.4.1&question=Alumni Association/Chapters contributes for institutional development&accreditation=NAACHU&documents=Contribution of alumni association' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Student Support-Progression</td>
                        <td>5.4.2</td>
                        <td>Alumni Contribution</td>
                        <td>
                        <Link to='/viewalumniconadmin' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.1.1</td>
                        <td>The institution has a clearly stated vision and mission</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=6.1.1&question=Institutional Vision and Mission&accreditation=NAACHU&documents=Vision-Mission documents' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.1.2</td>
                        <td>Effective leadership is reflected in various institutional practices</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=6.1.2&question=Institutional practises in effective leadership&accreditation=NAACHU&documents=Decentralization, Participative management' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.2.1</td>
                        <td>The institutional Strategic plan is effectively deployed</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=6.2.1&question=One successfully-implemented activity based on the strategic plan&accreditation=NAACHU&documents=Strategic Plan and deployment documents' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.2.2</td>
                        <td>Functioning of the institutional bodies is effective and efficient</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=6.2.2&question=Effective and efficient institutional bodies&accreditation=NAACHU&documents=Organogram, Policies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.2.3</td>
                        <td>Implementation of E Governance</td>
                        <td>
                        <Link to='/viewegovernadmin?name=6.2.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
<td>6</td>
<td>Governance-Leadership-Management</td>
<td>6.3.1</td>
<td>The Institution has effective welfare measures for teaching,nonteaching staff and other beneficiaries.</td>
<td>
<Link to='/viewnbaaug11adminnew?name=6.3.1&question=Institution has effective welfare measures&accreditation=NAACHU&documents=Policy document,List of beneficiaries' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
</td>
</tr>
                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.3.2</td>
                        <td>Financial Support provided for Faculty.

                        Each faculty may add data from their profile.
                        </td>
                        <td>
                        <Link to='/viewallseminars?name=6.3.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.3.2</td>
                        <td>Financial Support provided for Faculty.

                        Add data to a centralized level.
                        </td>
                        <td>
                        <Link to='/viewteacherfsadmin?name=6.3.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>


                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.3.3</td>
                        <td>Professional development/Administrative Training Programs.

                        Add data to all events with category as FDP.
                        </td>
                        <td>
                        <Link to='/vieweventadmin?name=6.3.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.3.4</td>
                        <td>Faculty Development Programs.

                        Add data to a centralized level.
                        </td>
                        <td>
                        <Link to='/viewfdpadmin?name=6.3.4' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.3.4</td>
                        <td>Faculty Development Programs.

                        Each faculty may add data from their profile.
                        </td>
                        <td>
                        <Link to='/viewallseminars?name=6.3.4' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.3.5</td>
                        <td>The institution has a performance appraisal system</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=6.3.5&question=Performance appraisal system, promotional avenues&accreditation=NAACHU&documents=Appraisal system' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.4.1</td>
                        <td>Institutional strategies for mobilisation of funds and the optimal utilisation of resources</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=6.4.1&question=Resource mobilisation policy and procedures&accreditation=NAACHU&documents=Policy, Procedures' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.4.2</td>
                        <td>Gov./Non-Governmnet Funds</td>
                        <td>
                        <Link to='/viewfundsadmin?name=6.4.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.4.3</td>
                        <td>Institution regularly conducts internal and external financial audits </td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=6.4.3&question=Internal and external financial audits conducted&accreditation=NAACHU&documents=Audit documents' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.5.1</td>
                        <td>Instituion has a streamlined Internal Quality Assurance Mechanism</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=6.5.1&question=Instituion has a streamlined Internal Quality Assurance Mechanism&accreditation=NAACHU&documents=Structure,Mechanism of IQAC,Report,MOM' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.5.2</td>
                        <td>Institutional Quality Initiatives</td>
                        <td>
                        <Link to='/viewqualityadmin?name=6.5.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>   

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.5.3</td>
                        <td>Impact analysis of the various initiatives carried out and used for quality improvement</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=6.5.3&question=Quality enhancement initiatives&accreditation=NAACHU&documents=Initiatives taken' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Institutional Values and Best Practices</td>
                        <td>7.1.1</td>
                        <td>Promotion of gender equity</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=7.1.1&question=Gender equity & sensitization in curricular and co-curricular activities&accreditation=NAACHU&documents=Annual gender sensitization action plan' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Institutional Values and Best Practices</td>
                        <td>7.1.2</td>
                        <td>The Institution has facilities for alternate sources of energy</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=7.1.2&question=Facilities for alternate sources of energy in the Institution&accreditation=NAACHU&documents=Geotagged photos,Invoice copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>


                    <tr>
                        <td>7</td>
                        <td>Institutional Values and Best Practices</td>
                        <td>7.1.3</td>
                        <td>Waste Management</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=7.1.3&question=Management of Waste&accreditation=NAACHU&documents=Agreements, Geotagged photos' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
<td>7</td>
<td>Institutional Values and Best Practices</td>
<td>7.1.4</td>
<td>Water conservation facilities available in the Institution</td>
<td>
<Link to='/viewnbaaug11adminnew?name=7.1.4&question=Water conservation facilities available in the Institution&accreditation=NAACHU&documents=Geotagged photos,Invoice copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
</td>
</tr>

<tr>
                        <td>7</td>
                        <td>Institutional Values and Best Practices</td>
                        <td>7.1.5</td>
                        <td>Green campus initiatives</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=7.1.5&question=Green campus initiatives&accreditation=NAACHU&documents=Geotagged photos' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
<td>7</td>
<td>Institutional Values and Best Practices</td>
<td>7.1.6</td>
<td>Quality audits on environment and energy are regularly undertaken by the
institution</td>
<td>
<Link to='/viewnbaaug11adminnew?name=7.1.6&question=Quality audits on environment and energy&accreditation=NAACHU&documents=Audit reports,Audit certificates,Award copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
</td>
</tr>

<tr>
<td>7</td>
<td>Institutional Values and Best Practices</td>
<td>7.1.7</td>
<td>The Institution has disabled-friendly, barrier free environment</td>
<td>
<Link to='/viewnbaaug11adminnew?name=7.1.7&question=Disabled-friendly,barrier free environment in Institution&accreditation=NAACHU&documents=Geotagged photos,Reports' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
</td>
</tr>


                    <tr>
                        <td>7</td>
                        <td>Institutional Values and Best Practices</td>
                        <td>7.1.8</td>
                        <td>Institutional efforts/initiatives in providing an inclusive environment</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=7.1.8&question=Inclusive Environment&accreditation=NAACHU&documents=Administrative, Academic activity reports ' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Institutional Values and Best Practices</td>
                        <td>7.1.9</td>
                        <td>Sensitization of students and employees</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=7.1.9&question=Sensitization of students and employees&accreditation=NAACHU&documents=Activity reports' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
<td>7</td>
<td>Institutional Values and Best Practices</td>
<td>7.1.10</td>
<td>The Institution has a prescribed code of conduct for students, teachers, administrators and other staff</td>
<td>
<Link to='/viewnbaaug11adminnew?name=7.1.10&question=Institution has a prescribed code of conduct&accreditation=NAACHU&documents=Policy document,Minutes and Committe composition,Reports of activities ' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
</td>
</tr>

                    <tr>
                        <td>7</td>
                        <td>Institutional Values and Best Practices</td>
                        <td>7.1.11</td>
                        <td>Institution celebrates national and international commemorative days, events and festivals </td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=7.1.11&question=Efforts of the Institution in celebrating events&accreditation=NAACHU&documents=Annual reports, Geotagged photo' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Institutional Values and Best Practices</td>
                        <td>7.2.1</td>
                        <td>Institutional Best Practices</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=7.2.1&question=Two Best practices successfully implemented &accreditation=NAACHU&documents=Best practices as hosted on the Institutional website' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Institutional Values and Best Practices</td>
                        <td>7.3.1</td>
                        <td>Institutional Distinctiveness</td>
                        <td>
                        <Link to='/viewnbaaug11adminnew?name=7.3.1&question=One Distinctive performance of the Institution&accreditation=NAACHU&documents=Performance of the Institution' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>


              

              
            </tbody>
            </Table>

            <br /><br />
            

            

       
        
        </div>
        <FooterNav1 />
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;