import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/Rappn';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);



    const yearref=useRef();
const collegehospitalref=useRef();
const parenthospitalref=useRef();
const ownedbyref=useRef();
const hospitalnameref=useRef();
const locationref=useRef();
const noofbedsref=useRef();
const opdperdayref=useRef();
const ipdperdayref=useRef();
const anyotherref=useRef();
const distanceref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
       

        const year=yearref.current.value;
const collegehospital=collegehospitalref.current.value;
const parenthospital=parenthospitalref.current.value;
const ownedby=ownedbyref.current.value;
const hospitalname=hospitalnameref.current.value;
const location=locationref.current.value;
const noofbeds=noofbedsref.current.value;
const opdperday=opdperdayref.current.value;
const ipdperday=ipdperdayref.current.value;
const anyother=anyotherref.current.value;
const distance=distanceref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/creaternphospitalsbyfac', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                year:year,
collegehospital:collegehospital,
parenthospital:parenthospital,
ownedby:ownedby,
hospitalname:hospitalname,
location:location,
noofbeds:noofbeds,
opdperday:opdperday,
ipdperday:ipdperday,
anyother:anyother,
distance:distance,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewnrnphospitals');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Add data</p>
                        <hr />
                       
                            
                  <label htmlFor="year">year</label>

<select id="year" className="form-control"  ref={yearref}>
<option value="2021-22">2021-22</option>
<option value="2022-23">2022-23</option>
<option value="2023-24">2023-24</option>
</select>
<br /><br />

<label htmlFor="collegehospital">collegehospital</label>

<select id="collegehospital" className="form-control"  ref={collegehospitalref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />

<label htmlFor="parenthospital">parenthospital</label>

<select id="parenthospital" className="form-control"  ref={parenthospitalref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />

<label htmlFor="ownedby">ownedby</label>

<select id="ownedby" className="form-control"  ref={ownedbyref}>
<option value="Trust">Trust</option>
<option value="Trust member with MoU">Trust member with MoU</option>
</select>
<br /><br />

Enter hospitalname<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter hospitalname" id="hospitalname" ref={hospitalnameref} />
<br /><br />

<label htmlFor="location">location</label>

<select id="location" className="form-control"  ref={locationref}>
<option value="Bengaluru urban">Bengaluru urban</option>
<option value="Mangalore urban">Mangalore urban</option>
<option value="Other areas">Other areas</option>
</select>
<br /><br />

Enter noofbeds<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter noofbeds" id="noofbeds" ref={noofbedsref} />
<br /><br />

Enter opdperday<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter opdperday" id="opdperday" ref={opdperdayref} />
<br /><br />

Enter ipdperday<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter ipdperday" id="ipdperday" ref={ipdperdayref} />
<br /><br />

<label htmlFor="anyother">anyother</label>

<select id="anyother" className="form-control"  ref={anyotherref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />

Enter distance<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter distance" id="distance" ref={distanceref} />
<br /><br />




                      
                        <button  onClick={searchapi}>Add data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;