
import axios from 'axios';

export default axios.create({
    //baseURL:'https://ctnodeapps2.azurewebsites.net'
    //baseURL:'https://canvasapi.azurewebsites.net'
    baseURL:'https://canvasapi1.azurewebsites.net'
    //baseURL:'http://localhost:3000'
    //baseURL:'https://ctpdf.azurewebsites.net'


});