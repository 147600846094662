import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/SidedocNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const passwordref=useRef();
    const password1ref=useRef();
    const departmentref=useRef();
    const nameref=useRef();
  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [faculties, setFaculties] = useState([]);
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;

    const metric1=global1.metric;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }

    const getfacultylist = async () => {
        //setLoading(true);
        setTerm(studid);
        const metric=global1.section1;
        
        const response = await ep1.get('/api/v1/getmetricrules', {
            params: {
                metric: metric,
                accreditation: 'NAAC',
                token: token

            }

        });
        //setLoading(false);
        console.log(response.data.data);
        setFaculties(response.data.data.classes);
       
        //alert(results.length);
       
    };


    useEffect(() => {
        departmentref.current.value=department;
        getfacultylist();

       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const uploadlink = async () => {

        const department1=departmentref.current.value;
        const criteria=global1.criteria;
        const section1=global1.section1;
        const field1=global1.field1;
        const yop1=global1.yop1;
        const link2=global1.link2;


        //link1=link1 + '?criteria=3&Section=3.4.6&Subsection=Book&field1=' + id1 + '&Year=' + yop1 + '&userid=' + user; 
        const link1=link2 + '?criteria=' + criteria + '&Section=' + section1 + '&Subsection=' + department1 + '&field1=' + field1 + '&Year=' + yop1 + '&userid=' + user;  
        var win = window.open(link1, '_blank');
        win.focus();
      };

    const upload = () => {
        const department1=departmentref.current.value;
      
        global1.type=department1;
        //global1.pagenav='viewevent';
        
        history.replace('/selectawsconfig');
    }

    const searchapi = async () => {
        const password=passwordref.current.value;
        const password1=password1ref.current.value;
        const department1=departmentref.current.value;
        const name1=nameref.current.value;
        //alert(department1);
        if(!password || !name1 ) {
            alert('All fields are required');
            return;
        }
        if(password==password1) {

        } else {
            alert('Confirm password is not matching.');
            return;
        }
      
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updatepassword', {
            params: {
                user: user,
                token: token,
                password: password,
                department: department1,
                name:name1

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        //history.replace('/viewpatent');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Select type of document for {metric1}</p>
                        <hr />

                        <p>Please ensure that you select the best option that describes the document. If selected properly, we may be able to generate declarations 
                            from our side properly without wasting your time later.</p>
                            <br /><br />

                            <p>If the category is not selected properly, our team would reach out later to you for clarification regarding document.</p>
                            <br /><br />

                       


<label htmlFor='affiliated'>Select document type</label>
                    <select id="affiliated" className="form-control"   ref={departmentref}>
                    <option value="1">Select type</option>

    {faculties.map((meetup3) => {
                return (
                    <option value={meetup3.comments}>{meetup3.title}</option>
 
                );
            })}
                  
                    </select>
                    <br />





               
                  


                      
                        <button  onClick={uploadlink}>Proceed to step 2</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;