import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faEdit, faComment, faFileAlt, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { DataGrid } from '@material-ui/data-grid';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import SidemetricNavigation from '../components/layout/SidemetricNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import Spinner from 'react-bootstrap/Spinner';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    
    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState([]);
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());
    const [format, setFormat] = useState(false);

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;
    //const name=global1.name;

    const [isUploading, setIsuploading] = useState(false);

    const columns = [
        // { field: '_id', headerName: 'ID', width: 70 },
        { field: 'student', headerName: 'Student', width: 130 },
        { field: 'regno', headerName: 'Reg no', width: 130 },
      ];

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    
    const addperiod = () => {
        history.replace('/addperiod');
    }  

    const addstudents = () => {
        // global1.courseid=courseid;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/addstudents');
    }

    let tutorials = [];
    const searchApi = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/gethigheredubyadmin', {
            params: {
                user: user,
                token: token,
                colid: colid
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        localStorage.setItem("p_highereduadmin", JSON.stringify(response.data.data.classes));

       

        
        setResults(response.data.data.classes);
        setLink(response.data.data.link);
        //alert('format ' + format);
        try{

            formattable();
        } catch(err) {

        }
        
       
        // alert(results.length);
       
    };

    function formattable() {
        if (!format) {
            $('#example').DataTable(
                {
                    pagingType: 'full_numbers',
                      pageLength: 5,
                      processing: true,
                      dom: 'Bfrtip',
                          buttons: ['copy', 'csv', 'print'
                          ]
                }
            );
            setFormat(true);

        }
    }

   

    const deleteenrolment = async (enrolmentid) => {
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deletehigheredubyfac', {
            params: {
                id: enrolmentid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const getlocal = () => {
        let collection=localStorage.getItem("p_highereduadmin");
        if(!collection) {

        } else {
            setResults(JSON.parse(collection));

        }
        
    }


    useEffect(() => {
        checklogin();
        //search1();
        //getexamlist();
        //getlocal();
        formattable();
        //searchApi('');
        //$('#example').DataTable();
        //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable();
    //     },
    //     1000
    //     );
    // });
    
    //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable(
    //         {
    //             pagingType: 'full_numbers',
    //               pageLength: 5,
    //               processing: true,
    //               dom: 'Bfrtip',
    //                   buttons: ['copy', 'csv', 'print'
    //                   ]
    //         }
    //     );
    //     } ,
    //     1000
    //     );
    // });
        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);


    const width=window.innerWidth;
    const mdivwidth=width - 330;

        //alert('loaded');
  

    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width: 60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidemetricNavigation />
                    </td>
                   
                    <td> 
                        <TopNavigation />

                    
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
            <p style={{ fontSize: 18 }}>NBA MCA Tier 1</p>
            <hr />
            
       <br />
        {isUploading ? (
				<div style={{ alignItems: 'center', marginTop: 30, marginBottom: 60}}>
                   <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
				</div>
			) : (
                <div></div>
			)} 
     
<Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
    <th>Criteria No.</th>
    <th>Criteria Name</th>
    <th>Metric No.</th>
    <th>Metric Name</th>
    <th>Links</th>
</tr>
</thead>
<tbody>

                    <tr>
                        <td>1</td>
                        <td>Vision, Mission and Program Educational Objectives</td>
                        <td>1.1.1</td>
                        <td>State the Vision and Mission of the Department & Institution</td>
                        <td>
                        <Link to='/viewnbaug11?name=1.1.1&question=Vision and Mission of the Department & Institution&accreditation=NBAMCA&documents=Vision,Mission, PEO list' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Vision, Mission and Program Educational Objectives</td>
                        <td>1.1.2</td>
                        <td>Indicate how and where the Vision and Mission are published and disseminated</td>
                        <td>
                        <Link to='/viewnbaug11?name=1.1.2&question=How and where the Vision and Mission are published and disseminated&accreditation=NBAMCA&documents=Describe Media, Utilisation' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Vision, Mission and Program Educational Objectives</td>
                        <td>1.1.3</td>
                        <td>Mention the process for defining Vision and Mission of the department</td>
                        <td>
                        <Link to='/viewnbaug11?name=1.1.3&question=Process for defining Vision and Mission of the department&accreditation=NBAMCA&documents=Process involved' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Vision, Mission and Program Educational Objectives</td>
                        <td>1.2.1</td>
                        <td>State the Program Educational Objectives (PEOs)</td>
                        <td>
                        <Link to='/viewnbaug11?name=1.2.1&question=Program Educational Objectives&accreditation=NBAMCA&documents=PEOs list' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Vision, Mission and Program Educational Objectives</td>
                        <td>1.2.2</td>
                        <td>State where and how PEOs are published and disseminated</td>
                        <td>
                        <Link to='/viewnbaug11?name=1.2.2&question=State where and how PEOs are published&accreditation=NBAMCA&documents=Details of process, Its implementation ' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Vision, Mission and Program Educational Objectives</td>
                        <td>1.2.3</td>
                        <td>List the stakeholders of the programme</td>
                        <td>
                        <Link to='/viewnbaug11?name=1.2.3&question=Stakeholders of the programme&accreditation=NBAMCA&documents=Details of process, Its implementation ' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Vision, Mission and Program Educational Objectives</td>
                        <td>1.2.4</td>
                        <td>State the process for establishing the PEOs</td>
                        <td>
                        <Link to='/viewnbaug11?name=1.2.4&question=Process for establishing the PEOs&accreditation=NBAMCA&documents=Details of process, Its implementation ' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Vision, Mission and Program Educational Objectives</td>
                        <td>1.2.5</td>
                        <td>Establish consistency of the PEOs with the Mission of the institute</td>
                        <td>
                        <Link to='/viewnbaug11?name=1.2.5&question=Establish consistency of the PEOs&accreditation=NBAMCA&documents=Details of process, Its implementation ' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Vision, Mission and Program Educational Objectives</td>
                        <td>1.3.1</td>
                        <td>Justify the academic factors involved in achievement of the PEOs</td>
                        <td>
                        <Link to='/viewnbaug11?name=1.3.1&question=Justify the academic factors involved&accreditation=NBAMCA&documents=Details of process, Its implementation ' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Vision, Mission and Program Educational Objectives</td>
                        <td>1.3.2</td>
                        <td>Explain how the administrative system helps in ensuring the achievement of the PEOs</td>
                        <td>
                        <Link to='/viewnbaug11?name=1.3.2question=Administrative system helping in ensuring the achievement of the PEOs &accreditation=NBAMCA&documents=Details of process, Its implementation ' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Vision, Mission and Program Educational Objectives</td>
                        <td>1.4.1</td>
                        <td>Indicate tools and processes used in assessment of the achievement ofthe PEOs</td>
                        <td>
                        <Link to='/viewnbaug11?name=1.4.1&question=Tools and processes used in assessment of the achievement ofthe PEOs&accreditation=NBAMCA&documents=Process involved' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Vision, Mission and Program Educational Objectives</td>
                        <td>1.4.2</td>
                        <td>Provide the evidences for the achievement of the PEOs</td>
                        <td>
                        <Link to='/viewnbaug11?name=1.4.2&question=Evidences for the achievement of the PEOs&accreditation=NBAMCA&documents=Process involved' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Vision, Mission and Program Educational Objectives</td>
                        <td>1.5</td>
                        <td>Indicate how the PEOs have been redefined in the past</td>
                        <td>
                        <Link to='/viewnbaug11?name=1.5&question=Indicate how the PEOs have been redefined&accreditation=NBAMCA&documents=Mission of the department, Mapping' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Programme Outcomes</td>
                        <td>2.1.1</td>
                        <td>List the Course Outcomes(COs) and Programme Outcomes (POs)</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.1.1&question=List the COs and POs&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Programme Outcomes</td>
                        <td>2.1.2</td>
                        <td>State how and where the POs are published and disseminated</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.1.2&question=State how and where the POs are published&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Programme Outcomes</td>
                        <td>2.1.3</td>
                        <td>Indicate processes employed for evolving of the POs</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.1.3&question=Processes employed for evolving of the POs&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Programme Outcomes</td>
                        <td>2.1.4</td>
                        <td>Indicate how the POs are aligned to the Graduate Attributes</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.1.4&question=How the POs are aligned to the Graduate Attributes&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Programme Outcomes</td>
                        <td>2.1.5</td>
                        <td>Establish the correlation between the POs and the PEOs</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.1.5&question=Establish the correlation between the POs and the PEOs&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Programme Outcomes</td>
                        <td>2.2.1</td>
                        <td>Illustrate how course outcomes contribute to the POs</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.2.1&question=How course outcomes contribute to the POs&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Programme Outcomes</td>
                        <td>2.2.2</td>
                        <td>Explain how modes of delivery of courses help in attainment of the POs</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.2.2&question=How modes of delivery of courses help in attainment of the POs&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Programme Outcomes</td>
                        <td>2.2.3</td>
                        <td>Indicate how assessment tools used to assess the impact of delivery of course</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.2.3&question=How assessment tools used to assess the impact&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Programme Outcomes</td>
                        <td>2.2.4</td>
                        <td>Indicate the extent to which the laboratory and project course work are contributing towards attainment of the POs</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.2.4&question=Justify the balance between theory and practical for the attainment of the POs&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Programme Outcomes</td>
                        <td>2.3.1</td>
                        <td>Describe assessment tools and processes used for assessing the attainment of each PO</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.3.1&question=Describe the assessment processe&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Programme Outcomes</td>
                        <td>2.3.2</td>
                        <td>Indicate results of evaluation of each PO</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.3.2&question=Results of evaluation of each PO&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Programme Outcomes</td>
                        <td>2.4.1</td>
                        <td>Indicate how the results of evaluation used for curricular improvement</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.4.1&question=How the results of evaluation used for curricular improvement&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Programme Outcomes</td>
                        <td>2.4.2</td>
                        <td>Indicate how results of evaluation used for improvement of course delivery</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.4.2&question=How results of evaluation used for improvement of course delivery&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Programme Outcomes</td>
                        <td>2.4.3</td>
                        <td>State the process used for revising/redefining the POs</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.4.3&question=Process used for revising/redefining the POs&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Programme Curriculum</td>
                        <td>3.1.1</td>
                        <td>Describe the Structure of the Curriculum</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.1.1&question=Structure of the Curriculum&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Programme Curriculum</td>
                        <td>3.1.2</td>
                        <td>Give the prerequisite flow chart of courses</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.1.2&question=Prerequisite flow chart of courses&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Programme Curriculum</td>
                        <td>3.1.3</td>
                        <td>Justify how the programme curriculum satisfies the programme specific criteria</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.1.3&question=How the programme curriculum satisfies the programme specific criteria&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Programme Curriculum</td>
                        <td>3.2</td>
                        <td>State the components of the curriculum and their relevance to the POs and the PEOs</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.2&question=State the components of the curriculum&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Programme Curriculum</td>
                        <td>3.3</td>
                        <td>State how the professional core courses are contributing towards Programme Outcomes</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.3&question=How the professional core courses are contributing towards PO&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Programme Curriculum</td>
                        <td>3.4</td>
                        <td>Industry interaction/internship</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.4&question=Industry interaction&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Programme Curriculum</td>
                        <td>3.5.1</td>
                        <td>State the process for designing the programme curriculum</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.5.1&question=Process for designing the programme curriculum&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Programme Curriculum</td>
                        <td>3.5.2</td>
                        <td>Illustrate the measures and processes used to improve courses and curriculum</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.5.2&question=Measures and Processes used to improve courses and curriculum&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Programme Curriculum</td>
                        <td>3.6</td>
                        <td>Course Syllabi</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.6&question=Course Syllabi&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Students’ Performance</td>
                        <td>4.1</td>
                        <td>Success Rate</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.1&question=Success Rate&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Students’ Performance</td>
                        <td>4.2</td>
                        <td>Academic Performance</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.2&question=Academic Performance&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Students’ Performance</td>
                        <td>4.3</td>
                        <td>Placement and Higher Studies</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.3&question=Placement and Higher Studies&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Students’ Performance</td>
                        <td>4.4.1</td>
                        <td>Professional societies / chapters and organised events</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.4.1&question=Professional societies&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Students’ Performance</td>
                        <td>4.4.2</td>
                        <td>Organisation of paper contests, design contests, etc. and achievements</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.4.2&question=Organisation of paper contests&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Students’ Performance</td>
                        <td>4.4.3</td>
                        <td>Publication of technical magazines, newsletters</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.4.3&question=Publication of technical magazines&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Students’ Performance</td>
                        <td>4.4.4</td>
                        <td>Entrepreneurship initiatives, product designs, and innovations</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.4.4&question=Entrepreneurship initiatives&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Students’ Performance</td>
                        <td>4.4.5</td>
                        <td>Publications and awards in inter-institute events by students of the programme of study</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.4.5&question=Publications and Awards in inter-institute events&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Faculty Contributions</td>
                        <td>5.1</td>
                        <td>Student Teacher Ratio</td>
                        <td>
                        <Link to='/viewnbaug11?name=5.1&question=Student Teacher Ratio&accreditation=NBAMCA&documents=Process for compliance' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Faculty Contributions</td>
                        <td>5.2</td>
                        <td>Faculty Cadre Proportion</td>
                        <td>
                        <Link to='/viewnbaug11?name=5.2&question=Faculty Cadre Proportion&accreditation=NBAMCA&documents=All faculty count' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Faculty Contributions</td>
                        <td>5.3</td>
                        <td>Faculty Qualification</td>
                        <td>
                        <Link to='/viewnbaug11?name=5.3&question=Faculty Qualification&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Faculty Contributions</td>
                        <td>5.4</td>
                        <td>Faculty Competencies correlation to Programme Specific Criteria</td>
                        <td>
                        <Link to='/viewnbaug11?name=5.4&question=Faculty Competencies correlation to Programme Specific Criteria&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Faculty Contributions</td>
                        <td>5.5</td>
                        <td>Faculty as participants/resource persons in faculty development/training activities</td>
                        <td>
                        <Link to='/viewnbaug11?name=5.5&question=Faculty as participants/resource persons in FDP&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Faculty Contributions</td>
                        <td>5.6</td>
                        <td>Faculty Retention</td>
                        <td>
                        <Link to='/viewnbaug11?name=5.6&question=Faculty Retention&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Faculty Contributions</td>
                        <td>5.7</td>
                        <td>Faculty Research Publications</td>
                        <td>
                        <Link to='/viewnbaug11?name=5.7&question=Faculty Research Publications&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Faculty Contributions</td>
                        <td>5.8</td>
                        <td>Faculty Intellectual Property Rights</td>
                        <td>
                        <Link to='/viewnbaug11?name=5.8&question=Faculty Intellectual Property Rights&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Faculty Contributions</td>
                        <td>5.9</td>
                        <td>Funded R&D Projects and Consultancy</td>
                        <td>
                        <Link to='/viewnbaug11?name=5.9&question=Funded R&D Projects and Consultancy&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Faculty Contributions</td>
                        <td>5.10</td>
                        <td>Faculty Interaction with Outside World</td>
                        <td>
                        <Link to='/viewnbaug11?name=5.10&question=Faculty Interaction with Outside World&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Facilities and Technical Support</td>
                        <td>6.1.1</td>
                        <td>Adequate number of rooms for lectures (core/electives), seminars,tutorials for the programme</td>
                        <td>
                        <Link to='/viewnbaug11?name=6.1.1&question=Adequate number of rooms for lectures&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Facilities and Technical Support</td>
                        <td>6.1.2</td>
                        <td>Teaching aids-multimedia projectors</td>
                        <td>
                        <Link to='/viewnbaug11?name=6.1.2&question=Teaching aids-multimedia projectors&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>


                    <tr>
                        <td>6</td>
                        <td>Facilities and Technical Support</td>
                        <td>6.1.3</td>
                        <td>Acoustics, classroom size, conditions of chairs/benches, air circulation</td>
                        <td>
                        <Link to='/viewnbaug11?name=6.1.3&question=Infrastructure&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>


                    <tr>
                        <td>6</td>
                        <td>Facilities and Technical Support</td>
                        <td>6.2.1</td>
                        <td>Availability of individual faculty rooms</td>
                        <td>
                        <Link to='/viewnbaug11?name=6.2.1&question=Availability of individual faculty rooms&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>


                    <tr>
                        <td>6</td>
                        <td>Facilities and Technical Support</td>
                        <td>6.2.2</td>
                        <td>Room equipped with white/black board, computer, internet, discussion space and such other amenities/facilities</td>
                        <td>
                        <Link to='/viewnbaug11?name=6.2.2&question=Classrooms infrastructure&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>


                    <tr>
                        <td>6</td>
                        <td>Facilities and Technical Support</td>
                        <td>6.2.3</td>
                        <td>Usage of room for counselling/discussion with students</td>
                        <td>
                        <Link to='/viewnbaug11?name=6.2.3&question=Usage of room for counselling&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>


                    <tr>
                        <td>6</td>
                        <td>Facilities and Technical Support</td>
                        <td>6.3.1</td>
                        <td>Adequate, well-equipped laboratories to meet the curriculumrequirements and the POs</td>
                        <td>
                        <Link to='/viewnbaug11?name=6.3.1&question=Laboratories infrastructure&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Facilities and Technical Support</td>
                        <td>6.3.2</td>
                        <td>Availability of laboratories with technical support within and beyond working hours</td>
                        <td>
                        <Link to='/viewnbaug11?name=6.3.2&question=Laboratories with technical support&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Facilities and Technical Support</td>
                        <td>6.3.3</td>
                        <td>Equipment to run experiments and their maintenance, number of students per experimental setup</td>
                        <td>
                        <Link to='/viewnbaug11?name=6.3.3&question=Equipment to run experiments&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Facilities and Technical Support</td>
                        <td>6.4.1</td>
                        <td>Availability of adequate and qualified technical supporting staff</td>
                        <td>
                        <Link to='/viewnbaug11?name=6.4.1&question=Availability of adequate and qualified technical supporting staff&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Facilities and Technical Support</td>
                        <td>6.4.2</td>
                        <td>Incentives, skill-upgrade, and professional advancement</td>
                        <td>
                        <Link to='/viewnbaug11?name=6.4.2&question=Incentives, skill-upgrade, and professional advancement&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Academic Support Units and Teaching-Learning Process</td>
                        <td>7.1.1</td>
                        <td>Admission Intake</td>
                        <td>
                        <Link to='/viewnbaug11?name=7.1.1&question=Admission Intake&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Academic Support Units and Teaching-Learning Process</td>
                        <td>7.1.2</td>
                        <td>Admission Quality</td>
                        <td>
                        <Link to='/viewnbaug11?name=7.1.2&question=Admission Quality&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Academic Support Units and Teaching-Learning Process</td>
                        <td>7.1.3</td>
                        <td>Language Laboratory</td>
                        <td>
                        <Link to='/viewnbaug11?name=7.1.3&question=Language Laboratory&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Academic Support Units and Teaching-Learning Process</td>
                        <td>7.1.4</td>
                        <td>CareerGuidance,Training,Placement, and Entrepreneurship Cell</td>
                        <td>
                        <Link to='/viewnbaug11?name=7.1.4&question=CareerGuidance,Training,Placement, and Entrepreneurship Cell&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Academic Support Units and Teaching-Learning Process</td>
                        <td>7.1.5</td>
                        <td>Co-curricular and Extra-curricular Activities</td>
                        <td>
                        <Link to='/viewnbaug11?name=7.1.5&question=Co-curricular and Extra-curricular Activities&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Academic Support Units and Teaching-Learning Process</td>
                        <td>7.1.6</td>
                        <td>Sports grounds, facilities, and qualified sports instructors</td>
                        <td>
                        <Link to='/viewnbaug11?name=7.1.6&question=Sports facilities&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Academic Support Units and Teaching-Learning Process</td>
                        <td>7.2.1</td>
                        <td>Tutorial classes to address student questions</td>
                        <td>
                        <Link to='/viewnbaug11?name=7.2.1&question=Tutorial classes to address student questions&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Academic Support Units and Teaching-Learning Process</td>
                        <td>7.2.2</td>
                        <td>Mentoring system to help at individual levels</td>
                        <td>
                        <Link to='/viewnbaug11?name=7.2.2&question=Mentoring system&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Academic Support Units and Teaching-Learning Process</td>
                        <td>7.2.3</td>
                        <td>Feedback analysis and reward / corrective measures taken</td>
                        <td>
                        <Link to='/viewnbaug11?name=7.2.3&question=Feedback analysis&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Academic Support Units and Teaching-Learning Process</td>
                        <td>7.2.4</td>
                        <td>Scope for self-learning</td>
                        <td>
                        <Link to='/viewnbaug11?name=7.2.4&question=Scope for self-learning&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Academic Support Units and Teaching-Learning Process</td>
                        <td>7.2.5</td>
                        <td>Generation of self-learning facilities, and availability of materials for learning</td>
                        <td>
                        <Link to='/viewnbaug11?name=7.2.5&question=Generation of self-learning facilities&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>8</td>
                        <td>Governance,Institutional Support and Financial Resources</td>
                        <td>8.1.1</td>
                        <td>Maintenance of academic infrastructure and facilities</td>
                        <td>
                        <Link to='/viewnbaug11?name=8.1.1&question=Maintenance of academic infrastructure&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>8</td>
                        <td>Governance,Institutional Support and Financial Resources</td>
                        <td>8.1.2</td>
                        <td>Hostel(boys and girls), transportation facility, and canteen</td>
                        <td>
                        <Link to='/viewnbaug11?name=8.1.2&question=Hostel facilities&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>8</td>
                        <td>Governance,Institutional Support and Financial Resources</td>
                        <td>8.1.3</td>
                        <td>Electricity,powerbackup,telecomfacility,drinkingwater, andsecurity</td>
                        <td>
                        <Link to='/viewnbaug11?name=8.1.3&question=Faculty Qualification&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>8</td>
                        <td>Governance,Institutional Support and Financial Resources</td>
                        <td>8.2.1</td>
                        <td>Governing body, administrative setup, and functions of various bodies</td>
                        <td>
                        <Link to='/viewnbaug11?name=8.2.1&question=Governing body, administrative setup&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>8</td>
                        <td>Governance,Institutional Support and Financial Resources</td>
                        <td>8.2.2</td>
                        <td>Defined rules, procedures, recruitment, and promotional policies</td>
                        <td>
                        <Link to='/viewnbaug11?name=8.2.2&question=Defined rules, procedures, recruitment, and promotional policies&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>8</td>
                        <td>Governance,Institutional Support and Financial Resources</td>
                        <td>8.2.3</td>
                        <td>Decentralisation in working including delegation of financial power and grievance redressal system</td>
                        <td>
                        <Link to='/viewnbaug11?name=8.2.3&question=Decentralisation in working&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>8</td>
                        <td>Governance,Institutional Support and Financial Resources</td>
                        <td>8.2.4</td>
                        <td>Transparency and availability of correct/unambiguous information</td>
                        <td>
                        <Link to='/viewnbaug11?name=8.2.4&question=Transparency and availability of correct information&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>8</td>
                        <td>Governance,Institutional Support and Financial Resources</td>
                        <td>8.3.1</td>
                        <td>Adequacy of budget allocation at institute level</td>
                        <td>
                        <Link to='/viewnbaug11?name=5.6.1&question=Institute level Budget Allocation&accreditation=NBAMCA&documents=Total budget allocation programwise' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Resources</td>
                        <td>8.3.2</td>
                        <td>Utilization of allocated funds at institute level</td>
                        <td>
                        <Link to='/viewnbaug11?name=8.3.2&question=Institute level Budget Utilization&accreditation=NBAMCA&documents=Total budget utilization programwise' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>8</td>
                        <td>Governance,Institutional Support and Financial Resources</td>
                        <td>8.3.3</td>
                        <td>Availability of the audited statements on the institute’s website</td>
                        <td>
                        <Link to='/viewnbaug11?name=8.3.3&question=Availability of the audited statements&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>8</td>
                        <td>Governance,Institutional Support and Financial Resources</td>
                        <td>8.4.1</td>
                        <td>Adequacy of budget allocation programwise</td>
                        <td>
                        <Link to='/viewnbaug11?name=8.4.1&question=Budget Allocation programwise&accreditation=NBAMCA&documents=Total budget allocation programwise' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Governance,Institutional Support and Financial Resources</td>
                        <td>8.4.2</td>
                        <td>Utilization of allocated funds programwise</td>
                        <td>
                        <Link to='/viewnbaug11?name=8.4.2&question=Budget Utilization programwise&accreditation=NBAMCA&documents=Total budget utilization programwise' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>8</td>
                        <td>Governance,Institutional Support and Financial Resources</td>
                        <td>8.5.1</td>
                        <td>Library space and ambience</td>
                        <td>
                        <Link to='/viewnbaug11?name=8.5.1&question=Library space and ambience&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>8</td>
                        <td>Governance,Institutional Support and Financial Resources</td>
                        <td>8.5.2</td>
                        <td>Titles and volumes per title</td>
                        <td>
                        <Link to='/viewnbaug11?name=8.5.2&question=Titles and volumes per title&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>8</td>
                        <td>Governance,Institutional Support and Financial Resources</td>
                        <td>8.5.3</td>
                        <td>Scholarly journal subscription</td>
                        <td>
                        <Link to='/viewnbaug11?name=8.5.3&question=Scholarly journal subscription&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>8</td>
                        <td>Governance,Institutional Support and Financial Resources</td>
                        <td>8.5.4</td>
                        <td>Digital Library</td>
                        <td>
                        <Link to='/viewnbaug11?name=8.5.4&question=Digital Library&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>8</td>
                        <td>Governance,Institutional Support and Financial Resources</td>
                        <td>8.5.5</td>
                        <td>Library expenditure</td>
                        <td>
                        <Link to='/viewnbaug11?name=8.5.5&question=Library expenditure&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>8</td>
                        <td>Governance,Institutional Support and Financial Resources</td>
                        <td>8.6</td>
                        <td>Internet</td>
                        <td>
                        <Link to='/viewnbaug11?name=8.6&question=Internet&accreditation=NBAMCA&documents=Internet provider, Bandwidth, Wi Fi availability, Cyber Security measures' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>


                    <tr>
                        <td>8</td>
                        <td>Governance,Institutional Support and Financial Resources</td>
                        <td>8.7.1</td>
                        <td>Checks for wiring and electrical installations for leakage and earthing</td>
                        <td>
                        <Link to='/viewnbaug11?name=8.7.1&question=Maintainance of wiring and electrical installations&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>8</td>
                        <td>Governance,Institutional Support and Financial Resources</td>
                        <td>8.7.2</td>
                        <td>Fire-fighting measurements</td>
                        <td>
                        <Link to='/viewnbaug11?name=8.7.2&question=Fire-fighting measurements&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>8</td>
                        <td>Governance,Institutional Support and Financial Resources</td>
                        <td>8.7.3</td>
                        <td>Safety of civil structure</td>
                        <td>
                        <Link to='/viewnbaug11?name=8.7.3&question=Safety of civil structure&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>8</td>
                        <td>Governance,Institutional Support and Financial Resources</td>
                        <td>8.7.4</td>
                        <td>Handling of hazardous chemicals</td>
                        <td>
                        <Link to='/viewnbaug11?name=8.7.4&question=Handling of hazardous chemicals&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>8</td>
                        <td>Governance,Institutional Support and Financial Resources</td>
                        <td>8.8</td>
                        <td>Counselling and Emergency Medical Care and First-aid</td>
                        <td>
                        <Link to='/viewnbaug11?name=8.8&question=Counselling&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>9</td>
                        <td>Continuous Improvement</td>
                        <td>9.1</td>
                        <td>Improvement in Success Index of Students</td>
                        <td>
                        <Link to='/viewnbaug11?name=9.1&question=Improvement in Success Index of Students&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>9</td>
                        <td>Continuous Improvement</td>
                        <td>9.2</td>
                        <td>Improvement in Academic Performance Index of Students</td>
                        <td>
                        <Link to='/viewnbaug11?name=9.2&question=Improvement in Academic Performance&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>9</td>
                        <td>Continuous Improvement</td>
                        <td>9.3</td>
                        <td>Improvement in Success Index of Students</td>
                        <td>
                        <Link to='/viewnbaug11?name=9.3&question=Improvement in Success Index of Students&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>9</td>
                        <td>Continuous Improvement</td>
                        <td>9.4</td>
                        <td>Enhancement of Faculty Qualification Index</td>
                        <td>
                        <Link to='/viewnbaug11?name=9.4&question=Enhancement of Faculty Qualification Index&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>9</td>
                        <td>Continuous Improvement</td>
                        <td>9.5</td>
                        <td>Improvement in Faculty Research Publications, R&D Work and Consultancy Work</td>
                        <td>
                        <Link to='/viewnbaug11?name=9.5&question=Improvement in Faculty Research Publications&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>9</td>
                        <td>Continuous Improvement</td>
                        <td>9.6</td>
                        <td>Continuing Education</td>
                        <td>
                        <Link to='/viewnbaug11?name=9.6&question=Continuing Education&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>9</td>
                        <td>Continuous Improvement</td>
                        <td>9.7</td>
                        <td>New Facility Created</td>
                        <td>
                        <Link to='/viewnbaug11?name=9.7&question=New Facility Created&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>9</td>
                        <td>Continuous Improvement</td>
                        <td>9.8</td>
                        <td>Overall improvement since last accreditation</td>
                        <td>
                        <Link to='/viewnbaug11?name=9.8&question=Improvement since last accreditation&accreditation=NBAMCA&documents=Faculty Qualification copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                   



                    

                    

              
            </tbody>
            </Table>

            <br /><br />
            

            

       
        
        </div>
        <FooterNav1 />
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;