import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faEdit, faComment, faCheckCircle, faFileAlt, faFlag, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { DataGrid } from '@material-ui/data-grid';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import ReportAdmin from '../components/layout/Reporteaqar';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import Spinner from 'react-bootstrap/Spinner';
//import { isInaccessible } from '@testing-library/dom';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const linkref=useRef();
    const moduleref=useRef();
    const topicref=useRef();

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState([]);
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());
    const [format, setFormat] = useState(false);

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;
    //const name=global1.name;

    const aqaryear=global1.aqaryear;
    const calendaryear=global1.calendaryear;

    const [isUploading, setIsuploading] = useState(false);

    const columns = [
        // { field: '_id', headerName: 'ID', width: 70 },
        { field: 'student', headerName: 'Student', width: 130 },
        { field: 'regno', headerName: 'Reg no', width: 130 },
      ];

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    const edit = (id, title, journal, yop, issn, articlelink, journallink, ugclisted) => {
        global1.pubid=id;
        global1.title=title;
        global1.journal=journal;
        global1.yop=yop;
        global1.issn=issn;
        global1.articlelink=articlelink;
        global1.journallink=journallink;
        global1.ugclisted=ugclisted;
        history.replace('/editpub');
    }

    const addperiod = () => {
        history.replace('/addperiod');
    }

    const addstudents = () => {
        // global1.courseid=courseid;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/addstudents');
    }

    

   

    let tutorials = [];
    const searchApi = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/publicationlinks', {
            params: {
                user: user,
                token: token,
                colid: colid,
                year:calendaryear,
                status1 : 'Flagged'
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        //localStorage.setItem("allpubs", JSON.stringify(response.data.data.classes));

       

        
        setResults(response.data.data.classes);
        //setLink(response.data.data.link);
        //alert('format ' + format);
        // try {
        //     formattable();
        // } catch(err) {
           
        // }
        
        
       
        //alert(results.length);
       
    };

    function formattable() {
        if (!format) {
            $('#example').DataTable(
                {
                    pagingType: 'full_numbers',
                      pageLength: 5,
                      processing: true,
                      dom: 'Bfrtip',
                          buttons: ['copy', 'csv', 'print'
                          ]
                }
            );
            setFormat(true);

        }
    }

   

    const deleteenrolment = async (enrolmentid) => {
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deletepubbyfac', {
            params: {
                id: enrolmentid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const getlocal = () => {
        let collection=localStorage.getItem("allpubs");
        if(!collection) {

        } else {
            setResults(JSON.parse(collection));

        }
        
    }

    

    useEffect(() => {
        checklogin();
        //search1();
        //getexamlist();
        //getlocal();
        searchApi('');
        //$('#example').DataTable();
        //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable();
    //     },
    //     1000
    //     );
    // });
    
    //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable(
    //         {
    //             pagingType: 'full_numbers',
    //               pageLength: 5,
    //               processing: true,
    //               dom: 'Bfrtip',
    //                   buttons: ['copy', 'csv', 'print'
    //                   ]
    //         }
    //     );
    //     } ,
    //     1000
    //     );
    // });
        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);


    const uploadlink = async (link1, id1, yop1) => {
        link1=link1 + '?criteria=3&Section=3.4.3&Subsection=Patent&field1=' + id1 + '&Year=' + yop1 + '&userid=' + user; 
        var win = window.open(link1, '_blank');
        win.focus();
      };

    


    
      const list1 = async (id1) => {
        var link1=window.location.origin + '/viewsupportingdocquery';
        link1=link1 + '?field1=' + id1 + '&colid=' + colid + '&user=' + user + '&name=' + name + '&token=' + token; 
        var win = window.open(link1, '_blank');
        win.focus();
      };

      const updatestatus = async (status1, id) => {
        const response = await ep1.get('/api/v1/updatepubcomments', {
            params: {
                user: user,
                token: token,
                colid: colid,
                status1: status1,
                comments: "NA",
                id: id

            }

        });
        setLoading(false);
        alert(response.data.status + ' - ' + status1);
    };


    const audit = (id, title, journal, year, issn, journallink, articlelink, ugclisted) => {
        var comments='';   
        if(!title || !journal || !year || !issn || !journallink || !articlelink || !ugclisted) {
            comments='All fields are required';
            return comments;
        }
       
        
        //comments=comments + ' year ' + year + ' ' + year.indexOf('-');

        if(year.indexOf('-') > -1) {
         
            comments = comments + ' Invalid year. Must be in calendar year format such as 2020.'
           
        } else {
           
            if(isNaN(year)) {
                comments = comments + ' Invalid year. Must be in calendar year format.'
            } else {
                const yr1=Number(year);
                if(yr1<2017 || yr1> 2022) {
                    comments=comments + ' data outside assessment period.';
                }
            }
        }
        
        var progstatus=' Invalid project name.';

      

        if(articlelink.toLowerCase().indexOf('doi')<0 ){
            comments=comments + ' Please provide doi link for article.';
        }
        if(articlelink.toLowerCase().indexOf('http')<0){
            comments=comments + ' Invalid article link';
        }
        if(journallink.toLowerCase().indexOf('http')<0){
            comments=comments + ' Invalid journal link';
        }
        if(journallink.toLowerCase().indexOf('scopus')>1 || journallink.toLowerCase().indexOf('academic')>1 || journallink.toLowerCase().indexOf('researchgate')>1 || journallink.toLowerCase().indexOf('google')>1 || journallink.toLowerCase().indexOf('portal')>1 || journallink.toLowerCase().indexOf('sciencedirect')>1 || journallink.toLowerCase().indexOf('elsevir')>1 || journallink.toLowerCase().indexOf('semanticscholar')>1 || journallink.toLowerCase().indexOf('pubmed')>1 || journallink.toLowerCase().indexOf('sciencepublishinggroup')>1 || journallink.toLowerCase().indexOf('ncbi')>1){
            comments=comments + ' Invalid journal link. Link should be of the journal website and not of scopus or any indexing website';
        }
        if(articlelink.toLowerCase().indexOf('scopus')>1 || articlelink.toLowerCase().indexOf('academic')>1 || articlelink.toLowerCase().indexOf('researchgate')>1 || articlelink.toLowerCase().indexOf('google')>1 || articlelink.toLowerCase().indexOf('portal')>1 || articlelink.toLowerCase().indexOf('sciencedirect')>1 || articlelink.toLowerCase().indexOf('elsevir')>1 || articlelink.toLowerCase().indexOf('semanticscholar')>1 || articlelink.toLowerCase().indexOf('pubmed')>1 || articlelink.toLowerCase().indexOf('sciencepublishinggroup')>1 || articlelink.toLowerCase().indexOf('ncbi')>1){
            comments=comments + ' Invalid article link. Please provide doi link. If doi is not available, please provide article link from journal website and not of scopus or any indexing website and also upload the supporting documents.';
        }

       
        if(ugclisted.toLowerCase().indexOf('yes')<0){
            comments=comments + ' Journal must be listed in UGC Care';
        }

        if(issn.indexOf('-') > -1) {
            const issnarray=issn.split('-');
            //comments=comments + ' ' + yeararray[1] + ' ' + isNaN(yeararray[1]);
            if(isNaN(issnarray[0]) || isNaN(issnarray[1].substring(0,3))) {
                comments=comments + ' Invalid ISSN number.'

            } else {
                if(issnarray[1].length !=4 || issnarray[0].length!=4) {
                    comments=comments + ' Invalid ISSN number format.';
                }

            }
        } else {
            comments = comments + ' Invalid ISSN number. Only mention the ISSN number.'
        }
        if(issn.length>9 || issn.length<9) {
            comments=comments + ' Invalid ISSN number format.';
        }


        
       
       
       
        return comments;
    }

    


    const generatelink = (link1,status1) => {
        var str1='';
        if(status1=='Flagged') {     
            return '';
        } else {
          
          return link1;
        }
    }

    const width=window.innerWidth;
    const mdivwidth=width - 330;

   
       
        //alert('loaded');
  

    


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    {/* <td valign="top" style={{ width: 60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <ReportAdmin />
                    </td> */}
                   
                    <td> 
                        {/* <TopNavigation /> */}

                    
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
            <p style={{ fontSize: 18 }}>View all publications (3.4.5)</p>
            <hr />
           
    
        <br />
        {isUploading ? (
				<div style={{ alignItems: 'center', marginTop: 30, marginBottom: 60}}>
                   <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
				</div>
			) : (
                <div></div>
			)} 

<div style={{fontSize: 10}}>
     
<Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
{/* <th>Document ID</th> */}
<th>Title of paper</th>
      <th>Name of the author/s</th>
      {/* <th>Department of the teacher</th> */}
      <th>Name of journal</th>
      <th>Documents</th>
      {/* <th>Year of publication</th>
      
      <th>ISSN number</th>
      
      <th>Link to website of the Journal</th> */}
      {/* <th>Link to article/paper /abstract of the article</th>
      
      <th>Is it listed in UGC Care list/Scopus/Web of Science /other, mention</th>
      <th>Audit observations</th>
      <th>Documents</th>
      <th>Comments</th>
      <th>Status</th>
      <th>Action</th> */}
    </tr>
    </thead>
    <tbody>
    

    {results.map((meetup3) => {
                return (
                    <tr>
                        {/* <td>
                        {meetup3._id}
                        </td> */}
                        <td>
                        {meetup3.title}
                        </td>
                        <td>
                        {meetup3.name}
                        </td>
                        {/* <td>
                            {meetup3.department}
                        </td> */}
                        <td>
                            {meetup3.journal}
                        </td>
                        {/* <td>
                            {meetup3.yop}
                        </td>
                        
                        <td>
                            {meetup3.issn}
                        </td>
                      
                        <td>
                            {meetup3.journallink}
                        </td> */}
                        {/* <td>
                            {meetup3.articlelink}
                        </td>
                        <td>
                            {meetup3.ugclisted}
                        </td>
                        <td>

                            {audit(meetup3._id, meetup3.title, meetup3.journal, meetup3.yop, meetup3.issn, meetup3.journallink, meetup3.articlelink, meetup3.ugclisted)}
                        
                        </td> */}
                        
                       
                        <td>
                        {meetup3.seminars.map((meetup31) => {
                return (
                    <div>
                        
                        
                        <a href={meetup31.link} target="_blank">{meetup31.link}</a> - {meetup31.status1} 
                        <br />
                      
                        </div>

                    );
                })}
                        </td>
                        {/* <td>
                        {meetup3.allcomments.map((meetup32) => {
                return (
                    <div>
                        
                        {meetup32.comments} 
                        <br />
                      
                        </div>

                    );
                })}
                        </td> */}
                        {/* <td>
                            {meetup3.status1}
                        </td> */}
                        {/* <td> */}
                     
                        {/* <Button variant="outline-danger" onClick={() => deleteenrolment(meetup3._id)}>
                        <FontAwesomeIcon icon={faTrash} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                            </Button> */}
                            {/* <Button variant="outline-danger" onClick={() => edit(meetup3._id,meetup3.title, meetup3.journal,  meetup3.yop, meetup3.issn, meetup3.articlelink, meetup3.journallink, meetup3.ugclisted)}>
                        <FontAwesomeIcon icon={faEdit} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                            </Button> */}
                             {/* <Button variant="outline-danger" onClick={() => list1(meetup3._id)}>
                        <FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                        List
                    </Button> 

                    <Button variant="outline-danger" onClick={() => updatestatus('Accepted',meetup3._id)}>
                        <FontAwesomeIcon icon={faCheckCircle} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                        Accept
                    </Button> 

                    <Button variant="outline-danger" onClick={() => updatestatus('Flagged',meetup3._id)}>
                        <FontAwesomeIcon icon={faFlag} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                        Flag
                    </Button> 
                        </td> */}
                
                    
                
                
                   

                   
                                </tr>
              

                );
            })}
            </tbody>
            </Table>

            </div>

            <br /><br />




        


            

       
        
        </div>
        {/* <FooterNav1 /> */}
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;