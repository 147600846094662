import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function SidefacNavigation() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    



    return (
        <div className={classes1.sidebar}>

<div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />
            
        {/* <div style={{marginBottom: 10}}><b>Teach</b></div> */}
        <Link to='/dashcourse'> My Workload</Link>
        <Link to='/viewclass'> My timetable (All classes)</Link>
<Link to='/viewclassseven'> My timetable (7 days)</Link>
<Link to='/viewstudparents'> All parents data</Link>
        {/* <Link to='/workload'> Create Class / All Classes</Link> */}
        
        
        {/* <Link to='/menteelist'> Mentor - My Mentee</Link> */}
        {/* <Link to='/workdiary'> Workdiary (Format 1)</Link>
        <Link to='/viewdynamicworkdiary'>Auto populated workdiary</Link>
        <Link to='/getmyfeedback'> My feedback</Link>
        <Link to='/proctorlist'> My exam proctoring</Link>
        <Link to='/examenrlist'> Check exam enrollment</Link> */}

        {/* <Link to='/checkocr'> Check OCR</Link> */}
        {/* <Link to='/ocrtest1'> Check OCR</Link>
        <Link to='/geotagtest1'> Check Geotag</Link>
        <Link to='/addpdfbulk'> Check Pdf</Link> */}
        

        <hr />

       
        
       

</div>
    );

}

export default SidefacNavigation;