import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Bar } from 'react-chartjs-2';

// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import SidefacNavigation from '../components/layout/SidefacNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';
import SidendashNavigation from '../components/layout/SidendashNavigation';

import Spinner from 'react-bootstrap/Spinner';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const linkref=useRef();
    const moduleref=useRef();
    const topicref=useRef();

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [projectcount, setProjectcount] = useState([]);
    const [projectamount, setProjectamount] = useState([]);
    const [bookcount, setBookcount] = useState([]);
    const [tdata, setTdata] = useState([]);
    const [publicationcount, setPublicationcount] = useState([]);
    const [q9, setQ9] = useState([]);
    const [q10, setQ10] = useState([]);
    const [q11, setQ11] = useState([]);
    const [q12, setQ12] = useState([]);
    
    const [link, setLink] = useState();
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;
    //const name=global1.name;

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    

    const addperiod = () => {
        history.replace('/addperiod');
    }

    const addstudents = () => {
        // global1.courseid=courseid;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/addstudents');
    }

    

    const searchApi2 = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getparentfeedbackavgscore', {
            params: {
                colid: colid

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setResults2(response.data.data.classes);
       
        //alert(results.length);
       
    };


    const searchApi = async () => {
        setLoading(true);
        setTerm(studid);
        var q1='Administrative staff in the institution is cooperative';
        const response = await ep1.get('/api/v1/getparentfeedbackscoresbyq', {
            params: {
                colid: colid,
                question: q1

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setResults(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const searchApi1 = async () => {
        setLoading(true);
        setTerm(studid);
        var q2='Direct communication with the teaching staff is available in the institution';
        const response = await ep1.get('/api/v1/getparentfeedbackscoresbyq', {
            params: {
                colid: colid,
                question: q2

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setResults1(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const searchPubcount = async () => {
        setLoading(true);
        setTerm(studid);
        var q3='Facilities provided by the institution is helpful for your child';
        const response = await ep1.get('/api/v1/getparentfeedbackscoresbyq', {
            params: {
                colid: colid,
                question: q3

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setPublicationcount(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const searchBookcount = async () => {
        setLoading(true);
        setTerm(studid);
        var q4='Hostel facilities are satisfactory for your child';
        const response = await ep1.get('/api/v1/getparentfeedbackscoresbyq', {
            params: {
                colid: colid,
                question: q4

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setBookcount(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const getprojectcount = async () => {
        setLoading(true);
        setTerm(studid);
        var q5='Institution provides a fair rate of transparency for evaluation system to children';
        const response = await ep1.get('/api/v1/getparentfeedbackscoresbyq', {
            params: {
                colid: colid,
                question: q5

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setProjectcount(response.data.data.classes);  
    };

    const getprojectamount = async () => {
        setLoading(true);
        setTerm(studid);
        var q6='Institution provides a standard academic growth for child';
        const response = await ep1.get('/api/v1/getparentfeedbackscoresbyq', {
            params: {
                colid: colid,
                question: q6

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setProjectamount(response.data.data.classes);  
    };

    const getq9 = async () => {
        setLoading(true);
        setTerm(studid);
        var q9='Institution provides physical safety for your child';
        const response = await ep1.get('/api/v1/getparentfeedbackscoresbyq', {
            params: {
                colid: colid,
                question: q9

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setQ9(response.data.data.classes);  
    };

    const getq10 = async () => {
        setLoading(true);
        setTerm(studid);
        var q10='Institution provides upgradation of hard & soft skills for children';
        const response = await ep1.get('/api/v1/getparentfeedbackscoresbyq', {
            params: {
                colid: colid,
                question: q10

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setQ10(response.data.data.classes);  
    };

    const getq11 = async () => {
        setLoading(true);
        setTerm(studid);
        var q11='Library Infrastructure is helpful for your child';
        const response = await ep1.get('/api/v1/getparentfeedbackscoresbyq', {
            params: {
                colid: colid,
                question: q11

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setQ11(response.data.data.classes);  
    };

    const getq12 = async () => {
        setLoading(true);
        setTerm(studid);
        var q12='This institution is suitable for your child’s education';
        const response = await ep1.get('/api/v1/getparentfeedbackscoresbyq', {
            params: {
                colid: colid,
                question: q12

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setQ12(response.data.data.classes);  
    };

 

    

    useEffect(() => {
        //checklogin();
        //search1();
        //getexamlist();
        searchApi('');
        searchApi1('');
        searchApi2();
        searchPubcount();
        searchBookcount();
        getprojectamount();
        getprojectcount();
        getq10();
        getq11();
        getq9();
        getq12();
        //gettdata();
        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);

    


    
   

    

    const width=window.innerWidth;
    const mdivwidth=width - 330;

    
    const timely = (date1) => {
        var dt1=new Date(date1);
        dt1.setHours(dt1.getHours() - 5);
        dt1.setMinutes(dt1.getMinutes() - 30);
        var hours=dt1.getHours();
        var minutes=dt1.getMinutes();
        var month=dt1.getMonth() + 1;
        if(minutes.length<2) {
            minutes='0' + minutes;
        }
        var t1;
        if(hours>11) {
            t1='PM';
        } else {
            t1='AM';
        }
        if(hours>12) {
            hours=hours-12;
        }
        var dt2=dt1.getDate() + '/' + month + '/' + dt1.getFullYear() + ' ' + hours + ':' + minutes + t1;
        return dt2;

    }


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                  
                  
                    
                    <td> 

                      
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
           <h4>Parent feedback analysis</h4>
           <hr />
           <h5>Introduction</h5>
           <br />
           The IQAC team of the institution has collected feedback from the parents as part of quality monitoring 
           and quality asssurance practices implemented in the institution. Feedback is usually collected during 
           the regular Parent Teacher meeting. the feedback link is also available in the website and parents are encouraged to 
           fill in the feedback form.
           <br /><br />
           The data so collected by using an online software is also analyzed by the software and 
           the result is reviewed by the IQAC team. Recommendations are prepared and forwarded to the management. 
           Action taken report is also prepared department wise for follow up actions. 
           <br /><br />
           The following is the analysis of number of responses per question.
           <br /><br /><br />

          
            {loading ? (
                <div style={{ alignItems: 'center', marginTop: 30, marginBottom: 60}}>
                   <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <div></div>
            )} 


        <div className="container">
                <div className="row">
                <div className="col-6">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                         >
                             Administrative staff in the institution is cooperative
            <hr />
            <div style={{width: 450, height: 250}}>

            <Bar
	            data={{
                    labels: results.map((labels) => {
                        return (
                            labels._id.score        
                            );
                        }),
                    datasets: [{
                        label: 'Number of responses',
                        data: 
                            results.map((labels1) => {
                                return (
                                    parseInt(labels1.total_attendance)        
                                    );
                                })
                            
                        ,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                }}
	            width={400}
	            height={250}
	            options={{ 
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                }

                            }
                            
                        ]
                    }
                }}
            />
           </div>
                           

                        </div>
                </div>
                <div className="col-6">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                         >
                             Direct communication with the teaching staff is available in the institution
        <hr />
        <div style={{width: 450, height: 250}}>

            <Bar
	            data={{
                    labels: results1.map((labels) => {
                        return (
                            labels._id.score        
                            );
                        }),
                    datasets: [{
                        label: 'Number of responses',
                        data: 
                            results1.map((labels1) => {
                                return (
                                    parseInt(labels1.total_attendance)        
                                    );
                                })
                            
                        ,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                }}
	            width={400}
	            height={250}
	            options={{ 
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                }

                            }
                            
                        ]
                    }
                }}
            />
           </div>


                         </div>
                </div>
               
               
                </div>


{/* start div */}
                <div className="row">
                <div className="col-6">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                         >
                            Facilities provided by the institution is helpful for your child
            <hr />
            <div style={{width: 450, height: 250}}>

            <Bar
	            data={{
                    labels: publicationcount.map((labels) => {
                        return (
                            labels._id.score        
                            );
                        }),
                    datasets: [{
                        label: 'Number of responses',
                        data: 
                            publicationcount.map((labels1) => {
                                return (
                                    parseInt(labels1.total_attendance)        
                                    );
                                })
                            
                        ,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                }}
	            width={400}
	            height={250}
	            options={{ 
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                }

                            }
                            
                        ]
                    }
                }}
            />
           </div>
                           

                        </div>
                </div>
                <div className="col-6">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                         >
                             Hostel facilities are satisfactory for your child
        <hr />
        <div style={{width: 450, height: 250}}>

            <Bar
	            data={{
                    labels: bookcount.map((labels) => {
                        return (
                            labels._id.score        
                            );
                        }),
                    datasets: [{
                        label: 'Number of responses',
                        data: 
                            bookcount.map((labels1) => {
                                return (
                                    parseInt(labels1.total_attendance)        
                                    );
                                })
                            
                        ,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                }}
	            width={400}
	            height={250}
	            options={{ 
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                }

                            }
                            
                        ]
                    }
                }}
            />
           </div>


                         </div>
                </div>
               
               
                </div>
                {/* end div */}

                

{/* start div 3 */}
<div className="row">
                <div className="col-6">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                         >
                             Institution provides a fair rate of transparency for evaluation system to children
            <hr />
            <div style={{width: 450, height: 250}}>

            <Bar
	            data={{
                    labels: projectcount.map((labels) => {
                        return (
                            labels._id.score        
                            );
                        }),
                    datasets: [{
                        label: 'Number of responses',
                        data: 
                            projectcount.map((labels1) => {
                                return (
                                    parseInt(labels1.total_attendance)        
                                    );
                                })
                            
                        ,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                }}
	            width={400}
	            height={250}
	            options={{ 
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                }

                            }
                            
                        ]
                    }
                }}
            />
           </div>
                           

                        </div>
                </div>
                <div className="col-6">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                         >
                            Institution provides a standard academic growth for child
        <hr />
        <div style={{width: 450, height: 250}}>

            <Bar
	            data={{
                    labels: projectamount.map((labels) => {
                        return (
                            labels._id.score        
                            );
                        }),
                    datasets: [{
                        label: 'Number of responses',
                        data: 
                            projectamount.map((labels1) => {
                                return (
                                    parseInt(labels1.total_attendance)        
                                    );
                                })
                            
                        ,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                }}
	            width={400}
	            height={250}
	            options={{ 
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                }

                            }
                            
                        ]
                    }
                }}
            />
           </div>


                         </div>
                </div>
               
               
                </div>
                {/* end div */}



{/* start div 4 */}
<div className="row">
                <div className="col-6">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                         >
                             Institution provides physical safety for your child
            <hr />
            <div style={{width: 450, height: 250}}>

            <Bar
	            data={{
                    labels: q9.map((labels) => {
                        return (
                            labels._id.score        
                            );
                        }),
                    datasets: [{
                        label: 'Number of responses',
                        data: 
                            q9.map((labels1) => {
                                return (
                                    parseInt(labels1.total_attendance)        
                                    );
                                })
                            
                        ,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                }}
	            width={400}
	            height={250}
	            options={{ 
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                }

                            }
                            
                        ]
                    }
                }}
            />
           </div>
                           

                        </div>
                </div>
                <div className="col-6">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                         >
                            Institution provides upgradation of hard & soft skills for children
        <hr />
        <div style={{width: 450, height: 250}}>

            <Bar
	            data={{
                    labels: q10.map((labels) => {
                        return (
                            labels._id.score        
                            );
                        }),
                    datasets: [{
                        label: 'Number of responses',
                        data: 
                            q10.map((labels1) => {
                                return (
                                    parseInt(labels1.total_attendance)        
                                    );
                                })
                            
                        ,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                }}
	            width={400}
	            height={250}
	            options={{ 
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                }

                            }
                            
                        ]
                    }
                }}
            />
           </div>


                         </div>
                </div>
               
               
                </div>
                {/* end div */}



{/* start div 5 */}
<div className="row">
                <div className="col-6">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                         >
                             Library Infrastructure is helpful for your child
            <hr />
            <div style={{width: 450, height: 250}}>

            <Bar
	            data={{
                    labels: q11.map((labels) => {
                        return (
                            labels._id.score        
                            );
                        }),
                    datasets: [{
                        label: 'Number of responses',
                        data: 
                            q11.map((labels1) => {
                                return (
                                    parseInt(labels1.total_attendance)        
                                    );
                                })
                            
                        ,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                }}
	            width={400}
	            height={250}
	            options={{ 
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                }

                            }
                            
                        ]
                    }
                }}
            />
           </div>
                           

                        </div>
                </div>
                <div className="col-6">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                         >
                            This institution is suitable for your child’s education
        <hr />
        <div style={{width: 450, height: 250}}>

            <Bar
	            data={{
                    labels: q12.map((labels) => {
                        return (
                            labels._id.score        
                            );
                        }),
                    datasets: [{
                        label: 'Number of responses',
                        data: 
                            q12.map((labels1) => {
                                return (
                                    parseInt(labels1.total_attendance)        
                                    );
                                })
                            
                        ,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                }}
	            width={400}
	            height={250}
	            options={{ 
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                }

                            }
                            
                        ]
                    }
                }}
            />
           </div>


                         </div>
                </div>
               
               
                </div>
                {/* end div */}

                <br /><br />
                <h5>Average score</h5>
           <br />
           The IQAC team has also analyzed the overall responses and the average score per question. In order to achieve this, 
           responses for each question was collected in a scale of 1-5, with 5 being the most favorable and 1 being 
           least favorable. The average score was calculated over total responses collected over time for all the programs and 
           it was analyzed by using the software.
           <br /><br />
           Recommendations are prepared and forwarded to the management. 
           Action taken report is also prepared department wise for follow up actions. 
           <br /><br />
           The following is the analysis of average score per question.
           <br /><br /><br />


           <div className="container">
                <div className="row">
               
                {results2.map((meetup32) => {
                return (
                    <div className="col-4">
                         <div className={classes1.card}>
                         {meetup32._id.question} <i>Average Score {meetup32.total_attendance} </i>
                <br /> 
                    </div>
                    </div>

                );
            })}
            </div></div>

            <br /><br /><br />
            
           <h5>Graphical representation of average score analysis</h5>
           <hr />
           <div style={{width: 900, height: 400}}>

            <Bar
	            data={{
                    labels: results2.map((labels) => {
                        return (
                            labels._id.question        
                            );
                        }),
                    datasets: [{
                        label: 'Average score',
                        data: 
                            results2.map((labels1) => {
                                return (
                                    parseInt(labels1.total_attendance)        
                                    );
                                })
                            
                        ,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                }}
	            width={900}
	            height={400}
	            options={{ 
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                }

                            }
                            
                        ]
                    }
                }}
            />
           </div>
           <br /><br /><br /><br /><br /><br /><br /><br />
           <h5>Conclusion</h5>
           <br />
           Feedback analysis and action taken report is a regular quality monitoring and quality assurance activity of the IQAC team 
           of the institution. Feedback is collected every semester and reports are analyzed. The recommendations are also reviewed 
           and appropriate actions are taken. The actions taken are noted in the IQAC Annual Quality Assurance Report and submitted 
           in the yearly reports.
           <br /><br />
           In case there is any recommendation that requires detailed planning and effort to implement, the same is noted 
           in the Plan of Action of IQAC for the subsequent year in the AQAR. The activities are monitored and outcome is mentioned in the Actions Taken 
           in the next year AQAR.
           <br /><br />
           <br /><br />

           <table className="table">
               <tr>
                   <td>
                       ______________________________________________
                   </td>
                   <td>
                       ______________________________________________
                   </td>
                   <td>
                       ______________________________________________
                   </td>
               </tr>
               <tr>
                   <td>
                       IQAC Coordinator
                   </td>
                   <td>
                       IQAC Analyst
                   </td>
                   <td>
                       Principal
                   </td>
               </tr>
           </table>





            </div>

            
    
        

        

            

       
        
        </div>
       
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;