import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/Sfin1';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);



    const yearref=useRef();
const accountref=useRef();
const transactionref=useRef();
const amountref=useRef();
const typeref=useRef();
const transactiondateref=useRef();
const transactionidref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [accounts, setAccounts] = useState([]);
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       getaccountlist();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const finyear=global1.finyear;
    //const account=global1.account;
    const transactionid=global1.transactionid;
    const transaction=global1.transaction + ' ^ ' + global1.account;
    const transactiondate=global1.transactiondate;


    const searchapi = async () => {
       

        //const year=yearref.current.value;
const account=accountref.current.value;
//const transaction=transactionref.current.value;
const amount=amountref.current.value;
const type=typeref.current.value;
//const transactiondate=transactiondateref.current.value;
//const transactionid=transactionidref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/createntledgerbyfac', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                year:finyear,
account:account,
transaction:transaction,
amount:amount,
type:type,
transactiondate:transactiondate,
transactionid:transactionid,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewntledger');
       
    };

    const getaccountlist = async () => {
        //setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getallnaccounts', {
            params: {
                colid: colid,
                user: user,
                token: token

            }

        });
        //setLoading(false);
        console.log(response.data.data);
        setAccounts(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const formatdates = (date1) => {
        var dt1=new Date(date1);
        var month=dt1.getMonth() + 1;
        return dt1.getDate() + '/' + month + '/' + dt1.getFullYear();
    }

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Add data for {finyear} {transaction} {formatdates(transactiondate)}</p>
                        <hr />
                       
                            
                  {/* Enter year<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter year" id="year" ref={yearref} />
<br /><br /> */}

{/* Enter account<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter account" id="account" ref={accountref} />
<br /><br /> */}


<label htmlFor='account'>Select account</label>
                    <select id="account" className="form-control"   ref={accountref}>
                    <option value="1">Select account</option>

    {accounts.map((meetup3) => {
                return (
                    <option value={meetup3.account}>{meetup3.account} - {meetup3.acccode}</option>
 
                );
            })}
                  
                    </select>
                    <br /><br />

{/* Enter transaction<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter transaction" id="transaction" ref={transactionref} />
<br /><br /> */}

Enter amount<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter amount" id="amount" ref={amountref} />
<br /><br />

<label htmlFor="type">type</label>

<select id="type" className="form-control"  ref={typeref}>
<option value="Debit">Debit</option>
<option value="Credit">Credit</option>
</select>
<br /><br />

{/* Enter transactiondate<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter transactiondate" id="transactiondate" ref={transactiondateref} />
<br /><br />

Enter transactionid<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter transactionid" id="transactionid" ref={transactionidref} />
<br /><br /> */}




                      
                        <button  onClick={searchapi}>Add data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;