import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidekpiNavigation from '../components/layout/ReportAdmin';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import { duration } from "@material-ui/core";



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const criteriaref=useRef();
    const metricref=useRef();
    const currentvalueref=useRef();
    const firstkpiref=useRef();
    const threekpiref=useRef();
    const fivekpiref=useRef();
    const linkref=useRef();
    const commentsref=useRef();
    const typeref=useRef();
    const interventionref=useRef();
    const interventionidref=useRef();
    const intownerref=useRef();
    const narrativeref=useRef();
    const followupdateref=useRef();
    const groupref=useRef();
    const weightageref=useRef();
    const impactref=useRef();
    const categoryref=useRef();
    
  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());
    const [faculties, setFaculties] = useState([]);

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const kpiid=global1.kpiid;


    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       //getfacultylist();
       criteriaref.current.value=global1.criteria;
       metricref.current.value=global1.metric;
       currentvalueref.current.value=global1.currentvalue;
       firstkpiref.current.value=global1.firstkpi;
       threekpiref.current.value=global1.threekpi;
       fivekpiref.current.value=global1.fivekpi;
       linkref.current.value=global1.link;
       commentsref.current.value=global1.comments;
       typeref.current.value=global1.type;
       interventionref.current.value=global1.intervention;
       //interventionidref.current.value=global1.interventionid;
       intownerref.current.value=global1.intowner;
       narrativeref.current.value=global1.narrative;
       groupref.current.value=global1.group;
       weightageref.current.value=global1.weightage;
       impactref.current.value=global1.impact;
       categoryref.current.value=global1.category;
       //followupdateref.current.value=global1.category;
       
       

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const getfacultylist = async () => {
        //setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getfacultylist', {
            params: {
                colid: colid,
                user: user,
                token: token

            }

        });
        //setLoading(false);
        console.log(response.data.data);
        setFaculties(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const searchapi = async () => {
        const criteria=criteriaref.current.value;
        const metric=metricref.current.value;
        const currentvalue=currentvalueref.current.value;
        const firstkpi=firstkpiref.current.value;
        const threekpi=threekpiref.current.value;
        const fivekpi=fivekpiref.current.value;
        const link=linkref.current.value;
        const comments=commentsref.current.value;
        const type=typeref.current.value;
        const intervention=interventionref.current.value;
       
        const intowner=intownerref.current.value;
        const narrative=narrativeref.current.value;
        const group=groupref.current.value;
        const weightage=weightageref.current.value;
        const impact=impactref.current.value;
        const category=categoryref.current.value;
        const followupdate=new Date(followupdateref.current.value);
        
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updatekpidate', {
            params: {
                id: kpiid,
                user: user,
                token: token,
                colid: colid,
                name: name,
                criteria: criteria,
                metric:metric,
                currentvalue:currentvalue,
                firstkpi:firstkpi,
                threekpi:threekpi,
                fivekpi:fivekpi,
                link:link,
                comments:comments,
                type:type,
                intervention:intervention,
                intowner:intowner,
                narrative:narrative,
                group:group,
                weightage:weightage,
                impact:impact,
                category:category,
                followupdate:followupdate

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewkpiuser1');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidekpiNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <h3><p style={{ fontSize: 18 }}> Edit KPI</p></h3>
                        <hr />
                       
                        Criteria <br />
                        <input type="text" required style={{width: '100%'}} placeholder="Enter criteria" id="criteria" ref={criteriaref} />
                            
                        <br /><br />
                        Metric <br />

                        <input type="text" required style={{width: '100%'}} placeholder="Enter metric" id="metric" ref={metricref} />
                            
                        <br /><br />
                        Evidences <br />

                        <input type="text" required style={{width: '100%'}} placeholder="Enter narrative" id="narrative" ref={narrativeref} />
                            
                            <br /><br />
                            Current value <br />

                        <input type="number" required style={{width: '100%'}} placeholder="Enter current value" id="currentvalue" ref={currentvalueref} />
                            
                        <br /><br />
                        One year target <br />

                        <input type="number" required style={{width: '100%'}} placeholder="Enter one year target" id="firstkpi" ref={firstkpiref} />
                            
                        <br /><br />
                        Third year target <br />

                        <input type="number" required style={{width: '100%'}} placeholder="Enter three years target" id="threekpi" ref={threekpiref} />
                            
                        <br /><br />
                        Fifth year target <br />

                        <input type="number" required style={{width: '100%'}} placeholder="Enter five years target" id="fivekpi" ref={fivekpiref} />
                            
                        <br /><br />
                        Current state <br />

                        <input type="text" required style={{width: '100%'}} placeholder="Enter current state" id="link" ref={linkref} />
                            
                        <br /><br />  
                        Desired future state <br />
                       
                        <input type="text" required style={{width: '100%'}} placeholder="Enter future state" id="comments" ref={commentsref} />
                            
                        <br /><br />

                        
                        <label htmlFor='year'>Select Type</label>
                        <select id="year" className="form-control"  ref={typeref}>
                            <option value="University">University</option>
                            <option value="Affiliated">Affiliated</option>
                            <option value="Autonomous">Autonomous</option>
                           
                       
                        </select>

                            <br /><br />

                            Intervention <br />

                        <input type="text" required style={{width: '100%'}} placeholder="Enter intervention" id="intervention" ref={interventionref} />
                            
                        <br /><br />
                        Intervention owner <br />

                        <input type="text" required style={{width: '100%'}} placeholder="Enter intervention owner" id="intowner" ref={intownerref} />
                            
                        <br /><br />

                      

                     
             

                       

                        {/* <input type="text" required style={{width: '100%'}} placeholder="Enter group" id="group" ref={groupref} />
                            
                        <br /><br /> */}

                        <label htmlFor='group'>Select group</label>
                        <select id="group" className="form-control"  ref={groupref}>
                            <option value="Feedback">Feedback</option>
                            <option value="Syllabus">Syllabus</option>
                            <option value="Courses">Courses</option>
                            <option value="Event">Event</option>
                            <option value="Exam">Exam</option>
                            <option value="Library">Library</option>
                            <option value="Teaching learning">Teaching learning</option>
                            <option value="IQAC">IQAC</option>
                            <option value="Governance">Governance</option>
                            <option value="Finance">Finance</option>
                            
                           
                       
                        </select>

                            <br /><br />
                            Weightage <br />

                        <input type="number" required style={{width: '100%'}} placeholder="Enter weightage" id="weightage" ref={weightageref} />
                            
                        <br /><br />
                        Impact <br />

                        <input type="number" required style={{width: '100%'}} placeholder="Enter impact" id="metric" ref={impactref} />
                            
                        <br /><br />

                        Category <br />

                        <input type="text" required style={{width: '100%'}} placeholder="Enter category" id="category" ref={categoryref} />
                            
                        <br /><br />

                        Follow up date <br />

                        <input type="date" required style={{width: '100%'}} placeholder="Enter followupdate" id="followupdate" ref={followupdateref} />
                            
                        <br /><br />

                        

                      
                        
                        <button  onClick={searchapi}>Update KPI</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;