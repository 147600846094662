import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';


// import classes from './Allclasses.module.css';

import classes1 from './newform.module.css';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    //const [term1, setTerm1] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [link, setLink] = useState();
    const [loading, setLoading] = useState();

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const token=global1.token;


    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');


        }
    }

    const getexamlist= () => {

        fetch('http://localhost:3000/api/v1/getexamlist?regno=S194&colid=30',{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
              }

        })
  .then(response => response.json())
  .then(data => {
      console.log(data.data.classes)
      setResults(data.data.classes);
    });

    }

   


    const searchApi = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/rgetexamlist', {
            params: {
                regno: regno,
                colid: colid,
                token: token

            }

        });
        setLoading(false);
        console.log(response.data.data.classes);
        setResults(response.data.data.classes);
        //alert(results.length);
       
    };

    useEffect(() => {
        checklogin();
        //search1();
        //getexamlist();
        searchApi('');
        //searchApi1('');

    }, []);

    function checkdata(examcode, examname) {
        alert (examcode + '-' + examname);
    }

    return (
        <div style={{ width: 900, margin: 'auto ', marginTop: 80, minHeight: 600 }}>
           <h3>List of examination</h3>
            <p style={{ fontSize: 20, marginTop: 20}}>Please click on the exam to get started.</p>
           
            <br />
            <div className={classes1.actions}>
                    <button type="button" onClick={() => searchApi()}>Refresh list</button>
                    </div>
           
        <br />
       
        
        {results.map((meetup) => {
                return (
                <div className={classes1.card}>
                    {meetup.course}
                    <br /><br />
                    <div className={classes1.actions}>
                    <button type="button" onClick={() => checkdata(meetup.examcode, meetup.course)}>Get started</button>
                    </div>
                    <br />
                </div>
                );
            })}
        
        </div>
        
    );

}

export default Allclasses;