import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faEdit, faComment, faFileAlt, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { DataGrid } from '@material-ui/data-grid';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import SidekpiNavigation from '../components/layout/SidemetricNavigationadmin';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import Spinner from 'react-bootstrap/Spinner';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const criteriaref=useRef();
    const metricref=useRef();
    const currentvalueref=useRef();
    const firstkpiref=useRef();
    const threekpiref=useRef();
    const fivekpiref=useRef();
    const linkref=useRef();
    const commentsref=useRef();
    const typeref=useRef();
    const interventionref=useRef();
    const interventionidref=useRef();
    const intownerref=useRef();
    const narrativeref=useRef();
    const groupref=useRef();
    const weightageref=useRef();
    const impactref=useRef();

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState([]);
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());
    const [format, setFormat] = useState(false);

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;
    //const name=global1.name;

    const [isUploading, setIsuploading] = useState(false);

    const columns = [
        // { field: '_id', headerName: 'ID', width: 70 },
        { field: 'student', headerName: 'Student', width: 130 },
        { field: 'regno', headerName: 'Reg no', width: 130 },
      ];

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    const edit = (id, criteria, metric, currentvalue, firstkpi, threekpi, fivekpi, link, comments,type, intervention, interventionid, intowner, narrative, group, weightage, impact, category  ) => {
        global1.kpiid=id;
        global1.criteria=criteria;
        global1.metric=metric;
        global1.currentvalue=currentvalue;
        global1.firstkpi=firstkpi;
        global1.threekpi=threekpi;
        global1.fivekpi=fivekpi;
        global1.link=link;
        global1.comments=comments;
        global1.type=type;
        global1.intervention=intervention;
        global1.interventionid=interventionid;
        global1.intowner=intowner;
        global1.narrative=narrative;
        global1.group=group;
        global1.weightage=weightage;
        global1.impact=impact;
        global1.category=category;
        
        history.replace('/editkpi');
    }

    const gototask = (group, intervention, criteria, metric, narrative) => {
        global1.group=group;
        global1.intervention=intervention;
        global1.criteria=criteria;
        global1.metric=metric;
        global1.narrative=narrative;

       
        
        history.replace('/viewtasks');
    }

    const addperiod = () => {
        history.replace('/addperiod');
    }

    const addstudents = () => {
        // global1.courseid=courseid;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/addstudents');
    }

    function getaffiliated(affvalue) {
        if(affvalue=="1") {
            return "Yes";
        } else {
            return "No";
        }
    }

    

   

    let tutorials = [];
    const searchApi = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getkpi', {
            params: {
                user: user,
                token: token,
                colid: colid
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        localStorage.setItem("p_kpi", JSON.stringify(response.data.data.classes));

       

        
        setResults(response.data.data.classes);
        setLink(response.data.data.link);
        //alert('format ' + format);
        try{

            formattable();
        } catch(err) {

        }
        
        
        
       
        //alert(results.length);
       
    };

    function formattable() {
        if (!format) {
            $('#example').DataTable(
                {
                    pagingType: 'full_numbers',
                      pageLength: 5,
                      processing: true,
                      dom: 'Bfrtip',
                          buttons: ['copy', 'csv', 'print'
                          ]
                }
            );
            setFormat(true);

        }
    }

   

    const deleteenrolment = async (enrolmentid) => {
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deletekpi', {
            params: {
                id: enrolmentid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const getlocal = () => {
        let collection=localStorage.getItem("p_kpi");
        if(!collection) {

        } else {
            setResults(JSON.parse(collection));

        }
        
    }

    

    useEffect(() => {
        checklogin();
        //search1();
        //getexamlist();
        getlocal();
        searchApi('');
        //$('#example').DataTable();
        //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable();
    //     },
    //     1000
    //     );
    // });
    
    //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable(
    //         {
    //             pagingType: 'full_numbers',
    //               pageLength: 5,
    //               processing: true,
    //               dom: 'Bfrtip',
    //                   buttons: ['copy', 'csv', 'print'
    //                   ]
    //         }
    //     );
    //     } ,
    //     1000
    //     );
    // });
        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);

    

    const uploadlink = async (link1, id1, year) => {
        link1=link1 + '?criteria=4&Section=4.1.3/4.3.1&Subsection=ICT&field1=' + id1 + '&Year=' + year; 
        var win = window.open(link1, '_blank');
        win.focus();
      };

    


    
   

    

    const width=window.innerWidth;
    const mdivwidth=width - 330;

   
       
        //alert('loaded');
  

    


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width: 60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidekpiNavigation />
                    </td>
                   
                    <td> 
                        <TopNavigation />

                    
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
            <h3><p style={{ fontSize: 18 }}>View KPI</p></h3>
            <hr />
            To add KPI for different accreditation, please contact your Account manager or Sulagna at sulagna.singharoy@epaathsala.com.
            <br /><br />
            <table>
                <tr>
                    <td>
                    <Link to='/addkpi' className="btn btn-dark"> Add KPI</Link>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <Link to='/addkpibulk' className="btn btn-dark"> Bulk Add KPI</Link>
                    </td>
                </tr>
            </table>
            
           
            <br />
        <br />
        {isUploading ? (
				<div style={{ alignItems: 'center', marginTop: 30, marginBottom: 60}}>
                   <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
				</div>
			) : (
                <div></div>
			)} 
     
<Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
        <th>Group</th>
        <th>Category</th>
        <th>Criteria</th>
        <th>Metric</th>
        <th>Evidences</th>
        
        <th>Current Value</th>
        <th>Target</th>
       
        <th>Current state</th>
        <th>Future state</th>
     
        <th>Intervention</th>
        
        <th>Intervention Owner</th>
      
      
        <th>Action</th>
    </tr>
    </thead>
    <tbody>
    

    {results.map((meetup3) => {
                return (
                    <tr>
                        <td>
                            {meetup3.group}
                        </td>
                        <td>
                            {meetup3.category}
                        </td>
                        <td>
                            {meetup3.criteria}
                        </td>
                        <td>
                            {meetup3.metric}
                        </td>
                        <td>
                            Evidences - {meetup3.narrative}
                        </td>
                       
                        <td>
                            Total {meetup3.currentvalue} In process {meetup3.submitted} Accepted {meetup3.accepted} Flagged {meetup3.flagged} Faculties {meetup3.faculties}
                        </td>
                        <td>
                        {meetup3.target} One year - {meetup3.firstkpi} Three years - {meetup3.threekpi} Five years - {meetup3.fivekpi}
                        </td>
                      
                        <td>
                            {meetup3.link}
                        </td>
                        <td>
                            {meetup3.comments}
                        </td>
                       
                        <td>
                            {meetup3.intervention}
                        </td>
                      
                        <td>
                            {meetup3.intowner}
                        </td>
                     
                        
                     
                        <td>
                        
                        <Button variant="outline-danger" onClick={() => deleteenrolment(meetup3._id)}>
                        <FontAwesomeIcon icon={faTrash} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                            </Button>
                            <Button variant="outline-danger" onClick={() => edit(meetup3._id,meetup3.criteria, meetup3.metric, meetup3.currentvalue, meetup3.firstkpi, meetup3.threekpi,  meetup3.fivekpi, meetup3.link, meetup3.comments, meetup3.type, meetup3.intervention, meetup3.interventionid, meetup3.intowner, meetup3.narrative, meetup3.group, meetup3.weightage, meetup3.impact, meetup3.category)}>
                        <FontAwesomeIcon icon={faEdit} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                            </Button>
                            <Button variant="outline-danger" onClick={() => gototask(meetup3.group, meetup3.intervention,meetup3.criteria,meetup3.metric, meetup3.narrative)}>
                        <FontAwesomeIcon icon={faHandsHelping} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                            </Button>
                        </td>
                
                    
                
                
                   

                   
                                </tr>
              

                );
            })}
            </tbody>
            </Table>

            <br /><br />
            

        


            

       
        
        </div>
        <FooterNav1 />
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;