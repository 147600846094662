import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/An';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import { duration } from "@material-ui/core";



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const institutionref=useRef();
const addressref=useRef();
const pinref=useRef();
const establishmentref=useRef();
const typeref=useRef();
const categoryref=useRef();
const streamref=useRef();
const autonomousref=useRef();
const autonomyyearref=useRef();
const autonomyvalidityref=useRef();
const siteref=useRef();
const mobileref=useRef();
const telephoneref=useRef();
const otherteleref=useRef();
const emailref=useRef();
const websiteref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const patentid=global1.addonid;


    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       

       institutionref.current.value=global1.institution;
addressref.current.value=global1.address;
pinref.current.value=global1.pin;
establishmentref.current.value=global1.establishment;
typeref.current.value=global1.type;
categoryref.current.value=global1.category;
streamref.current.value=global1.stream;
autonomousref.current.value=global1.autonomous;
autonomyyearref.current.value=global1.autonomyyear;
autonomyvalidityref.current.value=global1.autonomyvalidity;
siteref.current.value=global1.site;
mobileref.current.value=global1.mobile;
telephoneref.current.value=global1.telephone;
otherteleref.current.value=global1.othertele;
emailref.current.value=global1.email;
websiteref.current.value=global1.website;


    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        //const title=titleref.current.value;
        const institution=institutionref.current.value;
const address=addressref.current.value;
const pin=pinref.current.value;
const establishment=establishmentref.current.value;
const type=typeref.current.value;
const category=categoryref.current.value;
const stream=streamref.current.value;
const autonomous=autonomousref.current.value;
const autonomyyear=autonomyyearref.current.value;
const autonomyvalidity=autonomyvalidityref.current.value;
const site=siteref.current.value;
const mobile=mobileref.current.value;
const telephone=telephoneref.current.value;
const othertele=otherteleref.current.value;
const email=emailref.current.value;
const website=websiteref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updateainstbyfac', {
            params: {
                id: patentid,
                user: user,
                token: token,
                name: name,
                colid: colid,
                institution:institution,
address:address,
pin:pin,
establishment:establishment,
type:type,
category:category,
stream:stream,
autonomous:autonomous,
autonomyyear:autonomyyear,
autonomyvalidity:autonomyvalidity,
site:site,
mobile:mobile,
telephone:telephone,
othertele:othertele,
email:email,
website:website,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewnainst');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Edit data</p>
                        <hr />
                       

                   Enter Name of the college<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Name of the college" id="institution" ref={institutionref} />
<br /><br />

Enter Address<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Address" id="address" ref={addressref} />
<br /><br />

Enter Pincode<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Pincode" id="pin" ref={pinref} />
<br /><br />

Enter Year of establishment of college<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Year of establishment of college" id="establishment" ref={establishmentref} />
<br /><br />

<label htmlFor="type">Type of the institution</label>

<select id="type" className="form-control"  ref={typeref}>
<option value="Self financing">Self financing</option>
<option value="Government">Government</option>
<option value="Affiliated">Affiliated</option>
</select>
<br /><br />

<label htmlFor="category">Category of the college</label>

<select id="category" className="form-control"  ref={categoryref}>
<option value="NA">NA</option>
<option value="telugu Minority">telugu Minority</option>
</select>
<br /><br />

<label htmlFor="stream">Type of college</label>

<select id="stream" className="form-control"  ref={streamref}>
<option value="Engineering">Engineering</option>
<option value="Others">Others</option>
</select>
<br /><br />

<label htmlFor="autonomous">Is the college autonomous</label>

<select id="autonomous" className="form-control"  ref={autonomousref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />

Enter Autonomous approved from year<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Autonomous approved from year" id="autonomyyear" ref={autonomyyearref} />
<br /><br />

Enter Autonomous validity period of the year<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Autonomous validity period of the year" id="autonomyvalidity" ref={autonomyvalidityref} />
<br /><br />

Enter Is the college functioning from above approved site<br /><br />

<input type="dropdoan" required style={{width: "100%"}} placeholder="Enter Is the college functioning from above approved site" id="site" ref={siteref} />
<br /><br />

Enter Mobile numbers<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Mobile numbers" id="mobile" ref={mobileref} />
<br /><br />

Enter Telephone numbers<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Telephone numbers" id="telephone" ref={telephoneref} />
<br /><br />

Enter Other telephone numbers<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Other telephone numbers" id="othertele" ref={otherteleref} />
<br /><br />

Enter Email id<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Email id" id="email" ref={emailref} />
<br /><br />

Enter Website address<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Website address" id="website" ref={websiteref} />
<br /><br />


                  


                      
                        <button  onClick={searchapi}>Update data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;