import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function Sideaqar3() {

    const favcontxt=useContext(FavoritesContext);

    let content;
    return (
        <div className={classes1.sidebar}>
        <div className={classes1.sidebarinside}>   
        <div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />
        
        <Link to='/viewseedm'> View Seed Money (3.1.2 U-C-HU)</Link>
        <Link to='/viewteacherfellow'> View Teachers receiving Fellowship (3.1.3 U, 3.1.2 H, 3.1.3 HU)</Link>
        <Link to='/viewprojects'> View Research Projects (3.1.1-3.1.2 A, 3.1.6-3.2.1-3.2.2-3.2.3 U, 3.1.3 H)</Link>
        <Link to='/viewevent'> View Workshops/Conferences (3.1.3 A, 3.3.2 U, 3.2.2 H)</Link>
        <Link to='/viewcombined'> 3.1.1, 3.2.1, 3.4.2, 3.4.3, 3.4.4 and 3.4.5, 3.4.6, 3.4.7 (Old University AQAR)</Link>
        <Link to='/viewinnovation'> View Innovations (3.3.3 U)</Link>
        <Link to='/viewincubation'> Incubation center (3.3.1 U Old)</Link>
        <Link to='/viewstartup'> Startup (3.3.1b U Old)</Link>
        <Link to='/viewpatent'> View Patents (3.4.3.U)</Link>
        <Link to='/viewphdguide'> View Phd Guides (3.4.4 U)</Link> 
        <Link to='/viewpub'> View Research Papers (3.2.1 A, 3.4.5 U, 3.3.3 H)</Link>
        <Link to='/viewpubaqar'> View Books & Chapters (3.2.2 A, 3.4.6 U, 3.3.4 H)</Link>
        <Link to='/viewecontent'> View E-content ( 3.4.7 - 4.3.5 U, 4.3.5 HU , 4.3.6 H)</Link>
        <Link to='/viewextawards'> View Extension Awards/Recognitions (3.3.2 A, 3.6.2 U)</Link>
        <Link to='/viewextact'> View Extension Activities (3.3.3-3.3.4 A, 3.6.3 - 3.6.4 U, 3.4.1-3.4.2 H)</Link>
        <Link to='/viewcollaboration'> View Collaboration Activities (3.4.1 A, 3.7.1 U )</Link>
        <Link to='/viewmouact'> View MoU Activities (3.4.2 A, 3.7.2 U, 3.5.2 H )</Link>

        

<hr />      
<div style={{marginBottom: 10}}><b>Nomenclature</b></div>
A - Affiliated college<br />
U - University<br />
C- Autonomous institution<br />
H- Health Science institution<br />
HU- Health Science university<br />
        
        <hr />
        <div style={{marginBottom: 10}}><b>Useful links</b></div>
        <Link to='/dashcourse'> Dashboard</Link>
        <Link to='/getmyfeedback'> My feedback</Link>  
        
        </div>         
       

</div>
    );

}

export default Sideaqar3;