import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import classes from './MainNavigation1.module.css';
import FavoritesContext from '../../store/favourites-context';


function FooterNav1() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    if (favcontxt.colid==0) {
        content= <ul>
        <li>
            <Link to='/new'>Login</Link>
        </li>
    </ul>

    } else if (favcontxt.role=="Student") {
       content= <ul>
                    <li>
                        <Link to='/open'>Open Classes</Link>
                    </li>
                    <li>
                        <Link to='/exam'>Exam</Link>
                    </li>
                    <li>
                        <Link to='/new'>Log out</Link>
                    </li>
                </ul>

    } else if (favcontxt.role=="Faculty") {
        content=<ul>
                    <li>
                        <Link to='/workload'>Courses
                        <span className={classes.badge}>
                            {favcontxt.course}
                        </span>
                        </Link>
                    </li>
                    <li>
                        <Link to='/new'>Log out</Link>
                    </li>
                </ul>

    }




    return (
        <div style={{ textAlign: 'center'}}>
            <hr />
            
            <div style={{ alignSelf: 'center'}}>
                Copyright @2021 Campus.Technology
            </div>
            <br />
        </div>
    );

}

export default FooterNav1;