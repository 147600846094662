import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/An';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import { duration } from "@material-ui/core";



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const certinameref=useRef();
const availref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const patentid=global1.addonid;


    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       

       certinameref.current.value=global1.certiname;
availref.current.value=global1.avail;


    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        //const title=titleref.current.value;
        const certiname=certinameref.current.value;
const avail=availref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updatepaucertificatesbyfac', {
            params: {
                id: patentid,
                user: user,
                token: token,
                name: name,
                colid: colid,
                certiname:certiname,
avail:avail,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewnpaucertificates');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Edit data</p>
                        <hr />
                       

                   <label htmlFor="certiname">Certificates</label>

<select id="certiname" className="form-control"  ref={certinameref}>
<option value="Certificate under Section 37 (B) of Tamil Nadu Land Reforms (Land fixation and Ceiling) Act">Certificate under Section 37 (B) of Tamil Nadu Land Reforms (Land fixation and Ceiling) Act</option>
<option value="State Government permission for starting the College.">State Government permission for starting the College.</option>
<option value="AICTE approval for the course(s)">AICTE approval for the course(s)</option>
<option value="Audited statement of accounts of the college for the past three years.">Audited statement of accounts of the college for the past three years.</option>
<option value="Building and equipment insurance certificate.">Building and equipment insurance certificate.</option>
<option value="Building plan proposed.">Building plan proposed.</option>
<option value="Building sketch [details of Rooms]">Building sketch [details of Rooms]</option>
<option value="Certificate from Health Inspector.">Certificate from Health Inspector.</option>
<option value="Certificate from PWD Superintendent Engineer for the structual stability of the building.">Certificate from PWD Superintendent Engineer for the structual stability of the building.</option>
<option value="Certificates for fire/boiler/electrical safety from competent authorities.">Certificates for fire/boiler/electrical safety from competent authorities.</option>
<option value="College sitemap / plan">College sitemap / plan</option>
<option value="Composition of the Govening council.">Composition of the Govening council.</option>
<option value="Documentary proof for ownership of lands exclusively earmarked for the College.">Documentary proof for ownership of lands exclusively earmarked for the College.</option>
<option value="Documents showing the financial viability of the college [details of financial budgeted revenue and expenses statements (Current year)].">Documents showing the financial viability of the college [details of financial budgeted revenue and expenses statements (Current year)].</option>
<option value="Existing building plan">Existing building plan</option>
<option value="Irrevocable Trust Registration Deed.">Irrevocable Trust Registration Deed.</option>
<option value="Land use Certificate from an appropriate authority (RDO) and Land conversion certificate form the Directorate of Town & Country planning.">Land use Certificate from an appropriate authority (RDO) and Land conversion certificate form the Directorate of Town & Country planning.</option>
<option value="Legal opinion from not below the rank of the Govt. pleader on the ownership of land and extent of coverage.">Legal opinion from not below the rank of the Govt. pleader on the ownership of land and extent of coverage.</option>
<option value="Master Time Table for all courses and all sections with classroom arrangements.">Master Time Table for all courses and all sections with classroom arrangements.</option>
<option value="NAAC Certificate">NAAC Certificate</option>
<option value="NBA Certificate for accredited Courses">NBA Certificate for accredited Courses</option>
<option value="NIRF Certificate.">NIRF Certificate.</option>
<option value="Village field map / Field measurement book sketch">Village field map / Field measurement book sketch</option>
</select>
<br /><br />

<label htmlFor="avail">If available</label>

<select id="avail" className="form-control"  ref={availref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />


                  


                      
                        <button  onClick={searchapi}>Update data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;