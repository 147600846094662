import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';


// import classes from './Allclasses.module.css';

import classes1 from './newform1.module.css';

import SidefacNavigation from '../components/layout/SidefacNavigation';
import MainNavigation from '../components/layout/MainNavigtion';
import FooterNavigation from '../components/layout/FooterNav';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const linkref=useRef();
    const moduleref=useRef();
    const topicref=useRef();

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState();
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    const name1=global1.name;

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    const addnewworkload = () => {
        history.replace('/addworkloadf');
    }

    const addperiod = () => {
        history.replace('/addperiod');
    }

    const addstudents = (courseid, coursecode, coursename) => {
        global1.courseid=courseid;
        global1.coursecode=coursecode;
        global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/courseactions');
    }

    

   


    const searchApi = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getworkload', {
            params: {
                user: user,
                token: token

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setResults(response.data.data.classes);
        favcontxt.addFavorite({
            studid: user,
            name: name,
        },role,colid,name,response.data.data.classes.length);
        //alert(results.length);
       
    };

    const getperiod = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getperiod', {
            params: {
                colid: colid

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteperiod = async (periodid) => {
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteperiod', {
            params: {
                id: periodid
            }

        });
        setLoading(false);
        //console.log(response.data.data);
        getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const getclass = async () => {
        setLoading(true);
        setTerm(studid);
        // const response = await ep1.get('/api/v1/getclass', {
        //     params: {
        //         user: user
        //     }
        // });
        var dt1=new Date(date2);
        const response = await ep1.get('/api/v1/getclassbydate', {
            params: {
                user: user,
                classdate: dt1
            }
        });
        setLoading(false);
        console.log(response.data.data);
        setResults2(response.data.data.classes);
        //alert(results.length);
       
    };

    function getdata1() {
        const db = firebase.firestore();

        db.collection("workload").where("user", "==", user)
            .onSnapshot(function (querySnapshot) {
            const Comment = [];
            querySnapshot.forEach((doc) => {
                console.log(`${doc.id} => ${doc.data()}`);
                const { coursecode, course, program, section, semester } = doc.data();
                Comment.push({
                    _id: doc.id,
                    coursecode,
                    course,
                    program,
                    section,
                    semester
                });
            });
            //const { name, coursename, link } = querySnapshot.val();
            //console.log(doc.id + ' ' + doc.data);
            setResults(Comment);
        });
    }

    function deletedata1(id) {
        const db = firebase.firestore();

        db.collection("workload").doc(id).delete().then(() => {
            console.log("Document successfully deleted!");
            alert("Success");
        }).catch((error) => {
            console.error("Error removing document: ", error);
            alert(error);
        });

        // db.collection("workload").where("user", "==", user)
        //     .onSnapshot(function (querySnapshot) {
        //     const Comment = [];
        //     querySnapshot.forEach((doc) => {
        //         console.log(`${doc.id} => ${doc.data()}`);
        //         const { coursecode, course, program, section, semester } = doc.data();
        //         Comment.push({
        //             _id: doc.id,
        //             coursecode,
        //             course,
        //             program,
        //             section,
        //             semester
        //         });
        //     });
        //     //const { name, coursename, link } = querySnapshot.val();
        //     //console.log(doc.id + ' ' + doc.data);
        //     setResults(Comment);
        // });
    }

    useEffect(() => {
        checklogin();
        //search1();
        //getexamlist();
        //searchApi('');

        if (!firebase.apps.length) { //avoid re-initializing
            firebase.initializeApp({
                apiKey: "AIzaSyD4AZlzFUmachQCJYr-vlb9XKJix3NMg-E",
                authDomain: "posto-campus.firebaseapp.com",
                databaseURL: "https://posto-campus.firebaseio.com",
                projectId: "posto-campus",
                storageBucket: "posto-campus.appspot.com",
                messagingSenderId: "1002415317254",
                appId: "1:1002415317254:web:f198e146e4c2a9ab86fe87",
                measurementId: "G-W6SP1NZPMJ"
            });
        }

        getdata1();


        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);

  

    const _handlePressButtonAsync = async (classid, link) => {
        if(link=="NA") {
            link="https://meet.jit.si/" + classid;
        }
        var win = window.open(link, '_blank');
        win.focus();
      };

   
    

   

    const width=window.innerWidth;
    const mdivwidth=width - 330;

    
    const timely = (date1) => {
        var dt1=new Date(date1);
        dt1.setHours(dt1.getHours() - 5);
        dt1.setMinutes(dt1.getMinutes() - 30);
        var hours=dt1.getHours();
        var minutes=dt1.getMinutes();
        var month=dt1.getMonth() + 1;
        if(minutes.length<2) {
            minutes='0' + minutes;
        }
        var t1;
        if(hours>11) {
            t1='PM';
        } else {
            t1='AM';
        }
        if(hours>12) {
            hours=hours-12;
        }
        var dt2=dt1.getDate() + '/' + month + '/' + dt1.getFullYear() + ' ' + hours + ':' + minutes + t1;
        return dt2;

    }

    const addmodules = (courseid, coursecode, coursename) => {
        global1.courseid=courseid;
        global1.coursecode=coursecode;
        global1.coursename=coursename;
        history.replace('/addlessonplan');
    }

    const viewmodules = (courseid, coursecode, coursename) => {
        global1.courseid=courseid;
        global1.coursecode=coursecode;
        global1.coursename=coursename;
        history.replace('/lessonplan');
    }


    return (
        <div>
            <MainNavigation />
        
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
    <SidefacNavigation />
                    </td>
                    <td width="20"></td>
                    <td> 

                    
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
            <p style={{fontSize: 18 }}>Home - My Courses</p>
            <hr />
           
    
        <br />
        

        

        <div className="container">
                <div className="row">
                <div className="col-4">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                          onClick={() => addnewworkload()}
                         >
                             <br />
                             <FontAwesomeIcon icon={faPlusSquare} style={{fontSize: 30, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                             <p style={{ fontSize: 18}}>Add Course</p>

                        </div>
                </div>
                {results.map((meetup3) => {
                return (
                    <div className="col-4">
                         <div className={classes1.card}>
                          {meetup3.coursecode} {meetup3.course} <i>{meetup3.program} Section {meetup3.section} Semester {meetup3.semester}</i>
                   
                    <br /><br />
                    <div className={classes1.actions}>
                                    <button type="button" onClick={() => addmodules(meetup3._id, meetup3.coursecode, meetup3.course)}>
                                    <FontAwesomeIcon icon={faTachometerAlt} style={{fontSize: 18, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                                        Add modules</button>
                                </div>
                                <br />
                                <div className={classes1.actions}>
                                    <button type="button" onClick={() => viewmodules(meetup3._id, meetup3.coursecode, meetup3.course)}>
                                    <FontAwesomeIcon icon={faTachometerAlt} style={{fontSize: 18, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                                        View modules</button>
                                </div>
                                <br />
                                <div className={classes1.actions}>
                    
                    
                    <button type="button" onClick={() => deletedata1(meetup3._id)}>
                    <FontAwesomeIcon icon={faTachometerAlt} style={{fontSize: 18, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
    />
                        Delete Course</button>
                </div>
                    </div>
                    </div>

                );
            })}
            </div></div>

            

       
        
        </div>
        </td>
                </tr>
            </table>
        </div>


        </div>
        
    );

}

export default Allclasses;