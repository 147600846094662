import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function SideimpactNavigation() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    



    return (
        <div className={classes1.sidebar}>
        <div className={classes1.sidebarinside}>   

        <div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />  
    
        <div style={{marginBottom: 10}}><b>IMPACT Rankings</b></div>
            <Link to='/viewsdg1'>SDG1 - No Poverty </Link>
            <Link to='/viewsdg2'>SDG2 - Zero Hunger</Link>
            <Link to='/viewsdg3'>SDG3 - Good Health & Well Being</Link>
            <Link to='/viewsdg4'>SDG4 - Quality Education</Link>
            <Link to='/viewsdg5'>SDG5 - Gender Equality</Link>
            <Link to='/viewsdg6'>SDG6 - Clean Water & Sanitation</Link>
            <Link to='/viewsdg7'>SDG7 - Affordable & Clean Energy</Link>
            <Link to='/viewsdg8'>SDG8 - Decent Work & Economic Growth</Link>
            <Link to='/viewsdg9'>SDG9 - Industry, Innovation & Infrastructure</Link>
            <Link to='/viewsdg10'>SDG10 - Reduced Inequalities</Link>
            <Link to='/viewsdg11'>SDG11 - Sustainable cities & Communities</Link>
            <Link to='/viewsdg12'>SDG12 - Responsible Consumption & Production</Link>
            <Link to='/viewsdg13'>SDG13 - Climate Action</Link>
            <Link to='/viewsdg14'>SDG14 - Life Below Water</Link>
            <Link to='/viewsdg15'>SDG15 - Life on Land</Link>
            <Link to='/viewsdg16'>SDG16 - Peace, Justice & Strong Institutions</Link>
            <Link to='/viewsdg17'>SDG17 - Partnership for the Goals</Link>
            

 


        
        <hr />
        <div style={{marginBottom: 10}}><b>Useful links</b></div>
        <Link to='/dashcourse'> Dashboard</Link>   
        <Link to='/getmyfeedback'> My feedback</Link>
        
        </div>       

</div>
    );

}

export default SideimpactNavigation;