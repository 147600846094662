import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faEdit, faComment, faFileAlt, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { DataGrid } from '@material-ui/data-grid';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import Sidecr1Navigation from '../components/layout/Sidecr1Navigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import Spinner from 'react-bootstrap/Spinner';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const yearref=useRef();
    const studentnameref=useRef();
    const regnoref=useRef();
    const studcontactdetailsref=useRef();
    const programnameref=useRef();
    const employernameref=useRef();
    const empcontactdetailsref=useRef();
    const sectorref=useRef();
    const designationref=useRef();
    const employeridref=useRef();
    const jobidref=useRef();
    const salaryref=useRef();


    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState([]);
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());
    const [format, setFormat] = useState(false);

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;
    //const name=global1.name;

    const [isUploading, setIsuploading] = useState(false);

    const columns = [
        // { field: '_id', headerName: 'ID', width: 70 },
        { field: 'student', headerName: 'Student', width: 130 },
        { field: 'regno', headerName: 'Reg no', width: 130 },
      ];

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    const edit = (id, year,  studentname, regno, studcontactdetails, programname,employername,empcontactdetails, sector, designation,employerid,jobid,salary) => {
        global1.placementid=id;
        global1.year=year;
        global1.studentname=studentname;
        global1.regno=regno;
        global1.studcontactdetails=studcontactdetails;
        global1.programname=programname;
        global1.employername=employername;
        global1.empcontactdetails=empcontactdetails;
        global1.sector=sector;
        global1.designation=designation;
        global1.employerid=employerid;
        global1.jobid=jobid;
        global1.salary=salary;
        
        history.replace('/editplacement');
    }

    const list = (id, user) => {
        global1.field1=id;
        global1.criteria='5';
        global1.metric='5.2.1 A, 5.2.2 U, 5.2.1 C';
        global1.collection1='placement';
        global1.type='Student Placement';
        global1.buser=user;
        global1.pagenav='viewplacementadmin';
        
        history.replace('/viewsupportingdocadmin');
    }

    const addperiod = () => {
        history.replace('/addperiod');
    }

    const addstudents = () => {
        // global1.courseid=courseid;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/addstudents');
    }

    

   

    let tutorials = [];
    const searchApi = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getplacementbyadmin', {
            params: {
                user: user,
                token: token,
                colid: colid
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        localStorage.setItem("p_placementadmin", JSON.stringify(response.data.data.classes));

       

        
        setResults(response.data.data.classes);
        setLink(response.data.data.link);
        //alert('format ' + format);
        try{

            formattable();
        } catch(err) {

        }
        
       
        //alert(results.length);
       
    };

    function formattable() {
        if (!format) {
            $('#example').DataTable(
                {
                    pagingType: 'full_numbers',
                      pageLength: 5,
                      processing: true,
                      dom: 'Bfrtip',
                          buttons: ['copy', 'csv', 'print'
                          ]
                }
            );
            setFormat(true);

        }
    }

   

    const deleteenrolment = async (enrolmentid) => {
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteplacementbyfac', {
            params: {
                id: enrolmentid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteall = async (enrolmentid) => {
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallplacementbyfac', {
            params: {
                id: enrolmentid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        searchApi();
       
    };

    const getlocal = () => {
        let collection=localStorage.getItem("p_placementadmin");
        if(!collection) {

        } else {
            setResults(JSON.parse(collection));

        }
        
    }

    

    useEffect(() => {
        checklogin();
        //search1();
        //getexamlist();
        getlocal();
        searchApi('');
        //$('#example').DataTable();
        //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable();
    //     },
    //     1000
    //     );
    // });
    
    //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable(
    //         {
    //             pagingType: 'full_numbers',
    //               pageLength: 5,
    //               processing: true,
    //               dom: 'Bfrtip',
    //                   buttons: ['copy', 'csv', 'print'
    //                   ]
    //         }
    //     );
    //     } ,
    //     1000
    //     );
    // });
        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);


    const uploadlink = async (link1, id1, year) => {
        link1=link1 + '?criteria=5&Section=5.2.2&Subsection=StudentPlacement&field1=' + id1 + '&Year=' + year; 
        var win = window.open(link1, '_blank');
        win.focus();
      };

    


    
   

    

    const width=window.innerWidth;
    const mdivwidth=width - 330;

   
       
        //alert('loaded');
  

    


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width: 60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <Sidecr1Navigation />
                    </td>
                   
                    <td> 
                        <TopNavigation />

                    
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
            <p style={{ fontSize: 18 }}>View Student Placement Details</p>
            <hr />
            
       <br />
       <table>
                <tr>
                    <td>
                    <button  onClick={deleteall} className="btn btn-dark">Delete all data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    {/* <Link to='/addbulkpublications' className="btn btn-dark"> Bulk add data</Link> */}

                    </td>
                </tr>
            </table>
           
    
        <br /><br />
        {isUploading ? (
				<div style={{ alignItems: 'center', marginTop: 30, marginBottom: 60}}>
                   <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
				</div>
			) : (
                <div></div>
			)} 
     
<Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
      <th>Document ID</th>
      <th>Added by</th>
      <th>Academic Year</th>
      <th>Name of student placed</th>
      <th>Student Registration Number</th>
      <th>Contact details of the student placed</th>
      <th>Name of program student graduated form</th>
      <th>Name of the employer</th>
      <th>Contact details of the employer</th>
      <th>Job Sector</th>
      <th>Designation</th>
      <th>Employer Id</th>
      <th>Job Id</th>
      <th>Pay package at appointmnet(in INR/annum)</th>
      <th>Status</th>
      {/* <th>Comments</th> */}
      <th>Documents</th>
    </tr>
    </thead>
    <tbody>
    

    {results.map((meetup3) => {
                return (
                    <tr>
                        <td>
                            {meetup3._id}
                        </td>
                        <td>
                        {meetup3.name}
                        </td>
                        <td>
                        {meetup3.year}
                        </td>
                        <td>
                            {meetup3.studentname}
                        </td>
                        <td>
                            {meetup3.regno}
                        </td>
                        <td>
                            {meetup3.studcontactdetails}
                        </td>
                        <td>
                            {meetup3.programname}
                        </td>
                        <td>
                            {meetup3.employername}
                        </td>
                        <td>
                            {meetup3.empcontactdetails}
                        </td>
                        <td>
                            {meetup3.sector}
                        </td>
                        <td>
                            {meetup3.designation}
                        </td>
                        <td>
                            {meetup3.employerid}
                        </td>
                        <td>
                            {meetup3.jobid}
                        </td>
                        
                        <td>
                            {meetup3.salary}
                        </td>
                        <td>
                            {meetup3.status1}
                        </td>
                        {/* <td>
                            {meetup3.comments}
                        </td> */}
                        <td>
                            
                    <Button variant="outline-danger" onClick={() => list(meetup3._id, meetup3.user)}>
                        <FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                        List
                    </Button> 
                
                    
                      </td>
                    
                
                
                   

                   
                                </tr>
              

                );
            })}
            </tbody>
            </Table>



        


            

       
        
        </div>
        <FooterNav1 />
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;