import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedeleteNavigation from '../components/layout/SidedocNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    // const fnameref=useRef();
    // const academicbodyref=useRef();
    // const yearref=useRef();
    

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [loading, setLoading] = useState();
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }

    useEffect(() => {
       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const deleteallskilldevbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallskilldevbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };
    const deletealladdoncbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deletealladdoncbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };
    const deletealladmissionbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deletealladmissionbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };
    const deleteallteacherdatabyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallteacherdatabyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };
    const deleteallcbcsbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallcbcsbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };
    const deleteallbosbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallbosbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };
    
    const deletealleventbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deletealleventbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };
    const deleteallreservecatbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallreservecatbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };
    const deleteallpassexambyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallpassexambyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };
    const deleteallawardsbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallawardsbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteallextactbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallextactbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };
    const deleteallcollabbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallcollabbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };
    const deleteallmoubyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallmoubyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };
    const deleteallictbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallictbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };
    const deleteallexpenditurebyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallexpenditurebyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteallscholarshipbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallscholarshipbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deletealllibrarybyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deletealllibrarybyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };
    const deleteallfundsbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallfundsbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteallqualitybyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallqualitybyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };
    const deleteallcareercounselbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallcareercounselbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteallhigheredubyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallhigheredubyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };
    const deleteallhigherexambyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallhigherexambyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };
    const deleteallplacementbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallplacementbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteallteacherfsbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallteacherfsbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };
    const deleteallegovernbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallegovernbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };
    const deleteallextawardsbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallextawardsbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteallecontentbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallecontentbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteallresultbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallresultbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteallseedmbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallseedmbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteallconsultancybyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallconsultancybyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteallexplearningbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallexplearningbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteallsyllabusrevbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallsyllabusrevbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteallemployabilitybyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallemployabilitybyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteallphdbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallphdbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteallteacherawardbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallteacherawardbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteallteacherfellowbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallteacherfellowbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteallresearchfellowbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallresearchfellowbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteallalumniconbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallalumniconbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteallexamautomationbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallexamautomationbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteallmenteesbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallmenteesbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteallpatentbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallpatentbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteallprojectbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallprojectbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteallpubbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallpubbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteallseminarbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallseminarbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteallinnovationbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallinnovationbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteallbookbyuser = async () => {
        setLoading(true);
        //alert(coursecode1 + ' ' + user + ' ' + colid + ' ' + token);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteallbookbyuser', {
            params: {
                colid: colid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        // searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedeleteNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >

            <p style={{ fontSize: 18 }}>Delete for My details</p>
            <hr />
            <table>
                <tr>
                    <td>
                    <button  onClick={deleteallpatentbyuser} className="btn btn-dark">Delete Patent data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={deleteallprojectbyuser} className="btn btn-dark">Delete Project data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={deleteallpubbyuser} className="btn btn-dark">Delete Publications data</button>
                    </td>
                </tr>
            </table>
            <hr />
            <table>
                <tr>
                <td>
                    <button  onClick={deleteallbookbyuser} className="btn btn-dark">Delete Book data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={deleteallinnovationbyuser} className="btn btn-dark">Delete Innovation Awards data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={deleteallseminarbyuser} className="btn btn-dark">Delete Seminar data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={deleteallphdbyuser} className="btn btn-dark">Delete PHD Guide data</button>
                    </td>
                </tr>
            </table>
            <hr />
            <p style={{ fontSize: 18 }}>Delete for Criteria 1</p>
            <hr />
            <table>
                <tr>
                    <td>
                    <button  onClick={deleteallbosbyuser} className="btn btn-dark">Delete BOS data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={deleteallcbcsbyuser} className="btn btn-dark">Delete CBCS data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={deleteallsyllabusrevbyuser} className="btn btn-dark">Delete Syllabus Revision data</button>
                    </td>
                </tr>
            </table>
            <hr />
            <table>
                <tr>
                <td>
                    <button  onClick={deleteallexplearningbyuser} className="btn btn-dark">Delete Experential Learning data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={deletealladdoncbyuser} className="btn btn-dark">Delete Addon Course data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={deleteallemployabilitybyuser} className="btn btn-dark">Delete Employability Courses data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={deletealleventbyuser} className="btn btn-dark">Delete All Events data</button>
                    </td>
                </tr>
            </table>
            <hr />
            <p style={{ fontSize: 18 }}>Delete for Criteria 2</p>
            <hr />
            <table>
                <tr>
                    <td>
                    <button  onClick={deletealladmissionbyuser} className="btn btn-dark">Delete Admission data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={deleteallreservecatbyuser} className="btn btn-dark">Delete Reserved Category data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={deleteallteacherdatabyuser} className="btn btn-dark">Delete Full-time Teachers data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={deleteallteacherawardbyuser} className="btn btn-dark">Delete Teacher Awards data</button>
                    </td>
                </tr>
            </table>
            <hr />
            <table>
                <tr>
                <td>
                    <button  onClick={deleteallpassexambyuser} className="btn btn-dark">Delete Pass Percentage data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={deleteallmenteesbyuser} className="btn btn-dark">Delete Mentor-Mentee awards data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={deleteallresultbyuser} className="btn btn-dark">Delete Result Publications data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={deleteallexamautomationbyuser} className="btn btn-dark">Delete Exam Automation data</button>
                    </td>
                </tr>
            </table>
            <hr />
            <p style={{ fontSize: 18 }}>Delete for Criteria 3</p>
            <hr />
            <table>
                <tr>
                    <td>
                    <button  onClick={deleteallseedmbyuser} className="btn btn-dark">Delete Seed Money data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={deleteallteacherfellowbyuser} className="btn btn-dark">Delete Teacher Fellowship data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={deleteallresearchfellowbyuser} className="btn btn-dark">Delete Research Fellowship data</button>
                    </td>
                </tr>
            </table>
            <hr />
            <table>
                <tr>
                <td>
                    <button  onClick={deleteallecontentbyuser} className="btn btn-dark">Delete E-content data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={deleteallconsultancybyuser} className="btn btn-dark">Delete Consultancy data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={deleteallextawardsbyuser} className="btn btn-dark">Delete Extension Awards data</button>
                    </td>                    
                </tr>
            </table>
            <hr />
            <table>
                <tr>
                <td>
                    <button  onClick={deleteallextactbyuser} className="btn btn-dark">Delete Extension Activities data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={deleteallcollabbyuser} className="btn btn-dark">Delete Collaboration data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={deleteallmoubyuser} className="btn btn-dark">Delete MOU data</button>
                    </td>
                </tr>
            </table>
            <hr />
            <p style={{ fontSize: 18 }}>Delete for Criteria 4</p>
            <hr />
            <table>
                <tr>
                    <td>
                    <button  onClick={deleteallexpenditurebyuser} className="btn btn-dark">Delete Expenditures data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={deletealllibrarybyuser} className="btn btn-dark">Delete Library data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={deleteallictbyuser} className="btn btn-dark">Delete ICT data</button>
                    </td>
                </tr>
            </table>
            <hr />
            <p style={{ fontSize: 18 }}>Delete for Criteria 5</p>
            <hr />
            <table>
                <tr>
                    <td>
                    <button  onClick={deleteallscholarshipbyuser} className="btn btn-dark">Delete Scholarship data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={deleteallcareercounselbyuser} className="btn btn-dark">Delete Career Counselling Activities data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={deleteallskilldevbyuser} className="btn btn-dark">Delete Skill Develeopment Activities data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={deleteallalumniconbyuser} className="btn btn-dark">Delete Alumni Contribution data</button>
                    </td>
                </tr>
            </table>
            <hr />
            <table>
                <tr>
                <td>
                    <button  onClick={deleteallhigherexambyuser} className="btn btn-dark">Delete Higher Exam data</button>
                    </td>
                    <td>
                    <button  onClick={deleteallplacementbyuser} className="btn btn-dark">Delete Placement data</button>
                    </td>
                    <td>
                    <button  onClick={deleteallhigheredubyuser} className="btn btn-dark">Delete Higher Education data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={deleteallawardsbyuser} className="btn btn-dark">Delete Student Awards data</button>
                    </td>
                </tr>
            </table> 
            <hr />
            <p style={{ fontSize: 18 }}>Delete for Criteria 6</p>
            <hr />
            <table>
                <tr>
                    <td>
                    <button  onClick={deleteallegovernbyuser} className="btn btn-dark">Delete E-governance data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={deleteallteacherfsbyuser} className="btn btn-dark">Delete Teachers Financial Support data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={deleteallfundsbyuser} className="btn btn-dark">Delete Funds data</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={deleteallqualitybyuser} className="btn btn-dark">Delete Quality Initiatives data</button>
                    </td>
                </tr>
            </table>
                  
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;