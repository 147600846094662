import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function SidefacNavigation() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    



    return (
        <div className={classes1.sidebar}>
            <div className={classes1.sidebarinside}>

<div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />
            
        
        {/* <div style={{marginBottom: 10}}><b>Insights</b></div> */}
        <Link to='/viewnallcoursesadmin'> All courses</Link>
        {/* <Link to='/viewnstudmasteradmin'> Student master list</Link> */}
        <Link to='/viewnstudmasternewadmin'> Student master data</Link>
        <Link to='/viewnfacyearadmin'> Year wise faculty</Link>
        <Link to='/viewnlabsafetyadmin'> Lab safety</Link>
<Link to='/viewnlabequipmentsadmin'> Lab equipments</Link>
<Link to='/viewnprogrambudgetadmin'> Program budget</Link>
<Link to='/viewninternetadmin'> Internet</Link>
<Link to='/viewnemfacultyadmin'> Emiratus faculty</Link>
<Link to='/viewnstudentpubsadmin'> Student publications</Link>
<Link to='/viewndeppublicationsadmin'> Departmental publications</Link>


<Link to='/viewnseedmadmin'> Seed money</Link>
<Link to='/viewnplacementadmin'> Placement (integrated)</Link>
<Link to='/viewnstartupadmin'> Startup</Link>
<Link to='/viewnacadperfadmin'> Academic marks data</Link>


<br /><br /><br /><br /><br /><br />
      
       

        <hr />
        {/* <div style={{marginBottom: 10}}><b>Useful links</b></div> */}
     
     

        
       </div>

</div>
    );

}

export default SidefacNavigation;