import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';

import global1 from '../../pages/global1';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function Sidecr3Navigation() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    const changeHandler = () => {
        const instype=global1.instype;
        //alert(instype);
        if(instype=='Affiliated') {
            return (
                <>
             
             <Link to='/viewextawards'> View Institutional Awards (3.4.2)</Link>
        <Link to='/viewextact'> View Extension Activities (3.4.3)</Link>
        <Link to='/viewcollaboration'> View Collaboration Activities (3.5.1)</Link>
        <Link to='/viewmou'> View MoU Activities (3.5.2)</Link>
       
                </>
            )
  
        } else if(instype=='University') {
          return (
              <>

<Link to='/viewextawards'> View Institutional Awards (3.4.2 A. 2.4.4)</Link>
        <Link to='/viewextact'> View Extension Activities (3.6.3)</Link>
        <Link to='/viewcollaboration'> View Collaboration Activities (3.7.1)</Link>
        <Link to='/viewmou'> View MoU Activities (3.7.2)</Link>
        <Link to='/viewecontent'> View E-content ( 3.4.7)</Link>
        <Link to='/viewseedm'> View Seed Money (3.1.2)</Link>
        <Link to='/viewconsultancy'> View Consultancy (3.5.2, 5.8.4 NBA)</Link>
        <Link to='/viewteacherfellow'> View Teachers receiving Fellowship (3.1.3)</Link>
        <Link to='/viewresearchfellow'> View Research Fellowships (3.1.4)</Link>
    
              </>
          )
            
      } else if(instype=='HealthUniversity') {
        return (
            <>

<Link to='/viewextawards'> View Institutional Awards (3.6.3)</Link>
        <Link to='/viewextact'> View Extension Activities (3.6.1 - 3.6.2)</Link>
        <Link to='/viewcollaboration'> View Collaboration Activities (3.7.1)</Link>
        <Link to='/viewmou'> View MoU Activities (3.7.2)</Link>
        <Link to='/viewecontent'> View E-content (4.3.5)</Link>
        <Link to='/viewseedm'> View Seed Money (3.1.2)</Link>
        <Link to='/viewconsultancy'> View Consultancy (3.5.2, 5.8.4 NBA)</Link>
        <Link to='/viewteacherfellow'> View Teachers receiving Fellowship (3.1.3)</Link>
        <Link to='/viewresearchfellow'> View Research Fellowships (3.1.4)</Link>
       
            </>
        )
          
    } else if(instype=='Dual') {
        return (
            <>
     <Link to='/viewextawards'> View Institutional Awards (3.6.2)</Link>
        <Link to='/viewextact'> View Extension Activities (3.6.3 - 3.6.4)</Link>
        <Link to='/viewcollaboration'> View Collaboration Activities (3.7.1)</Link>
        <Link to='/viewmou'> View MoU Activities (3.7.2)</Link>
        <Link to='/viewecontent'> View E-content (3.4.7-4.3.5)</Link>
        <Link to='/viewseedm'> View Seed Money (3.1.2)</Link>
        <Link to='/viewconsultancy'> View Consultancy (3.5.2, 5.8.4 NBA)</Link>
        <Link to='/viewteacherfellow'> View Teachers receiving Fellowship (3.1.3)</Link>
        <Link to='/viewresearchfellow'> View Research Fellowships (3.1.4)</Link>
            </>
        )
          
    } else {
          return (
              <>

<Link to='/viewextawards'> View Institutional Awards (3.4.2 A. 2.4.4 U, 5.1.1 C, 3.6.3 HU, 3.4.3 H, 3.6.2 D)</Link>
        <Link to='/viewextact'> View Extension Activities (3.4.3 A, 3.6.3 U, 3.6.3 - 3.6.4 C-D, 3.6.1 - 3.6.2 HU, 3.4.1-3.4.2 H)</Link>
        <Link to='/viewcollaboration'> View Collaboration Activities (3.5.1 A-H, 3.7.1 U-C-HU-D)</Link>
        <Link to='/viewmou'> View MoU Activities (3.5.2 A-H, 3.7.2 U-C-HU-D)</Link>
        <Link to='/viewecontent'> View E-content ( 3.4.7 U, 3.4.7-4.3.5 D, 4.3.4 C, 4.3.5 HU , 4.3.6 H)</Link>
        <Link to='/viewseedm'> View Seed Money (3.1.2 U-C-HU-D)</Link>
        <Link to='/viewconsultancy'> View Consultancy (3.5.2 U-C-HU-D, 5.8.4 NBA)</Link>
        <Link to='/viewteacherfellow'> View Teachers receiving Fellowship (3.1.3 U-C-D-HU, 3.1.2 H)</Link>
        <Link to='/viewresearchfellow'> View Research Fellowships (3.1.4 U-HU-D)</Link>
<hr />      
<div style={{marginBottom: 10}}><b>Nomenclature</b></div>
A - Affiliated college<br />
U - University<br />
D - Dual Mode University<br />
C- Autonomous institution<br />
H- Health Science institution<br />
HU- Health Science university<br />
            
        
              </>
          )
  
      }
      };




    return (
        <div className={classes1.sidebar}>
        <div className={classes1.sidebarinside}>   
        
        <div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />
        
            {changeHandler()}
        
        <hr />
        <div style={{marginBottom: 10}}><b>Useful links</b></div>
        <Link to='/dashcourse'> Dashboard</Link>
        <Link to='/workload'> Create Class</Link>    
        <Link to='/getmyfeedback'> My feedback</Link>
        <Link to='/proctorlist'> Proctor list</Link>
        <Link to='/examenrlist'> Check exam enrollment</Link>
     

        </div>         
       

</div>
    );

}

export default Sidecr3Navigation;