import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';

import global1 from '../../pages/global1';

import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function Sidecr6Navigation() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    
    const changeHandler = () => {
        const instype=global1.instype;
        //alert(instype);
        if(instype=='Affiliated') {
            return (
                <>
             
             <Link to='/viewegovern'> View Implementation of E-governance (6.2.3)</Link>
        <Link to='/viewteacherfs'> View Teacher Financial Support (6.3.2)</Link>
        <Link to='/viewevent'> View Training Programs (6.3.3)</Link>
        <Link to='/viewevent'> View FDP (6.3.4)</Link>
        <Link to='/viewfunds'> View Gov./Non-Gov. Funds (6.4.2)</Link>
        <Link to='/viewquality'> View Quality Initiatives (6.5.3)</Link>
       
                </>
            )
  
        } else if(instype=='University') {
          return (
              <>
      <Link to='/viewegovern'> View Implementation of E-governance (6.2.3)</Link>
        <Link to='/viewteacherfs'> View Teacher Financial Support (6.3.2)</Link>
        <Link to='/viewevent'> View Training Programs (6.3.3)</Link>
        <Link to='/viewevent'> View FDP (6.3.4)</Link>
        <Link to='/viewfunds'> View Gov./Non-Gov. Funds (6.4.2-6.4.3)</Link>
        <Link to='/viewquality'> View Quality Initiatives (6.5.2)</Link>
              </>
          )
            
      } else if(instype=='HealthUniversity') {
        return (
            <>
         <Link to='/viewegovern'> View Implementation of E-governance (6.2.3)</Link>
        <Link to='/viewteacherfs'> View Teacher Financial Support (6.3.2)</Link>
        <Link to='/viewevent'> View Training Programs (6.3.3)</Link>
        <Link to='/viewevent'> View FDP (6.3.4)</Link>
        <Link to='/viewfunds'> View Gov./Non-Gov. Funds (6.4.2)</Link>
        <Link to='/viewquality'> View Quality Initiatives (6.5.3)</Link>
            </>
        )
          
    } else if(instype=='Dual') {
        return (
            <>
       <Link to='/viewegovern'> View Implementation of E-governance (6.2.3)</Link>
        <Link to='/viewteacherfs'> View Teacher Financial Support (6.3.2)</Link>
        <Link to='/viewevent'> View Training Programs (6.3.3)</Link>
        <Link to='/viewevent'> View FDP (6.3.4)</Link>
        <Link to='/viewfunds'> View Gov./Non-Gov. Funds (6.4.2-6.4.3)</Link>
        <Link to='/viewquality'> View Quality Initiatives (6.5.2)</Link>
            </>
        )
          
    } else {
          return (
              <>
              <Link to='/viewegovern'> View Implementation of E-governance (6.2.3 A-U-D-C-HU, 6.2.2 H)</Link>
        <Link to='/viewteacherfs'> View Teacher Financial Support (6.3.2 A-U-D-C-H-HU)</Link>
        <Link to='/viewevent'> View Training Programs (6.3.3 A-U-D-C-H-HU)</Link>
        <Link to='/viewevent'> View FDP (6.3.4 A-U-D-C-H-HU)</Link>
        <Link to='/viewfunds'> View Gov./Non-Gov. Funds (6.4.2-6.4.3 U-D, 6.4.2 C, 6.4.3 H, 6.4.2 HU)</Link>
        <Link to='/viewquality'> View Quality Initiatives (6.5.3 A-C-H-HU, 6.5.2 U-D)</Link>
<hr />
<div style={{marginBottom: 10}}><b>Nomenclature</b></div>
A - Affiliated college<br />
U - University<br />
D - Dual Mode University<br />
C- Autonomous institution<br />
H- Health Science institution<br />
HU- Health Science university<br />
        
              </>
          )
  
      }
      };



    return (
        <div className={classes1.sidebar}>
        <div className={classes1.sidebarinside}>  
        
        <div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />
       
          {changeHandler()}
        <hr />
        <div style={{marginBottom: 10}}><b>Useful links</b></div>
        <Link to='/dashcourse'> Dashboard</Link>
        <Link to='/workload'> Create Class</Link>        
        <Link to='/getmyfeedback'> My feedback</Link>
        <Link to='/proctorlist'> Proctor list</Link>
        <Link to='/examenrlist'> Check exam enrollment</Link>
     
        

        
       
        </div> 
</div>
    );

}

export default Sidecr6Navigation;