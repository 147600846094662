import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function SidefacNavigation() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    



    return (
        <div className={classes1.sidebar}>
            <div className={classes1.sidebarinside}>

<div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />
            
        
        {/* <div style={{marginBottom: 10}}><b>Insights</b></div> */}
        <Link to='/viewbosdoccommentsd'> Membership (1.1.3 A)</Link>
        <Link to='/viewcbcsdocd'> CBCS Status (1.2.1 A)</Link>
        <Link to='/viewsyllabusrevdocsd'> Syllabus Revision and CBCS (1.1.2-1.2.2 U)</Link>
        <Link to='/viewsyllabusrevdocsdt'> Syllabus Revision and CBCS template (1.1.2-1.2.2 U)</Link>
        <Link to='/viewsyllabusrevdocsdep'> Syllabus Revision and CBCS (1.1.2-1.2.2 U) with department</Link>
        <Link to='/viewemployabilitydocsd'> Employability and New Courses (1.1.3-1.2.1 U)</Link>
        <Link to='/viewemployabilitydocsdep'> Employability and New Courses (1.1.3-1.2.1 U) with department</Link>
        <Link to='/viewaddoncdoccommentsd'> Add on courses (1.3.2 U)</Link>
        <Link to='/viewexplearningdocsd'> Experiential Learning (1.3.4 U)</Link>
        <Link to='/viewexplearningdocsdep'> Experiential Learning (1.3.4 U) with department</Link>
        <Link to='/viewexplearningprojdocd'> Experiential Learning Projects (1.3.2 A)</Link>
        <Link to='/viewfieldprojdd'> Field Projects</Link>
        <Link to='/viewadmissiondoccommentsd'> Admission Data (2.1.1 U)</Link>
        <Link to='/viewreservecatdoccommentsd'> Admission Reserved Category (2.1.2 U)</Link>
        <Link to='/viewnreservecatadmin'> Reserved category (new)</Link>
        <Link to='/viewteacherawarddoccommentsd'> Teacher Award (2.4.4 - 3.4.2 U)</Link>
        <Link to='/viewteacherdatadocd'> Teacher Data (2.4.1)</Link>
        <Link to='/viewpassexamdocsd'> Pass percentage</Link>
        <Link to='/viewseedmdoccommentsd'> Seed money (3.1.2 U)</Link>
        <Link to='/viewteacherfellowdoccommentsd'> Teacher fellowship (3.1.3 U)</Link>
        <Link to='/viewresearchfellowdocsd'> Research fellow</Link>
        <Link to='/viewprojectyrdocsd'> Projects doc (3.1.6 U)</Link>
        <Link to='/viewinnovationdoccomentsd'> Innovation Awards (3.3.3 U)</Link>
        <Link to='/viewphdguidedoccommentsd'> Ph. D. Guide and Scholars</Link>
        <Link to='/viewallpatentdocsd'> Patents doc (3.4.3 U)</Link>
        <Link to='/viewallpubdocsd'> Publications (3.4.5 U)</Link>
        <Link to='/viewallpubdoccommentsd'> Publications comments(3.4.5 U)</Link>
        <Link to='/viewallpubdoccomcyd'> Publications Calendar Year(3.4.5 U)</Link>
        <Link to='/viewallbookdoccommentsd'> Books(3.4.6 U)</Link>
        <Link to='/viewcollabdoccommentsd'> Collaborations (3.7.1 U)</Link>
        <Link to='/viewmoudoccommentsd'> MoU (3.7.1 U)</Link>
        <Link to='/viewictdoccommentsd'> ICT (4.3.1 U)</Link>
        <Link to='/viewscholarshipdocs'> Scholarships (5.1.1 U)</Link>
        <Link to='/viewcareerdoccommentsd'> Career counseling (5.1.2 U)</Link>
        <Link to='/viewscholarshipdoccommentsd'> Skill development (5.1.3 U)</Link>
        <Link to='/viewplacementdoccommentsd'> Placement (5.2.2 U)</Link>
        <Link to='/viewplacementdep'> Placement (5.2.2 U) with department</Link>
        <Link to='/viewallsemreimbdocsd'> Reimbursement (6.3.2 U)</Link>
        <Link to='/viewfdpcold'> FDP AQAR (6.3.4 A)</Link>
        <Link to='/viewallseminardocsd'> Seminar Participation (6.3.4 U)</Link>
        <Link to='/vieweventdoccommentsd'> All events</Link>

        <Link to='/viewawardsdocsd'> Student awards (5.3.1 A)</Link>
        <Link to='/viewexamautomationdd'> Exam automation</Link>
        <Link to='/viewincubationdd'> Incubation center</Link>
        {/* <Link to='/viewalumnicondd'> Alumni contribution</Link> */}
        <Link to='/viewegoverndd'> E Governance (6.2.3)</Link>
        <Link to='/viewhigheredudd'> Higher education</Link>
        <Link to='/viewhigheredudep'> Higher education with department</Link>
        <Link to='/viewhigherexamdd'> Higher exam</Link>
        <Link to='/viewhigherexamdep'> Higher exam with department</Link>
        <Link to='/viewlibraryexpdd'> Library expenditure</Link>
        <Link to='/viewexpendituredd'> Expenditure (4.1.4 - 4.4.1)</Link>
        <Link to='/viewextensionactdd'> Extension activities</Link>
        <Link to='/viewconsultancydd'> Consultancy</Link>
        <Link to='/viewecontentdd'> E-content</Link>
        <Link to='/viewecontentdep'> E-content with department</Link>
        <Link to='/viewresultdd'> Exam days (2.5.1)</Link>
        <Link to='/viewqualitydd'> Quality initiative</Link>
        <Link to='/Viewqualityinitdd'> Quality initiative (2)</Link>
        <Link to='/viewfundsdd'> Funds</Link>
        <Link to='/viewextawardsd'> Extension awards</Link>
        {/* <Link to='/viewteacherfsdd'> Teacher reimbursement (6.3.2)</Link> */}
        <Link to='/viewsportsactdocs'> Sports activities (5.3.3 AQAR)</Link>

        <Link to='/viewnbaug33resultadmin'> Result publication (Unified)</Link>
        <Link to='/viewwriterdbadmin'> Content database</Link>

        <Link to='/viewnadmissionadmin'> Admission (all)</Link>
        <Link to='/viewnpassexamadmin'> Pass percentage (all)</Link>
        <Link to='/viewnplacementadmin'> Placement (all)</Link>

        <Link to='/viewnhighereduadmin'> Higher education (All)</Link>
        <Link to='/viewnhigherexamadmin'> Higher exam (All)</Link>

        <Link to='/viewnphdfacadmin'> Phd Faculties</Link>

        <Link to='/viewnteacherdataadmin'> Teacher data (new)</Link>
        <Link to='/viewnresultadmin'> Exam days - No. of days for exam</Link>
        <Link to='/viewnphdfaceadmin'> Phd Faculty (Affiliated extended)</Link>
        <Link to='/viewnmenteesadmin'> Mentor mentees (General)</Link>
        <Link to='/viewnnmentoradmin'> Mentor mentees (Health Science)</Link>

        <Link to='/viewnalumniconadmin'> Alumni contribution</Link>
        <Link to='/viewneventadmin'> All events (new)</Link>
        <Link to='/viewneventadmintype'> All events (by type)</Link>
        <Link to='/viewnteacherfsadmin'> Teacher financial support (6.3.2)</Link>



        

        {/* <Link to='/viewplacementall'> All placement</Link> */}
        
        {/* <Link to='/addsupportingdoc'> Add supporting doc</Link> */}
       

        <hr />
        {/* <div style={{marginBottom: 10}}><b>Useful links</b></div> */}
        <Link to='/dashcourse'> Dashboard</Link>
        <Link to='/workload'> Create Class</Link>
        
        <Link to='/getmyfeedback'> My feedback</Link>
        <Link to='/proctorlist'> Proctor list</Link>
        <Link to='/examenrlist'> Check exam enrollment</Link>

        
     

        
       </div>

</div>
    );

}

export default SidefacNavigation;