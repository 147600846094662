import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import classes from './MainNavigation1.module.css';
import classes1 from './SidefacNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function SidefacNavigation() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    



    return (
        <div className={classes1.sidebar}>

<div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />
            
        {/* <div style={{marginBottom: 10}}><b>Teach</b></div> */}
        <Link to='/dashcourse'> <FontAwesomeIcon icon={faHome} style={{fontSize: 14, color: '#000', marginRight: 10 }} />Dashboard</Link>
        {/* <Link to='/workload'> <FontAwesomeIcon icon={faBook} style={{fontSize: 14, color: '#000', marginRight: 10 }} />Create Class</Link> */}
        
        <hr />
        {/* <div style={{marginBottom: 10}}><b>Insights</b></div> */}
        {/* <Link to='/getmyfeedback'> <FontAwesomeIcon icon={faComment} style={{fontSize: 14, color: '#000', marginRight: 10 }} />My feedback</Link>
        <Link to='/proctorlist'> <FontAwesomeIcon icon={faVideo} style={{fontSize: 14, color: '#000', marginRight: 10 }} />Proctor list</Link>
        <Link to='/examenrlist'> <FontAwesomeIcon icon={faComment} style={{fontSize: 14, color: '#000', marginRight: 10 }} />Check exam enrollment</Link> */}

        {/* <hr /> */}
        {/* <div style={{marginBottom: 10}}><b>Hybrid</b></div> */}
        {/* <Link to='/dashcoursef'> <FontAwesomeIcon icon={faComment} style={{fontSize: 14, color: '#000', marginRight: 10 }} />Lesson plan</Link>
        <Link to='/assetlist'> <FontAwesomeIcon icon={faComment} style={{fontSize: 14, color: '#000', marginRight: 10 }} />Asset master</Link>
        <Link to='/menteelist'> <FontAwesomeIcon icon={faComment} style={{fontSize: 14, color: '#000', marginRight: 10 }} />Mentor</Link>
        <Link to='/fees'> <FontAwesomeIcon icon={faComment} style={{fontSize: 14, color: '#000', marginRight: 10 }} />Fees</Link>
        <Link to='/workdiary'> <FontAwesomeIcon icon={faComment} style={{fontSize: 14, color: '#000', marginRight: 10 }} />Workdiary</Link>
        
        */}

</div>
    );

}

export default SidefacNavigation;