import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function Sideaqar5() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    



    return (
        <div className={classes1.sidebar}>
        <div className={classes1.sidebarinside}>   
        
        <div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />
        <Link to='/viewscholarship'> View Student Scholarships(5.1.1-5.1.2 A, 5.1.1 U)</Link>   
        <Link to='/viewskilldev'> View Skill development Programs (5.1.3 A, 5.1.3 U)</Link>
        <Link to='/viewcareercounsel'> View Career Counselling Activities (5.1.4 A, 5.1.2 U)</Link>
        <Link to='/viewplacement'> View Placement (5.2.1 A, 5.2.2 U)</Link>
        <Link to='/viewhigheredu'> View Students going for Higher Education (5.2.2 A, 5.2.3 U)</Link>
        <Link to='/viewhigherexam'> View Students going for Higher Exam (5.2.3 A, 5.2.1 U)</Link>
        <Link to='/viewawards'> View Students Awards/Medals (5.3.1 A, 5.3.1 H, 5.3.1 U) </Link>
        <Link to='/viewsportsact'> View Sports/Cultural Activities (5.3.3 A, 5.3.3. U)</Link>
        <Link to='/viewalumnicon'> View Alumni Contribution (5.4.2 U-C-H-HU)</Link> 
        
        
        
<hr />
<div style={{marginBottom: 10}}><b>Nomenclature</b></div>
A - Affiliated college<br />
U - University<br />
C- Autonomous institution<br />
H- Health Science institution<br />
HU- Health Science university<br />

        <hr />
        <div style={{marginBottom: 10}}><b>Useful links</b></div>
        <Link to='/dashcourse'> Dashboard</Link>
        <Link to='/getmyfeedback'> My feedback</Link>  

        </div>     
       

</div>
    );

}

export default Sideaqar5;