import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/An';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);



    const positionref=useRef();
const titleref=useRef();
const firstnameref=useRef();
const lastnameref=useRef();
const designationref=useRef();
const emailref=useRef();
const mobprsnlref=useRef();
const officephnref=useRef();
const addressref=useRef();
const cityref=useRef();
const districtref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
       

        const position=positionref.current.value;
const title=titleref.current.value;
const firstname=firstnameref.current.value;
const lastname=lastnameref.current.value;
const designation=designationref.current.value;
const email=emailref.current.value;
const mobprsnl=mobprsnlref.current.value;
const officephn=officephnref.current.value;
const address=addressref.current.value;
const city=cityref.current.value;
const district=districtref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/createpauragsquadbyfac', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                position:position,
title:title,
firstname:firstname,
lastname:lastname,
designation:designation,
email:email,
mobprsnl:mobprsnl,
officephn:officephn,
address:address,
city:city,
district:district,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewnpauragsquad');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Add data</p>
                        <hr />
                       
                            
                  <label htmlFor="position">Position & Professional Designation</label>

<select id="position" className="form-control"  ref={positionref}>
<option value="Chairman">Chairman</option>
<option value="Convener">Convener</option>
<option value="Member">Member</option>
<option value="Others">Others</option>
</select>
<br /><br />

<label htmlFor="title">Title</label>

<select id="title" className="form-control"  ref={titleref}>
<option value="Dr.">Dr.</option>
<option value="Mr.">Mr.</option>
<option value="Mrs.">Mrs.</option>
<option value="Ms.">Ms.</option>
</select>
<br /><br />

Enter Name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Name" id="firstname" ref={firstnameref} />
<br /><br />

Enter Initial/Last Name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Initial/Last Name" id="lastname" ref={lastnameref} />
<br /><br />

Enter Designation<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Designation" id="designation" ref={designationref} />
<br /><br />

Enter Email Id<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Email Id" id="email" ref={emailref} />
<br /><br />

Enter Mobile No.<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Mobile No." id="mobprsnl" ref={mobprsnlref} />
<br /><br />

Enter Telephone No.<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Telephone No." id="officephn" ref={officephnref} />
<br /><br />

Enter Address(Number Street Area)<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Address(Number Street Area)" id="address" ref={addressref} />
<br /><br />

Enter Address(City Pincode)<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Address(City Pincode)" id="city" ref={cityref} />
<br /><br />

<label htmlFor="district">District</label>

<select id="district" className="form-control"  ref={districtref}>
<option value="Ariyalur">Ariyalur</option>
<option value="Chengalpattu">Chengalpattu</option>
<option value="Chennai">Chennai</option>
<option value="Coimbatore">Coimbatore</option>
<option value="Cuddalore">Cuddalore</option>
<option value="Dharmapuri">Dharmapuri</option>
<option value="Dindigul">Dindigul</option>
<option value="Erode">Erode</option>
<option value="Kallakurichi">Kallakurichi</option>
<option value="Kancheepuram">Kancheepuram</option>
<option value="Kanyakumari">Kanyakumari</option>
<option value="Karur">Karur</option>
<option value="Krishnagiri">Krishnagiri</option>
<option value="Madurai">Madurai</option>
<option value="Mayiladuthurai">Mayiladuthurai</option>
<option value="Nagapattinam">Nagapattinam</option>
<option value="Namakkal">Namakkal</option>
<option value="Nilgiris">Nilgiris</option>
<option value="Others">Others</option>
<option value="Perambalur">Perambalur</option>
<option value="Puducherry">Puducherry</option>
<option value="Pudukkottai">Pudukkottai</option>
<option value="Ramanathapuram">Ramanathapuram</option>
<option value="Ranipet">Ranipet</option>
<option value="Salem">Salem</option>
<option value="Sivagangai">Sivagangai</option>
<option value="Tenkasi">Tenkasi</option>
<option value="Thanjavur">Thanjavur</option>
<option value="Theni">Theni</option>
<option value="Thiruchirappalli">Thiruchirappalli</option>
<option value="Thiruvarur">Thiruvarur</option>
<option value="Thoothukudi">Thoothukudi</option>
<option value="Tirunelveli">Tirunelveli</option>
<option value="Tirupathur">Tirupathur</option>
<option value="Tiruppur">Tiruppur</option>
<option value="Tiruvallur">Tiruvallur</option>
<option value="Tiruvannamalai">Tiruvannamalai</option>
<option value="Vellore">Vellore</option>
<option value="Villupuram">Villupuram</option>
<option value="Virudhunagar">Virudhunagar</option>
</select>
<br /><br />




                      
                        <button  onClick={searchapi}>Add data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;