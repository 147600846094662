import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function SidelibNavigation() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    



    return (
        <div className={classes1.sidebar}>

<div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />

            <div style={{marginBottom: 10}}><b>Invoice</b></div>
       
       <Link to='/viewinvoice'> View Invoice</Link>

       <hr />
            
        
        <div style={{marginBottom: 10}}><b>Library</b></div>
        <Link to='/viewlibbooks'> View books</Link>
        <Link to='/addlibbooks'> Add books</Link>
        <Link to='/searchlibbooks1'> Issue and return</Link>
        <Link to='/libissuelist'> Issue list</Link>

        <hr />

        <div style={{marginBottom: 10}}><b>Asset Management</b></div>
        <Link to='/assetlist'> Asset master</Link>
        <Link to='/addasset'> Add asset</Link>


        {/* <div style={{marginBottom: 10}}><b>Useful links</b></div> */}
        {/* <Link to='/dashcourse'> Dashboard</Link>
        <Link to='/workload'> Create Class</Link>
        
        <Link to='/getmyfeedback'> My feedback</Link>
        <Link to='/proctorlist'> Proctor list</Link>
        <Link to='/examenrlist'> Check exam enrollment</Link> */}
     

        
       

</div>
    );

}

export default SidelibNavigation;