import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidetaskNavigation from '../components/layout/SidedocNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const titleref=useRef();
    const assignedtonameref=useRef();
    const assignedtouserref=useRef();
    const instructionsref=useRef();
    const commentsref=useRef();
    const documentlinkref=useRef();
    const classdateref=useRef();
    const classtimeref=useRef();
    const statusref=useRef();
    

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [faculties, setFaculties] = useState([]);
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const taskid=global1.taskid;
    //global1.title=title;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       getfacultylist();
       titleref.current.value=global1.title;

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const getfacultylist = async () => {
        //setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getfacultylist', {
            params: {
                colid: colid,
                user: user,
                token: token

            }

        });
        //setLoading(false);
        console.log(response.data.data);
        setFaculties(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        const title=titleref.current.value;
        const assignedtoname=assignedtonameref.current.value;
        const assignedtouser=assignedtouserref.current.value;
        const instructions=instructionsref.current.value;
        //const comments=commentsref.current.value;
        const documentlink=documentlinkref.current.value;
        const classdate=classdateref.current.value;
        const classtime=classtimeref.current.value;
        //const status=statusref.current.value;
        
        
    
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/createtaskassign', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                taskid:taskid,
                title:title,
                assignedtoname: assignedtoname,
                assignedtouser:assignedtouser,
                instructions:instructions,
                comments:'',
                documentlink:documentlink,
                classdate:classdate,
                classtime:classtime,
                status:'Assigned'
                

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewtaskassign');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidetaskNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Add Tasks Assignment</p>
                        <hr />
                        <input type="text" required style={{width: '100%'}} placeholder="Enter tasks" id="title" ref={titleref} />
                            
                            <br /><br />
                            
                        <input type="text" required style={{width: '100%'}} placeholder="Enter assigned name" id="assignedtoname" ref={assignedtonameref} />
                            
                        <br /><br />
                    

                        {/* <input type="text" required style={{width: '100%'}} placeholder="Enter assigned email" id="assignedtouser" ref={assignedtouserref} />
                            
                        <br /><br /> */}

                        <label htmlFor='affiliated'>Select user email to assign task</label>
                    <select id="affiliated" className="form-control"   ref={assignedtouserref}>
                    <option value="1">Select user</option>

    {faculties.map((meetup3) => {
                return (
                    <option value={meetup3.email}>{meetup3.name} - {meetup3.department}</option>
 
                );
            })}
                  
                    </select>
                    <br />

                        <input type="text" required style={{width: '100%'}} placeholder="Enter instructions" id="instructions" ref={instructionsref} />
                            
                        <br /><br />

                        {/* <input type="text" required style={{width: '100%'}} placeholder="Enter comments" id="comments" ref={commentsref} />
                            
                        <br /><br /> */}

                        <input type="text" required style={{width: '100%'}} placeholder="Enter document link" id="documentlink" ref={documentlinkref} />
                            
                        <br /><br />  
                       
                       <p> Enter Due Date </p>
                        <input type="date" required style={{width: '100%'}} id="classdate" ref={classdateref} />
                            
                        <br /><br />

                        <input type="text" required style={{width: '100%'}} placeholder="Enter Due Time" id="classtime" ref={classtimeref} />
                            
                        <br /><br />

{/* 
                        <input type="text" required style={{width: '100%'}} placeholder="Enter status" id="status" ref={statusref} />
                            
                        <br /><br /> */}


                        
                        
                        <button  onClick={searchapi}>Add Tasks Assignment</button>

                      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;