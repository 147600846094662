import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faBinoculars, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faCubes, faFile } from '@fortawesome/free-solid-svg-icons';


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Spinner from 'react-bootstrap/Spinner';
import Sideicon from '../components/layout/Sideicon';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const linkref=useRef();
    const moduleref=useRef();
    const topicref=useRef();

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState();
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;
    //const name=global1.name;

    const addcoursefiles = () => {
        history.replace('/addcoursefiles');
    }

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    const addnewstudent = () => {
        history.replace('/enrollstudents');
    }

    const addperiod = () => {
        history.replace('/addperiod');
    }

    const addstudents = () => {
        // global1.courseid=courseid;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/addstudents');
    }

    

   


    const searchApi = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getcoursefiles', {
            params: {
                user: user,
                token: token,
                coursecode: coursecode1

            }

        });
        setLoading(false);
        console.log(response.data.data);
        localStorage.setItem("classfiles" + coursecode1, JSON.stringify(response.data.data.classes));
        setResults(response.data.data.classes);
       
        //alert(results.length);
       
    };

    
    const getlocal = () => {
        let collection=localStorage.getItem("classfiles" + coursecode1);
        if(!collection) {

        } else {
            setResults(JSON.parse(collection));

        }
        
    }

   

    const deletecoursefile = async (coursefileid) => {
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deletecoursefile', {
            params: {
                id: coursefileid,
                token: token
            }

        });
        setLoading(false);
        searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    

    useEffect(() => {
        checklogin();
        //search1();
        //getexamlist();
        getlocal();
        searchApi('');
        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);

    const _handlePressButtonAsync = async (link) => {
        var win = window.open(link, '_blank');
        win.focus();
      };


    
   

    

    const width=window.innerWidth;
    const mdivwidth=width - 330;
    const height=window.innerHeight;

    
    const timely = (date1) => {
        var dt1=new Date(date1);
        dt1.setHours(dt1.getHours() - 5);
        dt1.setMinutes(dt1.getMinutes() - 30);
        var hours=dt1.getHours();
        var minutes=dt1.getMinutes();
        var month=dt1.getMonth() + 1;
        if(minutes.length<2) {
            minutes='0' + minutes;
        }
        var t1;
        if(hours>11) {
            t1='PM';
        } else {
            t1='AM';
        }
        if(hours>12) {
            hours=hours-12;
        }
        var dt2=dt1.getDate() + '/' + month + '/' + dt1.getFullYear();
        return dt2;

    }


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width: 60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidecolorNavigation />
                    </td>
                  
                    <td> 

                    <TopNavigation />
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
            <p style={{ fontSize: 18 }}><Link to='/dashcourse'>Dashboard</Link> - <Link to='/courseactions'>Course Dashboard</Link> - Study material for {coursecode} {coursename}</p>
            <hr />
           
    
        <br />
        

        

        <div className="container">
                <div className="row">
                <div className="col-4">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                          onClick={() => addcoursefiles()}
                         >
                             <br />
                             <FontAwesomeIcon icon={faPlusSquare} style={{fontSize: 30, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                             <p style={{ fontSize: 18}}>Add Study Material</p>

                        </div>
                </div>
                {results.map((meetup3) => {
                return (
                    <div className="col-4">
                         <div className={classes1.card}>
                         {meetup3.filename} 
                         <br /><br />
                        
                  

                
                <div className={classes1.actions}>
                    
                    <button type="button" onClick={() => _handlePressButtonAsync(meetup3.link)}>
                    <FontAwesomeIcon icon={faBinoculars} style={{fontSize: 18, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
    />
                        View Study Material</button>
                </div>
                <br />

                    <div className={classes1.actions}>
                    
                                    <button type="button" onClick={() => deletecoursefile(meetup3._id)}>
                                    <FontAwesomeIcon icon={faTrash} style={{fontSize: 18, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                                        Remove Study Material</button>
                                </div>
                    </div>
                    </div>

                );
            })}
            </div></div>

            

       
        
        </div>
        <FooterNav1 />
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;