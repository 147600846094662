import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function SidefacNavigation() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    



    return (
        <div className={classes1.sidebar}>
            <div className={classes1.sidebarinside}>

<div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />
            
        
        <div style={{marginBottom: 10}}><b>Universities</b></div>

        <Link to='/viewnexplearningadmin'> Experiential learning</Link>
<Link to='/viewnnufacultydataadmin'> Faculty sanctioned post</Link>
<Link to='/viewnphdfaceadmin'> PhD faculties</Link>
<Link to='/viewnteacherdataadmin'> Teacher data</Link>
<Link to='/viewnresultadmin'> Result publications</Link>
<Link to='/viewnnugrievanceadmin'> Exam grievances</Link>
<Link to='/viewnpasspercentadmin'> Pass percentage</Link>
<Link to='/viewnteacherfellowadmin'> Teacher fellowship</Link>
<Link to='/viewnpatentsadmin'> Patents</Link>
<Link to='/viewnconsultancyadmin'> Consultancy (University)</Link>
<Link to='/viewnmouadmin'> MoU</Link>
<Link to='/viewnnuefacilitiesadmin'> Facilities (University)</Link>
<Link to='/viewnnustudcompratioadmin'> Student computer ratio</Link>
<Link to='/viewnhigherexamadmin'> Higher exam</Link>
<Link to='/viewnawardsadmin'> Student awards</Link>
<Link to='/viewnnlibusageadmin'> Library usage</Link>

<br /><br />

<div style={{marginBottom: 10}}><b>Legal Universities</b></div>

<Link to='/viewnnluteacherdataadmin'> Teacher data</Link>
<Link to='/viewnnlufacilitiesadmin'> Facilities</Link>
<Link to='/viewnnlurecognitionsadmin'> Recognitions</Link>
<Link to='/viewnnlubillsadmin'> Bills</Link>
<Link to='/viewnnluincentivesadmin'> Incentives to faculties</Link>
<Link to='/viewnnluphdtheisisadmin'> PhD thesis</Link>
<Link to='/viewnnlulegaldatabasesadmin'> Legal databases</Link>
<Link to='/viewnnlucitationadmin'> Citation</Link>
<Link to='/viewnnlbaradmin'> Bar council registrations</Link>




<br /><br /><br /><br /><br /><br />
      
       

        <hr />
        {/* <div style={{marginBottom: 10}}><b>Useful links</b></div> */}
     
     

        
       </div>

</div>
    );

}

export default SidefacNavigation;