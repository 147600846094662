import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function Sideaqar6() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    



    return (
        <div className={classes1.sidebar}>
        <div className={classes1.sidebarinside}>  
        
        <div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />
        <Link to='/viewegov'> View Implementation of E-governance (6.2.3 A, 6.2.3 U)</Link>
        <Link to='/viewegovern'> View Implementation of E-governance (6.2.3 H)</Link>
        <Link to='/viewteacherfs'> View Teacher Financial Support (6.3.2 A, 6.3.2 U, 6.3.2 H)</Link>
        <Link to='/viewevent'> View Training Programs (6.3.3 A, 6.3.3 U, 6.3.3 H)</Link>
        <Link to='/viewfdp'> View FDP (6.3.4 A, 6.3.4 U)</Link>
        <Link to='/viewfunds'> View Gov./Non-Gov. Funds (6.4.2 A, 6.4.2 - 6.4.3 U)</Link>
        <Link to='/viewqualityinit'> View Quality Initiatives (6.5.3 A, 6.5.2 U)</Link>

        

<hr />
<div style={{marginBottom: 10}}><b>Nomenclature</b></div>
A - Affiliated college<br />
U - University<br />
C- Autonomous institution<br />
H- Health Science institution<br />
HU- Health Science university<br />

        <hr />
        <div style={{marginBottom: 10}}><b>Useful links</b></div>
        <Link to='/dashcourse'> Dashboard</Link>
        <Link to='/getmyfeedback'> My feedback</Link>  
       
        </div> 
</div>
    );

}

export default Sideaqar6;