import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/An';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import { duration } from "@material-ui/core";



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const designationref=useRef();
const courseref=useRef();
const nooffacref=useRef();
const intakeref=useRef();
const reqfacref=useRef();
const deficiencyref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const patentid=global1.addonid;


    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       

       designationref.current.value=global1.designation;
courseref.current.value=global1.course;
nooffacref.current.value=global1.nooffac;
intakeref.current.value=global1.intake;
reqfacref.current.value=global1.reqfac;
deficiencyref.current.value=global1.deficiency;


    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        //const title=titleref.current.value;
        const designation=designationref.current.value;
const course=courseref.current.value;
const nooffac=nooffacref.current.value;
const intake=intakeref.current.value;
const reqfac=reqfacref.current.value;
const deficiency=deficiencyref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updatepauconfacshebyfac', {
            params: {
                id: patentid,
                user: user,
                token: token,
                name: name,
                colid: colid,
                designation:designation,
course:course,
nooffac:nooffac,
intake:intake,
reqfac:reqfac,
deficiency:deficiency,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewnpauconfacshe');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Edit data</p>
                        <hr />
                       

                   <label htmlFor="designation">Designation</label>

<select id="designation" className="form-control"  ref={designationref}>
<option value="Assistant Professor">Assistant Professor</option>
<option value="Associate Professor">Associate Professor</option>
<option value="Others">Others</option>
<option value="Principal">Principal</option>
<option value="Professor">Professor</option>
</select>
<br /><br />

<label htmlFor="course">Name of the course</label>

<select id="course" className="form-control"  ref={courseref}>
<option value="Maths">Maths</option>
<option value="Physics">Physics</option>
<option value="Chemistry">Chemistry</option>
<option value="English">English</option>
<option value="General Engineering">General Engineering</option>
</select>
<br /><br />

Enter Total No.of Faculty<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Total No.of Faculty" id="nooffac" ref={nooffacref} />
<br /><br />

Enter Total intake for current year of all the B.E.&B.Tech.Courses(S1)<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Total intake for current year of all the B.E.&B.Tech.Courses(S1)" id="intake" ref={intakeref} />
<br /><br />

Enter Total no.of Faculty members required<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Total no.of Faculty members required" id="reqfac" ref={reqfacref} />
<br /><br />

Enter % of Deficiency[(1<br /><br />

<input type="A/R)*100]" required style={{width: "100%"}} placeholder="Enter % of Deficiency[(1" id="deficiency" ref={deficiencyref} />
<br /><br />


                  


                      
                        <button  onClick={searchapi}>Update data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;