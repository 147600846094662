import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/SidecolorNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const titleref=useRef();
    const patentnoref=useRef();
    const yopref=useRef();
    const websiteref=useRef();

    const templatetyperef=useRef();
    const calendaryearref=useRef();
    const lmsyearref=useRef();

    const commentsref=useRef();
    const passwordref=useRef();
    const usernameref=useRef();
  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [results, setResults] = useState([]);
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    //const coursecode=global1.coursecode;
    //const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    const gotocalendaryear = () => {
  
        const calendaryear=calendaryearref.current.value;
        if(!calendaryear) {
            alert('Please enter calendar year');
            return;
        }
        global1.calendaryear=calendaryear;
        alert('Calendar year set to ' + calendaryear);
    }

    const gotowebsite = () => {
  
        const website=websiteref.current.value;
        if(!website) {
            alert('Please enter website');
            return;
        }
        global1.website=website;
        alert('Website set to ' + website);
    }

    const gotolmsyear = () => {
  
        const templatetype=templatetyperef.current.value;
        if(!templatetype) {
            alert('Please enter templatetype');
            return;
        }
        global1.templatetype=templatetype;
        history.replace('/generatereport');
        //alert('LMS Year set to ' + lmsyear);
        
        
        //alert(global1.role);
    }

    const gotodashcourse = () => {
        history.replace('/dashcourse');
    }

    const gotoadminreports = () => {
        if(global1.role=='Admin') {

            history.replace('/viewworkloadadmin');

        } else {
            alert('This option is available only for Admin');

        }
        
    }

    const gotoattendance = () => {
        // global1.courseid=courseid;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        const threshold=patentnoref.current.value;
        if(!threshold) {
            alert('Please select threshold');
            return;
        }
        global1.threshold=threshold;
        global1.totalclasses=results.length;
        history.replace('/classattendance');
    }

   


    useEffect(() => {
        //searchApi();
       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const updatesyllabusrevc = async () => {
        var comments=commentsref.current.value;
        const password=passwordref.current.value;

        const dt1=new Date();
        var month=dt1.getMonth() + 1;
        var dat2=dt1.getDate();
        var year=dt1.getFullYear();
        const colid1=parseInt(colid);
        var s1=dat2*dat2 + dat2 + colid1;
        var s2=month * month + month + colid1;
        var s3=year*year + year + colid1;
        var dt2=dat2 + '/' + month + '/' + year + ' - ';
        comments=dt2 + comments;
        const passwordcheck=s1.toString() + '-' + s2.toString() + '-' + s3.toString();
        setTerm(passwordcheck);
        if(password!=passwordcheck){
            alert('Invalid password');
            return;
        }
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updatesyllabusrevc', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                comment: comments

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        //history.replace('/viewpatent');
       
    };

    const updateemployabilityc = async () => {
        var comments=commentsref.current.value;
        const password=passwordref.current.value;

        const dt1=new Date();
        var month=dt1.getMonth() + 1;
        var dat2=dt1.getDate();
        var year=dt1.getFullYear();
        const colid1=parseInt(colid);
        var s1=dat2*dat2 + dat2 + colid1;
        var s2=month * month + month + colid1;
        var s3=year*year + year + colid1;
        var dt2=dat2 + '/' + month + '/' + year + ' - ';
        comments=dt2 + comments;
        const passwordcheck=s1.toString() + '-' + s2.toString() + '-' + s3.toString();
        setTerm(passwordcheck);
        if(password!=passwordcheck){
            alert('Invalid password');
            return;
        }
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updateemployabilityc', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                comments: comments

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        //history.replace('/viewpatent');
       
    };

    const updateaddonc = async () => {
        var comments=commentsref.current.value;
        const password=passwordref.current.value;

        const dt1=new Date();
        var month=dt1.getMonth() + 1;
        var dat2=dt1.getDate();
        var year=dt1.getFullYear();
        const colid1=parseInt(colid);
        var s1=dat2*dat2 + dat2 + colid1;
        var s2=month * month + month + colid1;
        var s3=year*year + year + colid1;
        var dt2=dat2 + '/' + month + '/' + year + ' - ';
        comments=dt2 + comments;
        const passwordcheck=s1.toString() + '-' + s2.toString() + '-' + s3.toString();
        setTerm(passwordcheck);
        if(password!=passwordcheck){
            alert('Invalid password');
            return;
        }
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updateaddonc', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                comments: comments

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        //history.replace('/viewpatent');
       
    };

    const updateexplearning = async () => {
        var comments=commentsref.current.value;
        const password=passwordref.current.value;

        const dt1=new Date();
        var month=dt1.getMonth() + 1;
        var dat2=dt1.getDate();
        var year=dt1.getFullYear();
        const colid1=parseInt(colid);
        var s1=dat2*dat2 + dat2 + colid1;
        var s2=month * month + month + colid1;
        var s3=year*year + year + colid1;
        var dt2=dat2 + '/' + month + '/' + year + ' - ';
        comments=dt2 + comments;
        const passwordcheck=s1.toString() + '-' + s2.toString() + '-' + s3.toString();
        setTerm(passwordcheck);
        if(password!=passwordcheck){
            alert('Invalid password');
            return;
        }
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updateexplearning', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                comments: comments

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        //history.replace('/viewpatent');
       
    };

    const updateadmissionc = async () => {
        var comments=commentsref.current.value;
        const password=passwordref.current.value;

        const dt1=new Date();
        var month=dt1.getMonth() + 1;
        var dat2=dt1.getDate();
        var year=dt1.getFullYear();
        const colid1=parseInt(colid);
        var s1=dat2*dat2 + dat2 + colid1;
        var s2=month * month + month + colid1;
        var s3=year*year + year + colid1;
        var dt2=dat2 + '/' + month + '/' + year + ' - ';
        comments=dt2 + comments;
        const passwordcheck=s1.toString() + '-' + s2.toString() + '-' + s3.toString();
        setTerm(passwordcheck);
        if(password!=passwordcheck){
            alert('Invalid password');
            return;
        }
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updateadmissionc', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                comments: comments

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        //history.replace('/viewpatent');
       
    };

    const updatereservcatc = async () => {
        var comments=commentsref.current.value;
        const password=passwordref.current.value;

        const dt1=new Date();
        var month=dt1.getMonth() + 1;
        var dat2=dt1.getDate();
        var year=dt1.getFullYear();
        const colid1=parseInt(colid);
        var s1=dat2*dat2 + dat2 + colid1;
        var s2=month * month + month + colid1;
        var s3=year*year + year + colid1;
        var dt2=dat2 + '/' + month + '/' + year + ' - ';
        comments=dt2 + comments;
        const passwordcheck=s1.toString() + '-' + s2.toString() + '-' + s3.toString();
        setTerm(passwordcheck);
        if(password!=passwordcheck){
            alert('Invalid password');
            return;
        }
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updatereservcatc', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                comments: comments

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        //history.replace('/viewpatent');
       
    };

    const updatehighereduc = async () => {
        var comments=commentsref.current.value;
        const password=passwordref.current.value;

        const dt1=new Date();
        var month=dt1.getMonth() + 1;
        var dat2=dt1.getDate();
        var year=dt1.getFullYear();
        const colid1=parseInt(colid);
        var s1=dat2*dat2 + dat2 + colid1;
        var s2=month * month + month + colid1;
        var s3=year*year + year + colid1;
        var dt2=dat2 + '/' + month + '/' + year + ' - ';
        comments=dt2 + comments;
        const passwordcheck=s1.toString() + '-' + s2.toString() + '-' + s3.toString();
        setTerm(passwordcheck);
        if(password!=passwordcheck){
            alert('Invalid password');
            return;
        }
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updatehighereduc', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                comments: comments

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        //history.replace('/viewpatent');
       
    };

    const updatehigherexamc = async () => {
        var comments=commentsref.current.value;
        const password=passwordref.current.value;

        const dt1=new Date();
        var month=dt1.getMonth() + 1;
        var dat2=dt1.getDate();
        var year=dt1.getFullYear();
        const colid1=parseInt(colid);
        var s1=dat2*dat2 + dat2 + colid1;
        var s2=month * month + month + colid1;
        var s3=year*year + year + colid1;
        var dt2=dat2 + '/' + month + '/' + year + ' - ';
        comments=dt2 + comments;
        const passwordcheck=s1.toString() + '-' + s2.toString() + '-' + s3.toString();
        setTerm(passwordcheck);
        if(password!=passwordcheck){
            alert('Invalid password');
            return;
        }
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updatehigherexamc', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                comments: comments

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        //history.replace('/viewpatent');
       
    };

    const updatepassexamc = async () => {
        var comments=commentsref.current.value;
        const password=passwordref.current.value;

        const dt1=new Date();
        var month=dt1.getMonth() + 1;
        var dat2=dt1.getDate();
        var year=dt1.getFullYear();
        const colid1=parseInt(colid);
        var s1=dat2*dat2 + dat2 + colid1;
        var s2=month * month + month + colid1;
        var s3=year*year + year + colid1;
        var dt2=dat2 + '/' + month + '/' + year + ' - ';
        comments=dt2 + comments;
        const passwordcheck=s1.toString() + '-' + s2.toString() + '-' + s3.toString();
        setTerm(passwordcheck);
        if(password!=passwordcheck){
            alert('Invalid password');
            return;
        }
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updatepassexamc', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                comments: comments

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        //history.replace('/viewpatent');
       
    };

    const updateexamdaysc = async () => {
        var comments=commentsref.current.value;
        const password=passwordref.current.value;

        const dt1=new Date();
        var month=dt1.getMonth() + 1;
        var dat2=dt1.getDate();
        var year=dt1.getFullYear();
        const colid1=parseInt(colid);
        var s1=dat2*dat2 + dat2 + colid1;
        var s2=month * month + month + colid1;
        var s3=year*year + year + colid1;
        var dt2=dat2 + '/' + month + '/' + year + ' - ';
        comments=dt2 + comments;
        const passwordcheck=s1.toString() + '-' + s2.toString() + '-' + s3.toString();
        setTerm(passwordcheck);
        if(password!=passwordcheck){
            alert('Invalid password');
            return;
        }
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updateexamdaysc', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                comments: comments

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        //history.replace('/viewpatent');
       
    };


    const updateprojectsc = async () => {
        var comments=commentsref.current.value;
        const password=passwordref.current.value;

        const dt1=new Date();
        var month=dt1.getMonth() + 1;
        var dat2=dt1.getDate();
        var year=dt1.getFullYear();
        const colid1=parseInt(colid);
        var s1=dat2*dat2 + dat2 + colid1;
        var s2=month * month + month + colid1;
        var s3=year*year + year + colid1;
        var dt2=dat2 + '/' + month + '/' + year + ' - ';
        comments=dt2 + comments;
        const passwordcheck=s1.toString() + '-' + s2.toString() + '-' + s3.toString();
        setTerm(passwordcheck);
        if(password!=passwordcheck){
            alert('Invalid password');
            return;
        }
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updateprojectsc', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                comments: comments

            }
        });
        setIsuploading(false);
        alert(response.data.status);
    };


    const updatecollabc = async () => {
        var comments=commentsref.current.value;
        const password=passwordref.current.value;

        const dt1=new Date();
        var month=dt1.getMonth() + 1;
        var dat2=dt1.getDate();
        var year=dt1.getFullYear();
        const colid1=parseInt(colid);
        var s1=dat2*dat2 + dat2 + colid1;
        var s2=month * month + month + colid1;
        var s3=year*year + year + colid1;
        var dt2=dat2 + '/' + month + '/' + year + ' - ';
        comments=dt2 + comments;
        const passwordcheck=s1.toString() + '-' + s2.toString() + '-' + s3.toString();
        setTerm(passwordcheck);
        if(password!=passwordcheck){
            alert('Invalid password');
            return;
        }
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updatecollabc', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                comments: comments

            }
        });
        setIsuploading(false);
        alert(response.data.status);
    };

    //mou

    const updatemouc = async () => {
        var comments=commentsref.current.value;
        const password=passwordref.current.value;

        const dt1=new Date();
        var month=dt1.getMonth() + 1;
        var dat2=dt1.getDate();
        var year=dt1.getFullYear();
        const colid1=parseInt(colid);
        var s1=dat2*dat2 + dat2 + colid1;
        var s2=month * month + month + colid1;
        var s3=year*year + year + colid1;
        var dt2=dat2 + '/' + month + '/' + year + ' - ';
        comments=dt2 + comments;
        const passwordcheck=s1.toString() + '-' + s2.toString() + '-' + s3.toString();
        setTerm(passwordcheck);
        if(password!=passwordcheck){
            alert('Invalid password');
            return;
        }
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updatemouc', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                comments: comments

            }
        });
        setIsuploading(false);
        alert(response.data.status);
    };

    //scholarship

    const updatescholarshipc = async () => {
        var comments=commentsref.current.value;
        const password=passwordref.current.value;

        const dt1=new Date();
        var month=dt1.getMonth() + 1;
        var dat2=dt1.getDate();
        var year=dt1.getFullYear();
        const colid1=parseInt(colid);
        var s1=dat2*dat2 + dat2 + colid1;
        var s2=month * month + month + colid1;
        var s3=year*year + year + colid1;
        var dt2=dat2 + '/' + month + '/' + year + ' - ';
        comments=dt2 + comments;
        const passwordcheck=s1.toString() + '-' + s2.toString() + '-' + s3.toString();
        setTerm(passwordcheck);
        if(password!=passwordcheck){
            alert('Invalid password');
            return;
        }
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updatescholarshipc', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                comments: comments

            }
        });
        setIsuploading(false);
        alert(response.data.status);
    };

    // events

    const updateeventc = async () => {
        var comments=commentsref.current.value;
        const password=passwordref.current.value;
        const username=usernameref.current.value;

        const dt1=new Date();
        var month=dt1.getMonth() + 1;
        var dat2=dt1.getDate();
        var year=dt1.getFullYear();
        const colid1=parseInt(colid);
        var s1=dat2*dat2 + dat2 + colid1;
        var s2=month * month + month + colid1;
        var s3=year*year + year + colid1;
        var dt2=dat2 + '/' + month + '/' + year + ' - ';
        comments=dt2 + comments;
        const passwordcheck=s1.toString() + '-' + s2.toString() + '-' + s3.toString();
        setTerm(passwordcheck);
        if(password!=passwordcheck){
            alert('Invalid password');
            return;
        }
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updateeventc', {
            params: {
                //user: user,
                token: token,
                colid: colid,
                name: name,
                comments: comments,
                user:username

            }
        });
        setIsuploading(false);
        alert(response.data.status);
    };

    // career counseling

    const updatecareercounselc = async () => {
        var comments=commentsref.current.value;
        const password=passwordref.current.value;

        const dt1=new Date();
        var month=dt1.getMonth() + 1;
        var dat2=dt1.getDate();
        var year=dt1.getFullYear();
        const colid1=parseInt(colid);
        var s1=dat2*dat2 + dat2 + colid1;
        var s2=month * month + month + colid1;
        var s3=year*year + year + colid1;
        var dt2=dat2 + '/' + month + '/' + year + ' - ';
        comments=dt2 + comments;
        const passwordcheck=s1.toString() + '-' + s2.toString() + '-' + s3.toString();
        setTerm(passwordcheck);
        if(password!=passwordcheck){
            alert('Invalid password');
            return;
        }
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updatecareercounselc', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                comments: comments

            }
        });
        setIsuploading(false);
        alert(response.data.status);
    };


    // seed money

    const updateseedmc = async () => {
        var comments=commentsref.current.value;
        const password=passwordref.current.value;
        const username=usernameref.current.value;

        const dt1=new Date();
        var month=dt1.getMonth() + 1;
        var dat2=dt1.getDate();
        var year=dt1.getFullYear();
        const colid1=parseInt(colid);
        var s1=dat2*dat2 + dat2 + colid1;
        var s2=month * month + month + colid1;
        var s3=year*year + year + colid1;
        var dt2=dat2 + '/' + month + '/' + year + ' - ';
        comments=dt2 + comments;
        const passwordcheck=s1.toString() + '-' + s2.toString() + '-' + s3.toString();
        setTerm(passwordcheck);
        if(password!=passwordcheck){
            alert('Invalid password');
            return;
        }
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updateseedmc', {
            params: {
                //user: user,
                token: token,
                colid: colid,
                name: name,
                comments: comments,
                user:username

            }
        });
        setIsuploading(false);
        alert(response.data.status);
    };


    // extension activities

    const updateextactivitiesc = async () => {
        var comments=commentsref.current.value;
        const password=passwordref.current.value;
        const username=usernameref.current.value;

        const dt1=new Date();
        var month=dt1.getMonth() + 1;
        var dat2=dt1.getDate();
        var year=dt1.getFullYear();
        const colid1=parseInt(colid);
        var s1=dat2*dat2 + dat2 + colid1;
        var s2=month * month + month + colid1;
        var s3=year*year + year + colid1;
        var dt2=dat2 + '/' + month + '/' + year + ' - ';
        comments=dt2 + comments;
        const passwordcheck=s1.toString() + '-' + s2.toString() + '-' + s3.toString();
        setTerm(passwordcheck);
        if(password!=passwordcheck){
            alert('Invalid password');
            return;
        }
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updateextactivitiesc', {
            params: {
                //user: user,
                token: token,
                colid: colid,
                name: name,
                comments: comments,
                user:username

            }
        });
        setIsuploading(false);
        alert(response.data.status);
    };

    // teacher data

    const updateteacherdatac = async () => {
        var comments=commentsref.current.value;
        const password=passwordref.current.value;
        const username=usernameref.current.value;

        const dt1=new Date();
        var month=dt1.getMonth() + 1;
        var dat2=dt1.getDate();
        var year=dt1.getFullYear();
        const colid1=parseInt(colid);
        var s1=dat2*dat2 + dat2 + colid1;
        var s2=month * month + month + colid1;
        var s3=year*year + year + colid1;
        var dt2=dat2 + '/' + month + '/' + year + ' - ';
        comments=dt2 + comments;
        const passwordcheck=s1.toString() + '-' + s2.toString() + '-' + s3.toString();
        setTerm(passwordcheck);
        if(password!=passwordcheck){
            alert('Invalid password');
            return;
        }
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updateteacherdatac', {
            params: {
                //user: user,
                token: token,
                colid: colid,
                name: name,
                comments: comments,
                user:username

            }
        });
        setIsuploading(false);
        alert(response.data.status);
    };

    // ict

    const updateictc = async () => {
        var comments=commentsref.current.value;
        const password=passwordref.current.value;
        const username=usernameref.current.value;

        const dt1=new Date();
        var month=dt1.getMonth() + 1;
        var dat2=dt1.getDate();
        var year=dt1.getFullYear();
        const colid1=parseInt(colid);
        var s1=dat2*dat2 + dat2 + colid1;
        var s2=month * month + month + colid1;
        var s3=year*year + year + colid1;
        var dt2=dat2 + '/' + month + '/' + year + ' - ';
        comments=dt2 + comments;
        const passwordcheck=s1.toString() + '-' + s2.toString() + '-' + s3.toString();
        setTerm(passwordcheck);
        if(password!=passwordcheck){
            alert('Invalid password');
            return;
        }
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updateictc', {
            params: {
                //user: user,
                token: token,
                colid: colid,
                name: name,
                comments: comments,
                user:username

            }
        });
        setIsuploading(false);
        alert(response.data.status);
    };





    const searchapi = async () => {
        const title=titleref.current.value;
        const patentno=patentnoref.current.value;
        const yop=yopref.current.value;
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/createpatentbyfaculty', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                title: title,
                patentnumber:patentno,
                yop:yop,
                status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewpatent');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                       

                        <p style={{ fontSize: 18 }}> Enter comments</p>
                        <hr />
                        {/* {term} <br /><br /> */}

                        {/* <input type="textarea" rows="10"  style={{width: '100%', height: '300px'}} placeholder="Enter comments" id="comments" ref={commentsref} /> */}
                        <textarea id="w3review" name="w3review" rows="4" cols="80" ref={commentsref}></textarea>
                            <br /><br />
                    Enter password <br /><br />

                        <input type="text"  style={{width: '100%'}} placeholder="Enter password" id="password" ref={passwordref} />
                            
                            <br /><br />

                            Enter username 

                            <input type="text"  style={{width: '100%'}} placeholder="Enter username" id="username" ref={usernameref} />
                            
                            <br /><br />
                        
                    <table>
                        <tr>
                            <td>
                            <button  onClick={updatesyllabusrevc}>Syllabus revision</button>
                            </td>
                            <td>
                            <button  onClick={updateemployabilityc}>Employability</button>
                            </td>
                            <td>
                            <button  onClick={updateaddonc}>Add on course</button>
                            </td>
                            <td>
                            <button  onClick={updateexplearning}>Experiential Learning</button>
                            </td>
                        </tr>
                        </table>
                        <br /><br />
                        <table>
                        <tr>
                            <td>
                            <button  onClick={updateadmissionc}>Admission</button>
                            </td>
                            <td>
                            <button  onClick={updatereservcatc}>Reserve category</button>
                            </td>
                            <td>
                            <button  onClick={updateteacherdatac}>Teacher data (with id)</button>
                            </td>
                            <td>
                            <button  onClick={updateexamdaysc}>Exam days</button>
                            </td>
                            <td>
                            <button  onClick={updatepassexamc}>Pass percentage</button>
                            </td>
                        </tr>
                        </table>
                        <br /><br />
                        <table>
                        <tr>
                            <td>
                            <button  onClick={updateprojectsc}>Projects</button>
                            </td>
                            <td>
                            <button  onClick={updatecollabc}>Collaboration</button>
                            </td>
                            <td>
                            <button  onClick={updatemouc}>MoU</button>
                            </td>
                            <td>
                            <button  onClick={updateeventc}>Events (with ID)</button>
                            </td>
                            <td>
                            <button  onClick={updateextactivitiesc}>Extension activities (with ID)</button>
                            </td>
                            <td>
                            <button  onClick={updateseedmc}>Seed money (with ID)</button>
                            </td>
                            <td>
                            <button  onClick={updateictc}>ICT (with ID)</button>
                            </td>


                        </tr>
                        </table>
                        <br /><br />
                        <table>
                        <tr>
                        <td>
                            <button  onClick={updatescholarshipc}>Scholarship</button>
                            </td>
                            <td>
                            <button  onClick={updatecareercounselc}>Career counselling</button>
                            </td>
                            <td>
                            <button  onClick={updatehighereduc}>Higher education</button>
                            </td>
                            <td>
                            <button  onClick={updatehigherexamc}>Higher exam</button>
                            </td>
                            {/* <td>
                            <button  onClick={updateaddonc}>Add on course</button>
                            </td>
                            <td>
                            <button  onClick={updateexplearning}>Experiential Learning</button>
                            </td> */}
                        </tr>
                        </table>


                       


                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;