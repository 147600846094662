import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Bar } from 'react-chartjs-2';

// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import SidefacNavigation from '../components/layout/SidefacNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';
import ReportAdmin from '../components/layout/ReportAdmin';

import Spinner from 'react-bootstrap/Spinner';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const affiliatedref=useRef();
    const moduleref=useRef();
    const topicref=useRef();

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [faculties, setFaculties] = useState([]);
    const [results1, setResults1] = useState([]);
    const [projectcount, setProjectcount] = useState([]);
    const [projectamount, setProjectamount] = useState([]);
    const [bookcount, setBookcount] = useState([]);
    const [patentscount, setPatentscount] = useState([]);
    const [publicationcount, setPublicationcount] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState();
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;
    //const name=global1.name;

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    const addnewstudent = () => {
        history.replace('/enrollstudents');
    }

    const addperiod = () => {
        history.replace('/addperiod');
    }

    const addstudents = () => {
        // global1.courseid=courseid;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/addstudents');
    }

    

   


    const searchApi = async (user1) => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getseminaramount', {
            params: {
                colid: colid,
                user: user1

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setResults(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const getfacultylist = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getfacultylist', {
            params: {
                colid: colid,
                user: user,
                token: token

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setFaculties(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const searchApi1 = async (user1) => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getseminarcount', {
            params: {
                colid: colid,
                user: user1

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setResults1(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const searchPubcount = async (user1) => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getpublicationscount', {
            params: {
                colid: colid,
                user: user1

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setPublicationcount(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const searchBookcount = async (user1) => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getbookcount', {
            params: {
                colid: colid,
                user: user1

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setBookcount(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const getprojectcount = async (user1) => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getprojectscount', {
            params: {
                colid: colid,
                user: user1

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setProjectcount(response.data.data.classes);  
    };

    const getprojectamount = async (user1) => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getprojectscount', {
            params: {
                colid: colid,
                user: user1

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setProjectamount(response.data.data.classes);  
    };

   

    const deleteenrolment = async (enrolmentid) => {
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteclassenr', {
            params: {
                id: enrolmentid,
                token: token
            }

        });
        setLoading(false);
        searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    

    useEffect(() => {
        checklogin();
        //search1();
        //getexamlist();
        getfacultylist();
        // searchApi('');
        // searchApi1('');
        // searchPubcount();
        // searchBookcount();
        // getprojectamount();
        // getprojectcount();
        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);

    


    
   

    

    const width=window.innerWidth;
    const mdivwidth=width - 330;

    
    const timely = (date1) => {
        var dt1=new Date(date1);
        dt1.setHours(dt1.getHours() - 5);
        dt1.setMinutes(dt1.getMinutes() - 30);
        var hours=dt1.getHours();
        var minutes=dt1.getMinutes();
        var month=dt1.getMonth() + 1;
        if(minutes.length<2) {
            minutes='0' + minutes;
        }
        var t1;
        if(hours>11) {
            t1='PM';
        } else {
            t1='AM';
        }
        if(hours>12) {
            hours=hours-12;
        }
        var dt2=dt1.getDate() + '/' + month + '/' + dt1.getFullYear() + ' ' + hours + ':' + minutes + t1;
        return dt2;

    }

    const getval=()=> {
        const aff=affiliatedref.current.value;
        //alert(aff);
        searchApi(aff);
        searchApi1(aff);
        searchPubcount(aff);
        searchBookcount(aff);
        getprojectamount(aff);
        getprojectcount(aff);
    }

    const getval1=(event)=> {
      
      //alert(event.target.value);
      searchApi(event.target.value);
        searchApi1(event.target.value);
        searchPubcount(event.target.value);
        searchBookcount(event.target.value);
        getprojectamount(event.target.value);
        getprojectcount(event.target.value);
  }


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width: 60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <ReportAdmin />
                    </td>
                    
                    <td> 

                        <TopNavigation />

                    
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
            <p>Faculty activities</p>
            <hr />

          
            {loading ? (
                <div style={{ alignItems: 'center', marginTop: 30, marginBottom: 60}}>
                   <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <div></div>
            )} 


<label htmlFor='affiliated'>Select faculty</label>
                    <select id="affiliated" className="form-control" onChange={getval1}  ref={affiliatedref}>
                    <option value="1">Select faculty</option>

    {faculties.map((meetup3) => {
                return (
                    <option value={meetup3.email}>{meetup3.name} - {meetup3.department}</option>
 
                );
            })}
                  
                    </select>
                    <br />
                    <button  onClick={getval}>Get details</button>
                    <br /><br />


        <div className="container">
                <div className="row">
                <div className="col-6">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                         >
                             <h5>Total amount of seminar support per year</h5>
            <hr />
            <div style={{width: 450, height: 250}}>

            <Bar
	            data={{
                    labels: results.map((labels) => {
                        return (
                            labels._id        
                            );
                        }),
                    datasets: [{
                        label: 'Total Amount',
                        data: 
                            results.map((labels1) => {
                                return (
                                    parseInt(labels1.total_attendance)        
                                    );
                                })
                            
                        ,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                }}
	            width={400}
	            height={250}
	            options={{ 
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                }

                            }
                            
                        ]
                    }
                }}
            />
           </div>
                           

                        </div>
                </div>
                <div className="col-6">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                         >
                             <h5>Number of seminars attended per year</h5>
        <hr />
        <div style={{width: 450, height: 250}}>

            <Bar
	            data={{
                    labels: results1.map((labels) => {
                        return (
                            labels._id        
                            );
                        }),
                    datasets: [{
                        label: 'Number of seminars',
                        data: 
                            results1.map((labels1) => {
                                return (
                                    parseInt(labels1.total_attendance)        
                                    );
                                })
                            
                        ,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                }}
	            width={400}
	            height={250}
	            options={{ 
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                }

                            }
                            
                        ]
                    }
                }}
            />
           </div>


                         </div>
                </div>
               
               
                </div>


{/* start div */}
                <div className="row">
                <div className="col-6">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                         >
                             <h5>Number of publications per year</h5>
            <hr />
            <div style={{width: 450, height: 250}}>

            <Bar
	            data={{
                    labels: publicationcount.map((labels) => {
                        return (
                            labels._id        
                            );
                        }),
                    datasets: [{
                        label: 'Publications',
                        data: 
                            publicationcount.map((labels1) => {
                                return (
                                    parseInt(labels1.total_attendance)        
                                    );
                                })
                            
                        ,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                }}
	            width={400}
	            height={250}
	            options={{ 
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                }

                            }
                            
                        ]
                    }
                }}
            />
           </div>
                           

                        </div>
                </div>
                <div className="col-6">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                         >
                             <h5>Number of books published per year</h5>
        <hr />
        <div style={{width: 450, height: 250}}>

            <Bar
	            data={{
                    labels: bookcount.map((labels) => {
                        return (
                            labels._id        
                            );
                        }),
                    datasets: [{
                        label: 'Books published',
                        data: 
                            bookcount.map((labels1) => {
                                return (
                                    parseInt(labels1.total_attendance)        
                                    );
                                })
                            
                        ,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                }}
	            width={400}
	            height={250}
	            options={{ 
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                }

                            }
                            
                        ]
                    }
                }}
            />
           </div>


                         </div>
                </div>
               
               
                </div>
                {/* end div */}

                

{/* start div 3 */}
<div className="row">
                <div className="col-6">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                         >
                             <h5>Number of projects per year</h5>
            <hr />
            <div style={{width: 450, height: 250}}>

            <Bar
	            data={{
                    labels: projectcount.map((labels) => {
                        return (
                            labels._id        
                            );
                        }),
                    datasets: [{
                        label: 'Projects',
                        data: 
                            projectcount.map((labels1) => {
                                return (
                                    parseInt(labels1.total_attendance)        
                                    );
                                })
                            
                        ,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                }}
	            width={400}
	            height={250}
	            options={{ 
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                }

                            }
                            
                        ]
                    }
                }}
            />
           </div>
                           

                        </div>
                </div>
                <div className="col-6">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                         >
                             <h5>Project sanctioned amount per year</h5>
        <hr />
        <div style={{width: 450, height: 250}}>

            <Bar
	            data={{
                    labels: projectamount.map((labels) => {
                        return (
                            labels._id        
                            );
                        }),
                    datasets: [{
                        label: 'Total Amount',
                        data: 
                            projectamount.map((labels1) => {
                                return (
                                    parseInt(labels1.total_attendance)        
                                    );
                                })
                            
                        ,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                }}
	            width={400}
	            height={250}
	            options={{ 
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                }

                            }
                            
                        ]
                    }
                }}
            />
           </div>


                         </div>
                </div>
               
               
                </div>
                {/* end div */}





            </div>

            
    
        

        

            

       
        
        </div>
        <FooterNav1 />
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;