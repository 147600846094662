import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faUpload, faHome, faVideo, faEdit, faComment, faFileAlt, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { DataGrid } from '@material-ui/data-grid';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import Sidecr4Navigation from '../components/layout/Snbaugprog';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import Spinner from 'react-bootstrap/Spinner';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const linkref=useRef();
    const moduleref=useRef();
    const topicref=useRef();

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState([]);
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());
    const [format, setFormat] = useState(false);

    const [final, setFinal] = useState();
    const [placed, setPlaced] = useState();
    const [higherstudy, setHigherstudy] = useState();
    const [entre, setEntre] = useState();
    const [ratio, setRatio] = useState();

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;
    //const name=global1.name;

    const [isUploading, setIsuploading] = useState(false);

    const columns = [
        // { field: '_id', headerName: 'ID', width: 70 },
        { field: 'student', headerName: 'Student', width: 130 },
        { field: 'regno', headerName: 'Reg no', width: 130 },
      ];

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    const edit = (id, classroom, seminarhall, facitype, link, year, status1) => {
        if(status1=='Accepted') {
            alert('This document is accepted. Please contact IQAC coordinator to allow editing');
            return;
        }
        global1.ictid=id;
        global1.classroom=classroom;
        global1.seminarhall=seminarhall;
        global1.facitype=facitype;
        global1.link=link;
        global1.year=year;
        
        history.replace('/editict');
    }

    const addperiod = () => {
        history.replace('/addperiod');
    }

    const addstudents = () => {
        // global1.courseid=courseid;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/addstudents');
    }

    function getaffiliated(affvalue) {
        if(affvalue=="1") {
            return "Yes";
        } else {
            return "No";
        }
    }

    const upload = (id, status1) => {
        if(status1=='Accepted') {
            alert('This document is accepted. Please contact IQAC coordinator to allow editing');
            return;
        }
        global1.field1=id;
        global1.criteria='4';
        global1.metric='4.1.3 A, 4.3.1 U, 4.1.3 C';
        global1.collection1='ict';
        global1.type='ICT';
        global1.pagenav='viewict';
        
        // history.replace('/selectawsconfig');
        history.replace('/selectmetricrule');
    }

    const list = (id) => {
        global1.field1=id;
        global1.criteria='4';
        global1.metric='4.1.3 A, 4.3.1 U, 4.1.3 C';
        global1.collection1='ict';
        global1.type='ICT';
        
        history.replace('/viewsupportingdoc');
    }

    

   const aqaryear=global1.aqaryear;
   const nprogramcode=global1.nprogramcode;


    let tutorials = [];
    const searchApi = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getprogressionalert', {
            params: {
                user: user,
                token: token,
                colid: colid,
                year: aqaryear,
                programcode: nprogramcode
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        // localStorage.setItem("p_ict", JSON.stringify(response.data.data.classes));
        setFinal(response.data.data.finalstudents);
        setPlaced(response.data.data.placed);
        setHigherstudy(response.data.data.higherstudy);
        setEntre(response.data.data.entre);

       

       

        
        setResults(response.data.data.classes);
        
        //alert('format ' + format);
       
        
        
        
       
        //alert(results.length);
       
    };

    const calculate =  () => {
        var x=parseInt(entre);
        var y=parseInt(placed);
        var z=parseInt(higherstudy);
        var a=((x+y+z)/final) * 100;
        setRatio(a);
      };

    function formattable() {
        if (!format) {
            $('#example').DataTable(
                {
                    pagingType: 'full_numbers',
                      pageLength: 5,
                      processing: true,
                      dom: 'Bfrtip',
                          buttons: ['copy', 'csv', 'print'
                          ]
                }
            );
            setFormat(true);

        }
    }

   

    const deleteenrolment = async (enrolmentid, status1) => {
        if(status1=='Accepted') {
            alert('This document is accepted. Please contact IQAC coordinator to allow editing');
            return;
        }
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteictbyfac', {
            params: {
                id: enrolmentid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    // const getlocal = () => {
    //     let collection=localStorage.getItem("p_ict");
    //     if(!collection) {

    //     } else {
    //         setResults(JSON.parse(collection));

    //     }
        
    // }

    

    useEffect(() => {
        checklogin();
        //search1();
        //getexamlist();
        // getlocal();
        searchApi('');
        //$('#example').DataTable();
        //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable();
    //     },
    //     1000
    //     );
    // });
    
    //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable(
    //         {
    //             pagingType: 'full_numbers',
    //               pageLength: 5,
    //               processing: true,
    //               dom: 'Bfrtip',
    //                   buttons: ['copy', 'csv', 'print'
    //                   ]
    //         }
    //     );
    //     } ,
    //     1000
    //     );
    // });
        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);

    

    const uploadlink = async (link1, id1, year) => {
        link1=link1 + '?criteria=4&Section=4.1.3/4.3.1&Subsection=ICT&field1=' + id1 + '&Year=' + year; 
        var win = window.open(link1, '_blank');
        win.focus();
      };



    const uploadnew = (id, status1) => {
        if(status1=='Accepted') {
            alert('This document is accepted. Please contact IQAC coordinator to allow editing');
            return;
        }
        global1.field1=id;
        global1.criteria='1';
        global1.metric='1.3.4';
        global1.collection1='explearning';
        global1.type='Experiential Learning';
        global1.pagenav='viewict';
       
        global1.type='BOT';
        //global1.pagenav='viewevent';
        
        history.replace('/selectawsphoto');
        
     
    }
    


    
   

    

    const width=window.innerWidth;
    const mdivwidth=width - 330;

   
       
        //alert('loaded');
  

    


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width: 60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <Sidecr4Navigation />
                    </td>
                   
                    <td> 
                        <TopNavigation />

                    
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
            <p style={{ fontSize: 18 }}>Student progression data and Placement index</p>
            <hr />
           
            
           
            <br />
        <br />
        {isUploading ? (
				<div style={{ alignItems: 'center', marginTop: 30, marginBottom: 60}}>
                   <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
				</div>
			) : (
                <div></div>
			)} 

            Academic year {aqaryear} <br /><br />
            Total final year students {final} <br /><br />
            Placed {placed} <br /><br />
            Higher study {higherstudy} <br /><br />
            Entrepreneurship {entre} <br /><br />
            Placement index {ratio}
     
            <br /><br />
           
            <button  onClick={calculate}>Calculate placement index</button>

        


            

       
        
        </div>
        <FooterNav1 />
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;