import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/Sadditional1';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import { duration } from "@material-ui/core";



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const eventnameref=useRef();
const descriptionref=useRef();
const departmentref=useRef();
const brochurelinkref=useRef();
const dateref=useRef();
const timeref=useRef();
const coordinatorref=useRef();
const typeref=useRef();
const academicyearref=useRef();
const noofparticipantsref=useRef();
const noofteachersref=useRef();
const eventlinkref=useRef();
const fromtoref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const patentid=global1.addonid;


    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       

       eventnameref.current.value=global1.eventname;
descriptionref.current.value=global1.description;
departmentref.current.value=global1.department;
brochurelinkref.current.value=global1.brochurelink;
dateref.current.value=global1.date;
timeref.current.value=global1.time;
coordinatorref.current.value=global1.coordinator;
typeref.current.value=global1.type;
academicyearref.current.value=global1.academicyear;
noofparticipantsref.current.value=global1.noofparticipants;
noofteachersref.current.value=global1.noofteachers;
eventlinkref.current.value=global1.eventlink;
fromtoref.current.value=global1.fromto;


    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        //const title=titleref.current.value;
        const eventname=eventnameref.current.value;
const description=descriptionref.current.value;
const department=departmentref.current.value;
const brochurelink=brochurelinkref.current.value;
const date=dateref.current.value;
const time=timeref.current.value;
const coordinator=coordinatorref.current.value;
const type=typeref.current.value;
const academicyear=academicyearref.current.value;
const noofparticipants=noofparticipantsref.current.value;
const noofteachers=noofteachersref.current.value;
const eventlink=eventlinkref.current.value;
const fromto=fromtoref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updateeventbyfac', {
            params: {
                id: patentid,
                user: user,
                token: token,
                name: name,
                colid: colid,
                eventname:eventname,
description:description,
department:department,
brochurelink:brochurelink,
date:date,
time:time,
coordinator:coordinator,
type:type,
academicyear:academicyear,
noofparticipants:noofparticipants,
noofteachers:noofteachers,
eventlink:eventlink,
fromto:fromto,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewnevent');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Edit data</p>
                        <hr />
                       

                   Enter eventname<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter eventname" id="eventname" ref={eventnameref} />
<br /><br />

Enter description<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter description" id="description" ref={descriptionref} />
<br /><br />

Enter department<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter department" id="department" ref={departmentref} />
<br /><br />

Enter brochurelink<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter brochurelink" id="brochurelink" ref={brochurelinkref} />
<br /><br />

Enter date<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter date" id="date" ref={dateref} />
<br /><br />

Enter time<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter time" id="time" ref={timeref} />
<br /><br />

Enter coordinator<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter coordinator" id="coordinator" ref={coordinatorref} />
<br /><br />

Enter type<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter type" id="type" ref={typeref} />
<br /><br />

<label htmlFor="academicyear">academicyear</label>

<select id="academicyear" className="form-control"  ref={academicyearref}>
<option value="2017-18">2017-18</option>
<option value="2018-19">2018-19</option>
<option value="2019-20">2019-20</option>
<option value="2020-21">2020-21</option>
<option value="2021-22">2021-22</option>
<option value="2022-23">2022-23</option>
</select>
<br /><br />

Enter noofparticipants<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter noofparticipants" id="noofparticipants" ref={noofparticipantsref} />
<br /><br />

Enter noofteachers<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter noofteachers" id="noofteachers" ref={noofteachersref} />
<br /><br />

Enter eventlink<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter eventlink" id="eventlink" ref={eventlinkref} />
<br /><br />

Enter fromto<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter fromto" id="fromto" ref={fromtoref} />
<br /><br />


                  


                      
                        <button  onClick={searchapi}>Update data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;