import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';

import global1 from '../../pages/global1';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function Sidecr2Navigation() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    const changeHandler = () => {
        const instype=global1.instype;
        //alert(instype);
        if(instype=='Affiliated') {
            return (
                <>
             
             <Link to='/viewadmission'> View Student Enrollment for Admission (2.1.1) </Link>
             <Link to='/viewcurgap'> Action taken for curriculam gap (NBA)</Link>
        {/* <Link to='/viewreservecat'> View Student Seats against Reserved Category (2.1.2)</Link> */}
        <Link to='/viewnreservecat'> Reserved category (new)</Link>
        {/* <Link to='/viewteacherdata'> View Full time Teachers (2.4.1-2.4.3)</Link> */}
        <Link to='/viewpassexam'> View Student Pass Percentage (2.6.3)</Link>
        <Link to='/viewmentees'> View Mentor-Mentee</Link>
        <Link to='/viewnbaug33result'> Result publication (Unified)</Link>
<Link to='/viewwriterdb'> Content database</Link>
<Link to='/viewnadmission'> Admission (all)</Link>
<Link to='/viewnpassexam'> Pass percentage (all)</Link>
{/* <Link to='/viewnplacement'> Placement (all)</Link> */}
<Link to='/viewnphdfac'> PhD Faculties</Link>
<Link to='/viewnteacherdata'> Fulltime Teachers (2.4.1-2.4.3)</Link>
<Link to='/viewnresult'> No of days for result declaration</Link>
<Link to='/viewnphdface'> Phd Faculties (extended)</Link>
<Link to='/viewnmentees'> Mentor mentees new</Link>

       
                </>
            )
  
        } else if(instype=='University') {
          return (
              <>

<Link to='/viewadmission'> View Student Enrollment for Admission (2.1.1) </Link>
        {/* <Link to='/viewreservecat'> View Student Seats against Reserved Category (2.1.2)</Link> */}
        <Link to='/viewnreservecat'> Reserved category (new)</Link>
        <Link to='/viewcurgap'> Action taken for curriculam gap (NBA)</Link>
        <Link to='/viewteacherdata'> View Full time Teachers (2.4.2)</Link>
        <Link to='/viewpassexam'> View Student Pass Percentage (2.6.3)</Link>
        <Link to='/viewresult'> View Result Publications (2.5.1U)</Link>
        <Link to='/viewteacheraward'> View Teacher Awards/Recognitions (2.4.4-3.4.2)</Link>
        <Link to='/viewexamautomation'> View Automation for Examination (2.5.4)</Link>
        <Link to='/viewmentees'> View Mentor-Mentee (2.3.3)</Link>
        <Link to='/viewnmentees'> Mentor mentees new</Link>
        <Link to='/viewnbaug33result'> Result publication (Unified)</Link>
<Link to='/viewwriterdb'> Content database</Link>
<Link to='/viewnadmission'> Admission (all)</Link>
<Link to='/viewnpassexam'> Pass percentage (all)</Link>
{/* <Link to='/viewnplacement'> Placement (all)</Link> */}
<Link to='/viewnphdfac'> PhD Faculties</Link>
<Link to='/viewnteacherdata'> Fulltime Teachers (2.4.1-2.4.3)</Link>
<Link to='/viewnresult'> No of days for result declaration</Link>
<Link to='/viewnphdface'> Phd Faculties (extended)</Link>

    
              </>
          )
            
      } else if(instype=='HealthUniversity') {
        return (
            <>
       
       <Link to='/viewadmission'> View Student Enrollment for Admission (2.1.2) </Link>
       <Link to='/viewnreservecat'> Reserved category (new)</Link>
        <Link to='/viewteacherdata'> View Full time Teachers (2.4.3)</Link>
        <Link to='/viewcurgap'> Action taken for curriculam gap (NBA)</Link>
        <Link to='/viewpassexam'> View Student Pass Percentage (2.6.2)</Link>
        <Link to='/viewteacheraward'> View Teacher Awards/Recognitions (2.4.5)</Link>
        <Link to='/viewexamautomation'> View Automation for Examination (2.5.5)</Link>
        <Link to='/viewmentees'> View Mentor-Mentee (2.3.4)</Link>
        <Link to='/viewnmentees'> Mentor mentees new</Link>
        <Link to='/viewnbaug33result'> Result publication (Unified)</Link>
<Link to='/viewwriterdb'> Content database</Link>
<Link to='/viewnadmission'> Admission (all)</Link>
<Link to='/viewnpassexam'> Pass percentage (all)</Link>
{/* <Link to='/viewnplacement'> Placement (all)</Link> */}
<Link to='/viewnphdfac'> PhD Faculties</Link>
<Link to='/viewnteacherdata'> Fulltime Teachers (2.4.1-2.4.3)</Link>
<Link to='/viewnresult'> No of days for result declaration</Link>
<Link to='/viewnphdface'> Phd Faculties (extended)</Link>

            </>
        )
          
    } else if(instype=='Dual') {
        return (
            <>

<Link to='/viewadmission'> View Student Enrollment for Admission (2.1.1) </Link>
        {/* <Link to='/viewreservecat'> View Student Seats against Reserved Category (2.1.2)</Link> */}
        <Link to='/viewnreservecat'> Reserved category (new)</Link>
        <Link to='/viewcurgap'> Action taken for curriculam gap (NBA)</Link>
        <Link to='/viewteacherdata'> View Full time Teachers (2.4.1-2.4.3)</Link>
        <Link to='/viewresult'> View Result Publications (2.5.1)</Link>
        <Link to='/viewteacheraward'> View Teacher Awards/Recognitions (2.4.4)</Link>
        <Link to='/viewexamautomation'> View Automation for Examination (2.5.4)</Link>
        <Link to='/viewmentees'> View Mentor-Mentee</Link>
        <Link to='/viewnmentees'> Mentor mentees new</Link>
        <Link to='/viewnbaug33result'> Result publication (Unified)</Link>
<Link to='/viewwriterdb'> Content database</Link>
<Link to='/viewnadmission'> Admission (all)</Link>
<Link to='/viewnpassexam'> Pass percentage (all)</Link>
{/* <Link to='/viewnplacement'> Placement (all)</Link> */}
<Link to='/viewnphdfac'> PhD Faculties</Link>
<Link to='/viewnteacherdata'> Fulltime Teachers (2.4.1-2.4.3)</Link>
<Link to='/viewnresult'> No of days for result declaration</Link>
<Link to='/viewnphdface'> Phd Faculties (extended)</Link>
     
            </>
        )
          
    } else {
          return (
              <>

<Link to='/viewadmission'> View Student Enrollment for Admission (2.1.1 A-U-C-D, 2.1.2 HU) </Link>
        {/* <Link to='/viewreservecat'> View Student Seats against Reserved Category (2.1.2 A-U-C-D, 2.1.1 H)</Link> */}
        <Link to='/viewnreservecat'> Reserved category (new)</Link>
        <Link to='/viewcurgap'> Action taken for curriculam gap (NBA)</Link>
        <Link to='/viewteacherdata'> View Full time Teachers (2.4.1-2.4.3 A-D, 2.4.2 U, 2.4.1 C, 2.4.1-2.4.3 H, 2.4.3 HU)</Link>
        <Link to='/viewpassexam'> View Student Pass Percentage (2.6.3 A-U-C, 2.6.2 h-HU)</Link>
        <Link to='/viewresult'> View Result Publications (2.5.1 U-C-D)</Link>
        <Link to='/viewteacheraward'> View Teacher Awards/Recognitions (2.4.4-3.4.2 U, 2.4.5 H, 2.4.5 HU, 2.4.4 D)</Link>
        <Link to='/viewexamautomation'> View Automation for Examination (2.5.4 U-D, 2.5.5 HU)</Link>
        <Link to='/viewmentees'> View Mentor-Mentee (2.3.3 U-C-D, 2.3.4 H-HU)</Link>
        <Link to='/viewnmentees'> Mentor mentees new</Link>
        <Link to='/viewnbaug33result'> Result publication (Unified)</Link>
<Link to='/viewwriterdb'> Content database</Link>
<Link to='/viewnadmission'> Admission (all)</Link>
<Link to='/viewnpassexam'> Pass percentage (all)</Link>
{/* <Link to='/viewnplacement'> Placement (all)</Link> */}
<Link to='/viewnphdfac'> PhD Faculties</Link>
<Link to='/viewnteacherdata'> Fulltime Teachers (2.4.1-2.4.3)</Link>
<Link to='/viewnresult'> No of days for result declaration</Link>
<Link to='/viewnphdface'> Phd Faculties (extended)</Link>

<hr />
<div style={{marginBottom: 10}}><b>Nomenclature</b></div>
A - Affiliated college<br />
U - University<br />
D - Dual Mode University<br />
C- Autonomous institution<br />
H- Health Science institution<br />
HU- Health Science university<br />
            
        
              </>
          )
  
      }
      };

    



    return (
        <div className={classes1.sidebar}>
        <div className={classes1.sidebarinside}>    
        
        <div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />
            {changeHandler()}
        
        <hr />
        <div style={{marginBottom: 10}}><b>Useful links</b></div>
        <Link to='/dashcourse'> Dashboard</Link>
        <Link to='/workload'> Create Class</Link>    
        <Link to='/getmyfeedback'> My feedback</Link>
        <Link to='/proctorlist'> Proctor list</Link>
        <Link to='/examenrlist'> Check exam enrollment</Link>
     

        
        </div>      

</div>
    );

}

export default Sidecr2Navigation;