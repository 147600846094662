import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/SidedocNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const pinameref=useRef();
    const titleref=useRef();
    const journalref=useRef();
    const yopref=useRef();
    const issnref=useRef();
    const articlelinkref=useRef();
    const journallinkref=useRef();
    const ugclistedref=useRef();
    const hindexref=useRef();
    const citationref=useRef();
    const citationindexref=useRef();
    const levelref=useRef();
  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       pinameref.current.value=global1.name;

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        const piname=pinameref.current.value;
        const title=titleref.current.value;
        const journal=journalref.current.value;
        const yop=yopref.current.value;
        const issn=issnref.current.value;
        const articlelink=articlelinkref.current.value;
        const journallink=journallinkref.current.value;
        const ugclisted=ugclistedref.current.value;
        const hindex=hindexref.current.value;
        const citation=citationref.current.value;
        const citationindex=citationindexref.current.value;
        const level=levelref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/createpubbyfac', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: piname,
                title: title,
                journal:journal,
                yop:yop,
                issn:issn,
                articlelink:articlelink,
                journallink:journallink,
                ugclisted:ugclisted,
                status1:'Submitted',
                comments:'',
                department:department,
                hindex:hindex,
                citation:citation,
                citationindex:citationindex,
                level:level

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewpub');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Add publication</p>
                        <hr />
                       

                        Name
                            <br />
                            <input type="text" required style={{width: '100%'}} placeholder="Enter name" id="piname" ref={pinameref} />
                            
                        <br /><br />

                            Title
                            <br />
                            <input type="text" required style={{width: '100%'}} placeholder="Enter title" id="title" ref={titleref} />
                            
                        <br /><br />

                        Journal
                        <br />

                        <input type="text" required style={{width: '100%'}} placeholder="Enter journal" id="journal" ref={journalref} />
                            
                            <br /><br />

                    


                            <label htmlFor='academicyear'>Publication year</label>
                    <select id="academicyear" className="form-control"  ref={yopref}>
                    <option value="2016">2016</option>
                    <option value="2017">2017</option>
                    <option value="2018">2018</option>
                    <option value="2019">2019</option>
                        <option value="2020">2020</option>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                       
                    </select>
                    <br /><br />
                    ISSN 
                    <br />

                    <input type="text" required style={{width: '100%'}} placeholder="Enter issn" id="issn" ref={issnref} />
                            
                            <br /><br />
                            Article link 
                            <br />
                    
                            <input type="text" required style={{width: '100%'}} placeholder="Enter articlelink" id="articlelink" ref={articlelinkref} />
                            
                            <br /><br />
                            Journal link
                            <br />

                            <input type="text" required style={{width: '100%'}} placeholder="Enter journal link" id="journallink" ref={journallinkref} />
                            
                            <br /><br />
                            H-index (type NA is not available)
                            <br />

                            <input type="text" required style={{width: '100%'}} placeholder="Enter hindex" id="hindex" ref={hindexref} />
                            
                            <br /><br />

                            No of citations excluding self citation (type NA if not available)
                            <br />

                            <input type="text" required style={{width: '100%'}} placeholder="Enter citation" id="citation" ref={citationref} />
                            
                            <br /><br />


                            Citation index (type NA if not available)
                            <br />

                            <input type="text" required style={{width: '100%'}} placeholder="Enter citation index" id="citationindex" ref={citationindexref} />
                            
                            <br /><br />

                            Listed in UGC care / Scopus / Web of Science / Pubmed with ID

                            <br /><br />

                            <input type="text" required style={{width: '100%'}} placeholder="Enter listing" id="ugclisted" ref={ugclistedref} />

                            <br /><br />    

                            {/* <label htmlFor='ugclisted'>UGC listed</label>
                    <select id="ugclisted" className="form-control"  ref={ugclistedref}>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                 
                       
                    </select>
                    <br /><br /> */}

                    <label htmlFor='level'>Level</label>
                    <select id="level" className="form-control"  ref={levelref}>
                    <option value="National">National</option>
                    <option value="International">International</option>
                 
                       
                    </select>
                    <br /><br />



               
                  


                      
                        <button  onClick={searchapi}>Add publication</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;