import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faEdit, faComment, faFlag, faCheckCircle, faFileAlt, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { DataGrid } from '@material-ui/data-grid';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import ReportAdmin from '../components/layout/ReportAQAR';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import Spinner from 'react-bootstrap/Spinner';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const linkref=useRef();
    const moduleref=useRef();
    const topicref=useRef();

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState([]);
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());
    const [format, setFormat] = useState(false);

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;
    //const name=global1.name;

    const aqaryear=global1.aqaryear;

    const [isUploading, setIsuploading] = useState(false);

    const columns = [
        // { field: '_id', headerName: 'ID', width: 70 },
        { field: 'student', headerName: 'Student', width: 130 },
        { field: 'regno', headerName: 'Reg no', width: 130 },
      ];

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    const edit = (id, title,  duration, amount, yop, membership) => {
        global1.seminarid=id;
        global1.title=title;
        global1.duration=duration;
        global1.amount=amount;
        global1.yop=yop;
        global1.membership=membership;
        history.replace('/editseminar');
    }

    const addperiod = () => {
        history.replace('/addperiod');
    }

    const addstudents = () => {
        // global1.courseid=courseid;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/addstudents');
    }

    

   

    let tutorials = [];
    const searchApi = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/seminardoccomments', {
            params: {
                user: user,
                token: token,
                colid: colid,
                year: aqaryear
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        //localStorage.setItem("allseminars", JSON.stringify(response.data.data.classes));

       

        
        setResults(response.data.data.classes);
        //setLink(response.data.data.link);
        //alert('format ' + format);
        formattable();
        
       
        //alert(results.length);
       
    };

    function formattable() {
        if (!format) {
            $('#example').DataTable(
                {
                    pagingType: 'full_numbers',
                      pageLength: 5,
                      processing: true,
                      dom: 'Bfrtip',
                          buttons: ['copy', 'csv', 'print'
                          ]
                }
            );
            setFormat(true);

        }
    }

   

    const deleteenrolment = async (enrolmentid) => {
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteseminarbyfac', {
            params: {
                id: enrolmentid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const getlocal = () => {
        let collection=localStorage.getItem("allseminars");
        if(!collection) {

        } else {
            setResults(JSON.parse(collection));

        }
        
    }

    

    useEffect(() => {
        checklogin();
        //search1();
        //getexamlist();
        //getlocal();
        searchApi('');
        //$('#example').DataTable();
        //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable();
    //     },
    //     1000
    //     );
    // });
    
    //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable(
    //         {
    //             pagingType: 'full_numbers',
    //               pageLength: 5,
    //               processing: true,
    //               dom: 'Bfrtip',
    //                   buttons: ['copy', 'csv', 'print'
    //                   ]
    //         }
    //     );
    //     } ,
    //     1000
    //     );
    // });
        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);


    const uploadlink = async (link1, id1, yop1) => {
        link1=link1 + '?criteria=6&Section=6.3.2&Subsection=Seminar&field1=' + id1 + '&Year=' + yop1 + '&userid=' + user; 
        var win = window.open(link1, '_blank');
        win.focus();
      };

      const list1 = async (id1) => {
        var link1=window.location.origin + '/viewsupportingdocquery';
        link1=link1 + '?field1=' + id1 + '&colid=' + colid + '&user=' + user + '&name=' + name + '&token=' + token; 
        var win = window.open(link1, '_blank');
        win.focus();
      };

      const updatestatus = async (status1, id) => {
        const response = await ep1.get('/api/v1/updateseminarcomments', {
            params: {
                user: user,
                token: token,
                colid: colid,
                status1: status1,
                comments: "NA",
                id: id

            }

        });
        setLoading(false);
        alert(response.data.status + ' - ' + status1);
    };

    


    
   

    

    const width=window.innerWidth;
    const mdivwidth=width - 330;

   
       
        //alert('loaded');
  

    


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width: 60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <ReportAdmin />
                    </td>
                   
                    <td> 
                        <TopNavigation />

                    
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
            <p style={{ fontSize: 18 }}>View all seminar/membership reimbursement for 6.3.2 for {aqaryear} from faculties</p>
            <hr />
           
    
        <br />
        {isUploading ? (
				<div style={{ alignItems: 'center', marginTop: 30, marginBottom: 60}}>
                   <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
				</div>
			) : (
                <div></div>
			)} 

<div style={{fontSize: 10}}>
     
<Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
<th>Document ID</th>
<th>User</th>
<th>Year</th>
      <th>Name of teacher</th>
      <th>PAN</th>
      <th>Name of conference/ workshop attended for which financial support provided</th>
      {/* <th>Duration (from – to) (DD-MM-YYYY)</th> */}
      <th>Name of the professional body for which membership fee is provided</th>
      <th>Amount of support</th>
      {/* <th>Year</th>
      <th>Amount</th>
      <th>Status</th>
      <th>Role</th>
      <th>Level</th> */}
      
      <th>Documents</th>
      <th>Comments</th>
      <th>Status</th>
      <th>Action</th>
    </tr>
    </thead>
    <tbody>
    

    {results.map((meetup3) => {
                return (
                    <tr>
                        <td>
                        {meetup3._id}
                        </td>
                        <td>
                        {meetup3.user}
                        </td>
                        <td>
                            {meetup3.yop}
                        </td>
                        <td>
                        {meetup3.name}
                        </td>
                        <td>******</td>
                        <td>
                        {meetup3.title}
                        </td>
                        {/* <td>
                            {meetup3.duration}
                        </td> */}
                        <td>
                            {meetup3.membership}
                        </td>
                        <td>
                            {meetup3.amount}
                        </td>
                        {/* <td>
                            {meetup3.yop}
                        </td>
                        <td>
                            {meetup3.amount}
                        </td>
                        <td>
                            {meetup3.status1}
                        </td>
                        <td>
                            {meetup3.role}
                        </td>
                        <td>
                            {meetup3.level}
                        </td> */}
                        <td>
                        {meetup3.seminars.map((meetup31) => {
                return (
                    <div>
                        
                        {/* {meetup31.link} {meetup31.status1} */}
                        <a href={meetup31.link} target="_blank">{meetup31.link}</a> - {meetup31.status1} 
                        <br /><br />
                      
                        </div>

                    );
                })}
                        </td>
                        <td>
                        {meetup3.allcomments.map((meetup32) => {
                return (
                    <div>
                        
                        {meetup32.comments} 
                        <br />
                      
                        </div>

                    );
                })}
                        </td>
                        <td>
                            {meetup3.status1}
                        </td>
                    
                        <td>
                        {/* {link.map((meetup31) => {
                return (
                    <div>
                          <Button variant="outline-danger" onClick={() => uploadlink(meetup31.viewlink,meetup3._id,meetup3.yop)}>
                        <FontAwesomeIcon icon={faFileAlt} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                            </Button>
                         
                       
                        </div>

                    );
                })} */}
                        {/* <Button variant="outline-danger" onClick={() => deleteenrolment(meetup3._id)}>
                        <FontAwesomeIcon icon={faTrash} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                            </Button> */}
                            {/* <Button variant="outline-danger" onClick={() => edit(meetup3._id,meetup3.title,meetup3.duration,meetup3.amount,meetup3.yop,meetup3.membership)}>
                        <FontAwesomeIcon icon={faEdit} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                            </Button> */}

<Button variant="outline-danger" onClick={() => list1(meetup3._id)}>
                        <FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                        List
                    </Button> 

                    <Button variant="outline-danger" onClick={() => updatestatus('Accepted',meetup3._id)}>
                        <FontAwesomeIcon icon={faCheckCircle} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                        Accept
                    </Button> 

                    <Button variant="outline-danger" onClick={() => updatestatus('Flagged',meetup3._id)}>
                        <FontAwesomeIcon icon={faFlag} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                        Flag
                    </Button> 
                        </td>
                
                    
                
                
                   

                   
                                </tr>
              

                );
            })}
            </tbody>
            </Table>
            </div>

            <br /><br />




        


            

       
        
        </div>
        <FooterNav1 />
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;