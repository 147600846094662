import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function Sideaqar2() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    



    return (
        <div className={classes1.sidebar}>
        <div className={classes1.sidebarinside}>    
        
        <div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />
        <Link to='/viewadmission'> View Student Enrollment for Admission (2.1.1 A, 2.1.2 H, 2.1.1 U) </Link>
        <Link to='/viewreservecat'> View Student Seats against Reserved Category (2.1.2 A, 2,1,2 U, 2.1.1 H )</Link>
        <Link to='/viewteacherdata'> View Full time Teachers (2.4.1-2.4.3 A, 2.4.1-2.4.3 U, 2.4.1 HU, 2.4.1-2.4.3 H )</Link>
        <Link to='/viewteacherguide'> View Faculty as Guides (2.4.2 A)</Link>
        <Link to='/viewpassexam'> View Student Pass Percentage (2.6.3 A, 2.6.3 U, 2.6.2 HU, 2.6.2 H )</Link>
        <Link to='/viewmentees'> View Mentor-Mentee (2.3.3 U, 2.3.3 C, 2.3.4 H, 2.3.4 HU)</Link>
        <Link to='/viewteacheraward'> View Teacher Awards/Recognitions (2.4.4 U, 2.4.5 H, 2.4.5 HU)</Link>
        <Link to='/viewresult'> View Result Publications (2.5.1 U-C)</Link>
        <Link to='/viewexamautomation'> View Automation for Examination (2.5.4 U, 2.5.5 HU )</Link>
        <Link to='/viewphdguide'> View Phd Guides (2.4.2-3.4.4 U, 2.4.2-3.4.2 C, 2.4.2-3.2.2 H, 2.4.2-3.4.4 HU )</Link>
        
       
        

<hr />
<div style={{marginBottom: 10}}><b>Nomenclature</b></div>
A - Affiliated college<br />
U - University<br />
C- Autonomous institution<br />
H- Health Science institution<br />
HU- Health Science university<br />
        
        <hr />
        <div style={{marginBottom: 10}}><b>Useful links</b></div>
        <Link to='/dashcourse'> Dashboard</Link>
        <Link to='/getmyfeedback'> My feedback</Link>  
        
        </div>      

</div>
    );

}

export default Sideaqar2;