import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faEdit, faComment, faCheckCircle, faFlag, faFileAlt, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { DataGrid } from '@material-ui/data-grid';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import ReportAdmin from '../components/layout/Reporteaqar';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import Spinner from 'react-bootstrap/Spinner';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const linkref=useRef();
    const moduleref=useRef();
    const topicref=useRef();

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState([]);
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());
    const [format, setFormat] = useState(false);

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;
    //const name=global1.name;

    const aqaryear=global1.aqaryear;

    const [isUploading, setIsuploading] = useState(false);

    const columns = [
        // { field: '_id', headerName: 'ID', width: 70 },
        { field: 'student', headerName: 'Student', width: 130 },
        { field: 'regno', headerName: 'Reg no', width: 130 },
      ];

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    const edit = (id, project, type, duration, funds, yop, agency) => {
        global1.projectid=id;
        global1.project=project;
        global1.type=type;
        global1.duration=duration;
        global1.funds=funds;
        global1.yop=yop;
        global1.agency=agency;
        history.replace('/editprojects');
    }

    const addperiod = () => {
        history.replace('/addperiod');
    }

    const addstudents = () => {
        // global1.courseid=courseid;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/addstudents');
    }

    

   

    let tutorials = [];
    const searchApi = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/projectlinks', {
            params: {
                user: user,
                token: token,
                colid: colid,
                year: aqaryear,
                status1: 'Flagged'
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        //localStorage.setItem("allprojects", JSON.stringify(response.data.data.classes));

       

        
        setResults(response.data.data.classes);
        //setLink(response.data.data.link);
        //alert('format ' + format);
        formattable();
        
       
        //alert(results.length);
       
    };

    function formattable() {
        if (!format) {
            $('#example').DataTable(
                {
                    pagingType: 'full_numbers',
                      pageLength: 5,
                      processing: true,
                      dom: 'Bfrtip',
                          buttons: ['copy', 'csv', 'print'
                          ]
                }
            );
            setFormat(true);

        }
    }

   

    const deleteenrolment = async (enrolmentid) => {
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteprojectbyfaculty', {
            params: {
                id: enrolmentid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const getlocal = () => {
        let collection=localStorage.getItem("allprojects");
        if(!collection) {

        } else {
            setResults(JSON.parse(collection));

        }
        
    }

    

    useEffect(() => {
        checklogin();
        //search1();
        //getexamlist();
        //getlocal();
        searchApi('');
        //$('#example').DataTable();
        //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable();
    //     },
    //     1000
    //     );
    // });
    
    //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable(
    //         {
    //             pagingType: 'full_numbers',
    //               pageLength: 5,
    //               processing: true,
    //               dom: 'Bfrtip',
    //                   buttons: ['copy', 'csv', 'print'
    //                   ]
    //         }
    //     );
    //     } ,
    //     1000
    //     );
    // });
        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);


    const uploadlink = async (link1, id1, yop1) => {
        link1=link1 + '?criteria=3&Section=3.1.1/3.1.6&Subsection=Project&field1=' + id1 + '&Year=' + yop1 + '&userid=' + user; 
        var win = window.open(link1, '_blank');
        win.focus();
      };


      const list1 = async (id1) => {
        var link1=window.location.origin + '/viewsupportingdocquery';
        link1=link1 + '?field1=' + id1 + '&colid=' + colid + '&user=' + user + '&name=' + name + '&token=' + token; 
        var win = window.open(link1, '_blank');
        win.focus();
      };

      const updatestatus = async (status1, id) => {
        const response = await ep1.get('/api/v1/updateprojectscomments', {
            params: {
                user: user,
                token: token,
                colid: colid,
                status1: status1,
                comments: "NA",
                id: id

            }

        });
        setLoading(false);
        alert(response.data.status + ' - ' + status1);
    };

    

    const audit = (id, project, agency, year, type, funds, duration, department1) => {
        var comments='';   
        if(!project || !agency || !year || !type || !funds || !duration || !department1) {
            comments='All fields are required';
            return comments;
        }
        if(isNaN(funds)) {
            comments=comments + 'Invalid funds entry. Must be a number.';
        }  else {
            if(funds>100) {
                comments=comments + 'Funds must be in INR lakhs. Funds value appears to be too large.'
            }

        }
        
        //comments=comments + ' year ' + year + ' ' + year.indexOf('-');

        if(year.indexOf('-') > -1) {
            const yeararray=year.split('-');
            //comments=comments + ' ' + yeararray[1] + ' ' + isNaN(yeararray[1]);
            if(isNaN(yeararray[1]) || isNaN(yeararray[0])) {
                comments=comments + ' Invalid year. Must be in academic year such as 2017-18.'

            } else {

                if(Number(yeararray[0])<2017 || Number(yeararray[0])> 2022) {
                    comments=comments + ' Data outside assessment period.';
                }
                
                
                var firstyear=Number(yeararray[0].substring(2,4));
                var secondyear=Number(yeararray[1]);
                var diff=secondyear-firstyear;
                //comments=comments + ' firstyear ' + firstyear + ' secondyear ' + secondyear;
                if(diff>1) {
                    comments=comments + ' Invalid format for year of award.'
                } else {
                    comments=comments + 'Award year format ok.';
                }
                var firstfullyear=Number(yeararray[0]);
                if(project.toLowerCase().indexOf('sap')>-1 || project.toLowerCase().indexOf('fist')>-1 || project.toLowerCase().indexOf('dbt')>-1 || project.toLowerCase().indexOf('icssr')>-1 || project.toLowerCase().indexOf('cas')>-1 || project.toLowerCase().indexOf('cpepa')>-1) {
                    if(firstyear==2021) {
                        comments=comments + 'Only latest academic year data required for SAP / FIST / DBT / CAS / ICSSR / CPEPA funding.';
                    }
                }
            }
        } else {
            comments = comments + ' Invalid year. Must be in academic year format.'
        }
        
        var progstatus=' Invalid project name.';

        if(project.toLowerCase().indexOf('sap')>-1 || project.toLowerCase().indexOf('fist')>-1 || project.toLowerCase().indexOf('dbt')>-1 || project.toLowerCase().indexOf('icssr')>-1 || project.toLowerCase().indexOf('cas')>-1 || project.toLowerCase().indexOf('cpepa')>-1) {
            if(project.length > 6 || project.indexOf(' ')>-1) {
                comments=comments + 'Invalid project name. Please mention the funding agency only in case of departmental funding.';
            }
        } else {
            if(project.length < 6 || project.indexOf(' ')<0) {
                comments=comments + 'Invalid project name.';
            }
            if(agency.toLowerCase().indexOf('ugc') > -1 || (funds > 2)) {
                comments=comments + 'Funds sanctioned appears to be incorrect.';
            }
            if(agency.toLowerCase().indexOf('ugc') > -1 && (project.toLowerCase().indexOf('minor') < 0 || agency.toLowerCase().indexOf('major') < 0)) {
                comments=comments + 'Please mention if it is a major research project or a minor research project.';
            }
        }

        if(agency.toLowerCase().indexOf('major')>-1 || agency.toLowerCase().indexOf('minor')>-1 ){
            comments=comments + ' Major / Minor research project should not be mentioned in the agency. It should be mentioned in the project.';
        }
        if(agency.length<3) {
            comments=comments + ' Invalid agency.';
        }
        if(agency.toLowerCase().indexOf('frp')>-1 || project.toLowerCase().indexOf('frp')>-1 || agency.toLowerCase().indexOf('fdp')>-1 || project.toLowerCase().indexOf('fdp')>-1 || agency.toLowerCase().indexOf('refresher')>-1 || project.toLowerCase().indexOf('refresher')>-1) {
            comments=comments + ' Invalid project. This is not a funded research project.';
        }
        if(project.replace(/\s/g, '').toLowerCase().indexOf('startup')>-1 && project.toLowerCase().indexOf('ugc')>-1) {
            comments=comments + 'UGC Start up grant may be specified in agency, not in project name. Mention the project only in the project name.'
        }
        if(project.replace(/\s/g, '').toLowerCase().indexOf('startup')>-1 || project.replace(/\s/g, '').toLowerCase().indexOf('bsr')>-1 || project.replace(/\s/g, '').toLowerCase().indexOf('mid-career')>-1) {
            comments=comments + 'UGC faculty Research Promotion Program grant (Start up/ Mid-career award / BSR) may be specified in agency, not in project name. Mention the project only in the project name.'
        }
       
        if(duration.toLowerCase().indexOf('month')>-1 || duration.toLowerCase().indexOf('year')>-1) {

        } else {
            comments=comments+ ' Please mention month / year in duration.';
        }
        try {
            var matches = duration.match(/(\d+)/);
            if(Number(matches[0])>1) {
                comments=comments + 'Multi year project must be entered multiple times for every year, with funds sanctioned as funds received every year.';
            }

        } catch(err) {
            comments=comments + ' Please enter duration in numeric followed by duration such as 6 months.';

        }
        if(department1.toLowerCase().indexOf('na') > -1 || department1.length<3 || department1.indexOf(' ')>-1 || department1.indexOf('-')>-1) {
            comments=comments + ' Invalid department.'
        }
        
       
        
        return comments;
    }
    
   

    

    const width=window.innerWidth;
    const mdivwidth=width - 330;

   
       
        //alert('loaded');
  

    


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width: 60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <ReportAdmin />
                    </td>
                   
                    <td> 
                        <TopNavigation />

                    
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
            <p style={{ fontSize: 18 }}>View all projects</p>
            <hr />


            <table>
                <tr>
                    <td>
                    <Link to='/viewprojectlinks' className="btn btn-dark">Generate links</Link>
                    </td>
                    {/* <td width="20px"></td>
                    <td>
                    <Link to='/addphdguidebulk' className="btn btn-dark"> Bulk add data</Link>

                    </td> */}
                </tr>
            </table>
           
    
        <br />
        {isUploading ? (
				<div style={{ alignItems: 'center', marginTop: 30, marginBottom: 60}}>
                   <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
				</div>
			) : (
                <div></div>
			)} 
     
<Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>

<th>Name of the Project/ Endowments, Chairs</th>
      <th>Name of the Principal Investigator/Co-investivator</th>
      <th>Department of Principal Investigator</th>
      <th>Year of Award</th>
      
      <th>Amount Sanctioned</th>
      <th>Duration of the project</th>
      <th>Name of the Funding agency </th>
      <th>Type (Government/Non-Government)</th>
      
      
      
      
{/*       
      <th>Audit observations</th>
      <th>Status</th>
      <th>Documents</th>
      <th>Comments</th>
      <th>Action</th> */}
    </tr>
    </thead>
    <tbody>
    

    {results.map((meetup3) => {
                return (
                    <tr>
                         
                        <td>
                        {meetup3.project}
                        </td>
                        <td>
                        {meetup3.name}
                        </td>
                        <td>
                            {meetup3.department}
                        </td>
                        <td>
                            {meetup3.yop}
                        </td>
                        <td>
                            {meetup3.funds}
                        </td>
                        <td>
                            {meetup3.duration}
                        </td>
                        <td>
                            {meetup3.agency}
                        </td>
                        <td>
                            {meetup3.type}
                        </td>
                        
                        
                        
                     
                      
                        {/* <td>
                            {audit(meetup3._id, meetup3.project, meetup3.agency, meetup3.yop, meetup3.type, meetup3.funds, meetup3.duration, meetup3.department)}
                        </td> */}
                     
                        {/* <td>
                            {meetup3.status1}
                        </td> */}
                        {/* <td>
                        {meetup3.seminars.map((meetup31) => {
                return (
                    <div>
                        
                        
                        <a href={meetup31.link} target="_blank">{meetup31.link}</a> - {meetup31.status1} 
                        <br />
                      
                        </div>

                    );
                })}
                        </td> */}

                        {/* <td>
                        {meetup3.allcomments.map((meetup32) => {
                return (
                    <div>
                        
                        {meetup32.comments} 
                        <br />
                      
                        </div>

                    );
                })}
                        </td> */}

                        {/* <td>
                            
                   
                   
                    <Button variant="outline-danger" onClick={() => list1(meetup3._id)}>
                        <FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                        List
                    </Button> 

                    <Button variant="outline-danger" onClick={() => updatestatus('Accepted',meetup3._id)}>
                        <FontAwesomeIcon icon={faCheckCircle} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                        Accept
                    </Button> 

                    <Button variant="outline-danger" onClick={() => updatestatus('Flagged',meetup3._id)}>
                        <FontAwesomeIcon icon={faFlag} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                        Flag
                    </Button> 


                        </td> */}
                
                    
                
                
                   

                   
                                </tr>
              

                );
            })}
            </tbody>
            </Table>

            <br /><br />




        


            

       
        
        </div>
        <FooterNav1 />
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;