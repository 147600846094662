import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function SidefacNavigation() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    



    return (
        <div className={classes1.sidebar}>
            <div className={classes1.sidebarinside}>

<div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />
            
        
        {/* <div style={{marginBottom: 10}}><b>Insights</b></div> */}
        <Link to='/viewbosdoccomments'> Membership (1.1.3 A)</Link>
        <Link to='/viewcbcsdoc'> CBCS Status (1.2.1 A)</Link>
        <Link to='/viewsyllabusrevdocs'> Syllabus Revision and CBCS (1.1.2-1.2.2 U)</Link>
        <Link to='/viewemployabilitydocs'> Employability and New Courses (1.1.3-1.2.1 U)</Link>
        <Link to='/viewaddonccomments'> Add on courses (1.3.2 U)</Link>
        <Link to='/viewexplearningdocs'> Experiential Learning (1.3.4 U)</Link>
        <Link to='/viewexplearningprojdoc'> Experiential Learning Projects (1.3.2 A)</Link>
        <Link to='/viewadmissiondoccomments'> Admission Data (2.1.1 U)</Link>
        <Link to='/viewreservecatdoccomments'> Admission Reserved Category (2.1.2 U)</Link>
        <Link to='/viewteacherawarddoccomments'> Teacher Award (2.4.4 - 3.4.2 U)</Link>
        <Link to='/viewteacherdatadoc'> Teacher Data (2.4.1)</Link>
        <Link to='/viewpassexamdocs'> Pass percentage</Link>
        <Link to='/viewseedmdoccomments'> Seed money (3.1.2 U)</Link>
        <Link to='/viewteacherfellowdoccomments'> Teacher fellowship (3.1.3 U)</Link>
        <Link to='/viewresearchfellowdocs'> Research fellow</Link>
        <Link to='/viewprojectyrdocs'> Projects doc (3.1.6 U)</Link>
        <Link to='/viewinnovationdoccoments'> Innovation Awards (3.3.3 U)</Link>
        <Link to='/viewphdguidedoccomments'> Ph. D. Guide and Scholars</Link>
        <Link to='/viewallpatentdocs'> Patents doc (3.4.3 U)</Link>
        <Link to='/viewallpubdocs'> Publications (3.4.5 U)</Link>
        <Link to='/viewallpubdoccomments'> Publications comments(3.4.5 U)</Link>
        <Link to='/viewallpubdoccomcy'> Publications Calendar Year(3.4.5 U)</Link>
        <Link to='/viewallbookdoccomments'> Books(3.4.6 U)</Link>
        <Link to='/viewcollabdoccomments'> Collaborations (3.7.1 U)</Link>
        <Link to='/viewmoudoccomments'> MoU (3.7.1 U)</Link>
        <Link to='/viewictdoccomments'> ICT (4.3.1 U)</Link>
        <Link to='/viewscholarshipdoccomments'> Scholarships (5.1.1 U)</Link>
        <Link to='/viewcareerdoccomments'> Career counseling (5.1.2 U)</Link>
        <Link to='/viewskilldevdoccomments'> Skill development (5.1.3 U)</Link>
        <Link to='/viewplacementdoccomments'> Placement (5.2.2 U)</Link>
        <Link to='/viewallsemreimbdocs'> Reimbursement (6.3.2 U)</Link>
        <Link to='/viewallseminardocs'> Seminar Participation (6.3.4 U)</Link>
        <Link to='/vieweventdoccomments'> All events</Link>
        
        {/* <Link to='/viewplacementall'> All placement</Link> */}
        
        {/* <Link to='/addsupportingdoc'> Add supporting doc</Link> */}
       

        <hr />
        {/* <div style={{marginBottom: 10}}><b>Useful links</b></div> */}
        <Link to='/dashcourse'> Dashboard</Link>
        <Link to='/workload'> Create Class</Link>
        
        <Link to='/getmyfeedback'> My feedback</Link>
        <Link to='/proctorlist'> Proctor list</Link>
        <Link to='/examenrlist'> Check exam enrollment</Link>
     

        
       </div>

</div>
    );

}

export default SidefacNavigation;