import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

import Table from 'react-bootstrap/Table';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 

import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/ReportAdmin';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const passwordref=useRef();
    const password1ref=useRef();
    const departmentref=useRef();
    const nameref=useRef();
  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [faculties, setFaculties] = useState([]);
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const [results, setResults] = useState([]);
    const [format, setFormat] = useState(false);
    // const department=global1.department;
    const department1=global1.department;
    const [seminar, setSeminar] = useState([]);
    const [books, setBooks] = useState([]);
    const [publications, setPub] = useState([]);
    const [patents, setPatents] = useState([]);
    const [projects, setProjects] = useState([]);
    const [admission, setAdmission] = useState([]);
    const [addoncourse, setAddoncourse] = useState([]);
    const [alumnicon, setAlumnicon] = useState([]);
    const [awards, setAwards] = useState([]);
    const [bos, setBos] = useState([]);
    const [careercounsel, setCareercounsel] = useState([]);
    const [cbcs, setCbcs] = useState([]);
    const [collaboration, setCollaboration] = useState([]);
    const [consultancy, setConsultancy] = useState([]);
    const [econtent, setEcontent] = useState([]);
    const [egovern, setEgovern] = useState([]);
    const [employability, setEmployability] = useState([]);
    const [event, setEvent] = useState([]);
    const [examautomation, setExamautomation] = useState([]);
    const [expenditure, setExpenditure] = useState([]);
    const [explearning, setExplearning] = useState([]);
    const [extawards, setExtawards] = useState([]);
    const [extensionact, setExtensionact] = useState([]);
    const [higheredu, setHigheredu] = useState([]);
    const [higherexam, setHigherexam] = useState([]);
    const [ict, setIct] = useState([]);
    const [innovation, setInnovation] = useState([]);
    // const [instawards, setInstawards] = useState([]);
    const [libraryexp, setLibraryexp] = useState([]);
    const [mentees, setMentees] = useState([]);
    const [mou, setMou] = useState([]);
    const [passexam, setPassexam] = useState([]);
    const [phdguide, setPhdguide] = useState([]);
    const [placement, setPlacement] = useState([]);
    const [quality, setQuality] = useState([]);
    const [researchfellow, setResearchfellow] = useState([]);
    const [reservecat, setReservecat] = useState([]);
    const [result, setResult] = useState([]);
    const [scholarship, setScholarship] = useState([]);
    const [seedm, setSeedm] = useState([]);
    const [skilldev, setSkilldev] = useState([]);
    const [syllabusrev, setSyllabusrev] = useState([]);
    const [teacheraward, setTeacheraward] = useState([]);
    const [teacherdata, setTeacherdata] = useState([]);
    const [teacherfellow, setTeacherfellow] = useState([]);
    const [teacherfs, setTeacherfs] = useState([]);

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }

    const getfacultylist = async () => {
        //setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getalldepartments', {
            params: {
                colid: colid,
                user: user,
                token: token

            }

        });
        //setLoading(false);
        console.log(response.data.data);
        setFaculties(response.data.data.classes);
       
        //alert(results.length);
       
    };


    useEffect(() => {
        // departmentref.current.value=department;
        getfacultylist();

       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    // const searchapi = async () => {
    //     const department1=departmentref.current.value;
    //     global1.seldepartment=department1;  
    //     history.replace('/viewdeppub');
       
    // };

    const deptseminar = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depsemlist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setSeminar(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptbooks = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depbookslist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setBooks(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptprojects = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depprojectslist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setProjects(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptpub = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/deppublist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setPub(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptpatents = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/deppatentslist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setPatents(response.data.data.classes);
       
        //alert(results.length);
       
    };
    

    const deptaddoncourse = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depaddoncourselist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setAddoncourse(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptadmission = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depadmissionlist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setAdmission(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptalumnicon = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depalumniconlist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setAlumnicon(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptawards = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depawardslist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setAwards(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptbos = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depboslist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setBos(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptcareercounsel = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depcareercounsellist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setCareercounsel(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptcbcs = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depcbcslist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setCbcs(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptcollaboration = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depcollaborationlist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setCollaboration(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptconsultancy = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depconsultancylist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setConsultancy(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptecontent = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depecontentlist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setEcontent(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptegovern = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depegovernlist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setEgovern(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptemployability = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depemployabilitylist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setEmployability(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptevent = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depeventlist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setEvent(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptexamautomation = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depexamautomationlist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setExamautomation(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptexpenditure = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depexpenditurelist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setExpenditure(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptexplearning = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depexplearninglist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setExplearning(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptextensionact = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depextensionactlist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setExtensionact(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptextawards = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depextawardslist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setExtawards(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const depthigheredu = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/dephigheredulist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setHigheredu(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const depthigherexam = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/dephigherexamlist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setHigherexam(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptict = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depictlist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setIct(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptinnovation = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depinnovationlist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setInnovation(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptlibraryexp = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/deplibraryexplist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setLibraryexp(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptmentees = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depmenteeslist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setMentees(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptmou = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depmoulist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setMou(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptpassexam = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/deppassexamlist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setPassexam(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptphdguide = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depphdguidelist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setPhdguide(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptplacement = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depplacementlist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setPlacement(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptquality = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depqualitylist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setQuality(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptresearchfellow = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depresearchfellowlist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setResearchfellow(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptreservecat = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depreservecatlist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setReservecat(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptresult = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depresultlist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setResult(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptscholarship = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depscholarshiplist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setScholarship(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptseedm = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depseedmlist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setSeedm(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptskilldev = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depskilldevlist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setSkilldev(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptsyllabusrev = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depsyllabusrevlist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setSyllabusrev(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptteacheraward = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depteacherawardlist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setTeacheraward(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptteacherdata = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depteacherdatalist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setTeacherdata(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptteacherfellow = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depteacherfellowlist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setTeacherfellow(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const deptteacherfs = async (department1) => {
        // setLoading(true);
        setTerm(user);
        const response = await ep1.get('/api/v1/depteacherfslist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                department: department1

            }

        });
        // setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setTeacherfs(response.data.data.classes);
       
        //alert(results.length);
       
    };

    
    
   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };

          

          const getval1=(event)=> {
      
            //alert(event.target.value);
            deptseminar(event.target.value);
            deptbooks(event.target.value);
            deptprojects(event.target.value);
            deptpub(event.target.value);
            deptpatents(event.target.value);
            deptadmission(event.target.value);
            deptaddoncourse(event.target.value);
            deptalumnicon(event.target.value);
            deptawards(event.target.value);
            deptbos(event.target.value);
            deptcareercounsel(event.target.value);
            deptcbcs(event.target.value);
            deptcollaboration(event.target.value);
            deptconsultancy(event.target.value);
            deptecontent(event.target.value);
            deptegovern(event.target.value);
            deptemployability(event.target.value);
            deptevent(event.target.value);
            deptexamautomation(event.target.value);
            deptexpenditure(event.target.value);
            deptexplearning(event.target.value);
            deptextawards(event.target.value);
            deptextensionact(event.target.value);
            depthigherexam(event.target.value);
            depthigheredu(event.target.value);
            deptict(event.target.value);
            deptinnovation(event.target.value);
            deptlibraryexp(event.target.value);
            deptmentees(event.target.value);
            deptmou(event.target.value);
            deptpassexam(event.target.value);
            deptphdguide(event.target.value);
            deptplacement(event.target.value);
            deptquality(event.target.value);
            deptresearchfellow(event.target.value);
            deptreservecat(event.target.value);
            deptresult(event.target.value);
            deptscholarship(event.target.value);
            deptseedm(event.target.value);
            deptskilldev(event.target.value);
            deptsyllabusrev(event.target.value);
            deptteacheraward(event.target.value);
            deptteacherdata(event.target.value);
            deptteacherfellow(event.target.value);
            deptteacherfs(event.target.value);

        }

    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 20 }}> Departmental Report</p>
                        <hr />

                       


<label htmlFor='affiliated'>Select department</label>
                    <select id="affiliated" className="form-control"  onChange={getval1}  ref={departmentref}>
                    <option value="1">Select department</option>

    {faculties.map((meetup3) => {
                return (
                    <option value={meetup3.department}>{meetup3.department}</option>
 
                );
            })}
                  
                    </select>
                    <br />
 
                    
<h5> Seminar </h5>                  
                    
<Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
     
      <th>User email</th>
      <th>Name</th>
      <th>Details</th>
      
    </tr>
    </thead>
    <tbody>
    

    {seminar.map((meetup3) => {
                return (
                    <tr>
                        <td>
                            {meetup3.email} 
                        </td>
                        <td>
                            {meetup3.name}
                        </td>
                        <td>
                            {meetup3.seminars.map((meetup31) => {
                return (
                    <div>
                        
                        {meetup31.title} 
                        <br />Academic year {meetup31.yop}  <br />Duration {meetup31.duration}<br />
                        Membership {meetup31.membership} <br />Reimbursement {meetup31.amount} <br /><br /><br />
                        </div>

                    );
                })}
                        </td>
                       
                
                    
                
                
                   

                   
                                </tr>
              

                );
            })}
            </tbody>
            </Table>
 
            <br />
            <br />

<h5> Books </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {books.map((meetup4) => {
                return (
                    <tr>
                        <td>
                            {meetup4.email} 
                        </td>
                        <td>
                            {meetup4.name}
                        </td>
                        <td>
                            {meetup4.books.map((meetup41) => {
                return (
                    <div>
                        
                        Book title {meetup41.booktitle} <br /> Paper title {meetup41.papertitle} 
                        <br />Academic year {meetup41.yop}  <br />Proceeding {meetup41.proceeding}<br />
                        ISSN/ISBN {meetup41.issn} <br />Publisher {meetup41.publisher} <br />Affiliated {meetup41.affiliated}<br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Projects </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {projects.map((meetup5) => {
                return (
                    <tr>
                        <td>
                            {meetup5.email} 
                        </td>
                        <td>
                            {meetup5.name}
                        </td>
                        <td>
                        {meetup5.projects.map((meetup51) => {
                return (
                    <div>
                        
                        {meetup51.project} 
                        <br />Academic year {meetup51.yop}  <br />Agency {meetup51.agency}<br />
                        Type {meetup51.type} <br />Funds {meetup51.funds} <br />Duration {meetup51.duration}<br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Publications </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {publications.map((meetup6) => {
                return (
                    <tr>
                        <td>
                            {meetup6.email} 
                        </td>
                        <td>
                            {meetup6.name}
                        </td>
                        <td>
                        {meetup6.publications.map((meetup61) => {
                return (
                    <div>
                        
                        {meetup61.title} 
                        <br />Academic year {meetup61.yop} <br />Journal link {meetup61.journallink} <br />Article link {meetup61.ariclelink}<br />
                        <br />Journal {meetup61.journal} <br />ISSN {meetup61.issn} <br />UGC Listed {meetup61.ugclisted}<br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Add-on Course </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {addoncourse.map((meetup7) => {
                return (
                    <tr>
                        <td>
                            {meetup7.email} 
                        </td>
                        <td>
                            {meetup7.name}
                        </td>
                        <td>
                            {meetup7.addoncourse.map((meetup71) => {
                return (
                    <div>
                        
                        Course title {meetup71.coursetitle} <br /> Course code {meetup71.coursecode} 
                        <br />Year of offering {meetup71.year}  <br />No of times offered {meetup71.offeredtimes}<br />
                        Duration {meetup71.duration} <br />Students enrolled {meetup71.studentsenrolled} <br />Students completed {meetup71.studentscompleted}<br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Admission </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {admission.map((meetup8) => {
                return (
                    <tr>
                        <td>
                            {meetup8.email} 
                        </td>
                        <td>
                            {meetup8.name}
                        </td>
                        <td>
                            {meetup8.admission.map((meetup81) => {
                return (
                    <div>
                        
                        Program code {meetup81.programcode} <br /> Program name {meetup81.programname} 
                        <br />Academic year {meetup81.year} <br />No. of sanctioned seats {meetup81.sancseat}
                        <br />No. of eligible applications received {meetup81.studapply} <br />No. of students admitted {meetup81.studadmt}<br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Alumni Contribution </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {alumnicon.map((meetup9) => {
                return (
                    <tr>
                        <td>
                            {meetup9.email} 
                        </td>
                        <td>
                            {meetup9.name}
                        </td>
                        <td>
                            {meetup9.alumnicon.map((meetup91) => {
                return (
                    <div>
                        
                        Department {meetup91.department} <br />Academic year {meetup91.year}
                        <br />Amount(INR) {meetup91.amount}<br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Student Awards/Medals </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {awards.map((meetup10) => {
                return (
                    <tr>
                        <td>
                            {meetup10.email} 
                        </td>
                        <td>
                            {meetup10.name}
                        </td>
                        <td>
                            {meetup10.awards.map((meetup101) => {
                return (
                    <div>
                        
                        Student Name {meetup101.studentname} <br /> Name of the award/medal {meetup101.awardname} 
                        <br />Academic year {meetup101.year}  <br />Type of Participation {meetup101.engagementtype}<br />
                        Type of level {meetup101.level} <br />Type of activity {meetup101.activitytype}<br /> <br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> BOS </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {bos.map((meetup11) => {
                return (
                    <tr>
                        <td>
                            {meetup11.email} 
                        </td>
                        <td>
                            {meetup11.name}
                        </td>
                        <td>
                            {meetup11.bos.map((meetup111) => {
                return (
                    <div>
                        
                        Academic body {meetup111.academicbody} <br /> Name of Faculty {meetup111.fname} 
                        <br />Academic year {meetup111.year}<br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Career Counselling Activities </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {careercounsel.map((meetup12) => {
                return (
                    <tr>
                        <td>
                            {meetup12.email} 
                        </td>
                        <td>
                            {meetup12.name}
                        </td>
                        <td>
                            {meetup12.careercounsel.map((meetup121) => {
                return (
                    <div>
                        
                        Name of Activity {meetup121.activityname} 
                        <br />Academic year {meetup121.year}  <br />Number of students attended/participated {meetup121.studattd}<br />
                        Number of students placed through campus placement {meetup121.studplaced} <br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> CBCS </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {cbcs.map((meetup13) => {
                return (
                    <tr>
                        <td>
                            {meetup13.email} 
                        </td>
                        <td>
                            {meetup13.name}
                        </td>
                        <td>
                            {meetup13.cbcs.map((meetup131) => {
                return (
                    <div>
                        
                        Program code {meetup131.programcode} <br /> Program name {meetup131.programname} 
                        <br />Year of Introduction {meetup131.yearofintro}  <br />Status of CBCS Introduction {meetup131.statusofimplement}<br />
                        Year of CBCS Implementation {meetup131.yearofimplement} <br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Collaboration Activities </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {collaboration.map((meetup14) => {
                return (
                    <tr>
                        <td>
                            {meetup14.email} 
                        </td>
                        <td>
                            {meetup14.name}
                        </td>
                        <td>
                            {meetup14.collaboration.map((meetup141) => {
                return (
                    <div>
                        
                        Activity title {meetup141.title} <br /> Collaborative Agency {meetup141.agency} 
                        <br />Name of Participant {meetup141.participantname}  <br />Year of Collaboration {meetup141.year}<br />
                        Duration {meetup141.duration} <br />Nature of Activity {meetup141.activitynature} <br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> E-content developed  by Faculty </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {econtent.map((meetup15) => {
                return (
                    <tr>
                        <td>
                            {meetup15.email} 
                        </td>
                        <td>
                            {meetup15.name}
                        </td>
                        <td>
                            {meetup15.econtent.map((meetup151) => {
                return (
                    <div>
                        
                        Name of Faculty {meetup151.fname} <br /> Module developed {meetup151.module} 
                        <br />Academic year {meetup151.year}  <br />Platform {meetup151.platform}<br />
                        Date {meetup151.date} <br />Facility used {meetup151.facility} <br />Document link {meetup151.doclink}<br />Video link {meetup151.videolink}<br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> E-governance Areas </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {egovern.map((meetup16) => {
                return (
                    <tr>
                        <td>
                            {meetup16.email} 
                        </td>
                        <td>
                            {meetup16.name}
                        </td>
                        <td>
                            {meetup16.egovern.map((meetup161) => {
                return (
                    <div>
                        
                        Areas of egovernance {meetup161.egovernareas} <br /> <br />Year of Implementation {meetup161.yearofimplement} 
                          <br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Courses Focus on Employability </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {employability.map((meetup17) => {
                return (
                    <tr>
                        <td>
                            {meetup17.email} 
                        </td>
                        <td>
                            {meetup17.name}
                        </td>
                        <td>
                            {meetup17.employability.map((meetup171) => {
                return (
                    <div>
                        
                        Course name{meetup171.coursename} <br /> Course code {meetup171.coursecode} 
                        <br />Academic year {meetup171.year}  <br />Employability activities {meetup171.activity}<br />
                        <br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> All Events </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {event.map((meetup18) => {
                return (
                    <tr>
                        <td>
                            {meetup18.email} 
                        </td>
                        <td>
                            {meetup18.name}
                        </td>
                        <td>
                            {meetup18.event.map((meetup181) => {
                return (
                    <div>
                        
                        Name of Event {meetup181.eventname} <br /> Event Description {meetup181.description} 
                        <br />Academic year {meetup181.year}  <br />Department {meetup181.department}<br />
                        Brochure Link {meetup181.brochurelink} <br />Event date {meetup181.date} <br />
                        Event time {meetup181.time}<br />Event Co-ordinator {meetup181.coordinator} <br />
                        Event Type {meetup181.type}<br /> EEvent Link {meetup181.eventlink}<br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Exam Automation </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {examautomation.map((meetup19) => {
                return (
                    <tr>
                        <td>
                            {meetup19.email} 
                        </td>
                        <td>
                            {meetup19.name}
                        </td>
                        <td>
                            {meetup19.examautomation.map((meetup191) => {
                return (
                    <div>
                        
                        Type of Automation {meetup191.type} <br /> Status of Automation {meetup191.status} 
                        <br />Academic year {meetup191.year}<br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Budget/Expenditures </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {expenditure.map((meetup20) => {
                return (
                    <tr>
                        <td>
                            {meetup20.email} 
                        </td>
                        <td>
                            {meetup20.name}
                        </td>
                        <td>
                            {meetup20.expenditure.map((meetup201) => {
                return (
                    <div>
                        
                        Budget allocated for Infrastructure Augmentation(INR in Lakhs) {meetup201.budget} <br /> Expenditure for Infrastructure Augmentation(INR in Lakhs) {meetup201.infraexp} 
                        <br />Academic year {meetup201.year}  <br />Total Expenditure excluding salary(INR in Lakhs) {meetup201.totalexp}<br />
                        Expenditure on  maintenance of academic facilities(INR in Lakhs) {meetup201.academicexp} <br />Expenditure on  maintenance of physical facilities(INR in Lakhs) {meetup201.physicalexp}<br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Experential Learning </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {explearning.map((meetup21) => {
                return (
                    <tr>
                        <td>
                            {meetup21.email} 
                        </td>
                        <td>
                            {meetup21.name}
                        </td>
                        <td>
                            {meetup21.explearning.map((meetup211) => {
                return (
                    <div>
                        
                        Program name {meetup211.programname} <br /> Program code {meetup211.programcode} 
                        <br />Academic year {meetup211.year}  <br />Department {meetup211.department}<br />
                        Type {meetup211.type}<br />Activities {meetup211.activity} <br />
                        Student {meetup211.sname} <br />Reg no {meetup211.regno}<br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Institutional Awards </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {extawards.map((meetup22) => {
                return (
                    <tr>
                        <td>
                            {meetup22.email} 
                        </td>
                        <td>
                            {meetup22.name}
                        </td>
                        <td>
                            {meetup22.extawards.map((meetup221) => {
                return (
                    <div>
                        
                        Award title {meetup221.awardname} <br /> Activity title {meetup221.activity} 
                        <br />Year of award {meetup221.year}  <br />Name of the Awarding government/ government recognised bodies {meetup221.awardbody}<br />
                        <br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Extension Activities </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {extensionact.map((meetup23) => {
                return (
                    <tr>
                        <td>
                            {meetup23.email} 
                        </td>
                        <td>
                            {meetup23.name}
                        </td>
                        <td>
                            {meetup23.extensionact.map((meetup231) => {
                return (
                    <div>
                        
                        Activity title {meetup231.activityname} <br /> Organising Unit/Agency {meetup231.orgunit} 
                        <br />Academic year {meetup231.year}  <br />Name of Scheme {meetup231.scheme}<br />
                        No. of Students Participation {meetup231.noofstud} <br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Student Progression to Higher Education </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {higheredu.map((meetup24) => {
                return (
                    <tr>
                        <td>
                            {meetup24.email} 
                        </td>
                        <td>
                            {meetup24.name}
                        </td>
                        <td>
                            {meetup24.higheredu.map((meetup241) => {
                return (
                    <div>
                        
                        Student Name {meetup241.studentname} <br /> Program graduating from {meetup241.programgrad} 
                        <br />Academic year {meetup241.year}  <br />Institution Name {meetup241.institution}<br />
                        Program admitted to {meetup241.programadm}<br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Students Qualifying in Higher Examinations </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {higherexam.map((meetup25) => {
                return (
                    <tr>
                        <td>
                            {meetup25.email} 
                        </td>
                        <td>
                            {meetup25.name}
                        </td>
                        <td>
                            {meetup25.higherexam.map((meetup251) => {
                return (
                    <div>
                        
                        Academic year {meetup251.year}  <br />Roll/Registration Number {meetup251.regno} <br /> 
                        Name of Student {meetup251.studentname} <br />Examination Title {meetup251.examname}<br />
                        <br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> ICT </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {ict.map((meetup26) => {
                return (
                    <tr>
                        <td>
                            {meetup26.email} 
                        </td>
                        <td>
                            {meetup26.name}
                        </td>
                        <td>
                            {meetup26.ict.map((meetup261) => {
                return (
                    <div>
                        
                        Type of Facility {meetup261.facitype} <br /> Room number/Name of Classroom {meetup261.classroom} 
                        <br />Room number/Name of Seminar Hall {meetup261.seminarhall}<br />Link to geo tagged photos {meetup261.link}  <br />
                        <br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Innovation Awards </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {innovation.map((meetup27) => {
                return (
                    <tr>
                        <td>
                            {meetup27.email} 
                        </td>
                        <td>
                            {meetup27.name}
                        </td>
                        <td>
                            {meetup27.innovation.map((meetup271) => {
                return (
                    <div>
                        
                        Award title {meetup271.title} <br /> Awardee {meetup271.awardee} 
                        <br />Academic year {meetup271.year}  <br />Agency {meetup271.agency}<br />
                        Category {meetup271.category} <br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Library Expenditures </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {libraryexp.map((meetup28) => {
                return (
                    <tr>
                        <td>
                            {meetup28.email} 
                        </td>
                        <td>
                            {meetup28.name}
                        </td>
                        <td>
                            {meetup28.libraryexp.map((meetup281) => {
                return (
                    <div>
                        
                        Library resource {meetup281.resource} <br />Details of Memberships/Subscriptions {meetup281.details} 
                        <br />Academic year {meetup281.year}  <br />Expenditure on subscription to e-journals/e-books(INR in Lakhs) {meetup281.bookexp}<br />
                        Expenditure on subscription to other e-resources(INR in Lakhs) {meetup281.otherexp} <br />Total Library Expenditure(INR in Lakhs) {meetup281.totalexp} <br />Document Link {meetup281.link}<br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Mentor-Mentee </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {mentees.map((meetup29) => {
                return (
                    <tr>
                        <td>
                            {meetup29.email} 
                        </td>
                        <td>
                            {meetup29.name}
                        </td>
                        <td>
                            {meetup29.mentees.map((meetup291) => {
                return (
                    <div>
                        
                        Department {meetup291.department} <br /> Academic year {meetup291.year} 
                        <br />Mentor name {meetup291.mentor} <br />No. of mentee {meetup291.noofmentee}<br />
                        <br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> MOU </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {mou.map((meetup30) => {
                return (
                    <tr>
                        <td>
                            {meetup30.email} 
                        </td>
                        <td>
                            {meetup30.name}
                        </td>
                        <td>
                            {meetup30.mou.map((meetup301) => {
                return (
                    <div>
                        
                        Name of body with whom MOU is signed {meetup301.bodyname} <br /> Type of the body {meetup301.bodytype} 
                        <br />Year of signing MoU {meetup301.year}  <br />Duration {meetup301.duration}<br />
                        Name of activity under the MoU {meetup301.activity} <br />Web Link {meetup301.link}<br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Students Pass Percentage </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {passexam.map((meetup31) => {
                return (
                    <tr>
                        <td>
                            {meetup31.email} 
                        </td>
                        <td>
                            {meetup31.name}
                        </td>
                        <td>
                            {meetup31.passexam.map((meetup311) => {
                return (
                    <div>
                        
                        Program name {meetup311.programname} <br /> Program code {meetup311.programcode} 
                        <br />Academic year {meetup311.year}  <br />No. of students appeared for final exam {meetup311.studappear}<br />
                        No. of students passed the final exam {meetup311.studpass}<br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Phd Guide </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {phdguide.map((meetup32) => {
                return (
                    <tr>
                        <td>
                            {meetup32.email} 
                        </td>
                        <td>
                            {meetup32.name}
                        </td>
                        <td>
                            {meetup32.phdguide.map((meetup321) => {
                return (
                    <div>
                        
                        Theisis title {meetup321.title} <br /> Duration {meetup321.duration} 
                        <br />Academic year {meetup321.year}  <br />Reimbursement {meetup321.amount}<br />
                        Membership {meetup321.membership}<br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Placement </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {placement.map((meetup33) => {
                return (
                    <tr>
                        <td>
                            {meetup33.email} 
                        </td>
                        <td>
                            {meetup33.name}
                        </td>
                        <td>
                            {meetup33.placement.map((meetup331) => {
                return (
                    <div>
                        
                        Student name {meetup331.studentname} <br /> Program graduated from {meetup331.programname} 
                        <br />Academic year {meetup331.year}  <br />Employer Name {meetup331.employername}<br />
                        Employer Contact Details {meetup331.empcontactdetails} <br />Pay package at appointment(in INR/annum) {meetup331.salary}<br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Quality Initiatives </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {quality.map((meetup34) => {
                return (
                    <tr>
                        <td>
                            {meetup34.email} 
                        </td>
                        <td>
                            {meetup34.name}
                        </td>
                        <td>
                            {meetup34.quality.map((meetup341) => {
                return (
                    <div>
                        
                        Type of Quality Initiatives {meetup341.type} <br /> Follow up Action {meetup341.action} 
                        <br />Academic year {meetup341.year}  <br />Name of Collaborating Institution {meetup341.instname}<br />
                        Name of Collaborative Activity{meetup341.activity} <br />Start date {meetup341.startdate} <br />
                        End date {meetup341.enddate}<br />Status of Participation in NIRF {meetup341.partstatus}<br />Name of any other quality audit {meetup341.other}<br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Research Fellowships </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {researchfellow.map((meetup35) => {
                return (
                    <tr>
                        <td>
                            {meetup35.email} 
                        </td>
                        <td>
                            {meetup35.name}
                        </td>
                        <td>
                            {meetup35.researchfellow.map((meetup351) => {
                return (
                    <div>
                        
                        Name of Research fellow {meetup351.fellowname} <br /> Duration {meetup351.duration} 
                        <br />Academic year {meetup351.year}  <br />Name of Agency {meetup351.agency}<br />
                        Type of Fellowship {meetup351.type} <br />Name of Qualifying Exam {meetup351.exam}<br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Reserved Category Seats </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {reservecat.map((meetup36) => {
                return (
                    <tr>
                        <td>
                            {meetup36.email} 
                        </td>
                        <td>
                            {meetup36.name}
                        </td>
                        <td>
                            {meetup36.reservecat.map((meetup361) => {
                return (
                    <div>
                        
                        Reserved category {meetup361.category} <br />Academic year {meetup361.yop}
                        <br /> No. of sanctioned seats {meetup361.sancseat} <br />No. of students admitted {meetup361.studadmt}<br />
                        <br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Result Publications </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {result.map((meetup37) => {
                return (
                    <tr>
                        <td>
                            {meetup37.email} 
                        </td>
                        <td>
                            {meetup37.name}
                        </td>
                        <td>
                            {meetup37.result.map((meetup371) => {
                return (
                    <div>
                        
                        Program code {meetup371.programcode} <br /> Program name {meetup371.programname} 
                        <br />Academic year {meetup371.year}  <br />Semester {meetup371.semester}<br />
                        Last date of semester {meetup371.lastdate} <br />Result declaration date {meetup371.resultdate}<br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Student Scholarships </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {scholarship.map((meetup38) => {
                return (
                    <tr>
                        <td>
                            {meetup38.email} 
                        </td>
                        <td>
                            {meetup38.name}
                        </td>
                        <td>
                            {meetup38.scholarship.map((meetup381) => {
                return (
                    <div>
                        
                        Name of scheme {meetup381.scheme} <br />Academic year {meetup381.year}<br />
                        No. of students benefitted by Government Scholarships{meetup381.noooofgovstud} <br />Amount received from government {meetup381.amountgov}<br />
                        No. of students benefitted by Non-Government Scholarships {meetup381.noofinststud} <br />Amount received from institution/non-government {meetup381.amountinst} <br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Seed Money </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {seedm.map((meetup39) => {
                return (
                    <tr>
                        <td>
                            {meetup39.email} 
                        </td>
                        <td>
                            {meetup39.name}
                        </td>
                        <td>
                            {meetup39.seedm.map((meetup391) => {
                return (
                    <div>
                        
                        Name of Faculty {meetup391.fname} <br /> Amount Received {meetup391.amount} 
                        <br />Academic year {meetup391.year} <br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Skill Development Programs </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {skilldev.map((meetup40) => {
                return (
                    <tr>
                        <td>
                            {meetup40.email} 
                        </td>
                        <td>
                            {meetup40.name}
                        </td>
                        <td>
                            {meetup40.skilldev.map((meetup401) => {
                return (
                    <div>
                        
                        Program name {meetup401.programname} <br /> Date of Activity {meetup401.date} 
                        <br />Academic year {meetup401.year}  <br />No. of students enrolled {meetup401.noofstudenr}<br />
                        Name of Agency/Consultants {meetup401.agency} <br />Contact details of Agency/Consultants {meetup401.contactdetails}<br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Syllabus Revision </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {syllabusrev.map((meetup41) => {
                return (
                    <tr>
                        <td>
                            {meetup41.email} 
                        </td>
                        <td>
                            {meetup41.name}
                        </td>
                        <td>
                            {meetup41.syllabusrev.map((meetup411) => {
                return (
                    <div>
                        
                        programcode {meetup411.programcode} <br /> programname {meetup411.programname} 
                        <br />Year of introductio {meetup411.yearofintro}  <br />Status of implementation {meetup411.statusofimplement}<br />
                        Year of implementation {meetup411.yearofimplement} <br />Year of revision {meetup411.yearofrevision} <br />Change percentage {meetup411.changepercent}<br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Teacher Awards/Recognitions </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {teacheraward.map((meetup42) => {
                return (
                    <tr>
                        <td>
                            {meetup42.email} 
                        </td>
                        <td>
                            {meetup42.name}
                        </td>
                        <td>
                            {meetup42.teacheraward.map((meetup421) => {
                return (
                    <div>
                        
                        Name of faculty {meetup421.tname} <br /> Designation {meetup421.designation}<br />
                        Academic year {meetup421.year}  <br />PAN details {meetup421.pan}<br />
                        Name of Award {meetup421.award} <br />Name of Awarding Agency {meetup421.agency}<br />
                        Type of Award {meetup421.type} <br />Amount received {meetup421.amount}<br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Full-time Teachers </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {teacherdata.map((meetup43) => {
                return (
                    <tr>
                        <td>
                            {meetup43.email} 
                        </td>
                        <td>
                            {meetup43.name}
                        </td>
                        <td>
                            {meetup43.teacherdata.map((meetup431) => {
                return (
                    <div>
                        
                        Name of faculty {meetup431.fname} <br /> Department {meetup431.department}<br />
                        Academic year {meetup431.year}  <br />PAN details {meetup431.pan}<br />
                        Designation {meetup431.designation} <br />Year of Appointment {meetup431.yoa} <br />
                        Nature of Appointment {meetup431.type}<br />Total years of Experience in same Institution {meetup431.yoe}<br />
                        Is the faculty still serving the institution {meetup431.status} <br />Last year of service(if applicable) {meetup431.lastyear} <br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Teacher Fellowships </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {teacherfellow.map((meetup44) => {
                return (
                    <tr>
                        <td>
                            {meetup44.email} 
                        </td>
                        <td>
                            {meetup44.name}
                        </td>
                        <td>
                            {meetup44.teacherfellow.map((meetup441) => {
                return (
                    <div>
                        
                        Name of faculty {meetup441.tname} <br /> Year of Award {meetup441.year} 
                        <br />Name of Award/Fellowship {meetup441.award}  <br />Name of Awarding Agency {meetup441.agency}<br /><br />
                        
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Teacher Financial Support </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {teacherfs.map((meetup45) => {
                return (
                    <tr>
                        <td>
                            {meetup45.email} 
                        </td>
                        <td>
                            {meetup45.name}
                        </td>
                        <td>
                            {meetup45.teacherfs.map((meetup451) => {
                return (
                    <div>
                        
                        Title of conference/workshop {meetup451.title} <br /> Duration {meetup451.duration} 
                        <br />Academic year {meetup451.year}  <br />Membership {meetup451.membership}<br />
                        Reimbursement {meetup451.amount} <br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            <h5> Patents </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {patents.map((meetup46) => {
                return (
                    <tr>
                        <td>
                            {meetup46.email} 
                        </td>
                        <td>
                            {meetup46.name}
                        </td>
                        <td>
                            {meetup46.patents.map((meetup461) => {
                return (
                    <div>
                        
                        Title of patent {meetup461.title} <br /> Patent no {meetup461.patentnumber} 
                        <br />Year of publication {meetup461.yop}<br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />


            <h5> Revenue generated from Consultancy </h5>                  
                    
                    <Table striped bordered hover id="example">
                    {/* <table id="mytable"> */}
                    <thead>
                    <tr>
                         
                          <th>User email</th>
                          <th>Name</th>
                          <th>Details</th>
                          
                        </tr>
                        </thead>
                        <tbody>
                        
                    
                        {consultancy.map((meetup47) => {
                return (
                    <tr>
                        <td>
                            {meetup47.email} 
                        </td>
                        <td>
                            {meetup47.name}
                        </td>
                        <td>
                            {meetup47.consultancy.map((meetup471) => {
                return (
                    <div>
                        
                        Academic year {meetup471.year}  <br />Names of the teacher-consultants/corporate trainers {meetup471.consultant} <br /> 
                        Name of consultancy project {meetup471.project} <br />Consulting/Sponsoring agency with contact details {meetup471.sponseragency} <br />
                        Revenue generated (INR in Lakhs) {meetup471.revenue} <br />Title of the corporate training program {meetup471.title} <br />
                        Agency seeking training with contact details {meetup471.trainingagency} <br />Number of trainees {meetup471.nooftrainee} <br /><br />
                        </div>
                    
                                        );
                                    })}
                                            </td>
                                           
                                    
                                        
                                    
                                    
                                       
                    
                                       
                                                    </tr>
                                  
                    
                                    );
                                })}
                                </tbody>
                                </Table>

            <br />
            <br />

            

            

            <div>
                
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;