import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash } from '@fortawesome/free-solid-svg-icons';


// import classes from './Allclasses.module.css';

import classes1 from './newform.module.css';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const linkref=useRef();
    const moduleref=useRef();
    const topicref=useRef();

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState();
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    const addnewworkload = () => {
        history.replace('/addworkload');
    }

    const addperiod = () => {
        history.replace('/addperiod');
    }

    const getexamlist= () => {

        fetch('http://localhost:3000/api/v1/getexamlist?regno=S194&colid=30',{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
              }

        })
  .then(response => response.json())
  .then(data => {
      console.log(data.data.classes)
      setResults(data.data.classes);
    });

    }

   


    const searchApi = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getworkload', {
            params: {
                user: user

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setResults(response.data.data.classes);
        //alert(results.length);
       
    };

    const getperiod = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getperiod', {
            params: {
                colid: colid

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteperiod = async (periodid) => {
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteperiod', {
            params: {
                id: periodid
            }

        });
        setLoading(false);
        //console.log(response.data.data);
        getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const getclass = async () => {
        setLoading(true);
        setTerm(studid);
        // const response = await ep1.get('/api/v1/getclass', {
        //     params: {
        //         user: user
        //     }
        // });
        var dt1=new Date(date2);
        const response = await ep1.get('/api/v1/getclassbydate', {
            params: {
                user: user,
                classdate: dt1
            }
        });
        setLoading(false);
        console.log(response.data.data);
        setResults2(response.data.data.classes);
        //alert(results.length);
       
    };

    useEffect(() => {
        checklogin();
        //search1();
        //getexamlist();
        searchApi('');
        getperiod();
        getclass();
        //searchApi1('');

    }, []);

    function checkdata(link, ccode) {
        //alert (examcode + '-' + examname);
        if (link=='NA') {
            link="https://meet.jit.si/" + ccode;
        }
    }

    const _handlePressButtonAsync = async (classid, link) => {
        if(link=="NA") {
            link="https://meet.jit.si/" + classid;
        }
        var win = window.open(link, '_blank');
        win.focus();
      };

    const onDragOver = (ev) => {
        ev.preventDefault();
    }
    const onDragStart = (ev, ccode,  course, section, semester, program) => {
        //ev.preventDefault();
        ev.dataTransfer.setData("ccode", ccode);
        ev.dataTransfer.setData("course", course);
        ev.dataTransfer.setData("section", section);
        ev.dataTransfer.setData("semester", semester);
        ev.dataTransfer.setData("program", program);
        //setCoursecode(ccode);
    }
    const onDrop = async (ev, starttime, endtime) => {
        let ccode = ev.dataTransfer.getData("ccode");
        let course = ev.dataTransfer.getData("course");
        let section = ev.dataTransfer.getData("section");
        let semester = ev.dataTransfer.getData("semester");
        let program = ev.dataTransfer.getData("program");
        const module=moduleref.current.value;
        const link=linkref.current.value;
        const topic=topicref.current.value;
        if(!module) {
            alert("Please enter module");
            return;
        }
        if(!topic) {
            alert("Please enter topic");
            return;
        }
        if(!link) {
            alert("Please enter link");
            return;
        }
        
        setCoursecode(ccode);
        const response = await ep1.get('/api/v1/createclass', {
            params: {
                user: user,
                name: name,
                colid: colid,
                classdate: datechange,
                weeks: 0,
                coursecode: ccode,
                course: course,
                link: link,
                program: program,
                semester: semester,
                section: section,
                module: module,
                topic: topic,
                classtime: starttime



            }

        });
        if (response.data.status == "Success") {
            
            //history.replace('/workload');
            //setTerm2('Thank you');  
            getclass();
            alert('Data updated successfully');
        }
        //setResults(response.data.data.classes);

        //alert(ccode + '-' + starttime + '-' + endtime + '-' + datechange + '-' + module + '-' + topic + '-' + link);

    }

    async function refreshclass(newdate) {
        
        var dt1=new Date(newdate);
        setDate2(dt1);
        const response = await ep1.get('/api/v1/getclassbydate', {
            params: {
                user: user,
                classdate: dt1
            }
        });
        setLoading(false);
        console.log(response.data.data);
        setResults2(response.data.data.classes);
    }

    const width=window.innerWidth;
    const mdivwidth=width - 280;

    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ backgroundColor: '#f7f7f7', width: 230, borderRightColor: '#E2E1E0', borderRightWidth: 1 }}>
                    <div className={classes1.leftmenu1}>
                    <table cellpadding="5">
                        <tr>
                            <td>
                            <FontAwesomeIcon icon={faBook} style={{fontSize: 14, color: '#FC2406'}} />
                            </td>
                            <td>
                            <Link to='/new' style={{marginLeft: 3}} className={classes1.activelink1}>All courses</Link>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <FontAwesomeIcon icon={faBook} style={{fontSize: 14, color: '#FC2406'}} />
                            </td>
                            <td>
                            <Link to='/new' style={{marginLeft: 3}} className={classes1.passivelink1}>All courses</Link>
                            </td>
                        </tr>
                    </table>
                    
                        
                       
          
        </div>
                    </td>
                    <td width="20"></td>
                    <td> 

                    
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
            <p className={classes1.heading1}>Create Class</p>
            <p className={classes1.normal1}>Drag course and drop to respective periods below to create class.</p>
            
    
            <br />
            
            <div className={classes1.wokloadcontainer}>
            <div className={classes1.addworkload}
             onClick={() => addnewworkload()}
            >
            <span style={{fontSize: 15}}>+</span>
            <br />
                <span style={{fontSize: 15}}>Add new</span>  
                <br />
                <span style={{fontSize: 15}}>course</span>
                <br />
                <br />
                </div>
            {results.map((meetup) => {
                return (
                    <div className={classes1.addworkload}
            draggable
            onDragStart = {(e) => onDragStart(e, meetup.coursecode, meetup.course, meetup.section, meetup.semester, meetup.program)}
            >
                <span style={{fontSize: 15}}>{meetup.coursecode}</span>
                <br />
                <span style={{fontSize: 15}}>{meetup.course}</span>
                <br />
                <span style={{fontSize: 15}}>Section {meetup.section}</span>
                <br />
                <span style={{fontSize: 15}}>Semester {meetup.semester}</span>

                </div>

                );
            })}
            </div>
        <br />
        <table>
               <tr>
               <td width="15px"></td>
               <td>
                        <div style={{marginTop: -15}}>
                            <DatePicker
                                onChange={onDatechange}
                                value={datechange}
                            />
                        </div>
                   </td>
                   <td width="20px"></td>
                   <td>
                   <div style={{marginTop: -15, paddingLeft: 10 }}>
                   <input required type="text" style={{width: 160}} class="form-control" placeholder="Enter module" id="module" ref={moduleref}  />
                   </div>
                   </td>
                   
                 
                   <td width="20px"></td>
                   <td>
                   <div style={{marginTop: -15}}>
                   <input required type="text" style={{width: 160}} class="form-control" placeholder="Enter topic" id="topic" ref={topicref}  />
                   </div>
                   </td>
                   <td width="20px"></td>
                   <td>
                        <div style={{marginTop: -15}}>
                            <input required type="text" style={{width: 360}} class="form-control"  placeholder="Enter link (NA for jitsi)" id="link" ref={linkref}  />
                        </div>
                   </td>
               </tr>
           </table>
           <br />

        <div className={classes1.wokloadcontainer1}>
            <div className={classes1.addworkload}
             onClick={() => addperiod()}
            >
            <span style={{fontSize: 15}}>+</span>
            <br />
                <span style={{fontSize: 15}}>Add new</span>  
                <br />
                <span style={{fontSize: 15}}>period</span>
                <br />
                <br />
                <br />
                
                </div>
            {results1.map((meetup1) => {
                return (
                    <div className={classes1.addworkload}
                    onDrop={(e)=>onDrop(e, meetup1.starttime, meetup1.endtime)}
                    onDragOver={(ev)=>onDragOver(ev)}
            
            >
                <span style={{fontSize: 10}}></span>
                <br />
                <span style={{fontSize: 15}}>{meetup1.periodtitle}</span>
                <br />
                <span style={{fontSize: 15}}>Period {meetup1.periodnumber}</span>
                <br />
                <span style={{fontSize: 15}}>Starting at {meetup1.starttime}</span>
                <br />
                {/* <span style={{fontSize: 15}}>for {meetup1.endtime} mins</span> */}
                <FontAwesomeIcon icon={faTrash} style={{fontSize: 14, color: '#FC2406'}} 
                onClick={() => deleteperiod(meetup1._id)}
                />

                </div>

                );
            })}
            </div>

           







        {/* <div className={classes1.droparea}
        onDrop={(e)=>onDrop(e, "complete")}
        
            onDragOver={(ev)=>onDragOver(ev)}
            >
            <span style={{fontSize: 10}}>{coursecode}</span>
            <br />
                <span style={{fontSize: 18}}>Drop here</span>
                
                <br />
                </div>
       
        <br /> */}
        <hr />
        <br />
        <table>
            <tr>
                <td>
                <p className={classes1.heading1}>Class schedule starting </p>
                </td>
                <td width="20px"></td>
                <td>
                <div style={{marginTop: -15}}>
                            <DatePicker
                                onChange={newdate2 => refreshclass(newdate2)}
                                value={date2}
                            />
                        </div>

                </td>
            </tr>
        </table>
        <br /><br />

        {results2.map((meetup2) => {
                return (
                <div className={classes1.card}>
                    
                    
                    {meetup2.coursecode} {meetup2.course} <i>{meetup2.program} Section {meetup2.section} Semester {meetup2.semester}</i>
                    <br />
                    {meetup2.classdate}
                    <br /><br />
                    <table>
                        <tr>
                            <td>
                                <div className={classes1.actions}>
                                    <button type="button" onClick={() => _handlePressButtonAsync(meetup2._id, meetup2.link)}>Join Class</button>
                                </div>
                            </td>
                            {/* <td width="20px"></td>
                            <td>
                                <div className={classes1.actions}>
                                    <button type="button" onClick={() => checkdata(meetup2.coursecode, meetup2.course)}>Add Students</button>
                                </div>
                            </td>
                            <td width="20px"></td>
                            <td>
                                <div className={classes1.actions}>
                                    <button type="button" onClick={() => checkdata(meetup2.coursecode, meetup2.course)}>Add Study Material</button>
                                </div>
                            </td> */}
                        </tr>
                    </table>
                    
                    <br />
                </div>
                );
            })}
        
        </div>
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;