import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { DataGrid } from '@material-ui/data-grid';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 

import SidecourseNavigation from '../components/layout/SidecourseNavigation';

import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import Spinner from 'react-bootstrap/Spinner';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const linkref=useRef();
    const moduleref=useRef();
    const topicref=useRef();

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState();
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    const coursecode1=global1.coursecode;
    const course=global1.course;
    const semester=global1.semester;
    const section=global1.section;
    const classid=global1.classid;
    const classdate=global1.classdate;
    //const name=global1.name;

    const [isUploading, setIsuploading] = useState(false);

    const columns = [
        // { field: '_id', headerName: 'ID', width: 70 },
        { field: 'student', headerName: 'Student', width: 130 },
        { field: 'regno', headerName: 'Reg no', width: 130 },
      ];

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    const addnewstudent = () => {
        history.replace('/enrollstudents');
    }

    const addperiod = () => {
        history.replace('/addperiod');
    }

    const addstudents = () => {
        // global1.courseid=courseid;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/addstudents');
    }

    const checkstatus = (status) => {
        if(status==1) {
            return(
                <>
                Present
                </>
            )
        } else {
            return(
                <>
                Absent
                </>
            )
        }
    }

    

   

    let tutorials = [];
    const searchApi = async (sort) => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getclassattendance', {
            params: {
                user: user,
                token: token,
                colid: colid,
                coursecode: coursecode1,
                classid:classid,
                sort:sort

            }

        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);

       

        //localStorage.setItem("classstudents" + coursecode1, JSON.stringify(response.data.data.classes));
        setResults(response.data.data.classes);
        // try {
        //     $('#example').DataTable(
        //         {
        //             pagingType: 'full_numbers',
        //               pageLength: 5,
        //               processing: true,
        //               dom: 'Bfrtip',
        //                   buttons: ['copy', 'csv', 'print'
        //                   ]
        //         }
        //     );
            
        // } catch (err) {
            
        // }
        
       
        //alert(results.length);
       
    };

    const getlocal = () => {
        let collection=localStorage.getItem("classstudents" + coursecode1);
        if(!collection) {

        } else {
            setResults(JSON.parse(collection));

        }
        
    }

   

    const deleteenrolment = async (enrolmentid, status) => {
        var status1=1;
        if(status==1){
            status1=0
        }
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/updateattendance', {
            params: {
                id: enrolmentid,
                token: token,
                user:user,
                status:status1
            }

        });
        setLoading(false);
        searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    

    useEffect(() => {
        checklogin();
        //search1();
        //getexamlist();
        //getlocal();
        searchApi('regno');
        //$('#example').DataTable();
        //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable();
    //     },
    //     1000
    //     );
    // });
    
    //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable(
    //         {
    //             pagingType: 'full_numbers',
    //               pageLength: 5,
    //               processing: true,
    //               dom: 'Bfrtip',
    //                   buttons: ['copy', 'csv', 'print'
    //                   ]
    //         }
    //     );
    //     } ,
    //     1000
    //     );
    // });
        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);

    

    const formatdate = (dt1) => {
        const date1=new Date(dt1);
        const months=date1.getMonth() + 1;
        return date1.getDate() + '/' + months + '/' +  date1.getFullYear();
    
    }
    
   

    

    const width=window.innerWidth;
    const mdivwidth=width - 330;

   
       
        //alert('loaded');
  

    


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width: 60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        {/* <SidecourseNavigation /> */}

                        <SidecolorNavigation />
                    </td>
                   
                    <td>  
                        <TopNavigation />

                    
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
            <p style={{ fontSize: 18 }}><Link to='/dashcourse'>Dashboard</Link> - <Link to='/courseactions'>Course Dashboard</Link> - View attendance for Coursecode {coursecode1} Course {course} Classdate {formatdate(classdate)}</p>
            <hr />
            {/* <table>
                <tr>
                    <td>Sort by</td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={searchApi('name')}>+ Name</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={searchApi('-name')}>+ Name</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={searchApi('regno')}>+ Reg no</button>
                    </td>
                    <td width="20px"></td>
                    <td>
                    <button  onClick={searchApi('-regno')}>- Reg no</button>
                    </td>
                </tr>
                </table> */}
           
    
        <br /><br />
        {isUploading ? (
				<div style={{ alignItems: 'center', marginTop: 30, marginBottom: 60}}>
                   <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
				</div>
			) : (
                <div></div>
			)} 
     
<Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
     
      <th>Student</th>
      <th>Reg No</th>
      <th>Status</th>
      <th>Actions</th>
    </tr>
    </thead>
    <tbody>
    

    {results.map((meetup3) => {
                return (
                    <tr>
                        <td>
                        {meetup3.name}
                        </td>
                        <td>
                            {meetup3.regno}
                        </td>
                        <td>
                            {checkstatus(meetup3.status)}
                        </td>
                     
                        <td>
                        <Button variant="outline-danger" onClick={() => deleteenrolment(meetup3._id, meetup3.status)}>
                        <FontAwesomeIcon icon={faHandsHelping} style={{fontSize: 18, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} />
                            Change status</Button>
                      
                        </td>
                
                    
                
                
                   

                   
                                </tr>
              

                );
            })}
            </tbody>
            </Table>

            <br /><br />




        


            

       
        
        </div>

        <FooterNav1 />
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;