import { createContext, useState } from 'react';

const FavoritesContext = createContext({
    user: [],
    role: 'none',
    colid: 0,
    name: 'Not logged in',
    course: 0,
    addFavorite: (user, role,colid,name, course) => {}
});


export function FavoritesContextProvider(props) {

    const [appUser,setAppUser] = useState([]);
    const [role,setRole]=useState('');
    const [colid, setColid]=useState(0);
    const [name, setName]=useState('');
    const [course, setCourse]=useState(0);

    function addFavoritesHandler(user, role, colid, name,course) {
        setAppUser(user);
        setRole(role);
        setColid(colid);
        setName(name);
        setCourse(course);
    }

    function removeFavoritesHandler() {}
    
    const context={
        user: appUser,
        role: role,
        colid: colid,
        name: name,
        course: course,
        addFavorite: addFavoritesHandler
    };

    return <FavoritesContext.Provider value={context}>
        {props.children}
    </FavoritesContext.Provider>
};

export default FavoritesContext;