import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';

import ep1 from '../api/ep1';
import FavoritesContext from '../store/favourites-context';

import {GoogleLogin} from 'react-google-login';





function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const usernameref=useRef();
    const passwordref=useRef();

    const history=useHistory();

    const clientid="1002415317254-iq8aa2kuchd77svlgjsttsd1094fhaue.apps.googleusercontent.com";

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       logout();

    }, []);

    async function submitHandler(event) {
        event.preventDefault();
        const username=usernameref.current.value;
        const password=passwordref.current.value;

        console.log(username + '-' + password)
        
        //alert(username + '-' + password);
        const response = await ep1.get('/api/v1/loginapi', {
            params: {
                email: username.toLowerCase(),
                password: password

            }

        });
        //setLoading(false);
        //setResults(response.data);
        setTerm2('');
        console.log(response.data.status);

        if (response.data.status == "Success") {
            //addUserid(response.data[0].id);
            //props.navigation.navigate('Wv');
            //alert(response.data.user);
            const user=response.data.user;
            const name=response.data.name;
            const colid=response.data.colid;
            const role=response.data.name;
            global1.studid = response.data.user;
            global1.user = response.data.user;
            global1.name = response.data.name;
            global1.name1 = response.data.name;
            global1.colid = response.data.colid;
            // //global1.programid = response.data[0].programid;
            // //global1.batch = response.data[0].batch;
            global1.regno = response.data.regno;
            // //global1.photos1 = response.data[0].photos1;
            global1.semester = response.data.semester;
            global1.section = response.data.section;
            //alert(colid + '-' + name + '-' + user);
            favcontxt.addFavorite({
                studid: user,
                name: name
            },response.data.role,colid,name);
            //global1.pay = response.data[0].pay;
            //global1.cprice = response.data[0].amount;
            //global1.due1 = response.data[0].due1;
            // storestudid(response.data.user);
            // storename(response.data.name);
            // storeregno(response.data.regno);
            //alert(response.data.role.toString());
            if (response.data.role=='Student') {
                history.replace('/exam');
            } else if (response.data.role=='Faculty') {
                history.replace('/workload');
            }
            

            setTerm2('Thank you');  
            //navigation.navigate('Nland1');  
        }
        else {
            //alert('Invalid Username or Password. Please try again.');
            setTerm2('Invalid Username or Password. Please try again.');
        }

    }

    const onSuccess = (res) => {
        console.log(res.profileObj);
    }

    const onFailure= (res) => {
        console.log('Error ' + res);
    }

  


    return (
        <div style={{width: 600, margin: 'auto', marginTop: 100}}>
            <div className={classes.logindiv}>
           Start something new...
            <br />
            <form className={classes.form} onSubmit={submitHandler}>
                <div className={classes.control}>
                    <label htmlFor='username'>User name</label>
                    <input type="email" required placeholder="Enter email" id="username" ref={usernameref} />
                </div>
                <div className={classes.control}>
                    <label htmlFor='password'>Password</label>
                    <input type="password" required placeholder="Enter password" id="password" ref={passwordref} />
                </div>
                <div className={classes.actions}>
                    <button>Login</button>
                </div>
                <br />
                
                {term2}
            
           </form>
           </div>

           <div>
                    <GoogleLogin
                        clientId={clientid}
                        buttonText="Login"
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                        cookiePolicy={'single-host-origin'}
                        style={{ marginTop: 20}}
                        isSignedIn={true}
                        />
                </div>
        </div>
    );

}

export default Newclasses;