import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function SidefacNavigation() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    



    return (
        <div className={classes1.sidebar}>
            <div className={classes1.sidebarinside}>

<div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />
            
        
        {/* <div style={{marginBottom: 10}}><b>Insights</b></div> */}
        <Link to='/dashfacultydetails'> Faculty activities</Link>
        <Link to='/viewfacultyreport'> Faculty report</Link>
        <Link to='/viewallbooks'> View books published (3.4.6 U)</Link>
        <Link to='/viewallprojects'> View all projects (5.8.1-5.8.2 NBA, 3.1.6, 3.2.1, 3.2.2, 3.2.3 U)</Link>
        <Link to='/viewprojectsadmindoc'> View projects doc</Link>
        
        <Link to='/viewallpublications'> View all publications (5.8.1 NBA, 3.4.5 U)</Link>
        <Link to='/viewallseminars'> View all seminars (6.3.2, 6.3.4 U, 5.7 NBA)</Link>
        
        <Link to='/viewallpatents'> View all patents</Link>
        <Link to='/viewinnovationadmin'> View all awards</Link>
        <Link to='/viewallfaculties'> View all faculties</Link>
        <Link to='/viewallstudents'> View all students</Link>
        <Link to='/viewallhod'> View all HoD</Link>
        <Link to='/deleteroleuser'> Delete users</Link>
        {/* <Link to='/viewleavependinghod'> Approve Leave (HoD)</Link> */}
        <Link to='/viewleavependingprincipal'> Approve Leave (Principal)</Link>
        <Link to='/viewleaveapplybyadmin'> All leave application</Link>
        {/* <Link to='/viewkpiuser'> View and Edit KPI</Link>
        <Link to='/addallkpi'> Refresh KPI</Link>
        <Link to='/viewkpigroup'> View KPI groupwise</Link>
        <Link to='/viewkpicriteria'> View KPI criteriawise</Link> */}
        <Link to='/viewdepartment'> View departments</Link>
        <Link to='/viewawsconfig'> AWS / Azure config</Link>
        <Link to='/viewcircularfac'> Faculty circular</Link>
        <Link to='/viewprograms'> All programs</Link>
        <Link to='/viewsupportingdocall'> All supporting documents</Link>
        <Link to='/selectdepartment'> Departmental report</Link>
        {/* <Link to='/viewdepfacdocs'> Faculty consolidated report</Link> */}
        {/* <Link to='/getstudentcount'> Student count</Link> */}
        <Link to='/getfacultycount'> Faculty count</Link>
        {/* <Link to='/getstudentbycategory'> Student count (category)</Link>
        <Link to='/getstudentbycatgender'> Student count by category gender</Link> */}
        
        <Link to='/viewqspeer'> View Peer List</Link>
        <Link to='/viewqsemployers'> View Employer List</Link>
        <Link to='/viewdeptreport'> Departmental Report Details</Link>
        <Link to='/viewallclasses'> View all classes</Link>
        <Link to='/viewallcoursefiles'> View course files</Link>
        <Link to='/viewallassignments'> View all assignments</Link>

        <Link to='/selectattendanceweek'> Weekly attendance aggregate</Link>
        <Link to='/viewweeklyattendance'> Weekly attendance list</Link>

        <Link to='/viewntimesheetadmin'> Timesheet (Admin)</Link>

        
        
        {/* <Link to='/viewplacementall'> All placement</Link> */}
        
        {/* <Link to='/addsupportingdoc'> Add supporting doc</Link> */}
       
{/* 
        <hr />
        <div style={{marginBottom: 10}}><b>Useful links</b></div>
        <Link to='/dashcourse'> Dashboard</Link>
        <Link to='/workload'> Create Class</Link>
        
        <Link to='/getmyfeedback'> My feedback</Link>
        <Link to='/proctorlist'> Proctor list</Link>
        <Link to='/examenrlist'> Check exam enrollment</Link> */}
     <br /><br /><br /><br /><br /><br />

        
       </div>

</div>
    );

}

export default SidefacNavigation;