import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faFile } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidecolorNavigation from '../components/layout/SidedocNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';

import S3 from 'react-aws-s3';


import { createWorker } from "tesseract.js";
import { data } from "jquery";

function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const topicref=useRef();
  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const [isValidated, setIsValidated] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;

    const username=global1.username;
    const password=global1.password;
    const region=global1.region;
    const bucket=global1.bucket;

    const criteria=global1.criteria;
    const metric=global1.metric;
    const type=global1.type;
    const collection1=global1.collection1;
    const field1=global1.field1;

    const gobacknav = () => {
        const pagenav=global1.pagenav;
        //history.goBack();
        history.replace('/' + pagenav);
        
    };

    const datatocheck=global1.datatocheck;

    const worker = createWorker();

    // Process image with OCR
  const readImageText = async() => {
    setIsuploading(true);

    

    
    
    try {
      await worker.load()
      // Set the language to recognize
      await worker.loadLanguage("eng");
      await worker.initialize("eng");
      const { data: { text } } = await worker.recognize(selectedFile);
      await worker.terminate();

      //alert(text);
      setTerm2(text);
      var comments='';
      var status='ok';


      if(datatocheck.indexOf('`')>-1) {
        const ar=datatocheck.split('`');
        for (var i = 0; i < ar.length; i++) { 
            //console.log(xs[i]); 
            //alert('checking ' + ar[i]);
            if(ar[i].indexOf('-')>-1) {
                var ar1=ar[i].split('-');
                var yearok='no';
                for (var j = 0; j < ar1.length; j++) { 
                    
                  if(text.toLowerCase().indexOf(ar1[j].toLowerCase()) > -1) {                    
                      yearok='yes';
                  }
                }
                if (yearok == 'no') {
                    comments=comments + ' Invalid ' + ar[i];
                    status='Document validation failed.';
                }
  
            } else if (ar[i].indexOf(' ')>-1) {
                  var ar2=ar[i].split(' ');
                  var spok='no';
                  for (var k = 0; k < ar2.length; k++) { 
                      
                      if(text.toLowerCase().indexOf(ar2[k].toLowerCase()) > -1) {                    
                          spok='yes';
                      }
                  }
                  if (spok == 'no') {
                      comments=comments + ' Invalid ' + ar[i];
                      status='Document validation failed.';
                  }
  
            } else {
              if(text.toLowerCase().indexOf(ar[i].toLowerCase()) < 0) {
                  comments=comments + ' ' + ar[i] + ' missing.';
                  status='Document validation failed.';
              }
            }
            
          }
      } else {

        if(datatocheck.indexOf('-')>-1) {
            var ar12=datatocheck.split('-');
            var yearok='no';
            for (var jj = 0; jj < ar12.length; jj++) { 
                
              if(text.toLowerCase().indexOf(ar12[jj].toLowerCase()) > -1) {                    
                  yearok='yes';
              }
            }
            if (yearok == 'no') {
                comments=comments + ' Invalid ' + datatocheck;
                status='Document validation failed.';
            }

        } else if (datatocheck.indexOf(' ')>-1) {
              var ar22=datatocheck.split(' ');
              var spok='no';
              for (var k2 = 0; k2 < ar22.length; k2++) { 
                  
                  if(text.toLowerCase().indexOf(ar22[k2].toLowerCase()) > -1) {                    
                      spok='yes';
                  }
              }
              if (spok == 'no') {
                  comments=comments + ' Invalid ' + datatocheck;
                  status='Document validation failed.';
              }

        } else {
          if(text.toLowerCase().indexOf(datatocheck.toLowerCase()) < 0) {
              comments=comments + ' ' + datatocheck + ' missing.';
              status='Document validation  direct failed.';
          }
        }

      }



      
        //alert (status + ' ' + comments);
        if(status == 'ok') {
            setIsValidated(true);
            alert('Document verified. Please click on upload');
        } else {
            alert (status + ' ' + comments);
        }
       
    } catch (err) {
      alert (err);
    }
    setIsuploading(false);
}


    // const config = {
    //     bucketName: 'jadavpuruniversity',
    //     // dirName: 'media', /* optional */
    //     // region: 'us-east-2',
    //     region: 'ap-south-1',
    //     accessKeyId: 'AKIAUAC655EBDFT6YKIL',
    //     secretAccessKey: 'a7jpUecFZi5f8GCLhU8HJD9lsG9fSCF5DjIWKYXo',
    //     // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
    // }

    const config = {
        bucketName: bucket,
        // dirName: 'media', /* optional */
        // region: 'us-east-2',
        region: region,
        accessKeyId: username,
        secretAccessKey: password,
        // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
    }
    
    const ReactS3Client = new S3(config);
    /*  Notice that if you don't provide a dirName, the file will be automatically uploaded to the root of your bucket */
    
    /* This is optional */
    //var dt1=new Date();
    

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();

    }, []);

    const changeHandler = (event) => {
        
        if(event.target.files[0].name.toLowerCase().indexOf('pdf')>-1) {
            setSelectedFile(event.target.files[0]);
            setIsFilePicked(true);

            setIsValidated(false);
        } else {
            setIsFilePicked(false);
            alert('Please select a valid pdf file');
        }
        //setIsFilePicked(true);
        
    };

    const changeHandler1 = (files,event) => {
        
        if(files[0].name.toLowerCase().indexOf('pdf')>-1) {
            setSelectedFile(files[0]);
            setIsFilePicked(true);
            setIsValidated(false);
        } else {
            setIsFilePicked(false);
            alert('Please select a valid pdf file');
        }
        
    };

    // const submit1 = () => {
    //     ReactS3Client
    // .uploadFile(selectedFile, newFileName)
    // .then(data => console.log(data))
    // .catch(err => console.error(err))
    // alert('ok');
    // }

    // const submit1 = () => {
    //     const newFileName = dt1.toString() + '-' + selectedFile.name;
    //     ReactS3Client
    // .uploadFile(selectedFile, newFileName)
    // .then(data => {
    //     alert(data.location);
    // })
    // .catch(err => console.error(err))
    // alert('ok');
    // }

    const submit1 =  () => {
        setIsuploading(true);
        var dt1=new Date();
        var month=dt1.getMonth() + 1;
        //var dt2=dt1.getMonth().toString() + dt1.getFullYear().toString() + dt1.getDay().toString() + dt1.getMinutes().toString() + dt1.getSeconds();
        var dt2=month + '-' + dt1.getFullYear().toString() + '-' + dt1.getDate().toString() + '-' + dt1.getMinutes().toString() + dt1.getSeconds();
        const newFileName = dt2.toString() + '-' + selectedFile.name;
        ReactS3Client
    .uploadFile(selectedFile, newFileName)
    .then(data => {
        
        const response =  ep1.get('/api/v1/createsupportingdoc', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                criteria: criteria,
                filename: newFileName,
                link: data.location,
                metric: metric,
                type: type,
                collection1: collection1,
                field1: field1


            }
        });
        alert('File uploaded to ' + data.location);
        setIsuploading(false);

    })
    .catch(err => console.error(err))
    //alert('ok');
    }


    const handleSubmission = () => {
        //alert('checking');
        //setIsValidated(false);

        setIsFilePicked(false);
                
                //const topic=topicref.current.value;
                const formData = new FormData();
        		formData.append('upl', selectedFile);
                formData.append('token', token);
                formData.append('user', user);
                formData.append('name', name);
                formData.append('colid', colid);

                console.log(formData);
                
                setIsuploading(true);
                
        
        		fetch(
        			//'https://freeimage.host/api/1/upload?key=<YOUR_API_KEY>',
                    //'/api/v1/createcoursefiles',
                    // 'https://ctnodeapps2.azurewebsites.net/api/v1/createcoursefiles',
                    //'https://ctnodeapps2.azurewebsites.net/api/v1/uploadclassenr',
                    // 'http://localhost:3000/api/v1/uploadfacseminar',
                    //'https://canvasapi.azurewebsites.net/api/v1/uploadfacsyllabusrev',
                    //'https://canvasapi1.azurewebsites.net/api/v1/getpdf',
                    //'https://canvasapi1.azurewebsites.net/api/v1/getpdf',
                    //'http://localhost:3000/api/v1/getpdf',
                    'https://ctpdf.azurewebsites.net/api/v1/getpdf',
        			{
        				method: 'POST',
                        // headers: {
                        //     'Content-Length': selectedFile.length,
                        //     'Content-Type': 'multipart/form-data'
                        //   },
        				body: formData,
        			}
        		)
        			.then((response) => response.json())
        			.then((result) => {
        				console.log('Success:', result);
                        //alert(result.status);
                        setTerm2(result.data.text);
                        var text=result.data.text;
                        var numpages=result.data.numpages;

// start


try {
   

    var comments='';
    var status='ok';


    if(datatocheck.indexOf('`')>-1) {
      const ar=datatocheck.split('`');
      for (var i = 0; i < ar.length; i++) { 
          //console.log(xs[i]); 
          //alert('checking ' + ar[i]);
          if(ar[i].indexOf('-')>-1 && ar[i].indexOf(' ')<0) {
              var ar1=ar[i].split('-');
              var yearok='no';
              for (var j = 0; j < ar1.length; j++) { 
                  
                if(text.toLowerCase().indexOf(ar1[j].toLowerCase()) > -1) {                    
                    yearok='yes';
                }
              }
              if (yearok == 'no') {
                  comments=comments + ' Invalid ' + ar[i];
                  status='Document validation failed.';
              }

          } else if (ar[i].indexOf(' ')>-1) {
                var ar2=ar[i].split(' ');
                var spok='yes';
                for (var k = 0; k < ar2.length; k++) { 
                    
                    if(text.toLowerCase().indexOf(ar2[k].toLowerCase()) < 0) {                    
                        spok='no';
                    }
                }
                if (spok == 'no') {
                    comments=comments + ' Invalid ' + ar[i];
                    status='Document validation failed.';
                }

          } else {
            if(text.toLowerCase().indexOf(ar[i].toLowerCase()) < 0) {
                comments=comments + ' ' + ar[i] + ' missing.';
                status='Document validation failed.';
            }
          }
          
        }
    } else {

      if(datatocheck.indexOf('-')>-1 && datatocheck.indexOf(' ')<0) {
          var ar12=datatocheck.split('-');
          var yearok='no';
          for (var jj = 0; jj < ar12.length; jj++) { 
              
            if(text.toLowerCase().indexOf(ar12[jj].toLowerCase()) > -1) {                    
                yearok='yes';
            }
          }
          if (yearok == 'no') {
              comments=comments + ' Invalid ' + datatocheck;
              status='Document validation failed.';
          }

      } else if (datatocheck.indexOf(' ')>-1) {
            var ar22=datatocheck.split(' ');
            var spok='yes';
            for (var k2 = 0; k2 < ar22.length; k2++) { 
                
                if(text.toLowerCase().indexOf(ar22[k2].toLowerCase()) < 0) {                    
                    spok='no';
                }
            }
            if (spok == 'no') {
                comments=comments + ' Invalid ' + datatocheck;
                status='Document validation failed.';
            }

      } else {
        if(text.toLowerCase().indexOf(datatocheck.toLowerCase()) < 0) {
            comments=comments + ' ' + datatocheck + ' missing.';
            status='Document validation  direct failed.';
        }
      }

    }

    if(text.length<3000) {
        status='Document validation failed. Content length of this file (' + text.length +') is less. Please add more content.';
    }

    if(text.length<3000) {
        status=status + 'Document validation failed. Content length of this file (' + text.length +') is less. Please add more content.';
    }

    if (numpages<2) {
        status=status + 'Document validation failed. Please create at lease 2 page report.';
    }



    
      //alert (status + ' ' + comments);
      if(status == 'ok') {
          setIsValidated(true);
          alert('Document verified with ' + numpages + ' pages and ' + text.length + ' characters. Please click on upload');
      } else {
          alert (status + ' ' + comments);
      }
     
  } catch (err) {
    alert (err);
  }


// end



                        setIsuploading(false);
                        

                        //history.replace('/viewsyllabusrev');
        			})
        			.catch((error) => {
        				console.log('Error:', error);
                        setIsuploading(false);
        		});
                setIsFilePicked(true);
                
       	};

    const handleSubmissionold = () => {
        //alert('checking');
                
                //const topic=topicref.current.value;
                const formData = new FormData();
        		formData.append('upl', selectedFile);
                formData.append('token', token);
                formData.append('user', user);
                formData.append('name', name);
                formData.append('colid', colid);
                formData.append('criteria', '1');
                formData.append('metric', '1.1');
                formData.append('field1', 'field123');
                formData.append('type', 'Qlm');
                formData.append('collwction1', 'Event');

                setIsuploading(true);
                
        
        		fetch(
        			//'https://freeimage.host/api/1/upload?key=<YOUR_API_KEY>',
                    //'/api/v1/createcoursefiles',
                    'https://canvasapi.azurewebsites.net/api/v1/uploadsupportingdoc',
                    //'https://ctnodeapps2.azurewebsites.net/api/v1/uploadclassenr',
        			{
        				method: 'POST',
                        // headers: {
                        //     'Content-Length': selectedFile.length,
                        //     'Content-Type': 'multipart/form-data'
                        //   },
        				body: formData,
        			}
        		)
        			.then((response) => response.json())
        			.then((result) => {
        				console.log('Success:', result);
                        alert(result.status + '-' + result.error);
                        setIsuploading(false);
        			})
        			.catch((error) => {
        				console.error('Error:', error);
                        setIsuploading(false);
        		});
                
       	};
	

           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidecolorNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        <table>
                            <tr>
                                <td>Upload supporting document
                                    </td>
                                    <td width="20px"></td>
                                    <td>
                                        
                                    <button  onClick={gobacknav}>Go back</button>
                                    </td>
                            </tr>
                            </table>
                        
                        <hr />
                        
                        1. Only use editable pdf documents. Do not scan the document and convert to pdf.<br />
                        2. Select the file or drop the file and click on validate data. If it matches the requirement, Upload button will appear. Else, you will get an error message.<br />
                        3. It will take some time for the software to analyze the document and validate the information. Please wait till it is completed.
                        <br /><br />

                       

                        Checking for {datatocheck}

                        <br /><br />

                     

                        <input type="file" name="file" onChange={changeHandler} />

                        <br /><br />




                        <div style={styles}>
        <FileDrop className={classes2.FileDrop}
          onFrameDragEnter={(event) => console.log('onFrameDragEnter', event)}
          onFrameDragLeave={(event) => console.log('onFrameDragLeave', event)}
          onFrameDrop={(event) => console.log('onFrameDrop', event)}
          onDragOver={(event) => console.log('onDragOver', event)}
          onDragLeave={(event) => console.log('onDragLeave', event)}
          onDrop={(files, event) => changeHandler1(files, event)}
        >
          Drop some files here!
        </FileDrop>
      </div>
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
                {isFilePicked ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>File Information</p>
					<p>Filename: {selectedFile.name}</p>
					<p>Filetype: {selectedFile.type}</p>
					<p>Size in bytes: {selectedFile.size}</p>
                    <div className={classes1.actions}>
                    {/* <button  onClick={submit1}>Add Supporting Document</button> */}
                    <button  onClick={handleSubmission}>Validate entry</button>
                    <br /><br />
                    </div>
					{/* <p>
						lastModifiedDate:{' '}
						{selectedFile.lastModifiedDate.toLocaleDateString()}
					</p> */}
				</div>
			) : (
                <div>
				<p>Select a supporting document to show details</p>
                
                </div>
			)}

{isValidated ? (
				<div>
                   <button  onClick={submit1}>Add Supporting Document</button>
                   <br /><br />
				</div>
			) : (
                <div>Please validate file to upload</div>
			)} 
	
              
                <br />
                <h5>Extracted data</h5>
                <hr />
                <p>{term2}
                    </p>
                
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;