import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import global1 from '../../pages/global1';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function Sidecr5Navigation() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    const changeHandler = () => {
        const instype=global1.instype;
        //alert(instype);
        if(instype=='Affiliated') {
            return (
                <>
             
             <Link to='/viewscholarship'> View Student Scholarships(5.1.1)</Link>   
        <Link to='/viewskilldev'> View Skill development Programs (5.1.3)</Link>
        <Link to='/viewcareercounsel'> View Career Counselling Activities (5.1.4)</Link>
        <Link to='/viewplacement'> View Placement (5.2.1, 4.4 NBA)</Link>
        {/* <Link to='/viewhigheredu'> View Students going for Higher Education (5.2.2)</Link>
        <Link to='/viewhigherexam'> View Students going for Higher Exam (5.2.3)</Link> */}
        <Link to='/viewawards'> View Students Awards/Medals (5.3.1, 4.5.3 NBA) </Link>
        <Link to='/viewevent'> View Sports/Cultural Activities (5.3.3)</Link>
        <Link to='/viewalumnicon'> View Alumni Contribution (5.4.2)</Link> 
        <Link to='/viewnhigheredu'> Higher education (integrated)</Link>
        <Link to='/viewnhigherexam'> Higher exam (integrated)</Link>  
       
                </>
            )
  
        } else if(instype=='University') {
          return (
              <>
     <Link to='/viewscholarship'> View Student Scholarships(5.1.1)</Link>   
        <Link to='/viewskilldev'> View Skill development Programs (5.1.3)</Link>
        <Link to='/viewcareercounsel'> View Career Counselling Activities (5.1.2)</Link>
        {/* <Link to='/viewplacement'> View Placement (5.2.2, 4.4 NBA)</Link> */}
        {/* <Link to='/viewhigheredu'> View Students going for Higher Education (5.2.3)</Link>
        <Link to='/viewhigherexam'> View Students going for Higher Exam (5.2.1)</Link> */}
        <Link to='/viewawards'> View Students Awards/Medals (5.3.1, 4.5.3 NBA) </Link>
        <Link to='/viewevent'> View Sports/Cultural Activities (5.3.3)</Link>
        <Link to='/viewalumnicon'> View Alumni Contribution (5.4.2)</Link> 
        <Link to='/viewnhigheredu'> Higher education (integrated)</Link>
        <Link to='/viewnhigherexam'> Higher exam (integrated)</Link>  
              </>
          )
            
      } else if(instype=='HealthUniversity') {
        return (
            <>
        <Link to='/viewscholarship'> View Student Scholarships(5.1.1)</Link>   
        <Link to='/viewskilldev'> View Skill development Programs (5.1.2)</Link>
        <Link to='/viewcareercounsel'> View Career Counselling Activities (5.1.3)</Link>
        {/* <Link to='/viewplacement'> View Placement (5.2.2, 4.4 NBA)</Link> */}
        {/* <Link to='/viewhigheredu'> View Students going for Higher Education (5.2.3)</Link>
        <Link to='/viewhigherexam'> View Students going for Higher Exam (5.2.1)</Link> */}
        <Link to='/viewawards'> View Students Awards/Medals (5.3.1, 4.5.3 NBA) </Link>
        <Link to='/viewevent'> View Sports/Cultural Activities (5.3.3)</Link>
        <Link to='/viewalumnicon'> View Alumni Contribution (5.4.2)</Link> 
        <Link to='/viewnhigheredu'> Higher education (integrated)</Link>
        <Link to='/viewnhigherexam'> Higher exam (integrated)</Link>  
            </>
        )
          
    } else if(instype=='Dual') {
        return (
            <>
      <Link to='/viewscholarship'> View Student Scholarships(5.1.1)</Link>   
        <Link to='/viewskilldev'> View Skill development Programs (5.1.3)</Link>
        <Link to='/viewcareercounsel'> View Career Counselling Activities (5.1.2)</Link>
        {/* <Link to='/viewplacement'> View Placement (5.2.2, 4.4 NBA)</Link> */}
        {/* <Link to='/viewhigheredu'> View Students going for Higher Education (5.2.3)</Link>
        <Link to='/viewhigherexam'> View Students going for Higher Exam (5.2.1)</Link> */}
        <Link to='/viewawards'> View Students Awards/Medals (5.3.1, 4.5.3 NBA) </Link>
        <Link to='/viewevent'> View Sports/Cultural Activities (5.3.3)</Link>
        <Link to='/viewalumnicon'> View Alumni Contribution (5.4.2)</Link> 
        <Link to='/viewnhigheredu'> Higher education (integrated)</Link>
        <Link to='/viewnhigherexam'> Higher exam (integrated)</Link>  
            </>
        )
          
    } else {
          return (
              <>
            <Link to='/viewscholarship'> View Student Scholarships(5.1.1 A-U-D-C-H-HU)</Link>   
        <Link to='/viewskilldev'> View Skill development Programs (5.1.3 A-U-C-D, 5.1.2 HU)</Link>
        <Link to='/viewcareercounsel'> View Career Counselling Activities (5.1.4 A, 5.1.2 U-D, 5.1.4 C, 5.1.3 H-HU)</Link>
        {/* <Link to='/viewplacement'> View Placement (5.2.1 A-C, 5.2.2 U-D-H-HU, 4.4 NBA)</Link> */}
        {/* <Link to='/viewhigheredu'> View Students going for Higher Education (5.2.2 A-C, 5.2.3 U-D-H-HU)</Link>
        <Link to='/viewhigherexam'> View Students going for Higher Exam (5.2.3 A, 5.2.1 U-D-H-HU, 5.2.3 C)</Link> */}
        <Link to='/viewawards'> View Students Awards/Medals (5.3.1 A-U-D-C-H-HU, 4.5.3 NBA) </Link>
        <Link to='/viewevent'> View Sports/Cultural Activities (5.3.3 A-U-D-C-H-HU)</Link>
        <Link to='/viewalumnicon'> View Alumni Contribution (5.4.2 U-D-C-H-HU)</Link>    
        <Link to='/viewnhigheredu'> Higher education (integrated)</Link>
        <Link to='/viewnhigherexam'> Higher exam (integrated)</Link>     
<hr />
<div style={{marginBottom: 10}}><b>Nomenclature</b></div>
A - Affiliated college<br />
U - University<br />
D - Dual Mode University<br />
C- Autonomous institution<br />
H- Health Science institution<br />
HU- Health Science university<br />
        
              </>
          )
  
      }
      };




    return (
        <div className={classes1.sidebar}>
        <div className={classes1.sidebarinside}>   
        
        <div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />  
         
            {changeHandler()}  
        <hr />
        <div style={{marginBottom: 10}}><b>Useful links</b></div>
        <Link to='/dashcourse'> Dashboard</Link>
        <Link to='/workload'> Create Class</Link>    
        <Link to='/getmyfeedback'> My feedback</Link>
        <Link to='/proctorlist'> Proctor list</Link>
        <Link to='/examenrlist'> Check exam enrollment</Link>
     
        

        </div>     
       

</div>
    );

}

export default Sidecr5Navigation;