import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faEdit, faComment, faFileAlt, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { DataGrid } from '@material-ui/data-grid';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import SidendashNavigation from '../components/layout/SidemetricNavigationadmin';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import Spinner from 'react-bootstrap/Spinner';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const yearref=useRef();
    const dateref=useRef();
    const departmentref=useRef();
    const taskref=useRef();
    const percentageref=useRef();
    const statusref=useRef();
    const commentsref=useRef();

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState([]);
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());
    const [format, setFormat] = useState(false);


    const [faculties, setFaculties] = useState([]);

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;
    //const name=global1.name;

    const [isUploading, setIsuploading] = useState(false);

    const columns = [
        // { field: '_id', headerName: 'ID', width: 70 },
        { field: 'student', headerName: 'Student', width: 130 },
        { field: 'regno', headerName: 'Reg no', width: 130 },
      ];

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    const edit = (id, year, date, department, task, percentage, status, comments) => {
        global1.statusid=id;
        global1.year=year;
        global1.date=date;
        global1.department=department;
        global1.task=task;
        global1.percentage=percentage;
        global1.status=status;
        global1.comments=comments;
        
        history.replace('/editprojectstatus');
    }

    const addperiod = () => {
        history.replace('/addperiod');
    }

    const addstudents = () => {
        // global1.courseid=courseid;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/addstudents');
    }

    function getaffiliated(affvalue) {
        if(affvalue=="1") {
            return "Yes";
        } else {
            return "No";
        }
    }

    const getdepartmentlist = async () => {
        // setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getdepartmentlist', {
        params: {
        colid: colid,
        user: user,
        token: token
        
        }
        
        });
        // setLoading(false);
        console.log(response.data.data);
        setFaculties(response.data.data.classes);
        //alert(results.length);
        };

    

   

    let tutorials = [];
    const searchApi = async (task,dep) => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getprojectstatusbytaskdep', {
            params: {
                user: user,
                token: token,
                colid: colid,
                task: task,
                department: dep
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        localStorage.setItem("p_statusdep", JSON.stringify(response.data.data.classes));

       

        
        setResults(response.data.data.classes);
        setLink(response.data.data.link);
        //alert('format ' + format);
        try{

            formattable();
        } catch(err) {

        }
        
        
        
       
        //alert(results.length);
       
    };

    function formattable() {
        if (!format) {
            $('#example').DataTable(
                {
                    pagingType: 'full_numbers',
                      pageLength: 5,
                      processing: true,
                      dom: 'Bfrtip',
                          buttons: ['copy', 'csv', 'print'
                          ]
                }
            );
            setFormat(true);

        }
    }

   

    const deleteenrolment = async (enrolmentid) => {
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteprojectstatus', {
            params: {
                id: enrolmentid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const getlocal = () => {
        let collection=localStorage.getItem("p_statusdep");
        if(!collection) {

        } else {
            setResults(JSON.parse(collection));

        }
        
    }

    const timely = (date1) => {
        var dt1=new Date(date1);
        dt1.setHours(dt1.getHours() - 5);
        dt1.setMinutes(dt1.getMinutes() - 30);
        var hours=dt1.getHours();
        var minutes=dt1.getMinutes();
        var month=dt1.getMonth() + 1;
        if(minutes.length<2) {
            minutes='0' + minutes;
        }
        var t1;
        if(hours>11) {
            t1='PM';
        } else {
            t1='AM';
        }
        if(hours>12) {
            hours=hours-12;
        }
        // var dt2=dt1.getDate() + '/' + month + '/' + dt1.getFullYear() + ' ' + hours + ':' + minutes + t1;
        var dt2=dt1.getDate() + '/' + month + '/' + dt1.getFullYear();
        return dt2;

    }

    

    useEffect(() => {
        checklogin();
        //search1();
        //getexamlist();
        getdepartmentlist();
        getlocal();
        //searchApi('');
        //$('#example').DataTable();
        //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable();
    //     },
    //     1000
    //     );
    // });
    
    //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable(
    //         {
    //             pagingType: 'full_numbers',
    //               pageLength: 5,
    //               processing: true,
    //               dom: 'Bfrtip',
    //                   buttons: ['copy', 'csv', 'print'
    //                   ]
    //         }
    //     );
    //     } ,
    //     1000
    //     );
    // });
        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);

    const getval=()=> {
        const task=taskref.current.value;
        const dep=departmentref.current.value;
        //alert(aff);
        searchApi(task,dep);
        
    }

      const getvaldep=(event)=> {
        const task=taskref.current.value;
        //alert(event.target.value);
        searchApi(task,event.target.value);
       
    }

    const getvaltask=(event)=> {
        const dep=departmentref.current.value;
        //alert(event.target.value);
        searchApi(event.target.value, dep);
       
    }

    const uploadlink = async (link1, id1, year) => {
        link1=link1 + '?criteria=4&Section=4.1.3/4.3.1&Subsection=ICT&field1=' + id1 + '&Year=' + year; 
        var win = window.open(link1, '_blank');
        win.focus();
      };

    


    
   

    

    const width=window.innerWidth;
    const mdivwidth=width - 330;

   
       
        //alert('loaded');
  

    


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width: 60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidendashNavigation />
                    </td>
                   
                    <td> 
                        <TopNavigation />

                    
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
            <p style={{ fontSize: 18 }}>View Project Status</p>
            <hr />
            <Link to='/addprojectstatus' className="btn btn-dark"> Add Project status</Link>
           
            <br />
        <br />
        <label htmlFor='department'>Select Department</label>
                        <select id="department" className="form-control" onChange={getvaldep}  ref={departmentref}>
                            <option value="1">Select Department</option>

                            {faculties.map((meetup3) => {
                            return (
                            <option value={meetup3}>{meetup3}</option>
                        );
                        })}
                        </select>
                            <br />
                            <label htmlFor='year'>Select Task</label>
                        <select id="task" className="form-control" onChange={getvaltask}   ref={taskref}>
                            <option value="Initial faculty meeting conducted">Initial faculty meeting conducted</option>
                            <option value="HOD meeting conducted">HOD meeting conducted</option>
                            <option value="Faculty data updation">Faculty data updation</option>
                            <option value="Email sent">Email sent</option>
                            <option value="Call received">Call received</option>
                            <option value="Departmental data updation">Departmental data updation</option>
                            <option value="Faculty data validation">Faculty data validation</option>
                            <option value="Departmental data validation">Departmental data validation</option>
                            <option value="CO PO List">CO PO List</option>
                            <option value="CO PO Attainment">CO PO Attainment</option>
                            <option value="Feedback completed">Feedback completed</option>
                            <option value="LMS implemented">LMS implemented</option>
                            <option value="Online Fees">Online Fees</option>
                       
                        </select>

                            <br />

                            <button  onClick={getval}>Get details</button>
                            <br /><br /><br />




        {isUploading ? (
				<div style={{ alignItems: 'center', marginTop: 30, marginBottom: 60}}>
                   <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
				</div>
			) : (
                <div></div>
			)} 
     
<Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
        <th>Academic Year</th>
        <th>Date of Communication</th>
        <th>Department</th>
        <th>Tasks</th>
        <th>Percentage</th>
        <th>Status</th>
        <th>Comments</th>
        <th>Link</th>
      
    </tr>
    </thead>
    <tbody>
    

    {results.map((meetup3) => {
                return (
                    <tr>
                        <td>
                            {meetup3.year}
                        </td>
                        <td>
                            {timely(meetup3.date)}
                          
                        </td>
                        <td>
                            {meetup3.department}
                        </td>
                        <td>
                            {meetup3.task}
                        </td>
                        <td>
                            {meetup3.percentage}
                        </td>
                        <td>
                            {meetup3.status}
                        </td>
                        <td>
                            {meetup3.comments}
                        </td>
                        <td>
                            {meetup3.link}
                        </td>
                        
                    
                
                    
                
                
                   

                   
                                </tr>
              

                );
            })}
            </tbody>
            </Table>

            <br /><br />
            


        


            

       
        
        </div>
        <FooterNav1 />
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;