import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function SidefacNavigation() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    



    return (
        <div className={classes1.sidebar}>
            <div className={classes1.sidebarinside}>

<div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />
            
        
        {/* <div style={{marginBottom: 10}}><b>Insights</b></div> */}
        {/* <Link to='/viewnacademic'> Academic calendar</Link>
        <Link to='/viewnworkbook'> Personal workbook</Link>
        <Link to='/viewnenrollment'> Enrollment</Link>
        <Link to='/viewncourseplan'> Course plan</Link> */}

<Link to='/viewnallcourses'> All courses </Link>
{/* <Link to='/viewnstudmaster'> Student master</Link> */}
<Link to='/viewnstudmasternew'> Student master data </Link>
<Link to='/viewnfacyear'> Yearwise faculty</Link>
<Link to='/viewnlabsafety'> Lab safety</Link>
<Link to='/viewnlabequipments'> Lab equipments</Link>
<Link to='/viewnprogrambudget'> Program budget</Link>
<Link to='/viewninternet'> Internet </Link>
<Link to='/viewnemfaculty'> Emiretus faculties</Link>
<Link to='/viewnstudentpubs'> Student publications</Link>
<Link to='/viewndeppublications'> Departmental publications</Link>

<Link to='/viewnseedm'> Seed money</Link>
<Link to='/viewnplacement'> Placement </Link>
<Link to='/viewnstartup'> Startup</Link>
<Link to='/viewnacadperf'> Academic marks data</Link>


      
       

        <hr />
        {/* <div style={{marginBottom: 10}}><b>Useful links</b></div> */}
     
     

        
       </div>

</div>
    );

}

export default SidefacNavigation;