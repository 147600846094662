import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function SidefacNavigation() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    



    return (
        <div className={classes1.sidebar}>
            <div className={classes1.sidebarinside}>

<div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />
            
        
        {/* <div style={{marginBottom: 10}}><b>Insights</b></div> */}
        <Link to='/viewnallcoursesprog'> All courses by program</Link>
        <Link to='/viewfacyearprog'> Yearwise faculty</Link>
{/* <Link to='/viewstudmasterprog'> Student master list</Link> */}
<Link to='/viewstudmasternewprog'> Student master data</Link>
        <Link to='/viewnbaug33resultprog'> Result publication</Link>
        <Link to='/viewadmissionprog'> Admission data</Link>
<Link to='/viewpassexamprog'> Exam result data</Link>
{/* <Link to='/viewplacementadmin'> Placement</Link> */}
<Link to='/viewhighereduprog'> Higher education</Link>
<Link to='/viewhigherexamprog'> Higher exam</Link>
<Link to='/viewreservecatprog'> Reserved category</Link>
<Link to='/viewlabsafetyprog'> Lab safety</Link>
<Link to='/viewlabequipmentsprog'> Lab equipment</Link>
<Link to='/viewprogrambudgetprog'> Program budget</Link>
<Link to='/viewinternetprog'> Internet</Link>
<Link to='/viewemfacultyprog'> Emiretus faculty</Link>
<Link to='/viewstudentpubsprog'> Student publication</Link>
<Link to='/viewdeppublicationsprog'> Departmental publication</Link>

<Link to='/viewseedmprog'> Seed money</Link>
<Link to='/viewplacementprog'> Placement</Link>
<Link to='/viewstartupprog'> Startup</Link>
<Link to='/viewplacementindex'> Placement index</Link>
<Link to='/viewplacementindex1'> Placement index all</Link>
<Link to='/viewacadperfprog'> Academic performance data</Link>
<Link to='/viewacadapi'> Academic performance index</Link>
<Link to='/viewadmissionperfapi'> Admission performance index</Link>
<Link to='/viewsfrapi'> Student Faculty Ratio</Link>
<Link to='/viewfacultycadre'> Faculty Cadre Ratio</Link>




<br /><br /><br /><br /><br /><br />
      
       

        <hr />
        {/* <div style={{marginBottom: 10}}><b>Useful links</b></div> */}
     
     

        
       </div>

</div>
    );

}

export default SidefacNavigation;