import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';

import ep1 from '../api/ep1';
import FavoritesContext from '../store/favourites-context';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';


function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const coursecoderef=useRef();
    const courseref=useRef();
    const programref=useRef();
    const semesterref=useRef();
    const sectionref=useRef();
    const hoursref=useRef();
    const moduleref=useRef();

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
    //    firebase.initializeApp({
    //     apiKey: "AIzaSyD4AZlzFUmachQCJYr-vlb9XKJix3NMg-E",
    //     authDomain: "posto-campus.firebaseapp.com",
    //     databaseURL: "https://posto-campus.firebaseio.com",
    //     projectId: "posto-campus",
    //     storageBucket: "posto-campus.appspot.com",
    //     messagingSenderId: "1002415317254",
    //     appId: "1:1002415317254:web:f198e146e4c2a9ab86fe87",
    //     measurementId: "G-W6SP1NZPMJ"
    // });
    skipuser();

    }, []);

    async function submitHandler(event) {
        event.preventDefault();
        const coursecode=coursecoderef.current.value;
        const course=courseref.current.value;
        const program=programref.current.value;
        const semester=semesterref.current.value;
        const section=sectionref.current.value;
        const hours=hoursref.current.value;
        const module=moduleref.current.value;

        console.log(course + '-' + coursecode + '-' + program + '-' + semester + '-' + section + '-' + hours + '-' + module + '-' + name + '-' + user + '-' + colid)
        
        //alert(username + '-' + password);

        const db = firebase.firestore();
        db.collection("workload").add({
            course: course,
            program: program,
            coursecode: coursecode,
            semester: semester,
            section: section,
            hours: hours,
            name: name,
            user: user,
            colid: colid,
            module: module
        })
            .then(function (docRef) {
                console.log("Document written with ID: ", docRef.id);
                //alert("Workload created with ID: " + docRef.id);
                
            })
            .catch(function (error) {
                console.error("Error adding document: ", error);
                alert("Error adding document: ", error);
            });

            history.replace('/dashcoursef');



        // const response = await ep1.get('/api/v1/createworkload', {
        //     params: {
        //         course: course,
        //         program: program,
        //         coursecode: coursecode,
        //         semester: semester,
        //         section: section,
        //         hours: hours,
        //         name: name,
        //         user: user,
        //         colid: colid,
        //         module: module

        //     }

        // });
        //setLoading(false);
        //setResults(response.data);
        // setTerm2('');
        // console.log(response.data.status);

        // if (response.data.status == "Success") {
            
            
        //     history.replace('/workload');
        //     setTerm2('Thank you');  
        // }
        // else {
        //     setTerm2(response.data.status);
        // }

    }

    function clickcancel() {
        history.replace('/dashcoursef');
    }

    function skipuser() {
        firebase.auth().signInAnonymously().then( function() {
            const user = firebase.auth().currentUser;
            
        }).catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            alert('Error ' + errorCode + '-' + errorMessage);
            // ...
          });
    }

  


    return (
        <div style={{width: 900, margin: 'auto', marginTop: 100}}>
            <div className={classes.logindiv}>
           Add Course / Workload
            <br />
            <form className={classes.form} onSubmit={submitHandler}>
                <div className={classes.control}>
                    <label htmlFor='coursecode'>Course code</label>
                    <input type="text" required placeholder="Enter coursecode" id="coursecode" ref={coursecoderef} />
                </div>
                <div className={classes.control}>
                    <label htmlFor='course'>Course</label>
                    <input type="text" required placeholder="Enter course" id="course" ref={courseref} />
                </div>
                <div className={classes.control}>
                    <label htmlFor='program'>Program</label>
                    <input type="text" required placeholder="Enter program" id="program" ref={programref} />
                </div>
                <div class="form-group">
                <label htmlFor='hours'>Weekly hours</label>
                    <input required type="number" class="form-control" placeholder="Enter weekly hours" id="hours" ref={hoursref}  />
                </div>
                <div class="form-group">
                <label htmlFor='module'>Module</label>
                    <input required type="text" class="form-control" placeholder="Enter module" id="module" ref={moduleref}  />
                </div>
                
                <div className="form-group">
                    <label htmlFor='semester'>Semester</label>
                    <select id="semester" className="form-control"  ref={semesterref}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor='section'>Section</label>
                    <select className="form-control" id="section" ref={sectionref}>
                        <option value="A">A</option>
                        <option value="B">B</option>
                        <option value="C">C</option>
                        <option value="D">D</option>
                        <option value="E">E</option>
                        <option value="F">F</option>
                        <option value="G">G</option>
                        <option value="H">H</option>
                        <option value="I">I</option>
                    </select>
                </div>
                <div className={classes.actions}>
                    <button>Add Course / Workload</button>
                </div>
                <br />
                {term2}
            
           </form>
           </div>
        </div>
    );

}

export default Newclasses;