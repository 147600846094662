import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/Reportfive';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const oldprogramref=useRef();
    const oldprogramcoderef=useRef();
    const newprogramref=useRef();
    const newprogramcoderef=useRef();

    const academicyearref=useRef();
    const calendaryearref=useRef();
    const lmsyearref=useRef();
  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [results, setResults] = useState([]);
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    //const coursecode=global1.coursecode;
    //const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }

    const gotoacademicyear = () => {
  
        const academicyear=academicyearref.current.value;
        if(!academicyear) {
            alert('Please enter academic year');
            return;
        }
        global1.aqaryear=academicyear;
        alert('Academic year set to ' + academicyear);
        
    }

    const gotocalendaryear = () => {
  
        const calendaryear=calendaryearref.current.value;
        if(!calendaryear) {
            alert('Please enter calendar year');
            return;
        }
        global1.calendaryear=calendaryear;
        alert('Calendar year set to ' + calendaryear);
    }

   

    


   

    const changebyprogram = async () => {
        //let tutorials = [];
        //setResults([]);
        //setLoading(true);
        //setIsuploading(true);
        const oldprogram=oldprogramref.current.value;
        //const oldprogramcode=oldprogramcoderef.current.value;
        const newprogram=newprogramref.current.value;
        const newprogramcode=newprogramcoderef.current.value;
        setTerm(studid);
        const response = await ep1.get('/api/v1/usyllabusrevbyname', {
            params: {
                user: user,
                token: token,
                colid: colid,
                oldprogram: oldprogram,
                newprogram: newprogram,
                newprogramcode:newprogramcode
            }
        });
        //setLoading(false);
        //setIsuploading(false);
        console.log(response.data.data);
        alert(response.data.status);
       
       
    };

    const changebyprogramcode = async () => {
        //let tutorials = [];
        //setResults([]);
        //setLoading(true);
        //setIsuploading(true);
        const oldprogram=oldprogramref.current.value;
        const oldprogramcode=oldprogramcoderef.current.value;
        const newprogram=newprogramref.current.value;
        const newprogramcode=newprogramcoderef.current.value;
        setTerm(studid);
        const response = await ep1.get('/api/v1/usyllabusrevbynamecode', {
            params: {
                user: user,
                token: token,
                colid: colid,
                oldprogram: oldprogram,
                oldprogramcode:oldprogramcode,
                newprogram: newprogram,
                newprogramcode:newprogramcode
            }
        });
        //setLoading(false);
        //setIsuploading(false);
        console.log(response.data.data);
        alert(response.data.status);
       
       
    };


    useEffect(() => {
        //searchApi();
       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    // const searchapi = async () => {
    //     const title=titleref.current.value;
    //     const patentno=patentnoref.current.value;
    //     const yop=yopref.current.value;
    //     //alert(department);
    //     //setLoading(true);
    //     setIsuploading(true);
    //     const response = await ep1.get('/api/v1/createpatentbyfaculty', {
    //         params: {
    //             user: user,
    //             token: token,
    //             colid: colid,
    //             name: name,
    //             title: title,
    //             patentnumber:patentno,
    //             yop:yop,
    //             status1:'Submitted',
    //             comments:''

    //         }
    //     });
    //     //setLoading(false);
    //     setIsuploading(false);
    //     //console.log(response.data.data);
    //     //alert(response.data.status);
    //     history.replace('/viewpatent');
       
    // };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Change program name and program code by wrong program name</p>
                        <hr />

                        Old program name <br /><br />
                        <input type="text"  style={{width: '100%'}} placeholder="Enter old program" id="oldprogram" ref={oldprogramref} />
                            
                            <br /><br />

                            Old program code <br /><br />
                        <input type="text"  style={{width: '100%'}} placeholder="Enter old program code" id="oldprogramcode" ref={oldprogramcoderef} />
                            
                            <br /><br />

                            New program name <br /><br />
                        <input type="text"  style={{width: '100%'}} placeholder="Enter new program" id="newprogram" ref={newprogramref} />
                            
                            <br /><br />

                            New program code <br /><br />
                        <input type="text"  style={{width: '100%'}} placeholder="Enter new program code" id="newprogramcode" ref={newprogramcoderef} />
                            
                            <br /><br />

<table>
    <tr>
        <td>
        <button  onClick={changebyprogram}>Change by program name</button>
            </td>
            <td>
            <button  onClick={changebyprogramcode}>Change by program name and code</button>
                </td>
    </tr>
    </table>
                        

                        <br /><br />

                          
                        {/* <p style={{ fontSize: 18 }}> Select Calendar Year (for example, 2020)</p>
                        <hr />


                        <input type="text"  style={{width: '100%'}} placeholder="Enter calendar year" id="calendaryear" ref={calendaryearref} />
                            
                            <br /><br />

                        <button  onClick={gotocalendaryear}>Set Calendar Year</button>

                        <br /><br />

                        <p style={{ fontSize: 18 }}> Select full website url (for example, https://campus.technology)</p>
                        <hr />


                        <input type="text"  style={{width: '100%'}} placeholder="Enter website" id="website" ref={websiteref} />
                            
                            <br /><br />

                        <button  onClick={gotowebsite}>Set Website</button>

                        <br /><br />

                        <p style={{ fontSize: 18 }}> Select LMS Year</p>
                        <hr />


                        

                        <label htmlFor='year'>Academic Year</label>
                    <select id="year" className="form-control"  ref={lmsyearref}>
                    
                        <option value="2022-23">2022-23</option>
                        <option value="2021-22">2021-22</option>
                       
                    </select>
                    <br /><br />
                            
                            <br /><br />

                        <button  onClick={gotolmsyear}>Set LMS Year</button>

                        <br /><br />
                        <h4>Next steps</h4>
                        <hr />
                        <table>
                            <tr>
                                <td>
                                <button  onClick={gotodashcourse}  className="btn btn-dark">LMS Dashboard</button>
                                    </td>
                                    <td>
                                    <button  onClick={gotoadminreports}  className="btn btn-dark">Admin Dashboard</button>
                                        </td>
                            </tr>
                            </table> */}


                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;