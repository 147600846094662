import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';

import ep1 from '../api/ep1';
import FavoritesContext from '../store/favourites-context';

import TimePicker from 'react-time-picker';

import Sideicon from '../components/layout/Sideicon';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import classes1 from './newform2.module.css';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';


function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const periodtitleref=useRef();
    const periodnumberref=useRef();
    const starttimeref=useRef();
    const durationref=useRef();


    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');

    const [time1, setTime1] = useState('10:00');
    const [time2, setTime2] = useState('10:00');

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();

    }, []);

    async function submitHandler(event) {
        event.preventDefault();
        const periodtitle=periodtitleref.current.value;
        const periodnumber=periodnumberref.current.value;
        const starttime=starttimeref.current.value;
        const endtime=durationref.current.value;


        console.log(periodtitle + '-' + periodnumber + '-' + starttime + '-' + endtime);
        
        //alert(username + '-' + password);
        const response = await ep1.get('/api/v1/createperiod', {
            params: {
                periodtitle: periodtitle,
                periodnumber: periodnumber,
                starttime: starttime,
                endtime: endtime,
                name: name,
                user: user,
                colid: colid,
                module: module

            }

        });
        //setLoading(false);
        //setResults(response.data);
        // setTerm2('');
        // console.log(response.data.status);

        if (response.data.status == "Success") {
            
            // favcontxt.addFavorite({
            //     studid: user,
            //     name: name
            // },response.data.role,colid,name);
            
            
            history.replace('/workload');
            setTerm2('Thank you');  
        }
        else {
            setTerm2(response.data.status);
        }

    }

    const width=window.innerWidth;
    const mdivwidth=width - 330;

  


    return (

        <div className={classes1.fullwidth}>
        <table style={{ width: '100%'}}>
            <tr>
                <td valign="top" style={{ width:60 }}>
                    <Sideicon />
                </td>
                <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
               
                    <SidecolorNavigation />
                </td>
                
                <td> 

                <TopNavigation />


                <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}>
            <div>
           <h5>Add period</h5>
           <hr />
            <br />
            <form>
                <div>
                    <label htmlFor='periodtitle'>Period title (e.g. First Period MBA)</label>
                    <input type="text" required style={{width: '100%'}} placeholder="Enter period title" id="perioftitle" ref={periodtitleref} />
                </div>
                <div>
                    <label htmlFor='periodnumber'>Period Number (e.g. 1, 2 etc., number only)</label>
                    <input type="number" required style={{width: '100%'}} placeholder="Enter period number" id="periodnumber" ref={periodnumberref} />
                </div>
                <div>
                    <label htmlFor='starttime'>Starttime (e.g. 16:00), in the same format</label>
                    <input type="text" required style={{width: '100%'}} placeholder="Enter starttime" id="starttime" ref={starttimeref} />
                </div>
                <div>
                    <label htmlFor='duration'>Duration in minutes</label>
                    <input type="text" required style={{width: '100%'}} placeholder="Enter duration" id="duration" ref={durationref} />
                </div>
                
                
               <br /><br />
                
                <button  onClick={submitHandler}>Add period</button>
                {/* <div className={classes.actions}>
                    <button>Add new period</button>
                    
                </div> */}
                <br />
                {term2}
            
           </form>
           </div>
        </div>


        <FooterNav1 />
        </td>
                </tr>
            </table>
        </div>
    );

}

export default Newclasses;