import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import Sidecr1 from '../components/layout/Sidecr1';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const programref=useRef();
    const programcoderef=useRef();
    const courseref=useRef();
    const coursecoderef=useRef();
    const academicyearref=useRef();
    const lectureref=useRef();
    const practicalref=useRef();
    const theoryref=useRef();
    const totalref=useRef();
    const creditsref=useRef();
    const commentsref=useRef();
    

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const curstructureid=global1.curstructureid;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    // useEffect(() => {
    //    //logout();

    // }, []);

    let { id } = useParams();
    function useQuery() {
        return new URLSearchParams(useLocation().search);
      }

      let query = useQuery();

      let qno=query.get("name");
    

    useEffect(() => {
        //logout();
        
        programref.current.value=global1.program;
        programcoderef.current.value=global1.programcode;
        courseref.current.value=global1.course;
        coursecoderef.current.value=global1.coursecode;
        //academicyearref.current.value=global1.academicyear;
        lectureref.current.value=global1.lecture;
        theoryref.current.value=global1.theory;
        practicalref.current.value=global1.practical;
        totalref.current.value=global1.total;
        creditsref.current.value=global1.credits;
        commentsref.current.value=global1.comments;
        
        
        
 
     }, []);
 
    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        const program=programref.current.value;
        const programcode=programcoderef.current.value;
        const course=courseref.current.value;
        const coursecode=coursecoderef.current.value;
        //const academicyear=academicyearref.current.value;
        const lecture=lectureref.current.value;
        const theory=theoryref.current.value;
        const practical=practicalref.current.value;
        const total=totalref.current.value;
        const credits=creditsref.current.value;
        const comments=commentsref.current.value;
        

        

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updatecurstructure', {
            params: {
                id: curstructureid,
                user: user,
                token: token,
                colid: colid,
                name: name,
                department: department,
                program: program,
                programcode: programcode,
                course: course,
                coursecode: coursecode,
                lecture: lecture,
                theory: theory,
                practical: practical,
                total: total,
                credits: credits,
                status1:'Submitted',
                comments:comments

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewcurstructure?name=' + qno);
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 100;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    {/* <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <Sidecr1 />
                    </td> */}
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}>{qno} Course structure </p>
                        <hr />
                          
                        Program name <br />
                        
                        <input type="text" required style={{width: '100%'}} placeholder="Enter program name" id="program" ref={programref} />
                            
                            <br /><br /> 

                            Program code <br />

                        <input type="text" required style={{width: '100%'}} placeholder="Enter program code" id="programcode" ref={programcoderef} />
                            
                            <br /><br /> 

                          Course name <br />
                        
                        <input type="text" required style={{width: '100%'}} placeholder="Enter course name" id="coursename" ref={courseref} />
                            
                            <br /><br /> 

                            Course code <br />

                        <input type="text" required style={{width: '100%'}} placeholder="Enter course code" id="coursecode" ref={coursecoderef} />
                            
                            <br /><br /> 


                    {/* <label htmlFor='year'>Academic year</label>
                    <select id="year" className="form-control"  ref={academicyearref}>
                    <option value="2016-17">2016-17</option>
                    <option value="2017-18">2017-18</option>
                    <option value="2018-19">2018-19</option>
                    <option value="2019-20">2019-20</option>
                    <option value="2020-21">2020-21</option>
                    <option value="2021-22">2021-22</option>
                    <option value="2023-23">2022-23</option>
                       
                    </select>
                    <br /><br /> */}

                    <label htmlFor='comments'>Type of course</label>
                    <select id="comments" className="form-control"  ref={commentsref}>
                    <option value="Basic Sciences">Basic Sciences</option>
                    <option value="Engineering Sciences">Engineering Sciences</option>
                    <option value="Humanities and Social Sciences">Humanities and Social Sciences</option>
                    <option value="Program Core">Program Core</option>
                    <option value="Program Electives">Program Electives</option>
                    <option value="Open Electives">Open Electives</option>
                    <option value="Project(s)">Project(s)</option>
                    <option value="Internships/Seminars ">Internships/Seminars </option>
                    <option value="Employment Enhancement Courses/Skill Based Courses">Employment Enhancement Courses/Skill Based Courses</option>
                    <option value="Any other">Any other</option>
                       
                    </select>
                    <br /><br />

                    Lecture contact hours <br />

                    <input type="number" required style={{width: '100%'}} placeholder="Enter lecture contact hours" id="lecture" ref={lectureref} />
                            
                        <br /><br /> 

                        Theory contact hours <br />

                    <input type="number" required style={{width: '100%'}} placeholder="Enter theory contact hours" id="theory" ref={theoryref} />
                            
                        <br /><br /> 


                        Practical contact hours <br />

<input type="number" required style={{width: '100%'}} placeholder="Enter practical contact hours" id="practical" ref={practicalref} />
        
    <br /><br /> 

    Total contact hours <br />

<input type="number" required style={{width: '100%'}} placeholder="Enter total contact hours" id="total" ref={totalref} />
        
    <br /><br /> 

    Credits <br />

<input type="number" required style={{width: '100%'}} placeholder="Enter credits" id="credits" ref={creditsref} />
        
    <br /><br /> 

   


   



                      
                        <button  onClick={searchapi}>Update data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;