import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function SidefacNavigation() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    



    return (
        <div className={classes1.sidebar}>

<div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />
            
        
        {/* <div style={{marginBottom: 10}}><b>Insights</b></div> */}
        <Link to='/getmyfeedback'> My feedback</Link>
        <Link to='/parentfeedbackanalysis'> Parent feedback analysis</Link>
        <Link to='/curriculumfeedbackoption1'> Curriculum feedback</Link>
        <Link to='/employerfeedbackanalysis'> Employer feedback</Link>
        <Link to='/alumnifeedbakanalysis'> Alumni feedback</Link>
        <Link to='/viewalerts'> View alerts</Link>
        <Link to='/viewkpi'> View KPI</Link>
        <Link to='/viewprojectstatus'> Project status</Link>
        <Link to='/viewprojectstatusv'> Project status admin</Link>
        <Link to='/viewprojectstatusbytaskdep'> Project status by department and task</Link>
        <Link to='/viewprojectstatusbytask'> Project status by task</Link>
        <Link to='/viewweeklystatus'> Weekly status report</Link>
        {/* <Link to='/viewsuperweekly'> Weekly status all</Link> */}
        <Link to='/viewvacstudlist'> Value added courses attended</Link>
        <Link to='/viewntimesheetadmin'> Timesheet (Admin)</Link>

        <hr />
        {/* <div style={{marginBottom: 10}}><b>Useful links</b></div> */}
        <Link to='/dashcourse'> Dashboard</Link>
        <Link to='/workload'> Create Class</Link>
        
        <Link to='/getmyfeedback'> My feedback</Link>
        <Link to='/proctorlist'> Proctor list</Link>
        <Link to='/examenrlist'> Check exam enrollment</Link>
     

        
       

</div>
    );

}

export default SidefacNavigation;