import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/SidedocNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const startdateref=useRef();
    const enddateref=useRef();
    const noofdaysref=useRef();
  
    

    const type=global1.type;
    const final=global1.final;
    
  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [pending, setPending] = useState(0);
    const [balance, setBalance] = useState(0);
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const role=global1.role;

   
    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       getleavepending();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const getleavepending = async () => {
        //let tutorials = [];
        //setResults([]);
        //setLoading(true);
        //setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getpendingleavesumbyfac', {
            params: {
                user: user,
                token: token,
                colid: colid,
                type: type
            }
        });
        //setLoading(false);
        //setIsuploading(false);
        console.log(response.data);

        //alert('Applied ' + response.data.data.applied);
        setPending(response.data.data.applied);
        //var balance1=Number(final) - Number(pending);
        var balance1=Number(final) - Number(response.data.data.applied);
        setBalance(balance1);
       
    };

    const searchapi = async () => {
        const noofdays=noofdaysref.current.value;
        //const module=moduleref.current.value;
        const startdate=startdateref.current.value;
        const enddate=enddateref.current.value;


        if(balance < noofdays) {
            alert('You do not have enough leave balance');
            return;
        }
      

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/createleaveapply', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                noofdays:noofdays,
                startdate: startdate,
                enddate: enddate,
                type:type,
                role: role,
                level:'1',
                department: department

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewleavepending');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Apply for leave for {type}</p>
                        <hr />

                        Leave balance Available {final}  Pending for approval {pending} Net available {balance}
                        <br /><br />
                       
                            No of days (number) <br /><br />
                            <input type="number" required style={{width: '100%'}} placeholder="Enter no of days" id="noofdays" ref={noofdaysref} />
                            
                        <br /><br />

                        Start date <br /><br />
                            <input type="date" required style={{width: '100%'}} placeholder="Enter start date" id="startdate" ref={startdateref} />
                            
                        <br /><br />

                        End date <br /><br />
                            <input type="date" required style={{width: '100%'}} placeholder="Enter end date" id="enddate" ref={enddateref} />
                            
                        <br /><br />

                       



                    
                            

               
                  


                      
                        <button  onClick={searchapi}>Add data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;