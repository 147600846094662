import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/Rappn';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import { duration } from "@material-ui/core";



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const yearref=useRef();
const orgtyperef=useRef();
const orgnameref=useRef();
const presidentref=useRef();
const addressref=useRef();
const cityref=useRef();
const districtref=useRef();
const stateref=useRef();
const talukref=useRef();
const pinref=useRef();
const stdref=useRef();
const landlineref=useRef();
const faxref=useRef();
const orgemailref=useRef();
const orgmobileref=useRef();
const orgregnoref=useRef();
const orgregdateref=useRef();
const amedmentref=useRef();
const othercollegeref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const patentid=global1.addonid;


    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       

       yearref.current.value=global1.year;
orgtyperef.current.value=global1.orgtype;
orgnameref.current.value=global1.orgname;
presidentref.current.value=global1.president;
addressref.current.value=global1.address;
cityref.current.value=global1.city;
districtref.current.value=global1.district;
stateref.current.value=global1.state;
talukref.current.value=global1.taluk;
pinref.current.value=global1.pin;
stdref.current.value=global1.std;
landlineref.current.value=global1.landline;
faxref.current.value=global1.fax;
orgemailref.current.value=global1.orgemail;
orgmobileref.current.value=global1.orgmobile;
orgregnoref.current.value=global1.orgregno;
orgregdateref.current.value=global1.orgregdate;
amedmentref.current.value=global1.amedment;
othercollegeref.current.value=global1.othercollege;


    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        //const title=titleref.current.value;
        const year=yearref.current.value;
const orgtype=orgtyperef.current.value;
const orgname=orgnameref.current.value;
const president=presidentref.current.value;
const address=addressref.current.value;
const city=cityref.current.value;
const district=districtref.current.value;
const state=stateref.current.value;
const taluk=talukref.current.value;
const pin=pinref.current.value;
const std=stdref.current.value;
const landline=landlineref.current.value;
const fax=faxref.current.value;
const orgemail=orgemailref.current.value;
const orgmobile=orgmobileref.current.value;
const orgregno=orgregnoref.current.value;
const orgregdate=orgregdateref.current.value;
const amedment=amedmentref.current.value;
const othercollege=othercollegeref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updatertrustbyfac', {
            params: {
                id: patentid,
                user: user,
                token: token,
                name: name,
                colid: colid,
                year:year,
orgtype:orgtype,
orgname:orgname,
president:president,
address:address,
city:city,
district:district,
state:state,
taluk:taluk,
pin:pin,
std:std,
landline:landline,
fax:fax,
orgemail:orgemail,
orgmobile:orgmobile,
orgregno:orgregno,
orgregdate:orgregdate,
amedment:amedment,
othercollege:othercollege,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewnrtrust');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Edit data</p>
                        <hr />
                       

                   Enter year<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter year" id="year" ref={yearref} />
<br /><br />

<label htmlFor="orgtype">orgtype</label>

<select id="orgtype" className="form-control"  ref={orgtyperef}>
<option value="Government/Autonomous">Government/Autonomous</option>
<option value="University">University</option>
<option value="Private">Private</option>
<option value="Trust/Society">Trust/Society</option>
<option value="Defense">Defense</option>
<option value="Missionary">Missionary</option>
<option value="Company">Company</option>
<option value="NGO">NGO</option>
<option value="Voluntary">Voluntary</option>
<option value="Municipal">Municipal</option>
<option value="Others">Others</option>
</select>
<br /><br />

Enter orgname<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter orgname" id="orgname" ref={orgnameref} />
<br /><br />

Enter president<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter president" id="president" ref={presidentref} />
<br /><br />

Enter address<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter address" id="address" ref={addressref} />
<br /><br />

Enter city<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter city" id="city" ref={cityref} />
<br /><br />

Enter district<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter district" id="district" ref={districtref} />
<br /><br />

Enter state<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter state" id="state" ref={stateref} />
<br /><br />

Enter taluk<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter taluk" id="taluk" ref={talukref} />
<br /><br />

Enter pin<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter pin" id="pin" ref={pinref} />
<br /><br />

Enter std<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter std" id="std" ref={stdref} />
<br /><br />

Enter landline<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter landline" id="landline" ref={landlineref} />
<br /><br />

Enter fax<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter fax" id="fax" ref={faxref} />
<br /><br />

Enter orgemail<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter orgemail" id="orgemail" ref={orgemailref} />
<br /><br />

Enter orgmobile<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter orgmobile" id="orgmobile" ref={orgmobileref} />
<br /><br />

Enter orgregno<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter orgregno" id="orgregno" ref={orgregnoref} />
<br /><br />

Enter orgregdate<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter orgregdate" id="orgregdate" ref={orgregdateref} />
<br /><br />

<label htmlFor="amedment">amedment</label>

<select id="amedment" className="form-control"  ref={amedmentref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />

Enter othercollege<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter othercollege" id="othercollege" ref={othercollegeref} />
<br /><br />


                  


                      
                        <button  onClick={searchapi}>Update data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;