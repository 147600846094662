import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/An';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);



    const degreeref=useRef();
const courseref=useRef();
const totsancref=useRef();
const typeref=useRef();
const reqref=useRef();
const availref=useRef();
const deficiencyref=useRef();
const cdeficientref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
       

        const degree=degreeref.current.value;
const course=courseref.current.value;
const totsanc=totsancref.current.value;
const type=typeref.current.value;
const req=reqref.current.value;
const avail=availref.current.value;
const deficiency=deficiencyref.current.value;
const cdeficient=cdeficientref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/createpauconfacmemtechbyfac', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                degree:degree,
course:course,
totsanc:totsanc,
type:type,
req:req,
avail:avail,
deficiency:deficiency,
cdeficient:cdeficient,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewnpauconfacmemtech');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Add data</p>
                        <hr />
                       
                            
                  <label htmlFor="degree">Degree</label>

<select id="degree" className="form-control"  ref={degreeref}>
<option value="A.M.I.E.in Electrical Engineering">A.M.I.E.in Electrical Engineering</option>
<option value="B.A.in English">B.A.in English</option>
<option value="B.Arch.in Architecture">B.Arch.in Architecture</option>
<option value="B.B.A.in Business Administration">B.B.A.in Business Administration</option>
<option value="B.C.A.in Computer Applications">B.C.A.in Computer Applications</option>
<option value="B.Com.in Commerce">B.Com.in Commerce</option>
<option value="B.E.in Aeronautical Engineering">B.E.in Aeronautical Engineering</option>
<option value="B.E.in Aerospace Engineering">B.E.in Aerospace Engineering</option>
<option value="B.E.in Automobile Engineering">B.E.in Automobile Engineering</option>
<option value="B.E.in Automobile Engineering (SS)">B.E.in Automobile Engineering (SS)</option>
<option value="B.E.in BioMedical Engineering ">B.E.in BioMedical Engineering </option>
<option value="B.E.in Biomedical Engineering (SS)">B.E.in Biomedical Engineering (SS)</option>
<option value="B.E.in Civil Engineering ">B.E.in Civil Engineering </option>
<option value="B.E.in Civil Engineering (SS)">B.E.in Civil Engineering (SS)</option>
<option value="B.E.in Civil Engineering and Planning">B.E.in Civil Engineering and Planning</option>
<option value="B.E.in Computer and Communication Engineering">B.E.in Computer and Communication Engineering</option>
<option value="B.E.in Computer Science and Design">B.E.in Computer Science and Design</option>
<option value="B.E.in Computer Science and Engineering">B.E.in Computer Science and Engineering</option>
<option value="B.E.in Computer Science and Engineering (Artificial Intelligence and Machine Learning)">B.E.in Computer Science and Engineering (Artificial Intelligence and Machine Learning)</option>
<option value="B.E.in Computer Science and Engineering (Cyber Security)">B.E.in Computer Science and Engineering (Cyber Security)</option>
<option value="B.E.in Computer Science and Engineering (Internet of Things)">B.E.in Computer Science and Engineering (Internet of Things)</option>
<option value="B.E.in Computer Science and Engineering (SS)">B.E.in Computer Science and Engineering (SS)</option>
<option value="B.E.in Computer Science and Engineering (Tamil Medium)">B.E.in Computer Science and Engineering (Tamil Medium)</option>
<option value="B.E.in Computer Science and Engineering(Internet of Things and Cyber Security Including Block Chain Technology)">B.E.in Computer Science and Engineering(Internet of Things and Cyber Security Including Block Chain Technology)</option>
<option value="B.E.in Computer Science and Medical Engineering">B.E.in Computer Science and Medical Engineering</option>
<option value="B.E.in Computer Science and Technology">B.E.in Computer Science and Technology</option>
<option value="B.E.in Cyber Security">B.E.in Cyber Security</option>
<option value="B.E.in Electrical and Electronics Engineering">B.E.in Electrical and Electronics Engineering</option>
<option value="B.E.in Electrical and Electronics Engineering (SS)(SW)">B.E.in Electrical and Electronics Engineering (SS)(SW)</option>
<option value="B.E.in Electronics and Communication Engineering">B.E.in Electronics and Communication Engineering</option>
<option value="B.E.in Electronics and Communication Engineering (SS)">B.E.in Electronics and Communication Engineering (SS)</option>
<option value="B.E.in Electronics and Instrumentation Engineering">B.E.in Electronics and Instrumentation Engineering</option>
<option value="B.E.in Electronics and Telecommunications Engineering">B.E.in Electronics and Telecommunications Engineering</option>
<option value="B.E.in Environmental Engineering">B.E.in Environmental Engineering</option>
<option value="B.E.in General Engineering">B.E.in General Engineering</option>
<option value="B.E.in Geo Informatics Engineering">B.E.in Geo Informatics Engineering</option>
<option value="B.E.in Industrial Engineering">B.E.in Industrial Engineering</option>
<option value="B.E.in Industrial Engineering and Management">B.E.in Industrial Engineering and Management</option>
<option value="B.E.in Information Science and Engineering">B.E.in Information Science and Engineering</option>
<option value="B.E.in Instrumentation and Control Engineering">B.E.in Instrumentation and Control Engineering</option>
<option value="B.E.in Instrumentation and Control Engineering (SS)">B.E.in Instrumentation and Control Engineering (SS)</option>
<option value="B.E.in Manufacturing Engineering">B.E.in Manufacturing Engineering</option>
<option value="B.E.in Marine Engineering">B.E.in Marine Engineering</option>
<option value="B.E.in Material Science and Engineering ">B.E.in Material Science and Engineering </option>
<option value="B.E.in Mechanical and Automation Engineering">B.E.in Mechanical and Automation Engineering</option>
<option value="B.E.in Mechanical and Mechatronics Engineering(Additive Manufacturing)">B.E.in Mechanical and Mechatronics Engineering(Additive Manufacturing)</option>
<option value="B.E.in Mechanical and Smart Manufacturing">B.E.in Mechanical and Smart Manufacturing</option>
<option value="B.E.in Mechanical Engineering">B.E.in Mechanical Engineering</option>
<option value="B.E.in Mechanical Engineering (Sandwich)">B.E.in Mechanical Engineering (Sandwich)</option>
<option value="B.E.in Mechanical Engineering (SS)">B.E.in Mechanical Engineering (SS)</option>
<option value="B.E.in Mechanical Engineering (SS)(SW)">B.E.in Mechanical Engineering (SS)(SW)</option>
<option value="B.E.in Mechanical Engineering (Tamil Medium)">B.E.in Mechanical Engineering (Tamil Medium)</option>
<option value="B.E.in Mechatronics Engineering">B.E.in Mechatronics Engineering</option>
<option value="B.E.in Mechatronics Engineering">B.E.in Mechatronics Engineering</option>
<option value="B.E.in Medical Electronics">B.E.in Medical Electronics</option>
<option value="B.E.in Medical Electronics Engineering">B.E.in Medical Electronics Engineering</option>
<option value="B.E.in Metallurgical Engineering">B.E.in Metallurgical Engineering</option>
<option value="B.E.in Metallurgical Engineering (SS)">B.E.in Metallurgical Engineering (SS)</option>
<option value="B.E.in Production Engineering">B.E.in Production Engineering</option>
<option value="B.E.in Production Engineering (SS)">B.E.in Production Engineering (SS)</option>
<option value="B.E.in Production Engineering (SS)(SW)">B.E.in Production Engineering (SS)(SW)</option>
<option value="B.E.in Robotics and Automation">B.E.in Robotics and Automation</option>
<option value="B.E.in Safety and Fire Engineering">B.E.in Safety and Fire Engineering</option>
<option value="B.Sc.in Applied Science">B.Sc.in Applied Science</option>
<option value="B.Sc.in Computer Systems and Design">B.Sc.in Computer Systems and Design</option>
<option value="B.Sc.in Information Systems">B.Sc.in Information Systems</option>
<option value="B.Sc.in Software Systems">B.Sc.in Software Systems</option>
<option value="B.Sc.in Textiles (Business Analytics)">B.Sc.in Textiles (Business Analytics)</option>
<option value="B.Sc.in Textiles (Technical Textiles)">B.Sc.in Textiles (Technical Textiles)</option>
<option value="B.Sc.in Textiles and Apparel">B.Sc.in Textiles and Apparel</option>
<option value="B.Tech.in Agricultural Engineering">B.Tech.in Agricultural Engineering</option>
<option value="B.Tech.in Artificial Intelligence and Data Science">B.Tech.in Artificial Intelligence and Data Science</option>
<option value="B.Tech.in Artificial Intelligence and Machine Learning">B.Tech.in Artificial Intelligence and Machine Learning</option>
<option value="B.Tech.in Bio Technology (SS)">B.Tech.in Bio Technology (SS)</option>
<option value="B.Tech.in BioTechnology">B.Tech.in BioTechnology</option>
<option value="B.Tech.in Biotechnology and Biochemical Engineering">B.Tech.in Biotechnology and Biochemical Engineering</option>
<option value="B.Tech.in Chemical and Electrochemical Engineering">B.Tech.in Chemical and Electrochemical Engineering</option>
<option value="B.Tech.in Chemical Engineering ">B.Tech.in Chemical Engineering </option>
<option value="B.Tech.in Computer Science and Business Systems">B.Tech.in Computer Science and Business Systems</option>
<option value="B.Tech.in Computer Technology">B.Tech.in Computer Technology</option>
<option value="B.Tech.in Fashion Technology">B.Tech.in Fashion Technology</option>
<option value="B.Tech.in Fashion Technology (SS)">B.Tech.in Fashion Technology (SS)</option>
<option value="B.Tech.in Food Technology">B.Tech.in Food Technology</option>
<option value="B.Tech.in Handloom and Textile Technology">B.Tech.in Handloom and Textile Technology</option>
<option value="B.Tech.in Industrial Bio Technology">B.Tech.in Industrial Bio Technology</option>
<option value="B.Tech.in Information Technology">B.Tech.in Information Technology</option>
<option value="B.Tech.in Information Technology (SS)">B.Tech.in Information Technology (SS)</option>
<option value="B.Tech.in Medical Lab Technology">B.Tech.in Medical Lab Technology</option>
<option value="B.Tech.in Nano Science and Technology">B.Tech.in Nano Science and Technology</option>
<option value="B.Tech.in Petrochemical Technology">B.Tech.in Petrochemical Technology</option>
<option value="B.Tech.in Petrochemical Technology">B.Tech.in Petrochemical Technology</option>
<option value="B.Tech.in Petroleum Engineering ">B.Tech.in Petroleum Engineering </option>
<option value="B.Tech.in Pharmaceutical Technology">B.Tech.in Pharmaceutical Technology</option>
<option value="B.Tech.in Plastic Technology">B.Tech.in Plastic Technology</option>
<option value="B.Tech.in Polymer Technology">B.Tech.in Polymer Technology</option>
<option value="B.Tech.in Textile Chemistry">B.Tech.in Textile Chemistry</option>
<option value="B.Tech.in Textile Technology">B.Tech.in Textile Technology</option>
<option value="B.Tech.in Textile Technology (Part Time)">B.Tech.in Textile Technology (Part Time)</option>
<option value="B.Tech.in Textile Technology (SS)">B.Tech.in Textile Technology (SS)</option>
<option value="D.M.I.Tin Electronics Engineering">D.M.I.Tin Electronics Engineering</option>
<option value="M.Arch.in Architecture">M.Arch.in Architecture</option>
<option value="M.Arch.in Conservation">M.Arch.in Conservation</option>
<option value="M.Arch.in Digital Architecture">M.Arch.in Digital Architecture</option>
<option value="M.Arch.in Environmental Architecture">M.Arch.in Environmental Architecture</option>
<option value="M.Arch.in Real Estate Development ">M.Arch.in Real Estate Development </option>
<option value="M.Arch.in Urban Design">M.Arch.in Urban Design</option>
<option value="M.B.A.in Apparel Management">M.B.A.in Apparel Management</option>
<option value="M.B.A.in Human Resource Development and Management">M.B.A.in Human Resource Development and Management</option>
<option value="M.B.A.in Master of Business Administration">M.B.A.in Master of Business Administration</option>
<option value="M.B.A.in Master of Business Administration (Part Time)">M.B.A.in Master of Business Administration (Part Time)</option>
<option value="M.B.A.in Master of Business Administration(Integrated)">M.B.A.in Master of Business Administration(Integrated)</option>
<option value="M.B.A.in MBA(Innovation Entrepreneurship and Venture Development)">M.B.A.in MBA(Innovation Entrepreneurship and Venture Development)</option>
<option value="M.B.A.in MBA(Waste Management and Social Entrepreneurship)">M.B.A.in MBA(Waste Management and Social Entrepreneurship)</option>
<option value="M.B.A.in Project Management">M.B.A.in Project Management</option>
<option value="M.B.A.in Retail Management">M.B.A.in Retail Management</option>
<option value="M.B.A.in Textile Management">M.B.A.in Textile Management</option>
<option value="M.C.A.in Master of Computer Applications">M.C.A.in Master of Computer Applications</option>
<option value="M.C.A.in Master of Computer Applications(Stand Alone)">M.C.A.in Master of Computer Applications(Stand Alone)</option>
<option value="M.E.in Advanced Manufacturing Technology">M.E.in Advanced Manufacturing Technology</option>
<option value="M.E.in Aeronautical Engineering">M.E.in Aeronautical Engineering</option>
<option value="M.E.in Applied Electronics">M.E.in Applied Electronics</option>
<option value="M.E.in Applied Electronics (Part Time)">M.E.in Applied Electronics (Part Time)</option>
<option value="M.E.in Artificial Intelligence">M.E.in Artificial Intelligence</option>
<option value="M.E.in Automobile Engineering">M.E.in Automobile Engineering</option>
<option value="M.E.in Automotive Engineering">M.E.in Automotive Engineering</option>
<option value="M.E.in Avionics">M.E.in Avionics</option>
<option value="M.E.in Big Data Analytics">M.E.in Big Data Analytics</option>
<option value="M.E.in Biomedical Engineering">M.E.in Biomedical Engineering</option>
<option value="M.E.in Biometrics and Cyber Security">M.E.in Biometrics and Cyber Security</option>
<option value="M.E.in CAD/CAM">M.E.in CAD/CAM</option>
<option value="M.E.in Communication and Networking">M.E.in Communication and Networking</option>
<option value="M.E.in Communication Engineering">M.E.in Communication Engineering</option>
<option value="M.E.in Communication Systems">M.E.in Communication Systems</option>
<option value="M.E.in Computer Aided Design">M.E.in Computer Aided Design</option>
<option value="M.E.in Computer Aided Structural Engineering">M.E.in Computer Aided Structural Engineering</option>
<option value="M.E.in Computer Integrated Manufacturing">M.E.in Computer Integrated Manufacturing</option>
<option value="M.E.in Computer Science and Engineering">M.E.in Computer Science and Engineering</option>
<option value="M.E.in Computer Science and Engineering (Artificial Intelligence and Machine Learning)">M.E.in Computer Science and Engineering (Artificial Intelligence and Machine Learning)</option>
<option value="M.E.in Computer Science and Engineering (Cyber Security)">M.E.in Computer Science and Engineering (Cyber Security)</option>
<option value="M.E.in Computer Science and Engineering(with Specialization in Networks)">M.E.in Computer Science and Engineering(with Specialization in Networks)</option>
<option value="M.E.in Computer Science and Information Security">M.E.in Computer Science and Information Security</option>
<option value="M.E.in Construction Engineering and Management ">M.E.in Construction Engineering and Management </option>
<option value="M.E.in Construction Management">M.E.in Construction Management</option>
<option value="M.E.in Control and Instrumentation Engineering">M.E.in Control and Instrumentation Engineering</option>
<option value="M.E.in Control Systems">M.E.in Control Systems</option>
<option value="M.E.in Cryogenic Engineering">M.E.in Cryogenic Engineering</option>
<option value="M.E.in Digital Signal Processing">M.E.in Digital Signal Processing</option>
<option value="M.E.in ELearning Technologies">M.E.in ELearning Technologies</option>
<option value="M.E.in Electrical Drives and Embedded Control">M.E.in Electrical Drives and Embedded Control</option>
<option value="M.E.in Electrical Machines (Part Time)">M.E.in Electrical Machines (Part Time)</option>
<option value="M.E.in Electronics and Communication Engineering">M.E.in Electronics and Communication Engineering</option>
<option value="M.E.in Electronics and Communication Engineering (Industry Integrated)">M.E.in Electronics and Communication Engineering (Industry Integrated)</option>
<option value="M.E.in Embedded and Real Time Systems">M.E.in Embedded and Real Time Systems</option>
<option value="M.E.in Embedded System Technologies">M.E.in Embedded System Technologies</option>
<option value="M.E.in Embedded Systems">M.E.in Embedded Systems</option>
<option value="M.E.in Energy Engineering">M.E.in Energy Engineering</option>
<option value="M.E.in Engineering Design">M.E.in Engineering Design</option>
<option value="M.E.in Environmental Engineering">M.E.in Environmental Engineering</option>
<option value="M.E.in Environmental Engineering and Management">M.E.in Environmental Engineering and Management</option>
<option value="M.E.in Geo Technical Engineering">M.E.in Geo Technical Engineering</option>
<option value="M.E.in Heat Power Engineering">M.E.in Heat Power Engineering</option>
<option value="M.E.in High Voltage Engineering">M.E.in High Voltage Engineering</option>
<option value="M.E.in Industrial Automation and Robotics">M.E.in Industrial Automation and Robotics</option>
<option value="M.E.in Industrial Engineering">M.E.in Industrial Engineering</option>
<option value="M.E.in Industrial Engineering (Part Time)">M.E.in Industrial Engineering (Part Time)</option>
<option value="M.E.in Industrial Metallurgy">M.E.in Industrial Metallurgy</option>
<option value="M.E.in Industrial Metallurgy (Part Time)">M.E.in Industrial Metallurgy (Part Time)</option>
<option value="M.E.in Industrial Safety Engineering">M.E.in Industrial Safety Engineering</option>
<option value="M.E.in Infrastructure Engineering">M.E.in Infrastructure Engineering</option>
<option value="M.E.in Infrastructure Engineering and Management">M.E.in Infrastructure Engineering and Management</option>
<option value="M.E.in Instrumentation Engineering">M.E.in Instrumentation Engineering</option>
<option value="M.E.in Internal Combustion Engineering ">M.E.in Internal Combustion Engineering </option>
<option value="M.E.in Lean Manufacturing">M.E.in Lean Manufacturing</option>
<option value="M.E.in Manufacturing Engineering">M.E.in Manufacturing Engineering</option>
<option value="M.E.in Manufacturing Engineering (Part Time)">M.E.in Manufacturing Engineering (Part Time)</option>
<option value="M.E.in Mechatronics">M.E.in Mechatronics</option>
<option value="M.E.in Mechatronics Engineering">M.E.in Mechatronics Engineering</option>
<option value="M.E.in Medical Electronics">M.E.in Medical Electronics</option>
<option value="M.E.in Mobile and Pervasive Computing">M.E.in Mobile and Pervasive Computing</option>
<option value="M.E.in Multimedia Technology">M.E.in Multimedia Technology</option>
<option value="M.E.in Optical Communication">M.E.in Optical Communication</option>
<option value="M.E.in Power Electronics and Drives">M.E.in Power Electronics and Drives</option>
<option value="M.E.in Power Systems and Renewable Energy">M.E.in Power Systems and Renewable Energy</option>
<option value="M.E.in Power Systems Engineering">M.E.in Power Systems Engineering</option>
<option value="M.E.in Product Design and Commerce">M.E.in Product Design and Commerce</option>
<option value="M.E.in Product Design and Development ">M.E.in Product Design and Development </option>
<option value="M.E.in Production Engineering">M.E.in Production Engineering</option>
<option value="M.E.in Software Engineering">M.E.in Software Engineering</option>
<option value="M.E.in Soil Mechanics and Foundation Engineering">M.E.in Soil Mechanics and Foundation Engineering</option>
<option value="M.E.in Structural Engineering">M.E.in Structural Engineering</option>
<option value="M.E.in Structural Engineering (Part Time)">M.E.in Structural Engineering (Part Time)</option>
<option value="M.E.in Thermal Engineering">M.E.in Thermal Engineering</option>
<option value="M.E.in Virtual Prototyping and Digital Manufacturing">M.E.in Virtual Prototyping and Digital Manufacturing</option>
<option value="M.E.in VLSI Design">M.E.in VLSI Design</option>
<option value="M.E.in Welding Technology">M.E.in Welding Technology</option>
<option value="M.E.in Wireless and Mobile Communications">M.E.in Wireless and Mobile Communications</option>
<option value="M.E.in Wireless Communication">M.E.in Wireless Communication</option>
<option value="M.E.in Wireless Technologies">M.E.in Wireless Technologies</option>
<option value="M.S.in Computer Science Engineering">M.S.in Computer Science Engineering</option>
<option value="M.Sc.(Engg)in Mechanical Engineering">M.Sc.(Engg)in Mechanical Engineering</option>
<option value="M.Sc.in Applied Chemistry">M.Sc.in Applied Chemistry</option>
<option value="M.Sc.in Applied Mathematics">M.Sc.in Applied Mathematics</option>
<option value="M.Sc.in Artificial Intelligence and Machine Learning (Integrated)">M.Sc.in Artificial Intelligence and Machine Learning (Integrated)</option>
<option value="M.Sc.in Cyber Security (5 year Integrated)">M.Sc.in Cyber Security (5 year Integrated)</option>
<option value="M.Sc.in Data Science (5 year Integrated)">M.Sc.in Data Science (5 year Integrated)</option>
<option value="M.Sc.in Data Science (5 Years) (SS)">M.Sc.in Data Science (5 Years) (SS)</option>
<option value="M.Sc.in Decision and Computing Sciences (Integrated)">M.Sc.in Decision and Computing Sciences (Integrated)</option>
<option value="M.Sc.in Fashion Design and Merchandising (5 Years) (SS)">M.Sc.in Fashion Design and Merchandising (5 Years) (SS)</option>
<option value="M.Sc.in Medical Physics">M.Sc.in Medical Physics</option>
<option value="M.Sc.in Software Systems (5 Years)">M.Sc.in Software Systems (5 Years)</option>
<option value="M.Sc.in Software Systems (5 Years) (SS)">M.Sc.in Software Systems (5 Years) (SS)</option>
<option value="M.Sc.in Theoretical Computer Science (5 Years) (SS)">M.Sc.in Theoretical Computer Science (5 Years) (SS)</option>
<option value="M.Tech.in Apparel Technology">M.Tech.in Apparel Technology</option>
<option value="M.Tech.in BioTechnology ">M.Tech.in BioTechnology </option>
<option value="M.Tech.in Chemical Engineering">M.Tech.in Chemical Engineering</option>
<option value="M.Tech.in Computer Science and Engineering (5 year Integrated)">M.Tech.in Computer Science and Engineering (5 year Integrated)</option>
<option value="M.Tech.in Cyber Forensics and Information Security">M.Tech.in Cyber Forensics and Information Security</option>
<option value="M.Tech.in Data Science">M.Tech.in Data Science</option>
<option value="M.Tech.in Defence Technology">M.Tech.in Defence Technology</option>
<option value="M.Tech.in Environmental Science and Technology">M.Tech.in Environmental Science and Technology</option>
<option value="M.Tech.in Food Technology">M.Tech.in Food Technology</option>
<option value="M.Tech.in Information Technology">M.Tech.in Information Technology</option>
<option value="M.Tech.in Information Technology (Full Stack Engineering)">M.Tech.in Information Technology (Full Stack Engineering)</option>
<option value="M.Tech.in Information Technology(Information and Cyber Warfare)">M.Tech.in Information Technology(Information and Cyber Warfare)</option>
<option value="M.Tech.in Masters of Technology Management">M.Tech.in Masters of Technology Management</option>
<option value="M.Tech.in Nano Science and Technology">M.Tech.in Nano Science and Technology</option>
<option value="M.Tech.in Plastic Technology">M.Tech.in Plastic Technology</option>
<option value="M.Tech.in Polymer Science and Engineering">M.Tech.in Polymer Science and Engineering</option>
<option value="M.Tech.in Process Dyanmics and Control">M.Tech.in Process Dyanmics and Control</option>
<option value="M.Tech.in Remote Sensing and GIS">M.Tech.in Remote Sensing and GIS</option>
<option value="M.Tech.in Textile Technology">M.Tech.in Textile Technology</option>
<option value="M.Tech.in Textile Technology (Part Time)">M.Tech.in Textile Technology (Part Time)</option>
<option value="M.Tech.in Textile Technology(with specialization in Textile Chemistry)">M.Tech.in Textile Technology(with specialization in Textile Chemistry)</option>
<option value="NET in NET">NET in NET</option>
<option value="OTHERS in Others">OTHERS in Others</option>
<option value="Ph.D.in Faculty of Architecture and Planning">Ph.D.in Faculty of Architecture and Planning</option>
<option value="Ph.D.in Faculty of Civil Engineering">Ph.D.in Faculty of Civil Engineering</option>
<option value="Ph.D.in Faculty of Electrical Engineering">Ph.D.in Faculty of Electrical Engineering</option>
<option value="Ph.D.in Faculty of Information and Communication Engineering">Ph.D.in Faculty of Information and Communication Engineering</option>
<option value="Ph.D.in Faculty of Management">Ph.D.in Faculty of Management</option>
<option value="Ph.D.in Faculty of Mechanical Engineering">Ph.D.in Faculty of Mechanical Engineering</option>
<option value="Ph.D.in Faculty of Science and Humanities">Ph.D.in Faculty of Science and Humanities</option>
<option value="Ph.D.in Faculty of Technology">Ph.D.in Faculty of Technology</option>
<option value="S&H in Chemistry">S&H in Chemistry</option>
<option value="S&H in English">S&H in English</option>
<option value="S&H in Mathematics">S&H in Mathematics</option>
<option value="S&H in Physics">S&H in Physics</option>
<option value="SLET in SLET">SLET in SLET</option>
</select>
<br /><br />

Enter Course(s)<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Course(s)" id="course" ref={courseref} />
<br /><br />

Enter Total Sanctioned Strength(s)<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Total Sanctioned Strength(s)" id="totsanc" ref={totsancref} />
<br /><br />

<label htmlFor="type">Type</label>

<select id="type" className="form-control"  ref={typeref}>
<option value="Assistant Professor">Assistant Professor</option>
<option value="Associate Professor">Associate Professor</option>
<option value="Others">Others</option>
<option value="Principal">Principal</option>
<option value="Professor">Professor</option>
</select>
<br /><br />

Enter Required faculty for ME/M.Tech<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Required faculty for ME/M.Tech" id="req" ref={reqref} />
<br /><br />

Enter Available faculty for ME/M.Tech<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Available faculty for ME/M.Tech" id="avail" ref={availref} />
<br /><br />

Enter Deficiency%<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Deficiency%" id="deficiency" ref={deficiencyref} />
<br /><br />

Enter Cadre Deficiency<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Cadre Deficiency" id="cdeficient" ref={cdeficientref} />
<br /><br />




                      
                        <button  onClick={searchapi}>Add data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;