import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';


// import classes from './Allclasses.module.css';

import classes1 from './newform1.module.css';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const linkref=useRef();
    const moduleref=useRef();
    const topicref=useRef();

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState();
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;
    //const name=global1.name;

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    const addnewstudent = () => {
        history.replace('/enrollstudents');
    }

    const addperiod = () => {
        history.replace('/addperiod');
    }

    const addstudents = () => {
        // global1.courseid=courseid;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/addstudents');
    }

    

   


    const searchApi = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getclassstudlist', {
            params: {
                user: user,
                token: token,
                colid: colid,
                coursecode: coursecode1

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setResults(response.data.data.classes);
       
        //alert(results.length);
       
    };

   

    const deleteenrolment = async (enrolmentid) => {
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteclassenr', {
            params: {
                id: enrolmentid,
                token: token
            }

        });
        setLoading(false);
        searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    

    useEffect(() => {
        checklogin();
        //search1();
        //getexamlist();
        searchApi('');
        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);

    


    
   

    

    const width=window.innerWidth;
    const mdivwidth=width - 330;

    
    const timely = (date1) => {
        var dt1=new Date(date1);
        dt1.setHours(dt1.getHours() - 5);
        dt1.setMinutes(dt1.getMinutes() - 30);
        var hours=dt1.getHours();
        var minutes=dt1.getMinutes();
        var month=dt1.getMonth() + 1;
        if(minutes.length<2) {
            minutes='0' + minutes;
        }
        var t1;
        if(hours>11) {
            t1='PM';
        } else {
            t1='AM';
        }
        if(hours>12) {
            hours=hours-12;
        }
        var dt2=dt1.getDate() + '/' + month + '/' + dt1.getFullYear() + ' ' + hours + ':' + minutes + t1;
        return dt2;

    }


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
    	<div className={classes1.sidebar}>
            
        <div style={{marginBottom: 10}}><b>Teach</b></div>
        <Link to='/dashcourse'> <FontAwesomeIcon icon={faHome} style={{fontSize: 14, color: '#000', marginRight: 10 }} />Dashboard</Link>
        <Link to='/courseactions'> <FontAwesomeIcon icon={faTachometerAlt} style={{fontSize: 14, color: '#000', marginRight: 10 }} />Course Dashboard</Link>
        <Link to='/classstudents' className={classes1.a1}> <FontAwesomeIcon icon={faUser} style={{fontSize: 14, color: '#000', marginRight: 10 }} />Student List</Link>
        
        <hr />
        <div style={{marginBottom: 10}}><b>Insights</b></div>
        <Link to='/getmyfeedback'> <FontAwesomeIcon icon={faComment} style={{fontSize: 14, color: '#000', marginRight: 10 }} />My feedback</Link>
        {/* <Link to='/workload'> <FontAwesomeIcon icon={faBook} style={{fontSize: 14, color: '#000', marginRight: 10 }} />Create Class</Link>
        
        <hr />
        <div style={{marginBottom: 10}}><b>Manage</b></div>
        <Link to='/dashmentor'> <FontAwesomeIcon icon={faHandsHelping} style={{fontSize: 14, color: '#000', marginRight: 10 }} />Mentor</Link>
        <Link to='/discussionboard'> <FontAwesomeIcon icon={faEnvelope} style={{fontSize: 14, color: '#000', marginRight: 10 }} />Discussion Board</Link>
        
        <hr />
        <div style={{marginBottom: 10}}><b>Help and Support</b></div>
        <Link to='/tickets'> <FontAwesomeIcon icon={faComment} style={{fontSize: 14, color: '#000', marginRight: 10 }} />Tickets</Link>
        <Link to='/faq'> <FontAwesomeIcon icon={faQuestionCircle} style={{fontSize: 14, color: '#000', marginRight: 10 }} />Knowledge Base</Link>
        
   */}

</div>
                    </td>
                    <td width="20"></td>
                    <td> 

                    
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
            <p className={classes1.heading1}>Add students for {coursename} {coursecode1}</p>
            <hr />
           
    
        <br />
        

        

        <div className="container">
                <div className="row">
                <div className="col-4">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                          onClick={() => addnewstudent()}
                         >
                             <br />
                             <FontAwesomeIcon icon={faPlusSquare} style={{fontSize: 30, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                             <p style={{ fontSize: 18}}>Add Students</p>

                        </div>
                </div>
                {results.map((meetup3) => {
                return (
                    <div className="col-4">
                         <div className={classes1.card}>
                         {meetup3.student} <i>Reg no {meetup3.regno} </i>
                <br /><br />
                    
                {meetup3.userdetails.map((meetup31) => {
                return (
                    <div>
                        <img src={meetup31.photo} style={{width: 60, height: 60, borderRadius: 30}} />
                        <br />
                        {meetup31.email} Section {meetup31.section} Semester {meetup31.semester} Institution code {meetup31.colid}
                        
                        </div>

                    );
                })}
                <br /><br />

                
                   

                    <div className={classes1.actions}>
                    
                    
                                    <button type="button" onClick={() => deleteenrolment(meetup3._id)}>
                                    <FontAwesomeIcon icon={faTrash} style={{fontSize: 18, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                                        Remove Student</button>
                                </div>
                    </div>
                    </div>

                );
            })}
            </div></div>

            

       
        
        </div>
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;